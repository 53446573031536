<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li class="active">
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
         
        </ul>
      </div>
    </div>

    <!-- End User Sidebar -->

    <!--Page Title-->

    <!--End Page Title-->

    <!-- Listing Section -->
    <section style="background: none" class="page-title style-two text-left">
      <div class="auto-container">
        <div class="title-outer">
          <h1 class="text-left">Add Employer Type</h1>
          <!-- <ul class="page-breadcrumb">
            <li><a href="index.html">Dashboard</a></li>
            <li>Add Employer Type</li>
          </ul> -->
        </div>
        <!-- <div class="title-outer">
                <h1>Find Jobs</h1>
                <ul class="page-breadcrumb">
                    <li><a href="index.html">Home</a></li>
                    <li>Jobs</li>
                </ul> 
            </div> -->

        <!-- Contact Form -->
        <div class="contact-form default-form">
          <br />
          <br />

          <!--Contact Form-->
          <form ref="form_data" @submit.prevent="addEmployerType">
            <div class="row">
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <div class="response"></div>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                <!--label>Your Name</label-->
                <input
                  type="text"
                  v-model="employer_type_code"
                  class="username"
                  placeholder="Employer type Code*"
                  @input="code_error = ''"
                />
                <p v-if="code_error != ''" class="mt-1 text-danger">
                  {{ code_error }}
                </p>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                <!--label>Your Email</label-->
                <input
                  type="text"
                  v-model="employer_type"
                  class="subject"
                  placeholder="Employer type*"
                  @input="type_error = ''"
                />
                <p v-if="type_error != ''" class="mt-1 text-danger">
                  {{ type_error }}
                </p>
              </div>

              <!--div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <label>Subject</label>
                <input type="text" name="subject" class="subject" placeholder="Subject *" required>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <label>Your Message</label>
                <textarea name="message" placeholder="Write your message..." required=""></textarea>
              </div-->
              <br /><br />
              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <button
                  class="theme-btn btn-style-one"
                  type="submit"
                  id="submit"
                  name="submit-form"
                >
                  Add
                </button>
              </div>
            </div>
          </form>
          <br />
          <br />
          <div class="table-outer">
            <table class="default-table invoice-table">
              <thead>
                <tr>
                  <th>Employer Type Code</th>
                  <th>Employer Type</th>
                  <th>Created On</th>
                  <th>Updated On</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="type in employerType" :key="type.id">
                  <td>{{ type.code }}</td>
                  <td>{{ type.org_type }}</td>
                  <td>{{ format_date(type.created) }}</td>
                  <td>{{ format_date(type.last_updated) }}</td>
                  <td>
                    <div class="option-box">
                      <ul class="option-list">
                        <li>
                          <button
                            @click.prevent="edit_employer_type(type.id)"
                            data-text="Edit"
                          >
                            <span
                              class="icon la la-pen"
                              style="color: #070f0f"
                            ></span>
                          </button>
                        </li>
                        <!-- <li><button data-text="Reject Aplication"><span class="la la-pencil" style="color:   #b4e515;"></span></button></li> -->
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--End Contact Form -->
      </div>
    </section>

    <!--End Listing Page Section -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "AdminEmployerTypeView",
  data() {
    return {
      employerType: [],
      code_error: "",
      type_error: "",
    };
  },

  methods: {
    addEmployerType() {
      if (this.employer_type == null || this.employer_type == "") {
        this.type_error = "Please Enter Employer Type";
      } else {
        axios
          .post("app_admin/org-type/", {
            code: this.employer_type_code,
            org_type: this.employer_type,
          })
          .then((res) => {
            if (res.status == 201) {
              // this.$refs.form_data.reset();

              this.employer_type_code = "";
              this.employer_type = "";

              this.getEmployerType();

              this.$emit("get_message", "successfully added");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    edit_employer_type(id) {
      this.$router.push({ name: "admin-org-type-edit", query: { id: id } });
    },

    getEmployerType() {
      axios
        .get("app_admin/org-type/")
        .then((res) => {
          if (res.status == 201 || res.status == 200) {
            this.employerType = res.data;

            console.log(res.status);
          }
        })
        .catch((error) => {
          alert("error");
          console.log(error.response);
        });
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm:ss");
      }
    },
  },

  mounted: function () {
    this.getEmployerType();
  },
};
</script>

<style></style>
