import axios from 'axios';
import Router from '../router'; 
//axios.defaults.baseURL = 'https://apihive4work.co.uk/';
 axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:8080' ;
 axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.xsrfCookieName = "csrftoken";


import vcookie from 'vue-cookie';




axios.interceptors.request.use(
    function(config) {
     


    if(config.url.includes("anonymous")){

     

      config.headers.Authorization = ''



    }else{

      
      const user = JSON.parse(vcookie.get('user'));


        if(user){
         
        config.headers.Authorization = `Bearer ${user.access}`

        }

      

    }
        

      return config;
    },
    function(error) {
     alert(error.response)
      return Promise.reject(error);
    }
  );

  //  async function getNewAccessToken() {
  //   const user = JSON.parse(vcookie.get('user'));
  //   const response = await axios.post("access/login/refresh/", { refresh: user.refresh });

  //   if (response.status == 200) {

  //     var newtoken=await response.data.access;
  //     return newtoken
  
  //   }
  // }


  axios.interceptors.response.use(
    function(response) {
      return response;
    },

    async function(error) {

      if(error.config.url.includes("access")){
        return Promise.reject(error);

      }else{
        

        if (error.response && (error.response.status == 401 || error.response.status == 403)) {
          const user = JSON.parse(vcookie.get("user"));
      
  
          if (user) {
            vcookie.delete("user");
    
           Router.push({ name: "login" });
       
          }
         
  
  
  
          
  
      
        }



      }
      

     


      

 
  
  

    }

  );

