<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/candidate-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/candidate-profile"
              ><i class="la la-user-tie"></i>My Resume</a
            >
          </li>
          <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
          <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
          <li>
            <a href="/candidate-findjob"
              ><i class="la la-user-alt"></i>Find jobs</a
            >
          </li>
          <li>
            <a href="/candidate-appliedjob"
              ><i class="la la-briefcase"></i> Applied Jobs
            </a>
          </li>

          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 250px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <!-- <div class="upper-title-box">
          <h3>Application view</h3>
      
        </div> -->

        <div class="upper-box">
          <div class="auto-container">
            <!-- Job Block -->
            <div class="job-block-seven">
              <div class="inner-box">
                <div class="content">
                  <span class="company-logo">
                    <img
                      v-if="logo"
                      style="height: 100px; width: 100px"
                      :src="logo"
                      alt=""
                    />
                  </span>
                  <h4 class="c-text-left">
                    <a href="#">{{ ano_jobs_view.job_title }}</a>
                  </h4>
                  <ul class="job-info">
                    <!-- <li><span class="icon flaticon-briefcase"></span> Segment</li> -->
                    <li>
                      <span class="icon flaticon-map-locator"></span>
                      {{ ano_jobs_view.job_location }}
                    </li>
                    <!-- <li>
                  <span class="icon flaticon-clock-3"></span> 11 hours ago
                </li> -->

                    <li>
                      <span class="icon flaticon-money"></span>&pound;{{
                        salary[0]
                      }}--{{ salary[1] }}
                    </li>
                  </ul>
                  <!-- <ul class="job-other-info">
                <li class="time">Full Time</li>
                <li class="privacy">Private</li>
                <li class="required">Urgent</li>
              </ul> -->
                </div>

                <div class="btn-box">
                  <a
                    @click.prevent="applyjob"
                    class="theme-btn btn-style-one"
                    style="background: #3f4a5a"
                    >Apply For Job</a
                  >
                  <!-- <button class="bookmark-btn">
                <i class="flaticon-bookmark"></i>
              </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <br /><br />

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <!-- <div class="widget-title">
                  <h4>My Job Listings</h4>

                  
                </div> -->

                <div class="candidate-detail-outer">
                  <div class="auto-container">
                    <div class="row">
                      <div class="row">
                        <div
                          class="content-column col-lg-8 col-md-12 col-sm-12"
                        >
                          <div class="job-detail" style="margin: 30px">
                            <h4>Key Responsibilities</h4>
                            <p>
                              {{ ano_jobs_view.responsibility }}
                            </p>

                            <h4>Education</h4>
                            <ul>
                              <li>
                                {{ ano_jobs_view.education }}
                              </li>
                            </ul>
                            <h4>requirement</h4>
                            <p>
                              {{ ano_jobs_view.requirement }}
                            </p>

                            <h4>Skill & Experience</h4>
                            <ul class="list-style-three">
                              <li v-for="s in ano_jobs_view.skill" :key="s">
                                {{ s }}
                              </li>
                            </ul>

                            <h4>Benefits</h4>
                            <ul class="list-style-three">
                              <li v-for="s in ano_jobs_view.benefits" :key="s">
                                {{ s }}
                              </li>
                            </ul>

                            <h4>Others Benefits</h4>
                            <ul class="list-style-three">
                              <li
                                v-for="s in ano_jobs_view.other_benefits"
                                :key="s"
                              >
                                {{ s }}
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div
                          class="sidebar-column col-lg-4 col-md-12 col-sm-12"
                        >
                          <aside class="sidebar">
                            <div class="sidebar-widget">
                              <!-- Job Overview -->
                              <h4 class="widget-title">Job Overview</h4>
                              <div class="widget-content">
                                <ul class="job-overview">
                                  <li>
                                    <i class="icon icon-calendar"></i>
                                    <h5>Date Posted:</h5>
                                    <span>{{
                                      ano_jobs_view.opening_date
                                    }}</span>
                                  </li>
                                  <li>
                                    <i class="icon icon-expiry"></i>
                                    <h5>Expiration date:</h5>
                                    <span>{{ ano_jobs_view.expire_date }}</span>
                                  </li>
                                  <li>
                                    <i class="icon icon-location"></i>
                                    <h5>Location:</h5>
                                    <span>{{
                                      ano_jobs_view.job_location
                                    }}</span>
                                  </li>
                                  <li>
                                    <i class="icon icon-user-2"></i>
                                    <h5>Job Title:</h5>
                                    <span>{{ ano_jobs_view.job_title }}</span>
                                  </li>
                                  <li>
                                    <i class="icon icon-clock"></i>
                                    <h5>Salary Type</h5>
                                    <span>{{ ano_jobs_view.salary_type }}</span>
                                  </li>

                                  <!-- <li>
                      <i class="icon icon-salary"></i>
                      <h5>Salary Range:</h5>
                      <span>{{ ano_jobs_view.salary_range }}</span>
                    </li> -->
                                </ul>
                              </div>

                              <!-- Job Skills -->
                              <h4 class="widget-title">Keywords</h4>
                              <div class="widget-content">
                                <ul class="job-skills">
                                  <li
                                    v-for="s in ano_jobs_view.keywords"
                                    :key="s"
                                  >
                                    <a href="#">{{ s }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div class="sidebar-widget company-widget">
                              <div class="widget-content">
                                <div class="company-title">
                                  <div class="company-logo">
                                    <img
                                      v-if="logo"
                                      style="height: 50px; width: 50px"
                                      :src="logo"
                                      alt=""
                                    />
                                  </div>
                                  <h5 class="company-name">
                                    {{ ano_com.org_name }}
                                  </h5>
                                  <!-- <a href="#" class="profile-link">View company profile</a> -->
                                </div>

                                <ul class="company-info">
                                  <li>
                                    Primary industry:
                                    <span>{{ ano_org.org_type }}</span>
                                  </li>
                                  <li>
                                    Company size:
                                    <span>{{ ano_com.org_size }}</span>
                                  </li>
                                  <li>
                                    Founded in:
                                    <span>{{ ano_com.est_year }}</span>
                                  </li>
                                  <li>
                                    Description:
                                    <span>{{ ano_com.description }}</span>
                                  </li>
                                  <li>
                                    Location: <span>{{ ano_com.city }}</span>
                                  </li>
                                  <li>
                                    Country: <span>{{ ano_com.country }}</span>
                                  </li>
                                </ul>

                                <div class="btn-box">
                                  <a
                                    href="#"
                                    class="theme-btn btn-style-three"
                                    >{{ ano_com.website }}</a
                                  >
                                </div>
                              </div>
                            </div>
                          </aside>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="widget-content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->

    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
import vcookie from "vue-cookie";
export default {
  name: "CandidateFindJobsSingleView",
  data() {
    return {
      ano_jobs_view: "",
      jobid: "",
      ano_com: "",
      ano_org: "",
      org_logo: "",
      logo: "",
      salary: [],
    };
  },

  methods: {
    get_jobs_view(uuid) {
      axios
        .get("anonymous/job-list/" + uuid + "/", { ignore: "1" })
        .then((res) => {
          this.ano_jobs_view = res.data;
          this.jobid = res.data.id;
          this.ano_com = res.data.recruiter;
          this.ano_org = res.data.recruiter.org_type;
          this.org_logo = res.data.recruiter;
          this.logo = res.data.recruiter.logo.logo;
          this.salary = res.data.salary_range;

          if (res.status == 200) {
            console.log("done");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    applyjob() {
      if (this.jobid != "") {
        const user = JSON.parse(vcookie.get("user"));

        if (user) {
          if (user.user.role == "J") {
            axios
              .post("applicant/job_application/", {
                job_post: this.jobid,
              })
              .then((res) => {
                if (res.status == 201) {
                  this.$emit("get_message", "You applied for the job successfully !");

               
                }
                if (res.status == 200) {
                  alert(res.data.status);
                }
              })
              .catch((error) => {
                alert(error.response.data.status);
              });
          } else {
            this.$router.push({ name: "login" });
          }
        } else {
          this.$router.push({ name: "login" });
        }
      }
    },
  },

  mounted() {
    var uuid = this.$route.query.uuid;

    if (uuid != null) {
      this.get_jobs_view(uuid);
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style></style>
