import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/pages/HomeView";
import LoginView from "@/views/pages/LoginView";
import JobListView from "@/views/pages/JobListView";
//import EmployersListView from '../views/pages/EmployersListView'
import CandidatesListView from "@/views/pages/CandidatesListView";
import ContactView from "@/views/pages/ContactView";
import NeedHelpView from "@/views/pages/NeedHelpView";
import HowItWorks from "@/views/pages/HowItWorks";
import CareerAdviceView from "@/views/pages/CareerAdviceView";
import CareerAdviceCategoryView from "@/views/pages/CareerAdviceCategoryView";
import CareerAdviceSingleView from "@/views/pages/CareerAdviceSingleView";
import CourseView from "@/views/pages/CourseView";
import CourseSingleView from "@/views/pages/CourseSingleView";
import EnrollNowView from "@/views/pages/EnrollNowView";
import CourseWaiterSingleView from "@/views/pages/CourseWaiterSingleView";
import CourseChefSingleView from "@/views/pages/CourseChefSingleView";

import RegisterView from "@/views/pages/RegisterView";
import CandidateView from "@/views/pages/CandidateView";
import JobView from "@/views/pages/JobView";
import TermsView from "@/views/pages/TermsView";
import PrivacyView from "@/views/pages/PrivacyView";
import DirectHireSingleView from "@/views/pages/DirectHireSingleView";
import RecJobList from "@/views/pages/RecJobList";

import PostAJobView from "@/views/pages/PostAJobView";
import DashboardAdminView from "@/views/dashboard/DashboardAdminView";
import DashboardCandidateView from "@/views/dashboard/DashboardCandidateView";
import DashboardEmployeView from "@/views/dashboard/DashboardEmployeView";
import DashboardAdminAllEmpView from "@/views/dashboard/DashboardAdminAllEmpView";
import DashboardAdminAllApplicantView from "@/views/dashboard/DashboardAdminAllApplicantView";
import AdminAddJobCategoryView from "../views/dashboard/AdminAddJobCategoryView";
import AdminEmployerTypeView from "@/views/dashboard/AdminEmployerTypeView";
import AdminContractApplicantView from "@/views/dashboard/AdminContractApplicantView";
import AdminContractHireView from "@/views/dashboard/AdminContractHireView";
import AdminAdBoardView from "@/views/dashboard/AdminAdBoardView";
import AdminAdBoardUpdateView from "@/views/dashboard/AdminAdBoardUpdateView";

import AdminAddCourseView from "@/views/dashboard/AdminAddCourseView";
import AdminManageCourseView from "@/views/dashboard/AdminManageCourseView";
import AdminUpdateCourseView from "@/views/dashboard/AdminUpdateCourseView";
import AdminAddCareerAdviceView from "@/views/dashboard/AdminAddCareerAdviceView";
import AdminUpdateCareerAdviceView from "@/views/dashboard/AdminUpdateCareerAdviceView";
import AdminManageCareerAdviceView from "@/views/dashboard/AdminManageCareerAdviceView";

import AdminManageAdBoardView from "@/views/dashboard/AdminManageAdBoardView";

import AdminPaymentStatusView from "@/views/dashboard/AdminPaymentStatusView";
import AdminDeleteProfileView from "@/views/dashboard/AdminDeleteProfileView";
import AdminChangePasswordView from "@/views/dashboard/AdminChangePasswordView";
import AdminDetailApplicantView from "@/views/dashboard/AdminDetailApplicantView";
import AdminDetailEmpView from "@/views/dashboard/AdminDetailEmpView";
import EmployerProfileView from "@/views/dashboard/EmployerProfileView";
import EmployerProfileCreate from "@/views/dashboard/EmployerProfileCreate";
import EmployerPostJobView from "@/views/dashboard/EmployerPostJobView";

import EmployerPostJobSingleView from "@/views/dashboard/EmployerPostJobSingleView";
import EmployerManageJobView from "../views/dashboard/EmployerManageJobView";
import EmployerAllApplicantsView from "../views/dashboard/EmployerAllApplicantsView";
import EmployerContractApplicantsView from "../views/dashboard/EmployerContractApplicantsView";
import EmployerChangePasswordView from "../views/dashboard/EmployerChangePasswordView";
import EmployerDeleteProfileView from "../views/dashboard/EmployerDeleteProfileView";
import CandidateProfileView from "../views/dashboard/CandidateProfileView";
import CandidateProfileSingleView from "../views/dashboard/CandidateProfileSingleView";
import CandidateProfileUpdateView from "../views/dashboard/CandidateProfileUpdateView";

import CandidateResumeView from "../views/dashboard/CandidateResumeView";
import CandidateCvManagerView from "../views/dashboard/CandidateCvManagerView";
import EmployerProfileUpdate from "../views/dashboard/EmployerProfileUpdate";
import DirectHireView from "../views/pages/DirectHireView";
import JobSearchView from "../views/pages/JobSearchView";
import JobCategoryView from "../views/pages/JobCategoryView";
import Tier2JobView from "../views/pages/Tier2JobView";
import CourseCategoryView from "../views/pages/CourseCategoryView";


import CandidateFindJobsView from "../views/dashboard/CandidateFindJobsView";
import CandidateFindJobsSingleView from "../views/dashboard/CandidateFindJobsSingleView";

import ChangePasswordView from "../views/dashboard/ChangePasswordView";
import AdminCategoryEdit from "../views/dashboard/AdminCategoryEdit";
import AdminOrgTypeEdit from "../views/dashboard/AdminOrgTypeEdit";

import CandidateAppliedJobsView from "../views/dashboard/CandidateAppliedJobsView";
import CandidateAppliedSingleView from "../views/dashboard/CandidateAppliedSingleView";

import CandidateChangePasswordView from "../views/dashboard/CandidateChangePasswordView";
import ShopingCartView from "../views/pages/ShopingCartView";
import CheckoutView from "../views/pages/CheckoutView";
import ParttimeView from "../views/pages/ParttimeView";
import AdminManageJobView from "../views/dashboard/AdminManageJobView";
import vcookie from "vue-cookie";
import ApplicantListView from "../views/dashboard/ApplicantListView";

import EmployerApplicantSingleView from "../views/dashboard/EmployerApplicantSingleView";
import AdminAddCourseCategory from "../views/dashboard/AdminAddCourseCategory";
import AdminAddCareerCategory from "../views/dashboard/AdminAddCareerCategory";
import AdminJobViewSingle from "../views/dashboard/AdminJobViewSingle";
import AdminPartTimeSingle from "../views/dashboard/AdminPartTimeSingle";
import AdminJobDirectSingle from "../views/dashboard/AdminJobDirectSingle";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/login",
    name: "login",
    meta: { layout: "auth", loginAuth: true },
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    meta: { layout: "auth", loginAuth: true },
    component: RegisterView,
  },
  {
    path: "/job",
    name: "job",
    component: JobListView,
  },

  {
    path: "/recjob",
    name: "recjob",
    component: RecJobList,
  },
  {
    path: "/postajob",
    name: "postajob",
    component: PostAJobView,
  },
  {
    path: "/candidates",
    name: "candidates",
    component: CandidatesListView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },

  {
    path: "/needhelp",
    name: "needhelp",
    component: NeedHelpView,
  },

  {
    path: "/career-advice",
    name: "career-advice",
    component: CareerAdviceView,
  },

  {
    path: "/career-advice-category",
    name: "career-advice-category",
    component: CareerAdviceCategoryView,
  },

  {
    path: "/career-advice-single",
    name: "career-advice-single",
    component: CareerAdviceSingleView,
  },

  {
    path: "/enroll-now",
    name: "enroll-now",
    component: EnrollNowView,
  },

  {
    path: "/course",
    name: "course",
    component: CourseView,
  },
  {
    path: "/ano-course-category",
    name: "ano-course-category",
    component: CourseCategoryView,
  },

  {
    path: "/course-single",
    name: "course-single",
    component: CourseSingleView,
  },

  {
    path: "/course-single-waiter",
    name: "course-single-waiter",
    component: CourseWaiterSingleView,
  },

  {
    path: "/course-single-chef",
    name: "course-single-chef",
    component: CourseChefSingleView,
  },

  {
    path: "/howitworks",
    name: "howitworks",
    component: HowItWorks,
  },

  {
    path: "/candidate",
    name: "candidate",
    component: CandidateView,
  },

  {
    path: "/terms",
    name: "terms",
    component: TermsView,
  },

  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyView,
  },

  {
    path: "/admin-category-edit",
    name: "admin-category-edit",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminCategoryEdit,
  },

  {
    path: "/admin-org-type-edit",
    name: "admin-org-type-edit",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminOrgTypeEdit,
  },

  {
    path: "/course-category",
    name: "course-category",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminAddCourseCategory,
  },
  {
    path: "/career-category",
    name: "career-category",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminAddCareerCategory,
  },

  {
    path: "/search",
    name: "search",
    component: JobSearchView,
  },
  {
    path: "/job-single",

    name: "job-single",
    component: JobView,
  },

  {
    path: "/Direct-single",
    name: "Direct-single",
    component: DirectHireSingleView,
  },

  {
    path: "/job-category",
    name: "job-category",
    component: JobCategoryView,
  },
  {
    path: "/tiertojob",
    name: "tiertojob",
    component: Tier2JobView,
  },
  /*{
    path: '/employer-single',
    name: 'employer-single',
    component: EmployerView
  },*/

  //  dashboard Admin section start
  {
    path: "/admin-dashboard",
    name: "admin-dashboard",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: DashboardAdminView,
  },

  {
    path: "/change-password",
    name: "change-password",
    meta: { layout: "admin", requiresAuth: true },
    component: ChangePasswordView,
  },
  {
    path: "/admin-dashboard-allemployer",
    name: "admin-dashboard-allemployer",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: DashboardAdminAllEmpView,
  },
  {
    path: "/admin-job-view",
    name: "admin-job-view",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminJobViewSingle,
  },
  {
    path: "/admin-dashboard-allapplicant",
    name: "admin-dashboard-allapplicant",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: DashboardAdminAllApplicantView,
  },
  {
    path: "/admin-add-job-category",
    name: "admin-add-job-category",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminAddJobCategoryView,
  },
  {
    path: "/admin-add-employer-type",
    name: "admin-add-employer-type",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminEmployerTypeView,
  },
  {
    path: "/admin-payment-status",
    name: "admin-payment-status",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminPaymentStatusView,
  },
  {
    path: "/admin-add-contract-type",
    name: "admin-add-contract-type",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminContractApplicantView,
  },

  {
    path: "/admin-add-contracthire-type",
    name: "admin-add-contracthire-type",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminContractHireView,
  },

  {
    path: "/admin-delete-profile",
    name: "admin-delete-profile",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminDeleteProfileView,
  },
  {
    path: "/admin-change-password",
    name: "admin-change-password",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminChangePasswordView,
  },

  {
    path: "/admin-applicant-detail",
    name: "admin-applicant-detail",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminDetailApplicantView,
  },

  {
    path: "/admin-employer-detail",
    name: "admin-employer-detail",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminDetailEmpView,
  },
  {
    path: "/admin-parttime-single",
    name: "admin-parttime-single",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminPartTimeSingle,
  },
  {
    path: "/admin-direct-single",
    name: "admin-direct-single",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminJobDirectSingle,
  },

  {
    path: "/admin-ad-board",
    name: "admin-ad-board",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminAdBoardView,
  },

  {
    path: "/admin-add-course",
    name: "admin-add-course",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminAddCourseView,
  },

  {
    path: "/admin-manage-course",
    name: "admin-manage-course",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminManageCourseView,
  },

  {
    path: "/admin-update-course",
    name: "admin-update-course",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminUpdateCourseView,
  },

  {
    path: "/admin-add-carrer-advice",
    name: "admin-add-carrer-advice",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminAddCareerAdviceView,
  },
  {
    path: "/admin-update-carrer",
    name: "admin-update-carrer",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminUpdateCareerAdviceView,
  },

  {
    path: "/admin-manage-carrer-advice",
    name: "admin-manage-carrer-advice",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminManageCareerAdviceView,
  },

  {
    path: "/applicant-list",
    name: "applicant-list",
    meta: { layout: "admin", requiresRecAuth: true },
    component: ApplicantListView,
  },

  {
    path: "/applicant-list-single",
    name: "applicant-list-single",
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerApplicantSingleView,
  },

  {
    path: "/admin-updatead-board",
    name: "admin-updatead-board",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminAdBoardUpdateView,
  },

  {
    path: "/admin-manage-ad-board",
    name: "admin-manage-ad-board",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminManageAdBoardView,
  },

  //  dashboard Admin section end

  //  dashboard employer section start
  {
    path: "/recruiter-dashboard",
    name: "employee-dashboard",
    meta: { layout: "admin", requiresRecAuth: true },
    component: DashboardEmployeView,
  },

  {
    path: "/recruiter-profile-create",
    name: "employer-profile-create",
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerProfileCreate,
  },

  {
    path: "/recruiter-profile",
    name: "employer-profile",
    meta: { layout: "admin", requiresRecProfileAuth: true },
    component: EmployerProfileView,
  },

  {
    path: "/recruiter-profile-update",
    name: "employer-profile-update",
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerProfileUpdate,
  },

  {
    path: "/recruiter-postjob",
    name: "employer-postjob",
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerPostJobView,
  },

  {
    path: "/recruiter-singlepostjob",
    name: "employer-singlepostjob",
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerPostJobSingleView,
  },

  {
    path: "/recruiter-managejob",
    name: "employer-managejob",
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerManageJobView,
  },

  {
    path: "/employer-allapplicants",
    name: "employer-allapplicants",
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerAllApplicantsView,
  },

  {
    path: "/admin-managejob",
    name: "admin-managejob",
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminManageJobView,
  },

  {
    path: "/employer-contractapplicants",
    name: "employer-contractapplicants",
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerContractApplicantsView,
  },

  {
    path: "/employer-changepassword",
    name: "employer-changepassword",
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerChangePasswordView,
  },
  {
    path: "/employer-deleteprofile",
    name: "employer-deleteprofile",
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerDeleteProfileView,
  },
  {
    path: "/shopingcart",
    name: "shopingcart",
    //meta:{layout:"admin"},
    component: ShopingCartView,
  },

  {
    path: "/checkout",
    name: "checkout",
    //meta:{layout:"admin"},
    component: CheckoutView,
  },

  //  dashboard employer section end

  //  dashboard Applicant section start
  {
    path: "/candidate-dashboard",
    name: "candidate-dashboard",
    meta: { layout: "admin" , requiresCanAuth: true },

    component: DashboardCandidateView,
  },

  {
    path: "/candidate-profile",
    name: "candidate-profile",
    meta: { layout: "admin" , requiresCanAuth: true },
    component: CandidateProfileView,
  },

  {
    path: "/candidate-profile-single",
    name: "candidate-profile-single",
    meta: { layout: "admin", requiresCanProfileAuth: true },
    component: CandidateProfileSingleView,
  },

  {
    path: "/candidate-profile-update",
    name: "candidate-profile-update",
    meta: { layout: "admin" , requiresCanAuth: true },
    component: CandidateProfileUpdateView,
  },

  {
    path: "/candidate-resume",
    name: "candidate-resume",
    meta: { layout: "admin" , requiresCanAuth: true },
    component: CandidateResumeView,
  },

  {
    path: "/candidate-CvManager",
    name: "candidate-CvManager",
    meta: { layout: "admin" , requiresCanAuth: true },
    component: CandidateCvManagerView,
  },

  {
    path: "/candidate-findjob",
    name: "candidate-findjob",
    meta: { layout: "admin" , requiresCanAuth: true },
    component: CandidateFindJobsView,
  },

  {
    path: "/candidate-findjob-single",
    name: "candidate-findjob-single",
    meta: { layout: "admin" , requiresCanAuth: true },
    component: CandidateFindJobsSingleView,
  },

  {
    path: "/parttimejob",
    name: "parttimejob",
    component: ParttimeView,
  },
  {
    path: "/directhire",
    name: "directhire",
    component: DirectHireView,
  },

  {
    path: "/candidate-appliedjob",
    name: "candidate-appliedjob",
    meta: { layout: "admin" },
    component: CandidateAppliedJobsView,
  },

  {
    path: "/candidate-appliedjob-single",
    name: "candidate-appliedjob-single",
    meta: { layout: "admin" },
    component: CandidateAppliedSingleView,
  },

  {
    path: "/candidate-change-password",
    name: "candidate-change-password",
    meta: { layout: "admin" },
    component: CandidateChangePasswordView,
  },

  //  dashboard Applicant section end
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
    const user = JSON.parse(vcookie.get("user"));

    if (user) {
      if (user.user.role == "A") {
        next();
      } else {
        next({ name: "login" });
      }
    } else {
      next({ name: "login" });
    }
  } else if (to.matched.some((record) => record.meta.requiresRecProfileAuth)) {
    const user = JSON.parse(vcookie.get("user"));

    if (user) {
      if (user.user.role == "R") {
        if (user.user.action_capability) {
          next();
        } else {
          next({ name: "employer-profile-create" });
        }
      } else {
        next({ name: "login" });
      }
    } else {
      next({ name: "login" });
    }
  } else if (to.matched.some((record) => record.meta.requiresCanProfileAuth)) {
    const user = JSON.parse(vcookie.get("user"));

    if (user) {
      if (user.user.role == "J") {
        if (user.user.action_capability) {
          next();
        } else {
          next({ name: "candidate-profile" });
        }
      } else {
        next({ name: "login" });
      }
    } else {
      next({ name: "login" });
    }
  } else if (to.matched.some((record) => record.meta.requiresRecAuth)) {
    const user = JSON.parse(vcookie.get("user"));

    if (user) {
      if (user.user.role == "R") {
        next();
      } else {
        next({ name: "login" });
      }
    } else {
      next({ name: "login" });
    }
  }  else if (to.matched.some((record) => record.meta.requiresCanAuth)) {
    const user = JSON.parse(vcookie.get("user"));

    if (user) {
      if (user.user.role == "J") {
        next();
      } else {
        next({ name: "login" });
      }
    } else {
      next({ name: "login" });
    }
  }
  
 else if (to.matched.some((record) => record.meta.requiresAuth)) {
    const user = JSON.parse(vcookie.get("user"));

    if (user) {
      next();
    } else {
      next({ name: "login" });
    }
  } else if (to.matched.some((record) => record.meta.loginAuth)) {
    const user = JSON.parse(vcookie.get("user"));

    if (user) {
      if (user.user.role == "A") {
        next({ name: "admin-dashboard" });
      }

      if (user.user.role == "J") {
        next({ name: "candidate-dashboard" });



      } if(user.user.role == "R"){
        next({ name: "employee-dashboard" });



      }



    }else{
      next();
    }
  
  } else {
    next();
  }
});

export default router;
