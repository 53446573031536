<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li class="active">
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
        
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box text-center">
          <h3>Update Career Advice</h3>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box p-3">
                <div class="widget-content">
                  <form @submit.prevent="addCA" class="default-form">
                    <div class="row">
                      <!-- Input -->

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Title</label>
                        <input
                          type="text"
                          v-model.trim="career.title"
                          placeholder="Title"
                          @input="clearError('title')"
                        />

                        <div id="title" class="text-danger error"></div>
                      </div>

                      <!-- About Company -->
                      <div class="form-group col-lg-12 col-md-12">
                        <label>Abstract</label>
                        <textarea
                          v-model.trim="career.abstract"
                          placeholder="Abstract"
                          @input="clearError('abstract')"
                        ></textarea>

                        <div id="abstract" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Author</label>
                        <input
                          type="text"
                          v-model.trim="career.author"
                          placeholder="Author"
                          @input="clearError('author')"
                        />

                        <div id="author" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Article category*</label>
                        <select
                          v-model="article_category.id"
                          @change="clearError('article_category')"
                        >
                          <option disabled :value="null">
                            Select a option
                          </option>
                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.cat_name }}
                          </option>
                        </select>
                        <div
                          id="article_category"
                          class="text-danger error"
                        ></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Image</label>
                        <input
                          style="vertical-align: middle;"
                          class="form-control"
                          type="file"
                          @change="handleFileUpload($event)"
                          placeholder="eligibility"
                          @input="clearError('image')"
                        />

                        <div id="image" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        
                       <img v-if="url" :src="url" style="width: 100%;height: 200px;" >

                        <div id="image" class="text-danger error"></div>
                      </div>

                      <div class="form-group border p-5 col-lg-12 col-md-12">
                        <label>Sections</label>

                        <div class="row">
                          <div class="col-lg-5">
                            <input
                              type="text"
                              v-model.trim="section_title"
                              placeholder="Title"
                              @input="clearError('section_title')"
                            />

                            <div
                              id="section_title"
                              class="text-danger error"
                            ></div>
                          </div>
                          <div class="col-lg-5">
                            <input
                              type="text"
                              v-model.trim="content"
                              placeholder="Content"
                              @input="clearError('content')"
                            />

                            <div id="content" class="text-danger error"></div>
                          </div>

                          <div class="col-lg-2">
                            <button
                              @click.prevent="addsection"
                              class="theme-btn btn-style-one"
                            >
                              ADD
                            </button>
                          </div>

                          <div v-if="sections != ''" class="mt-2">
                            <span
                              v-for="section in sections"
                              :key="section"
                              class="mt-2 addElement"
                            >
                              {{ section.title }}
                              <i
                                @click="removeEle(section)"
                                class="fa-solid fa-xmark removeElebtn"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </div>

                      <!--                       
                      <div class="uploading-outer">
                        <div class="uploadButton">
                          <input
                            class="uploadButton-input"
                            type="file"
                            @change="handleFileUpload($event)"
                            accept="image/*"
                            id="upload"
                            multiple
                          />
                          <label
                            style="position:relative"
                              class="uploadButton-button ripple-effect"
                              for="upload"
                              >

                              <img v-if="url" :src="url"  style="position: absolute;top:0;bottom: 0;right: 0;left: 0;width: 100%;height: 100%;z-index: 0;"  alt="">
                             
                              
                              
                              <span v-if="url==''" >Browse image</span></label
                            >
                          <span class="uploadButton-file-name"></span>
                          <span id="Image" class="text-danger error"></span>

                        </div>
                        <button @click.prevent="add_logo"  type="submit" class="btn btn-primary" >Upload</button>
                      </div>
                       -->

                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12 text-right">
                        <button type="submit" class="theme-btn btn-style-one">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->

    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "AdminAddCareerAdviceView",

  data() {
    return {
      categories: [],
      article_category: {},
      sections: [],
      career:{},
      uuide:"",
      url:"",

      image: "",
    };
  },

  methods: {
    removeEle(ad) {
      this.sections.splice(this.sections.indexOf(ad), 1);
    },
    removeElebeni(ad) {
      this.benefits.splice(this.benefits.indexOf(ad), 1);
    },
    removeEleother(ad) {
      this.others.splice(this.others.indexOf(ad), 1);
    },

    removekeyword(ad) {
      this.keywords.splice(this.keywords.indexOf(ad), 1);
    },

    addskill() {
      if (this.adds != "" && this.adds != null) {
        if (this.skills.length < 10) {
          this.skills.push(this.adds);

          this.adds = null;
        } else {
          alert("Skills Max length will be 10");
        }
      } else {
        alert("Skill is empty");
      }
    },

    addbeni() {
      if (this.bene != "" && this.bene != null) {
        if (this.benefits.length < 10) {
          this.benefits.push(this.bene);

          this.bene = null;
        } else {
          alert("Benefits Max length will be 10");
        }
      } else {
        alert("Benefits is empty");
      }
    },

    addother() {
      if (this.other != "" && this.other != null) {
        if (this.others.length < 10) {
          this.others.push(this.other);

          this.other = null;
        } else {
          alert(" Others Benefits Max length will be 10");
        }
      } else {
        alert("Others Benefits is empty");
      }
    },

    addexperiance() {
      if (this.experiences.length < 2) {
        this.experiences.push(this.min_exp);

        this.experiences.push(this.max_exp);
      }
    },
    salaryrange() {
      if (this.salary.length < 2) {
        this.salary.push(this.salary_range);
        this.add4 = "" + this.salary;
      } else {
        alert("Salary range Max length will be 2");
      }
    },

    addbenefit() {
      if (this.benefit.length < 10) {
        this.benefit.push(this.benefits);
        this.add5 = "" + this.benefit;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_others_benefit() {
      if (this.others_benefit.length < 10) {
        this.others_benefit.push(this.others_benefits);
        this.add6 = "" + this.others_benefit;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    addkeywords() {
      if (this.keywords.length < 10) {
        this.keywords.push(this.keyword);
        this.add3 = "" + this.keywords;
        this.keyword = null;
      } else {
        alert("Keywords range Max length will be 10");
      }
    },

    addsection() {
      if (this.section_title == null || this.section_title == "") {
        this.nullcheck("section_title");
      } else if (this.content == null || this.content == "") {
        this.nullcheck("content");
      } else {
        var section = {
          title: this.section_title,
          content: this.content,
        };
        this.sections.push(section);
      }
    },

    category() {
      axios
        .get("/app_admin/career_advice_category/")
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    handleFileUpload(e) {
      this.image = e.target.files[0];
      this.url=URL.createObjectURL(this.image);
    },

    nullcheck(element) {
      $("#" + element).html("This field is required");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },
    addcheck(element) {
      $("#" + element).html("No section added");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },
    lengthcheck(element, value) {
      $("#" + element).html(element + " length will be max " + value);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    clearError(element) {
      $("#" + element).html("");
    },

    addCA() {
      if (this.career.title == null || this.career.title == "") {
        this.nullcheck("title");
      } else if (this.career.author == null || this.author == "") {
        this.nullcheck("author");
      } else if (this.article_category.id == null || this.article_category.id == "") {
        this.nullcheck("article_category");
      } else if (!this.sections.length > 0) {
        this.addcheck("section_title");
      } else {
        var formdata = new FormData();

        formdata.append("title", this.career.title);
        formdata.append("abstract", this.career.abstract);
        formdata.append("author", this.career.author);
        formdata.append("article_category", this.article_category.id);
        if(!this.image==""){
         
          formdata.append("image", this.image);
        }
        
        for (var i = 0; i < this.sections.length; i++) {
          formdata.append("sections[" + i + "]title", this.sections[i].title);
          formdata.append(
            "sections[" + i + "]content",
            this.sections[i].content
          );
        }
        axios
          .patch("/app_admin/career_advice/"+this.uuide+"/", formdata)
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);

       this.$router.push({
          name: "admin-manage-carrer-advice",
        
        });

              this.$emit("get_message", "Career Advice Updated Successfully");

              
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    getcareer(uuid) {
      axios
        .get("app_admin/career_advice/" + uuid + "/")
        .then((res) => {
          this.career = res.data;
          this.url = res.data.image;
          this.article_category=res.data.article_category
          this.sections=res.data.sections
     
          if (res.status == 200) {
            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
 
  },

  mounted: function () {
    this.category();
    var uuid = this.$route.query.uuid;
    this.uuide=uuid

if (uuid != null) {
  this.getcareer(uuid);
  this.uuide = uuid;
}
  }
};
</script>

<style>
.button_plus {
  position: absolute;
  width: 35px;
  height: 35px;
  background: #fff;
  cursor: pointer;
  border: 2px solid #095776;
  display: flex;
  top: 44%;
}

.button_plus:after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  height: 4px;
  width: 50%;
  background: #095776;
  top: 50%;
  left: 50%;
}

.button_plus:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #095776;
  height: 50%;
  width: 4px;
}

.button_plus:hover:before,
.button_plus:hover:after {
  background: #fff;
  transition: 0.2s;
}

.button_plus:hover {
  background-color: #095776;
  transition: 0.2s;
}
</style>
