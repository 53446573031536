<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li class="active">
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
        
        </ul>
      </div>
    </div>

    <!-- End User Sidebar -->
    <!-- Mobile Header -->
    <div class="mobile-header">
      <div class="logo">
        <a href="index.html"><img src="images/logo.svg" alt="" title="" /></a>
      </div>

      <!--Nav Box-->
      <div class="nav-outer clearfix">
        <div class="outer-box">
          <!-- Login/Register -->
          <div class="login-box">
            <a href="login-popup.html" class="call-modal"
              ><span class="icon-user"></span
            ></a>
          </div>

          <a href="#nav-mobile" class="mobile-nav-toggler"
            ><span class="flaticon-menu-1"></span
          ></a>
        </div>
      </div>
    </div>

    <!-- Mobile Nav -->
    <div id="nav-mobile"></div>
    <!--Page Title-->

    <!--End Page Title-->

    <!-- Listing Section -->
    <!--Page Title-->
    <section style="background: none;" class="page-title">
      <div class="auto-container">
        <div class="title-outer">
          <h1>Direct Hire</h1>
        
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Listing Section -->
    <section class="ls-section">
      <div class="auto-container">
        <div class="filters-backdrop"></div>
        <div class="row">
          <!-- Content Column -->
          <div
            v-for="hire in direct_hire"
            :key="hire.id"
            class="content-column col-lg-20 col-md-20 col-sm-20"
          >
            <div class="company-block-three">
              <div
                @click="openhire(hire.uuid)"
                style="text-align: left"
                class="inner-box"
              >
                <div class="content">
                  <div class="content-inner">
                    <!-- <span class="company-logo"><img src="images/resource/company-logo/6-1.png" alt=""></span> -->
                    <h4>
                      <a href="#">{{ hire.job_title }}</a>
                    </h4>
                    <ul class="job-info">
                      <li>
                        <span class="icon flaticon-briefcase"></span
                        >{{ hire.org_name }}
                      </li>
                      <li>
                        <span class="icon flaticon-map-locator"></span
                        >{{ hire.city }}
                      </li>

                      <li>
                        <span class="icon flaticon-money"></span> &pound;{{
                          hire.salary_range[0]
                        }}-&pound;{{ hire.salary_range[1] }}
                      </li>
                    </ul>
                  </div>

                  <ul class="job-other-info">
                    <!--li class="privacy">Featured</li-->
                    <li class="time">Open Position: {{ hire.position_no }}</li>
                  </ul>
                </div>

                <button class="bookmark-btn">
                  <span class="flaticon-bookmark"></span>
                </button>
              </div>
            </div>
          </div>
          <!-- Listing Show More -->
         
        </div>
      </div>
    </section>
    <!--End Listing Page Section -->

    <!--End Listing Page Section -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AdminContractHireView",
  data() {
    return {
      direct_hire: [],
    };
  },

  methods: {
    get_direct_job() {
      axios
        .get("app_admin/direct_hire/")
        .then((res) => {
          if (res.status == 200) {
            this.direct_hire = res.data.results;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    openhire(uuid) {
      if (uuid != null) {
        this.$router.push({ name: "admin-direct-single", query: { uuid: uuid } });
      }
    },

    get_direct(key) {
      axios
        .get(
          "app_admin/direct_hire/",
          {
            params: {
              search: key,
            },
          },
          { ignore: "1" }
        )
        .then((res) => {
          if (res.status == 200) {
            this.direct_hire = res.data.results;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    showjob(text) {
      if (text != null) {
        this.get_direct(text);
      }
    },
  },

  mounted: function () {
    this.get_direct_job();
  },
};
</script>

<style>
.company-block-three .inner-box:hover {
  background: none;
}
</style>
