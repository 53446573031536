<template>
  <div>
     <div id="shortside" class="d-none">
      <div style="height: 50%">
        <div class="shortbtn1 text-center pt-2">
          <a href="" style="color: white">PART TIME JOBS</a>
        </div>
      </div>
      <div style="height: 50%">
        <div style="height: 50%">
          <div class="shortbtn2 text-center pt-2">
            <a href="/directhire" style="color: black">DIRECT HIRING</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Banner Section-->
    <section class="banner-section">
      <!-- <div id="mySidenav" class="sidenav">
          <a href="/parttimejob" id="about">Part-time Job</a>
          <a href="#" id="blog">Direct Hire</a>
          <a href="#" id="projects">Summer Job</a>
          <a href="#" id="contact">Feature Job</a>
     </div> -->
      <div class="auto-container">
        <div class="row">
          <div class="content-column col-lg-7 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp" data-wow-delay="1000ms">
              <div class="title-box">
                <h3>
                  Bringing Together Excellent Candidates and Excellent Employers
                </h3>
                <h5 class="text-small-white">
                  Find Jobs, Employment & Career Opportunities
                </h5>
              </div>

              <!-- Job Search Form -->
              <div class="job-search-form">
                <form @submit.prevent="search('')" action="job-list-v10.html">
                  <div class="row">
                    <div class="form-group col-lg-6 col-md-12 col-sm-12">
                      <span class="icon flaticon-search-1"></span>
                      <input
                        type="text"
                        class="font110"
                        v-model="search_key"
                        placeholder="Job title, keywords, or company"
                      />
                    </div>
                    <!-- Form Group -->
                    <div
                      class="form-group col-lg-3 col-md-12 col-sm-12 location"
                    >
                      <span class="icon flaticon-map-locator"></span>
                      <input type="text" name="field_name" placeholder="City" />
                    </div>
                    <!-- Form Group -->
                    <div
                      class="form-group col-lg-3 col-md-12 col-sm-12 btn-box"
                    >
                      <button
                        type="submit"
                        class="btn-style-one theme-color-bg2"
                      >
                        <span class="btn-title"> FIND JOBS</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Job Search Form -->

              <!-- Popular Search -->
              <div class="text-white font140">Popular Searches :</div>
              <div class="mt-3 mb-5" style="cursor: pointer">
                <button
                  @click="search('Chef')"
                  class="theme-button3 font120 text-white"
                >
                  <span class="icon flaticon-search-1"></span> Chef
                </button>
                <button
                  @click="search('Manager')"
                  class="theme-button3 font120 text-white"
                  href="#"
                >
                  <span class="icon flaticon-search-1"></span> Manager
                </button>
                <button
                  @click="search('Bar Assistant')"
                  class="theme-button3 font120 text-white"
                  href="#"
                >
                  <span class="icon flaticon-search-1"></span> Bar Assistant
                </button>
                <button
                  @click="search('Front Desk')"
                  class="theme-button3 mt-2 font120 text-white"
                  href="#"
                >
                  <span class="icon flaticon-search-1"></span> Front Desk
                </button>

                <button
                  @click="search('Waiter')"
                  class="theme-button3 mt-2 font120 text-white"
                  href="#"
                >
                  <span class="icon flaticon-search-1"></span> Waiter
                </button>
              </div>

              <!-- <div class="mt-4 mb-5">
                <button class="theme-button4 font120">
                  <a class="theme-color2" href="/parttimejob"> PART TIME JOB</a>
                </button>
                <button href="/directhire" class="theme-button4 mt-2 font120">
                  <a class="theme-color2" href="/directhire">DIRECT HIRE</a>
                </button>
                <button class="theme-button4 mt-2 font120">
                  <a class="theme-color2" @click.prevent="opentierjob"
                    >TIER 2 SPONSORSHIP</a
                  >
                </button>
              </div> -->
              <!-- End Popular Search -->
            </div>
          </div>

          <div class="image-column col-lg-5 col-md-12">
            <div class="image-box">
              <div class="main-image wow fadeIn" data-wow-delay="500ms">
                <img src="images/girl.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features__section">
      <div class="auto-container">
        <div class="row features__grid justify-content-between">
          <div class="col-lg-3 col-md-6">
            <a href="/parttimejob" class="theme-color2">
              <div class="features -type-1">
                <div class="icon-wrap">
                  <div><i class="bi bi-clock-history theme-color2"></i></div>
                </div>
                <div class="title">
                  <a href="/parttimejob" class="theme-color2">PART TIME JOB</a>
                </div>
              </div>
            </a>
          </div>

          <div class="col-lg-auto features-line-col">
            <div class="features-line"></div>
          </div>

          <div class="col-lg-3 col-md-6">
            <a href="/directhire" class="theme-color2">
              <div class="features -type-1">
                <div class="icon-wrap">
                  <div><i class="fa-solid fa-person theme-color2"></i></div>
                </div>

                <div class="title">
                  <a href="/directhire" class="theme-color2">DIRECT HIRE</a>
                </div>
              </div>
            </a>
          </div>

          <div class="col-lg-auto features-line-col">
            <div class="features-line"></div>
          </div>

          <div class="col-lg-3 col-md-6">
            <a @click.prevent="opentierjob" class="theme-color2">
              <div class="features -type-1">
                <div class="icon-wrap">
                  <div><i class="las la-person-booth theme-color2"></i></div>
                </div>
                <div class="title">
                  <a @click.prevent="opentierjob" class="theme-color2"
                    >SKILLED WORK</a
                  >
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- End Banner Section-->
    <!-- Job Categories -->
    <section class="job-categories">
      <div class="auto-container">
        <div class="sec-title text-center">
          <h2>POPULER JOB CATEGORIES</h2>
          <h6 class="mt-3">
            <span class="theme-color bold-font">
              {{ jobcount.total_active_jobpost_count }}
            </span>
            JOBS LIVE,
            <span class="theme-color bold-font">
              {{ jobcount.todays_jobpost_count }}
            </span>
            JOBS ADDED TODAY
          </h6>
        </div>

        <div class="row wow fadeInUp">
          <!-- Category Block -->
          <div
            v-for="(ano_categorie, index) in ano_categories.slice(0, 9)"
            :key="ano_categorie.id"
            class="category-block col-lg-4 col-md-6 col-sm-12"
            @click.prevent="
              opencatjob(ano_categorie.id, ano_categorie.cat_name)
            "
          >
            <div class="inner-box">
              <div class="row">
                <div class="col-3">
                  <i class="cat-icon" :class="caticon[index].iconurl"></i>
                </div>
                <div class="col-9">
                  <h4 class="mt-1">
                    <a href="#">{{ ano_categorie.cat_name }}</a>
                  </h4>
                  <h6>
                    <span class="theme-color"
                      >{{ ano_categorie.active_job_count }}
                    </span>
                    open position(s)
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Job Categories -->
    <!-- Job Categories -->
    <!--section class="job-categories style-two">
      <div class="auto-container">
        <div class="sec-title text-center">
          <h2>Popular Job Categories</h2>
          <div class="text">2020 jobs live - 293 added today.</div>
        </div>

        <div class="row wow fadeInUp"-->
    <!-- Category Block -->
    <!--div v-for="category in categories" :key="category.id" class="category-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="content">
                <span class="icon flaticon-money-1"></span>
                <h4><a href="#">{{category.cat_name}}</a></h4>
                <p>(50 open position)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section-->
    <!-- End Job Categories -->

    <!-- Commercial Ad Section -->

    <section class="testimonial-section">
      <div class="container-fluid">
        <!-- Sec Title -->
        <!-- <div class="sec-title text-center">
          <h2 class="theme-color2">Avilable Now !</h2>

          <h5 class="mt-2">Check the offer from Recruiter</h5>
        </div> -->

        <VueAIcarousel
          :Property="{
            ID: 'one',
            CountPos: 'CounterPosition',
            CounterStyle: 'counterNewStyle',
            CounterActiveStyle: 'CAnewStyle',
          }"
        >
          <div v-for="adboard in adboards" :key="adboard.id" class="cards">
            <h4
              v-if="adboard.ad_title.length > 50"
              class="bold-font400 theme-color2"
            >
              {{ adboard.ad_title.substring(0, 50) }}
            </h4>

            <h4 v-else class="bold-font400 theme-color2">
              {{ adboard.ad_title }}
            </h4>

            <p v-if="adboard.ad_description.length > 200" class="mt-3">
              {{ adboard.ad_description.substring(1, 200) }}....<a>More</a>
            </p>
            <p v-else class="mt-3">{{ adboard.ad_description }}</p>

            <section class="row mt-4">
              <section class="col-3">
                <img
                  style="height: 80px; width: 100px"
                  :src="adboard.background"
                  alt=""
                />
              </section>
              <section class="col-9">
                <h5 class="name">{{ adboard.org_name }}</h5>

                <p>{{ adboard.url }}</p>
              </section>
            </section>
          </div>
        </VueAIcarousel>
      </div>
    </section>

    <section class="job-section-five style-two">
      <div class="auto-container">
        <div class="sec-title text-center">
          <h2 class="theme-color2">FEATURED JOB</h2>

          <!-- <h5 class="mt-2">Check the offer from Recruiter</h5> -->
        </div>

        <div style="z-index: 9" class="row wow fadeInUp px-5">
          <div
            v-for="hotjob in hotjobs"
            :key="hotjob.id"
            @click.prevent="openjob(hotjob.uuid)"
            class="col-md-6 p-3"
          >
            <div class="custom-card p-3">
              <div class="row">
                <div class="col-md-2 text-center m-auto">
                  <img
                    style="height: 50px; width: 50px"
                    v-if="hotjob.recruiter.logo != null"
                    :src="hotjob.recruiter.logo.logo"
                    alt=""
                  />
                </div>

                <div class="col-md-7 mt-sm-2">
                  <h6
                    class="font110"
                    style="text-align: left; font-weight: 500"
                  >
                    {{ hotjob.job_title }}
                  </h6>

                  <div class="row mt-1">
                    <div class="col-8">
                      <span class="icon flaticon-briefcase"></span>

                      <span class="small-font ms-2">
                        {{ hotjob.recruiter.org_name }}</span
                      >
                    </div>

                    <div class="col-4 text-left">
                      <span class="icon flaticon-map-locator"></span>
                      <span class="small-font ms-2">{{
                        hotjob.job_location
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <span class="icon flaticon-clock-3"></span>

                      <span class="small-font ms-2">
                        {{ getTime(hotjob.opening_date) }}
                      </span>
                    </div>
                    <div class="col-6 text-center">
                      <span class="icon flaticon-money"></span>

                      <span class="small-font ms-2 text-center">
                        &pound;{{ hotjob.salary_range[0] }}--{{
                          hotjob.salary_range[1]
                        }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-3 text-lg-center mt-sm-3 m-lg-auto">
                  <button href="#" class="theme-button mt-2 px-3 py-2">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Testimonial Section -->

    <!-- steps Section -->
    <section class="steps-section pt-0">
      <div class="auto-container">
        <div class="row">
          <div class="image-column mt-4 col-lg-7 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp p-5">
              <figure class="">
                <img
                  height="100%"
                  width="110%"
                  src="images/resource/manager.jpg"
                  alt=""
                />
              </figure>
              <!-- Count Employers -->
              <!--div class="count-employers wow fadeInUp">
                <span class="title">300k+ Employers</span>
                <figure class="image"><img src="images/resource/multi-peoples.png" alt=""></figure>
              </div-->
            </div>
          </div>

          <div class="content-column col-lg-5 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp">
              <div class="sec-title">
                <h2>FIND JOBS WITH 3 EASY STEPS</h2>
                <h5 class="text font110">
                  Finding the ideal employment for you is simple on hive4work!
                </h5>
                <ul class="steps-list">
                  <li>
                    <span class="count">1</span> Register an account to start
                  </li>
                  <li>
                    <span class="count">2</span> Explore over thousands of
                    positions
                  </li>
                  <li>
                    <span class="count">3</span> Find the most suitable job
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Steps Section -->

    <!-- Job Section -->

    <section class="job-section-five style-two iam">
      <div class="auto-container">
        <div class="row wow fadeInUp">
          <div class="recent-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
            <div class="content-column">
              <div class="inner-column iamrec wow fadeInUp">
                <div class="sec-title">
                  <h1>I AM RECRUITER</h1>
                  <h5 class="text font110 text-justify">
                    One of our jobs has some kind of flexibility option such as
                    a full-time, a part-time schedule or a flexible or flextime.
                  </h5>
                </div>

                <a
                  style="font-size: 110%; z-index: 30; margin-top: 20px"
                  v-if="name != ''"
                  href="/recruiter-postjob"
                  class="btn-style-one theme-color-bg"
                  >Post New Job</a
                >

                <a
                  v-else
                  style="font-size: 110%; z-index: 30; margin-top: 20px"
                  href="/login"
                  class="btn-style-one theme-color-bg"
                  >Post New Job</a
                >
              </div>
            </div>
          </div>

          <div class="featured-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
            <div class="image-column">
              <figure>
                <img src="images/resource/rec.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Job Section -->
    <!-- End Job Section -->

    <!-- Recruiter Section -->
    <!-- <section class="recruiter-section">
      <div class="outer-box">
        <div class="image-column">
          <figure><img src="images/resource/Recruiter.jpg" alt="" /></figure>
        </div>
        <div class="content-column">
          <div class="inner-column wow fadeInUp">
            <div class="sec-title">
              <h2>I am Recruiter</h2>
              <div class="text">
                One of our One of our jobs has some kind of flexibility jobs has
                some kind of flexibility option such as telecommuting, a
                part-time schedule or a flexible or flextime.
              </div>
              <a
                style="font-size: 130%; z-index: 30"
                href="/login"
                class="theme-btn btn-style-one"
                >Post New Job</a
              >
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Recruiter Section -->
    <!-- Top Companies -->
    <br />

    <section class="testimonial-section">
      <div class="container-fluid">
        <!-- Sec Title -->
        <div class="sec-title mb-0">
          <h2 class="color-blue-dark fw-700 text-center">Top 5 Companies</h2>
          <div class="text text-center font110">
            Some of the companies we've helped recruit excellent applicants over
            the years.
          </div>
        </div>
        <VueAIcarousel
          :Property="{
            ID: 'two',
            CountPos: 'CounterPosition',
            CounterStyle: 'counterNewStyle',
            CounterActiveStyle: 'CAnewStyle',
          }"
        >
          <div
            v-for="company in topjob_data"
            :key="company.id"
            style="background: none"
            class="cards2"
          >
            <section
              @click.prevent="open_rec_job(company.uuid)"
              class="company-block"
            >
              <section class="border">
                <figure class="image mt-3">
                  <img

                  
                    class="mt-0"
                    v-if="company.logo != null"
                    :src="company.logo.logo"
                    alt=""
                  />
                </figure>
                <h4 class="name">{{ company.org_name }}</h4>
                <section class="location">
                  <i class="flaticon-map-locator"></i> {{ company.city }},
                  {{ company.country }}
                </section>
                <section class="location">
                  <span
                    style="color: white; border-radius: 5px"
                    class="theme-color-bg border p-2"
                  >
                    {{ company.active_job_count }} Open job</span
                  >
                </section>
              </section>
            </section>
          </div>
        </VueAIcarousel>
      </div>
    </section>

    <!--End Page Title-->

    <!-- Testimonials Section -->
    <section
      id="testimonials-section"
      class="layout-pt-60 layout-pb-120"
      style="background-color: #53cecb"
    >
      <div class="auto-container">
        <div class="row justify-content-center text-center">
          <div class="col-auto">
            <div class="">
              <h2 class="fw-700">What people's are say about our services</h2>
              <!-- <div class="font120 mt-9">
                People's opinions what people are say about our services
              </div> -->
            </div>
          </div>
        </div>
        <VueAIcarousel
          :Property="{
            ID: 'three',
            CountPos: 'CounterPosition',
            CounterStyle: 'counterNewStyle',
            CounterActiveStyle: 'CAnewStyle',
          }"
          class="mt-3"
        >
          <div
            v-for="openion in openions"
            :key="openion.id"
            style="background: none"
            class="cards2 mt-3"
          >
            <section class="company-block mt-3">
              <section style="border-radius: 10px">
                <figure class="image">
                  <img
                    class="mt-0"
                    v-if="openion.image != null"
                    :src="openion.image"
                    alt=""
                  />
                </figure>
                <h4 class="name">{{ openion.comment }}</h4>

                <p class="text-black">
                  {{ openion.description }}
                </p>
                <section  class="location text-black">
                  {{ openion.full_name }},{{ openion.designation }}
                </section>
              </section>
            </section>
          </div>
        </VueAIcarousel>
      </div>
    </section>
    <!-- End Testimonials Section -->

    <!-- Call To Action Three -->
    <section class="let-section call-to-action-three theme-color-bg4">
      <div class="auto-container">
        <div class="outer-box">
          <div class="sec-title">
            <h2>Let employers find you</h2>
            <div class="text">
              Since hive4work is a premium job board that continuously earns the
              top spot in our rankings for both employers and job seekers, we
              advise you to give it a try.
            </div>
          </div>

          <div class="btn-box">
            <a href="/job" class="theme-btn btn-style-one theme-color-bg"
              ><span class="btn-title">Search Job</span></a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- End Call To Action -->
  </div>
</template>

<script>

import $ from "jquery";
import VueAIcarousel from "vue-ai-carousel";
import moment from "moment";


export default {
  name: "HomeView",
  components: {
    VueAIcarousel,
  },

  data() {
    return {
      show: false,
      load: false,
      name: "",

      categories: [],
      JobsCategory: [
        {
          categorytitle: "Accounting / Finance",
          position: "(50 open positions",
        },
        { categorytitle: "Marketing", position: "(160 open positions" },
        { categorytitle: "Design", position: "(30 open positions)" },
        { categorytitle: "Development", position: "(25 open positions" },
        { categorytitle: "Project Management", position: "(20 open positions" },
        { categorytitle: "Health and Care", position: "(40 open positions" },
      ],

      caticon: [
        { iconurl: "fa-solid fa-hotel" },
        { iconurl: "fa-solid fa-utensils" },
        { iconurl: "fa-solid fa-wine-bottle" },
        { iconurl: "fa-solid fa-kitchen-set" },
        { iconurl: "fa-solid fa-calendar-check" },
        { iconurl: "fa-solid fa-broom" },
        { iconurl: "fa-solid fa-handshake" },
        { iconurl: "fa-solid fa-burger" },
        { iconurl: "fa-solid fa-user-nurse" },
      ],

      images: [],
      jobcount: "",

      jobs: [],
      hotjobs: [],
      topjob_data: null,
      ano_categories: [],
      search_key: "",
      popupads: [],
      adboards: [],
      openions: [],
      feature_job_time: "",
    };
  },

  methods: {
   

    get_category() {
     
          this.ano_categories = [
    {
        "id": 48,
        "uuid": "8e8fba89-ef43-40e1-ab7c-5787fa5a3668",
        "cat_name": "Chef",
        "active_job_count": 0
    },
    {
        "id": 53,
        "uuid": "f81058d8-a444-4a3d-a447-41214d8f10f3",
        "cat_name": "Waiter",
        "active_job_count": 0
    },
    {
        "id": 54,
        "uuid": "47b6f6c8-4baa-45c1-9b83-504c90dc2bc9",
        "cat_name": "Bartender",
        "active_job_count": 0
    },
    {
        "id": 55,
        "uuid": "0d9e869e-9bff-4cc6-bf0f-ac5200b52712",
        "cat_name": "Kitchen Porter",
        "active_job_count": 0
    },
    {
        "id": 56,
        "uuid": "5309106a-4722-4531-b1e6-13f01e6c5fae",
        "cat_name": "Security Guard",
        "active_job_count": 0
    },
    {
        "id": 57,
        "uuid": "37f7294f-c408-4e21-b3f7-ccc6b7d10f17",
        "cat_name": "Cleaner",
        "active_job_count": 0
    },
    {
        "id": 58,
        "uuid": "c497ba17-4785-4aec-872e-453f765cf674",
        "cat_name": "House keeping",
        "active_job_count": 0
    },
    {
        "id": 59,
        "uuid": "33c3d30d-e560-4967-8194-3a3e847903f6",
        "cat_name": "Meat/Poultry",
        "active_job_count": 0
    },
    {
        "id": 60,
        "uuid": "0bfed7c1-76f1-4f33-ac86-8f0bc402a2b4",
        "cat_name": "Dental Nurse",
        "active_job_count": 0
    },
    {
        "id": 61,
        "uuid": "5d49e96c-35d1-4ad6-948a-0f2e7b77cf77",
        "cat_name": "Logistic and Supply Chain",
        "active_job_count": 0
    }
];

    },


    

    search(key) {
      if (this.search_key != "") {
        this.$router.push({ name: "search", query: { key: this.search_key } });
      }

      if (key != "") {
        this.$router.push({ name: "search", query: { key: key } });
      }
    },

    fetchImages() {
      const jsonData = [
        { id: 1, url: "images/resource/pop1.jpg", title: "Title1" },
        { id: 2, url: "images/resource/pop2.jpg", title: "title2" },
        { id: 3, url: "images/resource/pop3.jpg", title: "title3" },
      ];

      this.images = jsonData;
    },

    topjob() {
            this.load = true;
            this.topjob_data = [
    {
        "uuid": "0ab2b84c-f88c-4350-8413-f483b1d8e814",
        "org_name": "FRESCOBALDI RESTAURANT",
        "logo": null,
        "active_job_count": 0,
        "city": "London",
        "country": "UK"
    },
    {
        "uuid": "9924946b-f98e-41a5-acaa-243946e83d0d",
        "org_name": "Takla Limited",
        "logo": {
            "logo": "images/media/2023-09/logo/20230916140455_9b6e1b7b-433d-43b9-a2f8-172d351b1f89.PNG"
        },
        "active_job_count": 0,
        "city": "London",
        "country": "UK"
    },
    {
        "uuid": "b6edae0b-c102-4be5-a908-6c053201bbf7",
        "org_name": "Dymchurch Fish Bar Ltd",
        "logo": {
            "logo": "images/media/2023-10/logo/20231005074910_fdb72078-5bbc-4aed-9afd-62b0a5740760.PNG"
        },
        "active_job_count": 0,
        "city": "Romney Marsh",
        "country": "UK"
    },
    {
        "uuid": "ea402b18-bf7e-4bfd-80bb-afaff4e312b9",
        "org_name": "Direct Defence Solutions",
        "logo": {
            "logo": "images/media/2023-12/logo/20231206121914_9fd5bf55-6b07-4566-96d8-9d78a3c5f55e.jpg"
        },
        "active_job_count": 0,
        "city": "Barking",
        "country": "UK"
    }
];
         
    },

    openjob(uuid) {
      if (uuid != null) {
        this.$router.push({ name: "job-single", query: { uuid: uuid } });
      }
    },

    open_rec_job(uuid) {
      if (uuid != null) {
        this.$router.push({ name: "recjob", query: { uuid: uuid } });
      }
    },
    opencatjob(catid, cat_name) {
      if (catid != null && cat_name != null) {
        this.$router.push({
          name: "job-category",
          query: { cart_id: catid, cart_name: cat_name },
        });
      }
    },

    opentierjob() {
      this.$router.push({
        name: "tiertojob",
        query: { cart_id: 40, cart_name: "Tier 2 Job" },
      });
    },


   
    getTime(value) {
      if (value) {
        return moment(value, "YYYY-MM-DD").fromNow();
        //  return moment(String(value)).format("DD/MM/YYYY hh:mm:ss");
      }
    },

  
  },

  mounted: function () {
    this.topjob();
    this.getjob();
    this.gethotjob();
    this.get_total_job();
    this.get_category();
    this.job_count();
    this.fetchImages();
    this.getpoup();
    this.getadboard();
    this.get_openion();
    this.showprofile();

    $(".CounterBtnCommon").css("display", "none");
  },
};
</script>

<style scoped>
.cards {
  padding: 35px;
  background: #e0eaed;
  width: 450px;
  border-radius: 10px;
  display: block;
  height: auto;
  text-align: center;
}
.cards2 {
  margin-top: 20px;
  background: #e0eaed;
  width: 350px;
  border-radius: 10px;
  display: block;
  height: auto;
  text-align: center;
}
</style>

<style>
.naveBtnStyle {
  height: 50px;
  width: 50px;
  background: orange;
}
.CounterPosition {
  margin-top: 30px;
}
.counterNewStyle {
  background: rgb(14, 204, 229);
  border-radius: 50;
  font-size: 0%;
  padding: 0px 5px !important;
  height: 10px;
  margin: 2px;
  border-radius: 20%;
}
.CAnewStyle {
  background: rgb(5, 91, 112);
}

#mySidenav {
  position: fixed;

  top: 250px;

  right: 0;
}

/* Style the links inside the sidenav */
#mySidenav a {
  position: absolute; /* Position them relative to the browser window */
  left: -20px; /* Position them outside of the screen */
  transition: 0.3s; /* Add transition on hover */
  padding: 15px 15px 15px 30px; /* 15px padding */
  width: 150px; /* Set a specific width */
  text-decoration: none; /* Remove underline */
  text-orientation: sideways-right;
  font-size: 20px; /* Increase font size */
  color: white; /* White text color */
  border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
  overflow: hidden; /* Hide scrollbars */
  overflow-y: hidden; /* Hide vertical scrollbar */
  /*overflow-x: hidden; /* Hide horizontal scrollbar */
  z-index: 14;
  opacity: 100% !important;
}

#mySidenav a:hover {
  left: 0; /* On mouse-over, make the elements appear as they should */
}

/* The about link: 20px from the top with a green background */
#about {
  top: 20px;

  background-color: #04aa6d;
}

#blog {
  top: 80px;
  background-color: #2196f3; /* Blue */
}

#projects {
  top: 140px;
  background-color: #f44336; /* Red */
}

#contact {
  top: 200px;
  background-color: #555; /* Light Black */
}
</style>
