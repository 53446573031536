<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/recruiter-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/recruiter-profile"
              ><i class="la la-user-tie"></i>Profile</a
            >
          </li>

          <li>
            <a href="/recruiter-postjob"
              ><i class="la la-paper-plane"></i>Post a New Job</a
            >
          </li>
          <li>
            <a href="/recruiter-managejob"
              ><i class="la la-briefcase"></i> Manage Jobs
            </a>
          </li>
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          <li>
            <a href="#"><i class="la la-trash"></i>Delete Profile</a>
          </li>
          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <!-- <li><a href="#"><i class="la la-lock"></i>Change Password</a></li> -->
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <!-- <li><a @click.prevent="logout" ><i class="la la-sign-out"></i>Logout</a></li> -->

          <!-- <li><img src="images/boy.png" style=" height: 400px;width: 100%;"></li> -->
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 200px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Application list</h3>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>My Job Listings</h4>
                </div>

                <div class="widget-content">
                  <div class="table-outer">
                    <table class="default-table manage-job-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Gender</th>
                          <th>DOB</th>
                          <th>Primary contact</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="application in applications"
                          :key="application.uuid"
                        >
                          <td v-if="application.applicant.basic_info != null">
                            {{ application.applicant.basic_info.first_name }}
                            {{ application.applicant.basic_info.last_name }}
                          </td>

                          <td v-if="application.applicant.basic_info != null">
                            {{ application.applicant.basic_info.gender }}
                          </td>

                          <td v-if="application.applicant.basic_info != null">
                            {{ application.applicant.basic_info.dob }}
                          </td>
                          <td v-if="application.applicant.basic_info != null">
                            {{
                              application.applicant.basic_info
                                .primary_contact_no
                            }}
                          </td>

                          <td v-if="application.applicant.basic_info != null">
                            <div class="option-box">
                              <ul class="option-list">
                                <li>
                                  <button
                                    @click.prevent="openjob(application.uuid)"
                                    data-text="Edit"
                                  >
                                    <span
                                      class="icon la la-eye"
                                      style="color: #070f0f"
                                    ></span>
                                  </button>
                                </li>
                                <!-- <li><button data-text="Reject Aplication"><span class="la la-pencil" style="color:   #b4e515;"></span></button></li> -->
                                <!-- <li><button data-text="Delete"><span class="icon la la-trash" style="color:   #744812;"></span></button></li> -->
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->

    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "EmployerManageJobView",

  data() {
    return {
      applications: [],
    };
  },

  methods: {
    getjob() {
      axios
        .get("recruiter/jobpost/")
        .then((res) => {
          this.jobs = res.data.results;

          if (res.status == 200) {
            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    openjob(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "applicant-list-single",
          query: { uuid: uuid },
        });
      }
    },

    get_jobss(key) {
      axios
        .get("recruiter/jobpost/", {
          params: {
            search: key,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.jobs = res.data.results;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    showjob(text) {
      if (text != null) {
        this.get_jobss(text);
      }
    },

    getApplicant(uuid) {
      axios
        .get("recruiter/jobpost/" + uuid + "/application/")
        .then((res) => {
          this.applicant = res.data;
          this.applications = res.data.application;

          if (res.status == 200) {
            console.log("Appicants received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted: function () {
    var uuid = this.$route.query.uuid;

    if (uuid != null) {
      this.getApplicant(uuid);
      this.uuide = uuid;
    }
  },
};
</script>

<style></style>
