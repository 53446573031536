<template>
  <div>
    <!-- Map Section -->
    <!--section class="map-section">
      <div class="map-outer">
        <div class="map-canvas" data-zoom="12" data-lat="-37.817085" data-lng="144.955631" data-type="roadmap" data-hue="#ffc400" data-title="Envato" data-icon-path="images/icons/contact-map-marker.png" data-content="Melbourne VIC 3000, Australia<br><a href='mailto:info@youremail.com'>info@youremail.com</a>">
        </div>
      </div>
    </section-->
    <!-- End Map Section -->

    <br /><br /><br />
    <section class="page-title style-two">
      <div class="auto-container">
        <div class="row justify-content-left">
          <div class="col-lg-7 py-5">
            <div class="sec-title light">
              <h2
                class="text-white mt-3 text-left"
                style="font-size: 60px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:white"
              >
                Simple To Start !
              </h2>
              <div
                class="text-white mt-3 text-left"
                style="font-size: 25px;line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:white"
              >
                The answer to the most FAQ about Hive4work can be found here
              </div>

              <div
                class="text-white mt-3 text-left"
                style="font-size: 17px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
              >
                Any new query, you can
                <a href="/contact" style="color: #eab81e">Contact With Us</a>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <img src="images/faq.png" style="height: 100%; width: 100%" />
          </div>
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Faqs Section -->

    <section class="faqs-section">
      <div class="auto-container">
        <!-- <div class="sec-title text-center">
          <h2>Frequently Asked Questions</h2><br>
          <h5>Hive4work is amaizing</h5> 
          <p>The answers to the most frequently asked questions about Hive4work can be found here. Any new query, you can <a href="/contact">Contact With Us</a></p>
          <div class="text">Home / Faq</div>
        </div> -->

        <h3>For Jobseeker</h3>
        <!--Accordian Box-->
        <ul class="accordion-box">
          <!--Block-->
          <li class="accordion block active-block">
            <div class="acc-btn">
              How can I apply for a Job?<span class="icon flaticon-add"></span>
            </div>
            <div class="acc-content">
              <div class="content">
                <p>
                  For applying for a job, you need to signup first. Go to the
                  login page and click signup. After signing up, you can apply
                  for a job from your (Jobseeker) dashboard.
                </p>
              </div>
            </div>
          </li>

          <!--Block-->
          <li class="accordion block">
            <div class="acc-btn">
              Do I need to pay for a subscription?<span
                class="icon flaticon-add"
              ></span>
            </div>
            <div class="acc-content">
              <div class="content">
                <p>
                  No, currently Hive4work does not charge any subscription fee.
                </p>
              </div>
            </div>
          </li>

          <!--Block-->
          <li class="accordion block">
            <div class="acc-btn">
              How many jobs I can apply for?<span
                class="icon flaticon-add"
              ></span>
            </div>
            <div class="acc-content">
              <div class="content">
                <p>
                  There is no limitation for applying for a job. You can apply
                  for as many jobs as you want.
                </p>
              </div>
            </div>
          </li>

          <!--Block-->
          <li class="accordion block">
            <div class="acc-btn">
              How does my personal data will be preserved?<span
                class="icon flaticon-add"
              ></span>
            </div>
            <div class="acc-content">
              <div class="content">
                <p>
                  We are very cautious regarding our user data security and
                  privacy. Please visit “Terms & Conditions” for more details.
                </p>
              </div>
            </div>
          </li>
        </ul>

        <h3>For Employer</h3>
        <!--Accordian Box-->
        <ul class="accordion-box">
          <!--Block-->
          <li class="accordion block active-block">
            <div class="acc-btn">
              How can I post a job?<span class="icon flaticon-add"></span>
            </div>
            <div class="acc-content">
              <div class="content">
                <p>
                  For publishing a job, you need to signup first. Go to the
                  login page and click Signup. After signing up, you can post a
                  job from your (Employer) dashboard.
                </p>
              </div>
            </div>
          </li>

          <!--Block-->
          <li class="accordion block">
            <div class="acc-btn">
              Do I need to pay for a subscription?<span
                class="icon flaticon-add"
              ></span>
            </div>
            <div class="acc-content">
              <div class="content">
                <p>
                  No, currently Hive4work does not charge any subscription fee.
                </p>
              </div>
            </div>
          </li>

          <!--Block-->
          <li class="accordion block">
            <div class="acc-btn">
              How many jobs I can post in a month?<span
                class="icon flaticon-add"
              ></span>
            </div>
            <div class="acc-content">
              <div class="content">
                <p>
                  Currently, there is no limitation for publishing jobs. You can
                  publish as many jobs as you want for your company.
                </p>
              </div>
            </div>
          </li>

          <!--Block-->
          <li class="accordion block">
            <div class="acc-btn">
              I do not want to subscribe, but I want to hire directly. Can I
              hire directly?<span class="icon flaticon-add"></span>
            </div>
            <div class="acc-content">
              <div class="content">
                <p>
                  If you want to hire directly instead of subscribing, please
                  submit your query through “Direct Hiring” available on the
                  Home page, or click here. One of our customer support team
                  members will contact at the earliest.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <!-- End Faqs Section -->
    <br />

    <!-- Call To Action -->
    <section class="call-to-action style-two">
      <div class="auto-container">
        <div class="outer-box">
          <div class="content-column">
            <div class="sec-title">
              <h2>Recruiting?</h2>
              <div class="text">
                Advertise your jobs to hundreds of monthly users and search
                10k<br />
                CVs in our database.
              </div>
              <a href="/login" class="theme-btn btn-style-one theme-color-bg"
                ><span class="btn-title">LOGIN</span></a
              >
            </div>
          </div>

          <div
            class="image-column"
            style="background-image: url(images/resource/pop2.jpg)"
          >
            <figure class="image">
              <img src="images/resource/pop11.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <!-- End Call To Action -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "NeedHelpView",

  methods: {
    direct_message() {
      axios
        .post(
          "anonymous/direct-message/",
          {
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message,
          },
          { ignore: "1" }
        )
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted: function () {
    this.direct_message();
  },
};
</script>

<style></style>
