<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
          <li>
            <a href="/admin-payment-status"
              ><i class="la la-comment-o"></i>Payment Status</a
            >
          </li>
          <li>
            <a href="/admin-delete-profile"
              ><i class="la la-trash"></i>Delete Profile</a
            >
          </li>
          <!-- <li><a href="index.html"><i class="la la-trash"></i>Publish Ads</a></li> -->
          <li class="active">
            <a href="/admin-change-password"
              ><i class="la la-lock"></i>Change Password</a
            >
          </li>
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <li>
            <a href="/login"><i class="la la-sign-out"></i>Logout</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- End User Sidebar -->

    <!--Page Title-->

    <!--End Page Title-->

    <!-- Listing Section -->
    <section class="page-title style-two">
      <div class="auto-container">
        <div class="title-outer">
          <h1>Change Password</h1>
          <ul class="page-breadcrumb">
            <li><a href="/">Dashboard</a></li>
            <li>Change Password</li>
          </ul>
        </div>
        <br />
        <br />
        <!-- Info Section -->
        <div class="login-section">
          <div
            class="image-layer"
            style="background-image: url(images/background/12.jpg)"
          ></div>
          <div class="outer-box">
            <!-- Login Form -->
            <div class="login-form default-form">
              <div class="form-inner">
                <h3>Input information to Change Password</h3>

                <!--Login Form-->
                <form method="post" action="add-parcel.html">
                  <div class="form-group">
                    <label>Email Address</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label>Password</label>
                    <input
                      id="password-field"
                      type="password"
                      name="old-password"
                      value=""
                      placeholder="Old Password"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      id="new-password"
                      type="password"
                      name="new-password"
                      value=""
                      placeholder="New Password"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      id="confirm-password"
                      type="password"
                      name="confirm-password"
                      value=""
                      placeholder="Confirm Password"
                    />
                  </div>
                  <div class="form-group">
                    <button
                      class="theme-btn btn-style-one"
                      type="submit"
                      name="change-password"
                    >
                      Change Password
                    </button>
                  </div>
                </form>
                <br />
                <br />

                <form method="post" action="add-parcel.html">
                  <div class="form-group">
                    <label>Type/paste OTP from your email</label>
                    <input type="text" name="otp" placeholder="OTP" required />
                  </div>

                  <div class="form-group">
                    <button
                      class="theme-btn btn-style-two"
                      type="submit"
                      name="submit-otp"
                    >
                      Submit OTP
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <!--End Login Form -->
          </div>
        </div>
        <!-- End Info Section -->
      </div>
    </section>

    <!--End Listing Page Section -->
  </div>
</template>

<script>
export default {
  name: "AdminChangePasswordView",
};
</script>

<style></style>
