<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li class="active">
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
         
        </ul>
      </div>
    </div>

    <!-- End User Sidebar -->

    <!--Page Title-->

    <!--End Page Title-->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Manage Jobs</h3>
        </div>

        <p class="p-3">
          Showing {{ jobs.length }} from {{ all_data.count }} item
        </p>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <div class="chosen-outer">
                    <!--Tabs Box-->
                    <!-- <select class="chosen-select">
                        <option>Last 6 Months</option>
                        <option>Last 12 Months</option>
                        <option>Last 16 Months</option>
                        <option>Last 24 Months</option>
                        <option>Last 5 year</option>
                      </select> -->
                  </div>
                </div>

                <div class="widget-content">
                  <div class="table-outer">
                    <table class="default-table manage-job-table">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Recruiter</th>
                          <th>Opening & Closing</th>
                          <th>Approval</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="job in jobs" :key="job.id">
                          <td>
                            <h6>{{ job.job_title }}</h6>
                            <span class="info"
                              ><i class="icon flaticon-map-locator"></i> London,
                              UK</span
                            >
                          </td>
                          <td>{{ job.recruiter.org_name }}</td>
                          <td>
                            {{ format_date(job.opening_date) }} <br />{{
                              format_date(job.expire_date)
                            }}
                          </td>

                          <td>
                            <div class="option-box text-center">
                              <ul class="option-list text-center">
                                <li  v-if="job.status == 'approved'">
                              
                                    <span class="text-success">Approved</span>
                           
                                </li>
                                <li
                                  
                                  v-else-if="job.status == 'reviewing'"
                                >  

                          
                                 
                                    <span class="text-danger">Need Approval</span>
                                 
                                
                                  
                                </li>

                                <li
                                  
                                  v-else-if="job.status == 'rejected'"
                                >  

                          
                                 
                                    <span class="text-danger">Need Approval</span>
                                 
                                
                                  
                                </li>

                                <li>
                                  <button
                                    @click.prevent="openjob(job.uuid)"
                                    data-text="Edit"
                                  >
                                    <span
                                      class="icon la la-eye"
                                      style="color: #070f0f"
                                    ></span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <nav class="ls-pagination">
                      <ul>
                        <li v-if="all_data.previous != null" class="prev">
                          <a @click.prevent="page(current_page - 1)"
                            ><i class="fa fa-arrow-left"></i
                          ></a>
                        </li>
                        <li v-for="i in pages" :key="i">
                          <a
                            :class="{ 'current-page': current_page == i }"
                            @click.prevent="page(i)"
                            >{{ i }}</a
                          >
                        </li>

                        <li v-if="all_data.next != null" class="next">
                          <a @click.prevent="page(current_page + 1)"
                            ><i class="fa fa-arrow-right"></i
                          ></a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->

    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "AdminManageJobView",

  data() {
    return {
      jobs: [],

      apr: "approved",
      current_page: 1,
      next_page: "",
      next: "",
      previous: "",
      pages: 1,
      total_item: "",

      all_data: {},
    };
  },

  methods: {
    getjob() {
      axios
        .get("app_admin/jobList/?page=1&page_size=10")
        .then((res) => {
          if (res.status == 200) {
            this.jobs = res.data.results;
            this.total_item = res.data.count;
            this.all_data = res.data;
            if (this.total_item > 0) {
              this.pages = Math.ceil(this.total_item / 10);
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    page(page_number) {
      axios
        .get("app_admin/jobList/?page=" + page_number + "&page_size=" + 10)
        .then((res) => {
          if (res.status == 200) {
            this.current_page = page_number;

            this.jobs = res.data.results;
            this.all_data = res.data;

            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    approve(uuid) {
      axios
        .put(
          "app_admin/jobpost/update/" + uuid + "/",

          {
            status: this.apr,
          }
        )
        .then((res) => {
          if (res.status == 200) {
           
            this.getjob();
            this.$emit("get_message", " Job Approved !");

          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    review(uuid) {
      axios
        .put(
          "app_admin/jobpost/update/" + uuid + "/",

          {
            status: "reviewing",
          }
        )
        .then((res) => {
          if (res.status == 200) {
            console.log("Data received");
            this.getjob();
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    openjob(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "admin-job-view",
          query: { uuid: uuid },
        });
      }
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("MMM DD, YYYY");
      }
    },
  },

  mounted: function () {
    this.getjob();
  },
};
</script>

<style></style>
