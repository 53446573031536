<template>
  <div class="page-wrapper">
    <!-- Preloader -->
    <!-- <div class="preloader"></div -->

    <div class="loader-large">
      <img
        style="height: 80px; width: 80px"
        src="../../../public/images/resource/favicon.png"
      />
      <span class="loading__dot_large"></span>
      <span class="loading__dot_large"></span>
      <span class="loading__dot_large"></span>
    </div>
    <div v-if="msg" id="message_popup" class="alert alert-success">
      <h4>{{ msg }}</h4>
    </div>

    <!-- Main Header-->
    <header-view  @get_message="message_show" ></header-view>

    <router-view @get_message="message_show"></router-view>
    <!--End Main Header -->

    <!-- Main Footer -->
    <footer-view @get_message="message_show"></footer-view>
    <!-- End Main Footer -->
  </div>
  <!-- End Page Wrapper -->
</template>

<script>
import HeaderView from "@/components/frontend/HeaderView.vue";
import FooterView from "@/components/frontend/FooterView.vue";
export default {
  name: "MasterView",
  components: { HeaderView, FooterView },

  data() {
    return {
      msg: "",
    };
  },

  methods: {
    message_show(message) {

 
      this.msg = message;

      alert(this.msg)

     
 
      this.message_hide()
     
    },

    confirm_btn(){
     
      if(this.dataToPass==false){
        this.dataToPass=true
      }else{
        this.dataToPass=false

  

      }

      document.getElementById("delete_popup").style.display = "none";
    

    },

    cancel_btn() {
      document.getElementById("delete_popup").style.display = "none";

    },

    delete_show(message) {

 
 
  

    this.delete_msg = message;



    this.delete_popup_show();

    if ( document.getElementById('delete_popup') ){
      const box = document.getElementById("delete_popup");
    box.style.display = "block";


    }







},

  delete_popup_show(){


  },

    message_hide() {

   
    

      setTimeout(() => {
     
      document.getElementById("message_popup").style.display = "none";

      }, 2000);

      
    if ( document.getElementById('message_popup') ){
      const box = document.getElementById("message_popup");
    box.style.display = "block";


    }


      

    },



  
  },
  created() {
    var scripts = ["frontend/js/script.js"];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.getElementById("allscript").appendChild(tag);
    });

    window.onclick = function () {
      if (document.getElementById("message_popup") != null) {
        document.getElementById("message_popup").style.display = "none";
      }
    };
  },
};
</script>

<style></style>
