<template>
  <br />
  <br />
  <br />
  <!-- TNC Section -->
  <section class="tnc-section">
    <div class="auto-container">
      <div class="sec-title text-center">
        <h2>Terms and Conditions</h2>
      </div>

      <div class="text-box">
        <!--h3>1. Terms<h3-->
        <p>
          You are presumed to have accepted these terms and conditions each time
          you access or use our website and/or mobile device application
          (collectively, the "Website").
        </p>
        <p>
          "Our" and "Hive4work Ltd." should be understood in this context. The
          terms "you" and "your" refer to the individual, business, or
          organization viewing and/or using the website, respectively. All
          businesses associated with Hive4work Ltd collectively constitute our
          corporate family. If a company fits into one of the following
          categories, it is considered to be related to us: (i) a subsidiary or
          holding company of us; (ii) controlled by the same person or people
          that own us or our holding company; (iii) a subsidiary or holding
          company of any of the companies in (i) or (ii) above; or (iv) in the
          same group as any of the companies in (i), (i), or (iii) above. The
          definitions of "holding company" and "subsidiary" in Section 1159 of
          the Companies Act 2006 shall apply. The definition of "control" in
          Section 416 of the Income and Corporation Taxes Act of 1988 shall
          apply to the term "control." If two businesses have the same ultimate
          holding company, they belong to the same group.
        </p>
      </div>

      <div class="text-box">
        <h3>Interruptions and Omissions in Service</h3>
        <p>
          Although we make every effort to maintain the Website's high standards
          and continuity, errors, omissions, service outages, and delays can
          happen at any time since the internet is not an inherently reliable
          medium. The operation of the Website (or any specific portion of it)
          or the provision of the service made available on the Website are
          continuing obligations or responsibilities that we do not accept
          liable for. The specifications of this website may occasionally change
          without prior notification.
        </p>
      </div>

      <div class="text-box">
        <h3>Links to other sites</h3>
        <p>
          Automatic links to other websites that we think you'll find
          interesting will be provided on this website. Since the owners of
          those websites may not necessarily have any relationship with us,
          either commercially or otherwise, we do not accept any obligation or
          responsibility for their content. You do it fully at your own risk if
          you choose to access such sites using automatic links.
        </p>
      </div>

      <div class="text-box">
        <h3>Information on this Site</h3>
        <p>
          Despite our best efforts to assure the authenticity and completeness
          of the material on our website, some of it is provided to us by
          outside sources, and we are unable to verify its veracity. We disclaim
          all responsibility for any errors or omissions in the content on our
          website, as well as any responsibility for information posted on the
          website that was provided by you, another website user, or a third
          party.
        </p>
      </div>

      <div class="text-box">
        <h3>Your Use of this Site</h3>
        <p>
          The Website may only be used for legitimate work-related or
          career-related purposes. Under no circumstances may you attempt to
          compromise the integrity or security of the Website or any information
          provided on or accessible via it. In particular, but not exclusively,
          you must not attempt to access, modify, or delete any information to
          which you do not have authorized access, try to overwhelm the system
          by spamming or flooding, take any action or use any tool, routine, or
          program to obstruct, delay, harm, or otherwise interfere with the
          operation of the Website, or make any attempt to decipher,
          disassemble, or modify any of the software, coding, or data contained
          on the Website.
        </p>
        <p>
          Any information you provide to the website is your exclusive
          responsibility. You are responsible for making sure that all of the
          information you provide is true, accurate, current, not defamatory,
          obscene, offensive, or otherwise against the law or in violation of
          any applicable laws, rules, guidelines, or codes of practice, or the
          copyright, trade mark, or other intellectual property rights of any
          person in any jurisdiction. Before submitting any information, data,
          or files to the Website, you must make sure that it is free of viruses
          and any other programs or engines that could harm or interfere with
          any system or data. We have the right to delete any information you
          submit to the website at any time, for any reason, and without having
          to provide a justification.
        </p>
      </div>

      <div class="text-box">
        <h3>Information Submitted by You</h3>
        <p>
          Your information, including sensitive personal data, will be used by
          us and/or the Hive4work Ltd Corporate Family to facilitate the hiring
          process and related administrative procedures. This includes, among
          other things, the processing and storage of information (including,
          without limitation, sensitive personal data) and the transmission or
          online availability of such information to potential employers and
          clients. As part of the hiring process, we enlist the assistance of
          third parties to process your information. In order to better serve
          you, we may gather and combine data from the information you supply to
          us in order to better understand our users as a whole. With carefully
          chosen third parties, we may also share aggregate data without
          revealing any specific names or other personal information. You agree
          that we may use the information you supply to us, including without
          limitation sensitive personal information, in any of the methods
          described above.
        </p>
        <p>
          Please be aware that all Third Party clients have agreed to our Terms
          and Conditions and that they will no longer be able to use our
          services if they are found to be in violation of those Terms and
          Conditions.
        </p>
        <p>
          Any information you supply when filling out the online registration or
          application forms, as well as any other forms, questionnaires, or
          personal information you provide to us while using the Website, will
          be processed in compliance with UK data protection laws. In our
          Privacy Policy, we go into further detail concerning your personal
          information.
        </p>
        <p>
          Transfer beyond the EEA: Third parties outside the European Economic
          Area ("EEA") may access the database of this website through which
          your CV contains personal information. For example, if you apply for a
          position where the employer is headquartered outside the EEA, this
          could occur. You provide your agreement to this transfer by
          registering and using the website.
        </p>
      </div>

      <div class="sec-title text-center">
        <h2>Terms of Business</h2>
      </div>

      <div class="text-box">
        <h3>Recruitment</h3>
        <p>
          Every employment assignment or placement that results from a referral
          provided by Hive4work Ltd Ltd is subject to those provisions of our
          normal Terms of Business that apply in that specific situation. A copy
          of the Terms of Business that apply to them will be given to all
          potential customers and employers for whom we organize assignments or
          placements at or after registration. The usage of our Recruiter site
          is also subject to other terms and conditions.
        </p>
      </div>

      <div class="text-box">
        <h3>Content Rights</h3>
        <p>
          You agree to use the website in a way that respects the international
          copyright, software, and trademark laws that protect the rights to the
          content on the website. You are allowed to copy content from the
          website for personal or domestic use only; commercial or business use
          is not permitted
        </p>
      </div>

      <div class="text-box">
        <h3>Security and Passwords</h3>
        <p>
          You will require a username and password in order to register with the
          website and sign in each time you visit it. Your password should
          always be kept private and should never be shared with anyone else.
          You are entirely responsible for the security and proper usage of your
          password. If you suspect that someone else knows your password or that
          it might be used in an unauthorized manner, you must inform us right
          away. We disclaim all responsibility for any unauthorized or
          inappropriate use of a password or its disclosure.
        </p>
      </div>

      <div class="text-box">
        <h3>Termination</h3>
        <p>
          At any time, at our sole discretion, and without providing any
          justification or notification, we may cancel your registration and/or
          refuse you access to the Website or any portion of it (including any
          services, products, or information offered on or through the Website).
        </p>
      </div>

      <div class="text-box">
        <h3>Liability</h3>
        <p>
          We hereby disclaim all liability for any loss (whether direct or
          indirect, for loss of business, revenue, or profits, wasted expenses,
          corruption or destruction of data, or for any other indirect or
          consequential loss of any kind) resulting from your use of the
          Website. This disclaimer applies regardless of whether the liability
          arises from a contract, tort (including negligence), or other cause.
          To the fullest extent permissible by law, we expressly disclaim any
          and all representations, warranties, and conditions with respect to
          the Website and your use of it.
        </p>
        <p>
          You acknowledge that your use or misuse of the Website will subject us
          to charges, expenditures, claims, damages, liabilities, and legal
          action.If somebody asserts a claim against you pertaining to your use
          of the Website, you must let us know right away.
        </p>
      </div>

      <div class="text-box">
        <h3>Choice of Law and Jurisdiction</h3>
        <p>
          English law shall govern the use of the Website and the interpretation
          of any agreements made through the Website. Any dispute arising out of
          or related to the use of the Website or any agreement made through the
          Website shall be exclusively brought before the English courts.
        </p>
        <p>
          In some nations outside of the United Kingdom, some of the products or
          services offered via the website might not be legal or might not be
          permitted for other reasons. If you try to order, receive, pay for, or
          otherwise take advantage of any such goods or services, we disclaim
          all responsibility for any losses you might have experienced had you
          been accessing the website as a resident of the United Kingdom
          (including if we were unable to respond to your application or answer
          your query due to local laws, rules, or regulations).
        </p>
      </div>

      <div class="text-box">
        <h3>Changes to Terms and Conditions and Invalidity</h3>
        <p>
          We have the right to modify these terms and conditions at any time.
          When you use the Website again after any alteration, you will be
          deemed to have accepted the terms and conditions (as amended).
        </p>
        <p>
          The validity of the remaining sections, which shall remain in full
          force and effect, shall not be affected if any provision of these
          terms and conditions is found to be unenforceable by a court of
          competent jurisdiction.
        </p>
      </div>
    </div>
  </section>
  <!-- End TNC Section -->
</template>

<script>
export default {
  name: "TermsView",
};
</script>

<style></style>
