<template>
  <div>
    <!-- Map Section -->
    <!--section class="map-section">
      <div class="map-outer">
        <div class="map-canvas" data-zoom="12" data-lat="-37.817085" data-lng="144.955631" data-type="roadmap" data-hue="#ffc400" data-title="Envato" data-icon-path="images/icons/contact-map-marker.png" data-content="Melbourne VIC 3000, Australia<br><a href='mailto:info@youremail.com'>info@youremail.com</a>">
        </div>
      </div>
    </section-->
    <!-- End Map Section -->

    <!-- <br /><br /><br /> -->
    <!-- <section class="page-title style-two">
      <div class="auto-container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="sec-title light">
          <h2 style="font-size: 45px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500;" >Have More Questions?</h2>
          <div class="text" style="font-size: 22px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">You can contact us using any of the following methods!</div>
        </div>
          </div>
        </div>

        
        
      </div>
    </section> -->
    <!--End Page Title-->

    <!-- <br /><br /><br />
    <br /><br /><br />
    <br><br> -->

    <!-- Contact Section -->
    <section class="contact-section">
      <div class="auto-container">
        <!-- <div class="upper-box">
          <div class="row">
            <div class="contact-block col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <span class="icon las la-map-marker-alt" style="font-size: 300%;"></span>
                <p style="color: black;">128-130 The Grove, Stratford<br>London England, 15 1NS</p>
              </div>
            </div>
            <div class="contact-block col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <span class="icon las la-mobile" style="font-size: 300%;" ></span>
                
                <p><a href="#" style="color: black;" class="phone">+44 7495 587992</a></p>
              </div>
            </div>
            <div class="contact-block col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <span class="icon  lar la-envelope" style="font-size: 300%;"></span>
                
                <p><a href="#" style="color: black;" >support@hive4work.co.uk</a></p>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <br><br> -->

        <!-- Contact Form -->
        <div class="contact-form default-form">
          <h3>Fill Up For Enroll Course</h3>
          <!--Contact Form-->
          <form @submit.prevent="direct_message()">
            <div class="row">
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <div class="response"></div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12 form-group">
                <label>Your Name*</label>
                <input
                  type="text"
                  v-model="name"
                  class="username"
                  placeholder="Your Name*"
                  required
                />
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12 form-group">
                <label>Your Email*</label>
                <input
                  type="email"
                  v-model="email"
                  class="email"
                  placeholder="Your Email*"
                  required
                />
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12 form-group">
                <label>Your Mobile No.*</label>
                <input
                  type="number"
                  v-model="subject"
                  class="subject"
                  placeholder="+44 1234 123456"
                  pattern="[+]{1}[0-9]{2} [0-9]{4} [0-9]{6}"
                  required
                />
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <label>Reason for choosing the course*</label>
                <textarea
                  v-model="message"
                  placeholder="Why choosing the course"
                  required=""
                ></textarea>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <button
                  class="theme-btn btn-style-one"
                  type="submit"
                  id="submit"
                  name="submit-form"
                >
                  Enroll
                </button>
              </div>
            </div>
          </form>
        </div>
        <!--End Contact Form -->
      </div>
    </section>
    <!-- Contact Section -->

    <!-- Call To Action -->
    <section class="call-to-action style-two">
      <div class="auto-container"></div>
    </section>
    <!-- End Call To Action -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactView",

  methods: {
    direct_message() {
      axios
        .post(
          "anonymous/course_enrollment/",
          {
            course: this.$route.query.id,
            full_name: this.name,
            email: this.email,
            contact_no: this.subject,
            reason: this.message,
          },
          { ignore: "1" }
        )
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);

            this.$emit("get_message", "Enrolled Successfully");
            // alert("ok")
            this.$router.push("/course");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
