<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/recruiter-dashboard">
              <i class="la la-home"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/recruiter-profile"
              ><i class="la la-user-tie"></i>Profile</a
            >
          </li>
          <li class="active">
            <a href="/recruiter-postjob"
              ><i class="la la-paper-plane"></i>Post a New Job</a
            >
          </li>
          <li>
            <a href="/recruiter-managejob"
              ><i class="la la-briefcase"></i> Manage Jobs
            </a>
          </li>
          <!-- <li>
            <a href="/employer-allapplicants"
              ><i class="la la-file-invoice"></i> All Applicants</a
            >
          </li> -->
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          <li>
            <a href="#"><i class="la la-trash"></i>Delete Profile</a>
          </li>
          <!-- <li>
            <a href="#"
              ><i class="la la-lock"></i>Change Password</a
            >
          </li> -->

          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <!-- <li>
            <a  @click.prevent="logout"><i class="la la-sign-out"></i>Logout</a>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section @submit.prevent="postjob" class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Update the Job</h3>
          <div class="text text-danger">1.* field is mendotory</div>
          <div class="text">
            2.After filling out the relevant fields, click the + button.
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box p-3">
                <div class="widget-content">
                  <form class="default-form">
                    <div class="row">
                      <!-- Input -->

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Job Title*</label>
                        <input
                          type="text"
                          v-model.trim="posted_job.job_title"
                          placeholder="Title"
                          @input="clearError('Title')"
                        />

                        <div id="Title" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Code</label>
                        <input
                          type="text"
                          v-model.trim="posted_job.code"
                          placeholder="Code"
                          @input="clearError('Code')"
                        />
                        <div id="Code" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Education*</label>
                        <input
                          type="text"
                          v-model.trim="posted_job.education"
                          placeholder="Education"
                          @input="clearError('Education')"
                        />
                        <div id="Education" class="text-danger error"></div>
                      </div>

                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Job Profile*</label>
                        <input 
                          type="text"
                          v-model.trim="posted_job.job_profile"
                          placeholder="Job Profile"
                          @input="clearError('Profile')"
                        />

                        <div id="Profile" class="text-danger error"></div>
                      </div> -->

                      <!-- About Company -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Job Responsibility*</label>
                        <textarea
                          v-model.trim="posted_job.responsibility"
                          placeholder="Job Responsibility"
                          @input="clearError('Responsibility')"
                        ></textarea>

                        <div
                          id="Responsibility"
                          class="text-danger error"
                        ></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Requirement*</label>
                        <textarea
                          v-model.trim="posted_job.requirement"
                          placeholder="Requirement"
                          @input="clearError('Requirement')"
                        ></textarea>
                        <div id="Requirement" class="text-danger error"></div>
                      </div>

                      

                      <!-- <div class="form-group col-lg-6 col-md-12"">
                        <label>Skills*</label>

                        <div class="row">
                          <div class="col-10">
                            <input
                              type="text"
                              v-model.trim="adds"
                              placeholder="Add skils"
                              @input="clearError('Skill')"
                            />
                          </div>
                          <div class="col-2">
                            <button
                              class="theme-button h-100 w-100"
                              @click.prevent="addskill"
                            >
                              ADD
                            </button>
                          </div>
                        </div>

                        <div v-if="skills != ''" class="mt-2">
                          <span
                            v-for="skill in skills"
                            :key="skill"
                            class="mt-2 addElement"
                          >
                            {{ skill }}
                            <i
                              @click="removeEle(skill)"
                              class="fa-solid fa-xmark removeElebtn"
                            ></i>
                          </span>
                        </div>

                        <div id="Skill" class="text-danger error"></div>
                      </div> -->

                      <!-- <div class="form-group col-lg-6 col-md-12"">
                        <label>Age Ranges*</label>

                        <div class="row">
                          <div class="col-md-6">
                            <input
                              type="number"
                              v-model.trim="min_age"
                              placeholder="Minimum"
                              @input="clearError('Minimum')"
                            />

                            <div id="Minimum" class="text-danger error"></div>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="number"
                              v-model.trim="max_age"
                              placeholder="Maximum"
                              @input="clearError('Maximum')"
                            />

                            <div id="Maximum" class="text-danger error"></div>
                          </div>
                        </div>

                        <div v-html="add1" class="mt-2"></div>
                      </div> -->

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Exprience Ranges*</label>

                        <div class="row">
                          <div class="col-md-6">
                            <input
                              type="number"
                              v-model.trim="min_exp"
                              placeholder="Minimum  "
                              @input="clearError('Minimumexp')"
                            />

                            <div
                              id="Minimumexp"
                              class="text-danger error"
                            ></div>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="number"
                              v-model.trim="max_exp"
                              placeholder="Maximum"
                              @input="clearError('Maximumexp')"
                            />

                            <div
                              id="Maximumexp"
                              class="text-danger error"
                            ></div>
                          </div>
                        </div>
                        <div v-html="add2" class="mt-2"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Salary Type</label>
                        <select v-model="posted_job.salary_type">
                          <option disabled value="null">Select a option</option>

                          <option value="hourly">hourly</option>
                          <option value="monthly">monthly</option>
                          <option value="yearly">yearly</option>
                        </select>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Job Type*</label>
                        <select
                          v-model="posted_job.job_type"
                          @change="clearError('Job-type')"
                        >
                          <option disabled value="null">Select a option</option>
                          <option value="full-time">Full time</option>
                          <option value="part-time">Part time</option>
                          <option value="contractual">Contractual</option>
                        </select>
                        <div id="Job-type" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Salary Range</label>

                        <div class="row">
                          <div class="col-md-6">
                            <input
                              type="number"
                              v-model.trim="min_salary"
                              placeholder="Minimum salary "
                              @input="clearError('Minimum-salary')"
                            />

                            <div
                              id="Minimum-salary"
                              class="text-danger error"
                            ></div>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="number"
                              v-model.trim="max_salary"
                              placeholder="Maximum salary"
                              @input="clearError('Maximum-salary')"
                            />

                            <div
                              id="Maximum-salary"
                              class="text-danger error"
                            ></div>
                          </div>
                        </div>
                        <div v-html="add4" class="mt-2"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Benefits</label>

                        <div class="row">
                          <div class="col-10">
                            <input
                              type="text"
                              v-model.trim="bene"
                              placeholder="Add Benefits"
                              @input="clearError('Benefit')"
                            />
                          </div>
                          <div class="col-2">
                            <button
                              class="theme-button h-100 w-100"
                              @click.prevent="addbeni"
                            >
                              ADD
                            </button>
                          </div>
                        </div>
                        <div v-if="benefits != ''" class="mt-2">
                          <span
                            v-for="benefit in benefits"
                            :key="benefit"
                            class="mt-2 addElement"
                          >
                            {{ benefit }}
                            <i
                              @click="removeElebeni(benefit)"
                              class="fa-solid fa-xmark removeElebtn"
                            ></i>
                          </span>
                        </div>

                        <div id="Benefit" class="text-danger error"></div>
                      </div>

                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Other Benefits</label>
                        <div class="row">
                          <div class="col-10">
                            <input
                              type="text"
                              v-model.trim="other"
                              placeholder="Add Other Benefits"
                              @input="clearError('Other-Benifits')"
                            />
                          </div>
                          <div class="col-2">
                            <button
                              class="theme-button h-100 w-100"
                              @click.prevent="addother"
                            >
                              ADD
                            </button>
                          </div>
                        </div>
                        <div v-if="others != ''" class="mt-2">
                          <span
                            v-for="other in others"
                            :key="other"
                            class="mt-2 addElement"
                          >
                            {{ other }}
                            <i
                              @click="removeEleother(other)"
                              class="fa-solid fa-xmark removeElebtn"
                            ></i>
                          </span>
                        </div>

                        <div
                          id="Other-Benifits"
                          class="text-danger error"
                        ></div>
                      </div> -->

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Keywords*</label>
                        <div class="row">
                          <div class="col-10">
                            <input
                              type="text"
                              v-model.trim="keyword"
                              placeholder="Add Keywords"
                              @input="clearError('keyword')"
                            />
                          </div>
                          <div class="col-2">
                            <button
                              class="theme-button h-100 w-100"
                              @click.prevent="addkeywords"
                            >
                              ADD
                            </button>
                          </div>
                        </div>
                        <div v-if="keywords != ''" class="mt-2">
                          <span
                            v-for="keyword in keywords"
                            :key="keyword"
                            class="mt-2 addElement"
                          >
                            {{ keyword }}
                            <i
                              @click="removekeyword(keyword)"
                              class="fa-solid fa-xmark removeElebtn"
                            ></i>
                          </span>
                        </div>

                        <div id="keyword" class="text-danger error"></div>
                      </div>

                      <!-- Input -->
                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Application Opening Date*</label>

                        <input
                          class="form-control"
                          type="date"
                          v-model="opening_date"
                          placeholder="06.04.2020"
                        />
                      </div> -->

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Application Opening Date*</label>
                        <input
                          class="form-control"
                          type="date"
                          v-model.trim="posted_job.opening_date"
                          placeholder="06.04.2020"
                          @input="clearError('opening-date')"
                        />

                        <div id="opening-date" class="text-danger error"></div>
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Application Deadline Date*</label>
                        <input
                          class="form-control"
                          type="date"
                          v-model.trim="posted_job.expire_date"
                          placeholder="06.04.2020"
                          @input="clearError('deadline')"
                        />
                        <div id="deadline" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Location*</label>
                        <input
                          class="form-control"
                          type="text"
                          v-model.trim="posted_job.job_location"
                          placeholder="Enter location"
                          @input="clearError('location')"
                        />
                        <div id="location" class="text-danger error"></div>
                      </div>

                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Location*</label>
                        <input
                          class="form-control"
                          type="text"
                          v-model="location"
                          placeholder="Enter location"
                        />
                      </div> -->

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Job Category*</label>
                        <select
                          v-model="job_category.id"
                          @input="clearError('category')"
                        >
                          <option disabled value="null">Select a option</option>

                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.cat_name }}
                          </option>
                        </select>

                        <div id="category" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Apply Instruction*</label>
                        <input
                          type="text"
                          v-model.trim="posted_job.apply_instruction"
                          placeholder="Apply Instruction"
                          @input="clearError('Instruction')"
                        />
                        <div id="Instruction" class="text-danger error"></div>
                        <div class="mt-3">
                          <input
                            v-model.trim="featured"
                            type="checkbox"
                            value="true"
                            @input="clearError('featured')"
                          />

                          <label class="ms-2 font-weight-bold">
                            Featured Job</label
                          >

                          <input
                            class="ms-3"
                            v-model.trim="sponsord"
                            type="checkbox"
                            value="true"
                            @input="clearError('sponsord')"
                          />

                          <label class="ms-2 font-weight-bold">
                            Tier 2 Job</label
                          >
                        </div>
                      </div>

                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Apply Instruction*</label>
                        <input
                          type="text"
                          v-model="apply_instruction"
                          placeholder="Apply Instruction"
                        />
                        <div class="mt-3">
                          <input
                            v-model="featured"
                            type="checkbox"
                            value="true"
                          />
                          <label class="ms-2 font-weight-bold">
                            Featured Job</label
                          >

                          <input
                            class="ms-3"
                            v-model="sponsord"
                            type="checkbox"
                            value="true"
                          />
                          <label class="ms-2 font-weight-bold">
                            Tier 2 Job</label
                          >
                        </div>
                      </div> -->

                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12 text-right">
                        <button type="submit" class="theme-btn btn-style-one">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "EmployerPostJobView",

  data() {
    return {
      skills: [],
      ages: [],
      experiences: [],
      keywords: [],

      add1: "",
      add2: "",
      add3: "",
      add4: "",
      add5: "",
      add6: "",
      posted_job: {},
      job_category: {},

      categories: [],
      salaries: [],
      benefits: [],
      others: [],
      others_benefit: [],
      featured: false,
      min_age: "",
      max_age: "",
      min_exp: "",
      max_exp: "",
      min_salary: "",
      max_salary: "",
      uuide: "",
    };
  },

  methods: {
    // Form validation
    // removeEle(ad) {
    //   this.skills.splice(this.skills.indexOf(ad), 1);
    // },
    removeElebeni(ad) {
      this.benefits.splice(this.benefits.indexOf(ad), 1);
    },
    // removeEleother(ad) {
    //   this.others.splice(this.others.indexOf(ad), 1);
    // },

    removekeyword(ad) {
      this.keywords.splice(this.keywords.indexOf(ad), 1);
    },

    // addskill() {
    //   if (this.adds != "" && this.adds != null) {
    //     if (this.skills.length < 10) {
    //       this.skills.push(this.adds);

    //       this.adds = null;
    //     } else {
    //       alert("Skills Max length will be 10");
    //     }
    //   } else {
    //     alert("Skill is empty");
    //   }
    // },

    addbeni() {
      if (this.bene != "" && this.bene != null) {
        if (this.benefits.length < 10) {
          this.benefits.push(this.bene);

          this.bene = null;
        } else {
          alert("Benefits Max length will be 10");
        }
      } else {
        alert("Benefits is empty");
      }
    },

    // addother() {
    //   if (this.other != "" && this.other != null) {
    //     if (this.others.length < 10) {
    //       this.others.push(this.other);

    //       this.other = null;
    //     } else {
    //       alert(" Others Benefits Max length will be 10");
    //     }
    //   } else {
    //     alert("Others Benefits is empty");
    //   }
    // },

    addexperiance() {
      if (this.experiences.length < 2) {
        this.experiences.push(this.min_exp);

        this.experiences.push(this.max_exp);
      }
    },
    salaryrange() {
      if (this.salary.length < 2) {
        this.salary.push(this.salary_range);
        this.add4 = "" + this.salary;
      } else {
        alert("Salary range Max length will be 2");
      }
    },

    addbenefit() {
      if (this.benefit.length < 10) {
        this.benefit.push(this.benefits);
        this.add5 = "" + this.benefit;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    // add_others_benefit() {
    //   if (this.others.length < 10) {
    //     this.others.push(this.others_benefits);
    //     this.add6 = "" + this.others;
    //   } else {
    //     alert("Benefits range Max length will be 10");
    //   }
    // },

    addkeywords() {
      if (this.keywords.length < 10) {
        this.keywords.push(this.keyword);
        this.add3 = "" + this.keywords;
        this.keyword = null;
      } else {
        alert("Keywords range Max length will be 10");
      }
    },

    category() {
      axios
        .get("recruiter/job_category/")
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    nullcheck(element) {
      $("#" + element).html("Enter" + " " + element);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },
    addcheck(element) {
      $("#" + element).html("Add" + " " + element);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },
    lengthcheck(element, value) {
      $("#" + element).html(element + " length will be max " + value);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    clearError(element) {
      $("#" + element).html("");
    },

    postjob() {
      $(".error").html("");

      if (
        this.posted_job.job_title == null ||
        this.posted_job.job_title == ""
      ) {
        this.nullcheck("Title");
      } else if (this.posted_job.job_title.length > 255) {
        this.lengthcheck("Title", 255);
      } else if (
        this.posted_job.code != null &&
        this.posted_job.code.length > 20
      ) {
        this.lengthcheck("Code", 20);
      } 
      // else if (
      //   this.posted_job.job_profile == null ||
      //   this.posted_job.job_profile == ""
      // ) {
      //   this.nullcheck("Profile");
      // } 
      else if (
        this.posted_job.responsibility == null ||
        this.posted_job.responsibility == ""
      ) {
        this.nullcheck("Responsibility");
      } else if (
        this.posted_job.requirement == null ||
        this.posted_job.requirement == ""
      ) {
        this.nullcheck("Requirement");
      } else if (
        this.posted_job.education == null ||
        this.posted_job.education == ""
      ) {
        this.nullcheck("Education");
      } else if (this.posted_job.education.length > 1000) {
        this.lengthcheck("Education", 1000);
      } 
      // else if (this.skills == "") {
      //   this.addcheck("Skill");
      // } 
      // else if (this.min_age == "" || this.min_age == null) {
      //   this.nullcheck("Minimum");
      // } 
      // else if (this.max_age == "" || this.max_age == null) {
      //   this.nullcheck("Maximum");
      // } 
      else if (this.min_exp == "" || this.min_exp == null) {
        this.nullcheck("Minimumexp");
      } else if (this.max_exp == "" || this.max_exp == null) {
        this.nullcheck("Maximumexp");
      } else if (
        this.posted_job.job_type == "" ||
        this.posted_job.job_type == null
      ) {
        this.nullcheck("Job-type");
      } else if (this.min_salary == "" || this.min_salary == null) {
        this.nullcheck("Minimum-salary");
      } else if (this.max_salary == "" || this.max_salary == null) {
        this.nullcheck("Maximum-salary");
      } else if (this.benefits == "") {
        this.addcheck("Benefit");
      } 
      // else if (this.others == "") {
      //   this.addcheck("Other-Benifits");
      // }
       else if (this.keywords == "") {
        this.addcheck("keyword");
      } else if (
        this.posted_job.opening_date == "" ||
        this.posted_job.opening_date == null
      ) {
        this.nullcheck("opening-date");
      } else if (
        this.posted_job.expire_date == "" ||
        this.posted_job.expire_date == null
      ) {
        this.nullcheck("deadline");
      } else if (
        this.posted_job.job_location == "" ||
        this.posted_job.job_location == null
      ) {
        this.nullcheck("location");
      } else if (this.job_category.id == "" || this.job_category.id == null) {
        this.nullcheck("category");
      } else if (
        this.posted_job.apply_instruction == "" ||
        this.posted_job.apply_instruction == null
      ) {
        this.nullcheck("Instruction");
      } else {
        if (this.ages.length < 2) {
          this.ages.push(this.min_age);
          this.ages.push(this.max_age);
        }
        if (this.experiences.length < 2) {
          this.experiences.push(this.min_exp);

          this.experiences.push(this.max_exp);
        }
        if (this.salaries.length < 2) {
          this.salaries.push(this.min_salary);

          this.salaries.push(this.max_salary);
        }

        if (this.ages != "" && this.experiences != "" && this.salaries != "") {
          axios
            .patch("recruiter/modify-jobpost/" + this.uuide + "/", {
              code: this.posted_job.code,
              job_title: this.posted_job.job_title,
              job_profile: this.posted_job.job_profile,
              age_range: this.ages,
              responsibility: this.posted_job.responsibility,
              education: this.posted_job.education,
              requirement: this.posted_job.requirement,
              skill: this.skills,
              experience_range: this.experiences,
              salary_type: this.salary_type,
              salary_range: this.salaries,
              benefits: this.benefits,
              other_benefits: this.others,
              job_type: this.posted_job.job_type,
              keywords: this.keywords,
              opening_date: this.posted_job.opening_date,
              expire_date: this.posted_job.expire_date,
              apply_instruction: this.posted_job.apply_instruction,
              job_category: this.category_id,
              job_location: this.posted_job.job_location,
              is_featured: this.featured,
              is_sponsored: this.sponsord,
            })
            .then((res) => {
              if (res.status == 200) {
                console.log(res.data);
                this.$router.push({
          name: "employer-managejob"
        });
                this.$emit("get_message", "Job updated successfully");
                // alert("Job Updated");
              }
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
      }
    },

    // form validation end

    // Get single job post data

    getSingleJobPost(uuid) {
      axios
        .get("recruiter/modify-jobpost/" + uuid + "/")
        .then((res) => {
          if (res.status == 200) {
            this.posted_job = res.data;
            this.skills = res.data.skill;
            this.min_age = res.data.age_range[0];
            this.max_age = res.data.age_range[1];
            this.min_exp = res.data.experience_range[0];
            this.max_exp = res.data.experience_range[1];
            this.min_salary = res.data.salary_range[0];
            this.max_salary = res.data.salary_range[1];
            this.benefits = res.data.benefits;
            this.others = res.data.other_benefits;
            this.keywords = res.data.keywords;
            this.job_category = res.data.job_category;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted: function () {
    var uuid = this.$route.query.uuid;

    if (uuid != null) {
      this.getSingleJobPost(uuid);
      this.uuide = uuid;
    }
    this.category();
  },
};
</script>

<style>
.button_plus {
  position: absolute;
  width: 35px;
  height: 35px;
  background: #fff;
  cursor: pointer;
  border: 2px solid #095776;
  display: flex;
  top: 44%;
}

.button_plus:after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  height: 4px;
  width: 50%;
  background: #095776;
  top: 50%;
  left: 50%;
}

.button_plus:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #095776;
  height: 50%;
  width: 4px;
}

.button_plus:hover:before,
.button_plus:hover:after {
  background: #fff;
  transition: 0.2s;
}

.button_plus:hover {
  background-color: #095776;
  transition: 0.2s;
}
</style>
