<template>
  <!--Page Title-->
  <div>
    <br /><br /><br />
    <section class="page-title style-two">
      <div class="auto-container">
        <div class="row">
          <div class="col-lg-7 text-left">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="sec-title text-center">
                  <h2
                    class="text-white mt-3 text-left"
                    style="font-size: 57px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
                  >
                    Build Your Skills
                  </h2>

                  <!-- <div class="text text-white text-left" style="font-size: 30px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">
                    Are you looking for a job!
                  </div> -->

                  <div
                    class="text text-white text-left"
                    style="font-size: 25px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
                  >
                    Offering courses through hive4work
                  </div>
                </div>
              </div>
            </div>

            <div style="background-color: white; border-radius: 10px">
              <div class="row">
                <div
                  class="col-lg-10"
                  style="margin: 0px; padding: 0px; z-index: 10"
                >
                  <div class="form-group text-left px-5 py-3">
                    <span
                      style="color: grey"
                      class="icon flaticon-search-1"
                    ></span>
                    <input
                      style="
                        border-radius: 10px;
                        width: 80%;
                        color: grey;
                        padding-left: 20px;
                      "
                      type="text"
                      @input="showjob($event.target.value)"
                      name="field_name"
                      placeholder="Search Course"
                    />
                    <span style="height: 100%; background-color: red"></span>
                  </div>
                </div>
                <div
                  id="find-job"
                  class="col-2 pt-3 text-center"
                  style="
                    background-color: #3f4a5a;
                    margin: 0px;
                    padding: 0px;
                    border-radius: 0px 10px 10px 0px;
                    color: white;
                  "
                >
                  <span class="text-center">SEARCH</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <img src="images/skill.png" style="height: 100%; width: 100%" />
          </div>
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Sidebar Page Container -->
    <div class="sidebar-page-container">
      <div
        v-for="coursedata in coursedatas"
        :key="coursedata"
        class="auto-container"
      >
        <div class="sec-title text-left">
          <h2>Courses for {{ coursedata.cat_name }}</h2>
          <div
            style="
              display: block;
              margin-top: 0.5em;
              margin-bottom: 0.5em;
              margin-left: auto;
              margin-right: auto;
              border-style: inset;
              border-width: 1px;
            "
          ></div>
        </div>

        <div class="row">
          <!--Content Side-->
          <div class="content-side col-lg-12 col-md-12 col-sm-12">
            <div class="blog-grid">
              <div class="row">
                <!-- News Block -->
                <div
                  v-for="coursesubdata in coursedata.courses"
                  :key="coursesubdata"
                  @click.prevent="course_single(coursesubdata.uuid)"
                  class="news-block col-lg-4 col-md-6 col-sm-12"
                >
                  <div class="inner-box">
                    <div class="image-box">
                      <figure class="image">
                        <img
                          :src="baseURL + coursesubdata.image"
                          style="height: 220px; width: 100%"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div class="lower-content">
                      <ul class="post-meta">
                        <li><a href="#">June 20, 2023</a></li>
                      </ul>
                      <h3>
                        <a href="/course_single" style="font-size: 25px">{{
                          coursesubdata.title
                        }}</a>
                      </h3>
                      <p class="text">{{ coursesubdata.overview }}</p>
                      <a href="/course-single" class="read-more"
                        >Read More <i class="fa fa-angle-right"></i
                      ></a>
                    </div>

                    <div style="font-size: 25px">
                      {{
                        coursesubdata.price - coursesubdata.discount == 0
                          ? "Free"
                          : "&pound;" +
                            coursesubdata.price -
                            coursesubdata.discount
                      }}
                      <del>&pound;{{ coursesubdata.price }}</del>
                    </div>
                  </div>
                </div>

               


          
              </div>
              <div class="text-center">
                  <span @click.prevent="loadmore(coursedata.id,coursedata.cat_name)" id="load-more" >load more</span>


                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Sidebar Container -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CareerAdviceView",
  data() {
    return {
      jobs: [
        {
          jobtitle: "Software Developer",
          segmemt: "segmemt",
          location: "Dhaka",
          time: "11",
          salary: "35k",
          type: "Fulltime",
        },
        {
          jobtitle: "Product Manager",
          segmemt: "segmemt",
          location: "Khulna",
          time: "12",
          salary: "30k",
          type: "Fulltime",
        },
        {
          jobtitle: "HR",
          segmemt: "segmemt",
          location: "Chandpur",
          time: "13",
          salary: "35k",
          type: "Parttime",
        },
        {
          jobtitle: "Accounts",
          segmemt: "segmemt",
          location: "Dhaka",
          time: "11",
          salary: "25k",
          type: "Fulltime",
        },
      ],
      ano_jobs: [],
      coursedatas: [],

      page_number: 1,
      next: null,
      previous: null,
      count: "",
      baseURL: axios.defaults.baseURL,
    };
  },

  methods: {
    get_jobs() {
      axios
        .get("anonymous/job-list/", { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;
            this.next = res.data.next;
            this.previous = res.data.previous;
            this.count = res.data.count;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    page(pageno) {
      axios
        .get("anonymous/job-list/?page" + "=" + pageno, { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;
            this.page_number = pageno;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    next_page(nextpage) {
      axios
        .get(nextpage, { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    previous_page(prepage) {
      axios
        .get(prepage, { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    course_single(uuid) {
      if (uuid != null) {
        this.$router.push({ name: "course-single", query: { uuid: uuid } });
      }
    },
    loadmore(id,cat_name) {
      if (id != null) {
        this.$router.push({ name: "ano-course-category", query: { id: id,cat_name:cat_name } });
      }
    },

    get_jobss(key) {
      axios
        .get(
          "anonymous/job-list/",
          {
            params: {
              search: key,
            },
          },
          { ignore: "1" }
        )
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    showjob(text) {
      if (text != null) {
        this.get_jobss(text);
      }
    },

    async getcoursedata() {
      const response = await axios.get(
        "anonymous/category_with_three_course/",
        { ignore: "1" }
      );
      if (response.status == 200) {
        this.coursedatas = await response.data;
        if (response.data.length == 0) {
          this.$emit("get_message", "Sorry! No Data Available");
        }

        console.log("Category With Course Loaded");
      }
    },
  },

  mounted: function () {
    this.get_jobs();
    this.getcoursedata();
  },
};

// let loadMoreBtn = document.querySelector('#load-more');
// let currentItem = 3;

// loadMoreBtn.onclick = () =>{

//   let boxes =[document.querySelectorAll()];

//   for (var i = currentItem; i < currentItem + 3; i++){
//     boxes[i].style.display = 'inline-block';
//   }

//   currentItem += 3;

//   if(currentItem >= boxes.length){

//     loadMoreBtn.style.display = 'none';
//   }
// }
</script>

<style>
.news-block .inner-box:hover {
  background: none;
}

#load-more {
  margin-top: 10px;
  display: inline-block;
  padding: 13px 30px 20px;
  border: 1px solid #334;
  color: #334;
  font-size: 16px;
  background-color: #fff;
  cursor: pointer;
  width: 200px;
  height: 50px;
  text-align: center;

}

#load-more:hover {
  background-color: crimson;
  border-color: crimson;
  color: #fff;
}
</style>
