<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li class="active">
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
      
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section @submit.prevent="adboard" class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box text-center">
          <h3>Ad Board!</h3>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box p-3">
                <div class="widget-content">
                  <form class="default-form">
                    <div class="row">
                      <!-- Input -->

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Ad Title</label>
                        <input
                          type="text"
                          v-model="ad.ad_title"
                          placeholder="Title"
                          @input="clearError('Title')"
                        />

                        <div id="Title" class="text-danger error"></div>
                      </div>

                      <!-- About Company -->
                      <div class="form-group col-lg-12 col-md-12">
                        <label>Description</label>
                        <textarea
                          v-model="ad.ad_description"
                          placeholder="Description"
                          @input="clearError('Description')"
                        ></textarea>

                        <div id="Description" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization name</label>
                        <input
                          type="text"
                          v-model="ad.org_name"
                          placeholder="Organization"
                          @input="clearError('Organization')"
                        />

                        <div id="Organization" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>URL</label>
                        <input
                          type="text"
                          v-model="ad.url"
                          placeholder="Url"
                          @input="clearError('URL')"
                        />

                        <div id="URL" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Start Date</label>
                        <input
                          class="form-control"
                          type="date"
                          v-model="ad.start_date"
                          @input="clearError('Start-Date')"
                        />

                        <div id="Start-Date" class="text-danger error"></div>

                        <div class="uploading mt-5">
                          <div class="uploadButton">
                            <input
                              class="uploadButton-input"
                              type="file"
                              @change="handleFileUpload($event)"
                              accept="image/*"
                              id="upload"
                            />
                            <label
                              style="position: relative"
                              class="uploadButton-button ripple-effect"
                              for="upload"
                            >
                              <img
                                v-if="url"
                                :src="url"
                                style="
                                  position: absolute;
                                  top: 0;
                                  bottom: 0;
                                  right: 0;
                                  left: 0;
                                  width: 100%;
                                  height: 100%;
                                  z-index: 0;
                                "
                                alt=""
                              />

                              <span v-if="url == null"
                                >Browse image</span
                              ></label
                            >
                            <div id="image" class="text-danger error"></div>
                            <span class="uploadButton-file-name"></span>
                          </div>
                          <!-- <button @click.prevent="add_logo"  type="submit" class="btn btn-primary" >Upload</button> -->
                        </div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>End Date</label>
                        <input
                          class="form-control"
                          type="date"
                          v-model="ad.end_date"
                          @input="clearError('End-Date')"
                        />
                        <div id="End-Date" class="text-danger error"></div>
                        <div class="mt-5">
                          <input v-model="ad.is_popup" type="checkbox" />

                          <label class="ms-2 font-weight-bold"> Popup</label>
                        </div>
                      </div>

                      <!-- Input -->
                      <div
                        class="form-group col-lg-12 col-md-12 text-right mt-3"
                      >
                        <button type="submit" class="theme-btn btn-style-one">
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "AdminAdBoardUpdateView",
  data() {
    return {
      background: "",
      is_popup: false,
      ad: {},

      url: "",

      uuide: "",
    };
  },

  methods: {
    nullcheck(element) {
      $("#" + element).html("Enter" + " " + element);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    lengthcheck(element, value) {
      $("#" + element).html(element + " length will be max " + value);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    filecheck(element) {
      $("#" + element).html("Upload a image");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    clearError(element) {
      $("#" + element).html("");
    },

    handleFileUpload(event) {
      this.background = event.target.files[0];

      this.url = URL.createObjectURL(this.background);
      this.clearError("image");
    },

    adboard() {
      $(".error").html("");

      if (this.ad.ad_title == null || this.ad.ad_title == "") {
        this.nullcheck("Title");
      } else if (this.ad.ad_title.length > 100) {
        this.lengthcheck("Title", 100);
      } else if (
        this.ad.ad_description == null ||
        this.ad.ad_description == ""
      ) {
        this.nullcheck("Description");
      } else if (this.ad.ad_description.length > 300) {
        this.lengthcheck("Description", 300);
      } else if (this.ad.org_name == null || this.ad.org_name == "") {
        this.nullcheck("Organization");
      } else if (this.ad.url == null || this.ad.url == "") {
        this.nullcheck("URL");
      } else if (this.ad.start_date == "" || this.ad.start_date == null) {
        this.nullcheck("Start-Date");
      } else if (this.ad.end_date == "" || this.ad.end_date == null) {
        this.nullcheck("End-Date");
      }  else {
        let formData = new FormData();

        formData.append("ad_title", this.ad.ad_title);
        formData.append("org_name", this.ad.org_name);
        formData.append("ad_description", this.ad.ad_description);
        formData.append("url", this.ad.url);
        formData.append("start_date", this.ad.start_date);
        formData.append("end_date", this.ad.end_date);
        formData.append("is_popup", this.ad.is_popup);

        if (this.background != "") {
          formData.append("background", this.background);
        }

        axios
          .patch("app_admin/ad_board/" + this.uuide + "/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$router.push("admin-manage-ad-board");

              this.$emit("get_message", "ad board updated sucessfully");
             

              this.$refs.background.reset();

              // alert("ad board updated sucessfully");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    getadboard(uuid) {
      axios
        .get("app_admin/ad_board/" + uuid + "/")
        .then((res) => {
          this.ad = res.data;
          this.url = res.data.background;
          
   

          if (res.status == 200) {
            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted: function () {
    var uuid = this.$route.query.uuid;

    if (uuid != null) {
      this.getadboard(uuid);
      this.uuide = uuid;
    }
  },
};
</script>

<style>
.button_plus {
  position: absolute;
  width: 35px;
  height: 35px;
  background: #fff;
  cursor: pointer;
  border: 2px solid #095776;
  display: flex;
  top: 44%;
}

.button_plus:after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  height: 4px;
  width: 50%;
  background: #095776;
  top: 50%;
  left: 50%;
}

.button_plus:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #095776;
  height: 50%;
  width: 4px;
}

.button_plus:hover:before,
.button_plus:hover:after {
  background: #fff;
  transition: 0.2s;
}

.button_plus:hover {
  background-color: #095776;
  transition: 0.2s;
}
</style>
