<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li class="active">
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
        
        </ul>
      </div>
    </div>
  <!-- End User Sidebar -->
    <!-- End User Sidebar -->

    <!--Page Title-->
    <section
      style="background: none; text-align: left !important"
      class="page-title mt-5"
    >
      <div class="auto-container">
        <div style="text-align: left !important" class="title-outer">
          <h1 style="text-align: left !important">All Employer</h1>
          <!-- <ul class="page-breadcrumb">
            <li><a href="index.html">Home</a></li>
            <li>Companies</li>
          </ul> -->
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Listing Section -->
    <section
      style="background: none; text-align: left !important"
      class="page-title"
    >
      <div class="auto-container">
        <!-- Job Search Form -->

        <!-- Job Search Form -->

        <!-- <div class="top-filters">
          <div class="form-group">
            <select class="chosen-select">
              <option>Company Size</option>
              <option>New Jobs</option>
              <option>Freelance</option>
              <option>Full Time</option>
              <option>Internship</option>
              <option>Part Time</option>
              <option>Temporary</option>
            </select>
          </div>

          <div class="form-group">
            <select class="chosen-select">
              <option>Founded Date</option>
              <option>New Jobs</option>
              <option>Freelance</option>
              <option>Full Time</option>
              <option>Internship</option>
              <option>Part Time</option>
              <option>Temporary</option>
            </select>
          </div>
        </div> -->
      </div>
    </section>
    <!--End Page Title-->

    <!-- Listing Section -->
    <section style="padding: 0px 50px" class="ls-section">
      <div class="auto-container">
        <div class="filters-backdrop"></div>

        <div class="row">
          <!-- Content Column -->
          <div class="content-column col-lg-12 col-md-12 col-sm-12">
            <div class="ls-outer">
              <!-- <button
                type="button"
                class="theme-btn btn-style-two toggle-filters"
              >
                Show Filters
              </button> -->

              <p class="p-3">
                Showing {{ recruiters.length }} from {{ all_data.count }} item
              </p>

              <div class="row">
                <!-- Company Block Four -->
                <div
                  v-for="recruiter in recruiters"
                  :key="recruiter"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  class="company-block-four col-xl-3 col-lg-4 col-md-6 col-sm-12"
                  @click="show_recruiter(recruiter.uuid)"
                >
                  <div class="inner-box" style="height: 100%">
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                    <!-- <span class="featured">Featured</span> -->
                    <span v-if="recruiter.logo != null" class="company-logo"
                      ><img :src="recruiter.logo.logo" alt=""
                    /></span>
                    <h4>
                      <a href="/admin-employer-detail">{{
                        recruiter.org_name
                      }}</a>
                    </h4>
                    <ul class="job-info">
                      <li>
                        <span class="icon flaticon-map-locator"></span>
                        {{ recruiter.county }}, {{ recruiter.province }}
                      </li>
                      <li>
                        <span class="icon flaticon-briefcase"></span>
                        {{ recruiter.org_type.org_type }}
                      </li>
                    </ul>
                    <div class="job-type">
                      Open Jobs – {{ recruiter.approved_jobpost_count }}
                    </div>
                    <br />
                  </div>
                </div>
              </div>

              <!-- Pagination -->
              <nav class="ls-pagination">
                <ul>
                  <li v-if="all_data.previous != null" class="prev">
                    <a @click.prevent="page(current_page - 1)"
                      ><i class="fa fa-arrow-left"></i
                    ></a>
                  </li>
                  <li v-for="i in pages" :key="i">
                    <a
                      :class="{ 'current-page': current_page == i }"
                      @click.prevent="page(i)"
                      >{{ i }}</a
                    >
                  </li>

                  <li v-if="all_data.next != null" class="next">
                    <a @click.prevent="page(current_page + 1)"
                      ><i class="fa fa-arrow-right"></i
                    ></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--End Listing Page Section -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DashboardAdminAllEmpView",

  data() {
    return {
      recruiters: [],
      current_page: 1,
      next_page: "",
      next: "",
      previous: "",
      pages: 1,
      total_item: "",

      all_data: {},
    };
  },

  methods: {
    getRecruiters() {
      axios
        .get("/app_admin/recruiter/?page=1&page_size=10")
        .then((response) => {
          if (response.status == 200) {
            this.recruiters = response.data.results;
            this.total_item = response.data.count;
            this.all_data = response.data;
            if (this.total_item > 0) {
              this.pages = Math.ceil(this.total_item / 10);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    page(page_number) {
      axios
        .get("/app_admin/recruiter/?page=" + page_number + "&page_size=" + 10)
        .then((res) => {
          if (res.status == 200) {
            this.current_page = page_number;

            this.recruiters = res.data.results;
            this.all_data = res.data;

            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    show_recruiter(uuid) {
      this.$router.push({
        name: "admin-employer-detail",
        query: { uuid: uuid },
      });
    },
  },
  mounted: function () {
    this.getRecruiters();
  },
};
</script>

<style>
.inner-box {
  text-align: center;
}

.inner-box:hover {
  background-color: yellow;
}
</style>
