<template>
  <!--Page Title-->
  <div>
    <br /><br /><br />
    <section class="page-title style-two">
      <div class="auto-container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="sec-title text-center">
              <h2 class="text-white">Search result for '{{ skey }}'</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Listing Section -->
    <section class="ls-section">
      <div class="auto-container">
        <div class="filters-backdrop"></div>

        <div class="row">
          <!-- Content Column -->
          <div class="content-column col-lg-12">
            <div v-if="ano_jobs.length > 0" class="ls-outer">
              <!-- ls Switcher -->
              <!-- <div class="ls-switcher">
                 <div class="showing-result">
                   <div class="top-filters">
                     <div class="form-group">
                       <select class="chosen-select">
                         <option>Job Type</option>
                         <option>Full Time</option>
                         <option>Part Time</option>
                         <option>Contractual</option>
                         <option>Internship</option>
                       </select>
                     </div>
 
                     <div class="form-group">
                       <select class="chosen-select">
                         <option>Date Posted</option>
                         <option>Today's Job</option>
                         <option>This week</option>
                         <option>Last week</option>
                         <option>This Month</option>
                         <option>Last 6 months</option>
                         <option>This year</option>
                       </select>
                     </div>
 
                     <div class="form-group">
                       <select class="chosen-select">
                         <option>Experience Level</option>
                         <option>No Experience</option>
                         <option>1 year Experience</option>
                         <option>1-3 years Experience</option>
                         <option>3-6 years Experience</option>
                         <option>6-10 years Experience</option>
                         <option>10+ Years Experience</option>
                       </select>
                     </div>
 
                     <div class="form-group">
                       <select class="chosen-select">
                         <option>Salary estimate</option>
                         <option>Upto 5$/hour</option>
                         <option>Upto 10$/hour</option>
                         <option>Upto 15$/hour</option>
                         <option>Upto 20$</option>
                         <option>Upto 2000$/monthly</option>
                         <option>2000$/monthly</option>
                       </select>
                     </div>
                   </div>
                 </div>
 
                 <div class="sort-by">
                   <select class="chosen-select">
                     <option>Job Type</option>
                         <option>Full Time</option>
                         <option>Part Time</option>
                         <option>Contractual</option>
                         <option>Internship</option>
                   </select>
 
                   <select class="chosen-select">
                     <option>Show 10</option>
                     <option>Show 20</option>
                     <option>Show 30</option>
                     <option>Show 40</option>
                     <option>Show 50</option>
                     <option>Show 60</option>
                   </select>
                 </div>
               </div> -->

              <div class="row">
                <!-- Job Block -->
                <div
                  v-for="job in ano_jobs"
                  :key="job.id"
                  class="job-block col-lg-6"
                >
                  <div @click="openjob(job.uuid)" class="inner-box">
                    <div class="content">
                      <span
                        v-if="job.recruiter.logo != null"
                        class="company-logo"
                        ><img :src="job.recruiter.logo.logo" alt="Logo"
                      /></span>

                      <h4 class="c-text-left">{{ job.job_title }}</h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span
                          >{{ job.recruiter.org_name }}
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span
                          >{{ job.job_location }}
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> Closing
                          Date:{{ job.expire_date }}
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> &pound;{{
                            job.salary_range[0]
                          }}-&pound;{{ job.salary_range[1] }}
                        </li>
                      </ul>
                      <ul class="job-other-info">
                        <li v-if="job.is_sponsored == true" class="time">
                          Skilled Work
                        </li>
                        <!-- <li class="privacy">Private</li>
                         <li class="required">Urgent</li> -->
                      </ul>
                      <button class="bookmark-btn">
                        <span class="flaticon-bookmark"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Pagination -->
              <nav class="ls-pagination">
                <ul>
                  <li v-if="all_data.previous != null" class="prev">
                    <a @click.prevent="page(current_page - 1)"
                      ><i class="fa fa-arrow-left"></i
                    ></a>
                  </li>
                  <li v-for="i in pages" :key="i">
                    <a
                      :class="{ 'current-page': current_page == i }"
                      @click.prevent="page(i)"
                      >{{ i }}</a
                    >
                  </li>

                  <li v-if="all_data.next != null" class="next">
                    <a @click.prevent="page(current_page + 1)"
                      ><i class="fa fa-arrow-right"></i
                    ></a>
                  </li>
                </ul>
              </nav>
            </div>
            <div v-else>
              <h3 class="mt-5 text-center">{{ message }}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--End Listing Page Section -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "JobSearchView",
  data() {
    return {
      ano_jobs: [],
      skey: "",
      populer_skey: "",
      message: "",

      //Pagenation
      current_page: 1,
      next_page: "",
      next: "",
      previous: "",
      pages: 1,
      total_item: "",
      all_data: {},
    };
  },

  methods: {
    get_jobs(key) {
      axios
        .get(
          "anonymous/job-list/?page=1&page_size=10",
          {
            params: {
              search: key,
            },
          },
          { ignore: "1" }
        )
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;

            this.total_item = res.data.count;
            this.all_data = res.data;
            if (this.total_item > 0) {
              this.pages = Math.ceil(this.total_item / 10);
            }

            if (this.ano_jobs.length <= 0) {
              this.message = "No jobs found !";
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    page(page_number) {
      axios
        .get("anonymous/job-list/?page=" + page_number + "&page_size=" + 10, {
          params: {
            search: this.skey,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.current_page = page_number;

            this.ano_jobs = res.data.results;
            this.all_data = res.data;
            window.scrollTo(0, 0);

            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    openjob(uuid) {
      if (uuid != null) {
        this.$router.push({ name: "job-single", query: { uuid: uuid } });
      }
    },
  },

  mounted() {
    var key = this.$route.query.key;

    if (key != null) {
      this.get_jobs(key);
      this.skey = key;
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style></style>
