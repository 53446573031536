<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div v-if="role == 'R'" class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/recruiter-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/recruiter-profile"
              ><i class="la la-user-tie"></i>Profile</a
            >
          </li>

          <li>
            <a href="/recruiter-postjob"
              ><i class="la la-paper-plane"></i>Post a New Job</a
            >
          </li>
          <li>
            <a href="/recruiter-managejob"
              ><i class="la la-briefcase"></i> Manage Jobs
            </a>
          </li>
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          
        </ul>
      </div>
    </div>

    <div v-else-if="role == 'J'" class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/candidate-dashboard">
              <i class="la la-home"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/candidate-profile"
              ><i class="la la-user-tie"></i>My Resume</a
            >
          </li>
          <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
          <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
          <li class="active">
            <a href="/candidate-findjob"
              ><i class="la la-user-alt"></i>Find jobs</a
            >
          </li>
          <li>
            <a href="/candidate-appliedjob"
              ><i class="la la-briefcase"></i> Applied Jobs
            </a>
          </li>
         
        </ul>
      </div>
    </div>

    <div v-else-if="role == 'A'" class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li class="active">
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manange Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
         
        
        </ul>
      </div>
    </div>

    <!-- End User Sidebar -->

    <!--Page Title-->

    <!--End Page Title-->

    <!-- Listing Section -->
    <section style="margin-top: 100px; background: none" class="page-title">
      <div class="auto-container">
        <div class="title-outer">
          <h1>Change Password</h1>
          <!-- <ul class="page-breadcrumb">
            <li><a href="/employee-dashboard">Dashboard</a></li>
            <li>Change Password</li>
          </ul> -->
        </div>
        <br />

        <div class="row">
          <div class="col-md-6 m-auto">
            <div class="login-form default-form">
              <div class="form-inner">
                <!--Login Form-->
                <form @submit.prevent="changepassword">
                  <div class="form-group">
                    <input
                      class="border bg-white"
                      id="new-password"
                      type="password"
                      v-model="current_password"
                      placeholder="Current Password"
                      @input="current_password_error=''"
                    />
                    <p
                      v-if="current_password_error != ''"
                      class="mt-1 text-danger text-left"
                    >
                      {{ current_password_error }}
                    </p>
                  </div>

                  <div class="form-group">
                    <input
                      class="border bg-white"
                      id="new-password "
                      type="password"
                      v-model="new_password"
                      placeholder="New Password"
                      @input="new_password_error=''"
                    />
                    <p
                      v-if="new_password_error != ''"
                      class="mt-1 text-danger text-left"
                    >
                      {{ new_password_error }}
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      class="border bg-white"
                      id="confirm-password "
                      type="password"
                      v-model="confirm_password"
                      placeholder="Confirm Password"
                      @input="confirm_password_error=''"
                    />
                    <p
                      v-if="confirm_password_error != ''"
                      class="mt-1 text-danger text-left"
                    >
                      {{ confirm_password_error }}
                    </p>
                  </div>
                  <div class="form-group">
                    <button
                      class="theme-btn btn-style-one theme-color-bg"
                      type="submit"
                    >
                      Change Password
                    </button>
                  </div>
                </form>
                <br />
                <br />
                <!-- 
             <form method="post" action="add-parcel.html">
             

              <div class="form-group">
                <label>Type/paste OTP from your email</label>
                <input type="text" name="otp" placeholder="OTP" required>
              </div>

              <div class="form-group">
                <button class="theme-btn btn-style-two " type="submit" name="submit-otp">Submit OTP</button>
              </div>
            </form> -->
              </div>
            </div>
          </div>
        </div>

        <!-- End Info Section -->
      </div>
    </section>

    <!--End Listing Page Section -->
  </div>
</template>

<script>
import axios from "axios";
import vcookie from "vue-cookie";
export default {
  name: "ChangePasswordView",
  data() {
    return {
      role: "",
      current_password_error: "",

      new_password_error: "",

      confirm_password_error: "",
    };
  },

  methods: {
    changepassword() {
      if (this.current_password == null) {
        this.current_password_error = "Enter current password";
      } else if (this.current_password.length < 8) {
        this.current_password_error = "Password is two short ! Enter 8 digit";
      } else if (this.new_password == null) {
        this.new_password_error = "Enter new password";
      } else if (this.new_password.length < 8) {
        this.new_password_error = "Enter 8 digit password";
      } else if (this.confirm_password == null) {
        this.confirm_password_error = "Confirm password";
      } else if (this.confirm_password.length < 8) {
        this.confirm_password_error = "Enter 8 digit password";
      } else if (this.confirm_password != this.new_password) {
        this.confirm_password_error = "Password will be same";
      } else {
        axios
          .post("access/change_password/", {
            current_password: this.current_password,
            new_password: this.new_password,
          })
          .then((res) => {
            if (res.status == 200) {
              alert("Password changes successfully");

              this.logout();
            }
          })
          .catch((error) => {
           if(error.response.status == 400){
             this.current_password_error="Incorrect Current Password"
           }
          });
      }
    },

    checkusertype() {
      const user = JSON.parse(vcookie.get("user"));
      if (user) {
        this.role = user.user.role;
      }
    },
    logout() {
      const user = JSON.parse(vcookie.get("user"));

      if (user) {
        vcookie.delete("user");

        this.$router.push({ name: "home" });
        location.reload();
      }
    },
  },
  mounted: function () {
    this.checkusertype();
  },
};
</script>

<style></style>
