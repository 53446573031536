<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li class="active">
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
      
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Manage Ad Board</h3>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>My Ad Listings</h4>
                </div>

                <div class="widget-content">
                  <div
                    style="
                      display: flex;
                      justify-content: end;
                      padding-bottom: 30px;
                    "
                    class="col-12"
                  >
                    <button class="theme-button h-100 p-2">
                      <a
                        href="/admin-ad-board"
                        style="color: #ffffff; font-size: 120%"
                        ><i></i>Create Ad
                      </a>
                    </button>
                  </div>

                  <div class="table-outer">
                    <table class="default-table manage-job-table">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Org name</th>
                          <th>Website</th>
                          <th>Start & End</th>
                          <!-- <th>Popup </th> -->
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="ad in ads" :key="ad.id">
                          <td>
                            <h6>{{ ad.ad_title }}</h6>
                            <!-- <span class="info"  style="color:   #36a8a6;"><i class="icon flaticon-map-locator" style="color:   #050e0d;"></i> {{}}</span> -->
                          </td>
                          <td>{{ ad.ad_description }}</td>
                          <td>{{ ad.org_name }}</td>
                          <td>{{ ad.url }}</td>
                          <td>
                            Start: {{ ad.start_date }}<br />End:
                            {{ ad.end_date }}
                          </td>
                          <!-- <td class="status">{{ad.is_popup}}</td> -->
                          <td>
                            <div class="option-box">
                              <ul class="option-list">
                                <li>
                                  <button
                                    data-text="Edit"
                                    @click="openad(ad.uuid)"
                                  >
                                    <span
                                      class="icon la la-pen"
                                      style="color: #070f0f"
                                    ></span>
                                  </button>
                                </li>
                                <!-- <li><button data-text="Reject Aplication"><span class="la la-pencil" style="color:   #b4e515;"></span></button></li> -->
                                <li>
                                  <button data-text="Delete">
                                    <span
                                      class="icon la la-trash"
                                      style="color: #744812"
                                    ></span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->

    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AdminManageAdBoardView",

  data() {
    return {
      ads: [],
    };
  },

  methods: {
    getadboard() {
      axios
        .get("app_admin/ad_board/active/")
        .then((res) => {
          this.ads = res.data;

          if (res.status == 200) {
            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    openad(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "admin-updatead-board",
          query: { uuid: uuid },
        });
      }
    },

    get_ads(key) {
      axios
        .get("app_admin/ad_board", {
          params: {
            search: key,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.ads = res.data.results;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    showjob(text) {
      if (text != null) {
        this.get_ads(text);
      }
    },
  },

  mounted: function () {
    this.getadboard();
  },
};
</script>

<style></style>
