<template>
  <div>
    <!-- Main Header-->

    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/candidate-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/candidate-profile-single"
              ><i class="la la-user-tie"></i>My Resume</a
            >
          </li>
          <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
          <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
          <li>
            <a href="/candidate-findjob"
              ><i class="la la-user-alt"></i>Find jobs</a
            >
          </li>
          <li class="active">
            <a href="/candidate-appliedjob"
              ><i class="la la-briefcase"></i> Applied Jobs
            </a>
          </li>
          <!-- <li><a href="/candidate-change-password"><i class="la la-lock"></i>Change Password</a></li>
                <li><a href="#"><i class="la la-sign-out"></i>Logout</a></li> -->

          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 250px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3 style="text-align: center">Applied Jobs</h3>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>Applied Listings</h4>
                </div>

                <div class="widget-content">
                  <div class="table-outer">
                    <table class="default-table manage-job-table">
                      <thead>
                        <tr>
                          <th>Job Title</th>
                          <th>Organization</th>
                          <th>Applied Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="job in applied_job" :key="job.id">
                          <td>
                            <h6>{{ job.job_post.job_title }}</h6>
                            <span class="info" style="color: #36a8a6"
                              ><i
                                class="icon flaticon-map-locator"
                                style="color: #050e0d"
                              ></i>
                              {{ job.job_post.job_location }}</span
                            >
                          </td>
                          <td class="status">
                            {{ job.job_post.recruiter.org_name }}
                          </td>
                          <td>{{ job.created }}</td>
                          <!-- <td class="applied"><a>{{job.created }}</a></td> -->
                          <!-- <td><br>Deadline: {{ job.expire_date }}</td> -->

                          <td>
                            <div class="option-box">
                              <ul class="option-list">
                                <li>
                                  <button
                                    @click.prevent="openjob(job.job_post.uuid)"
                                    data-text="Edit"
                                  >
                                    <span
                                      class="icon la la-eye"
                                      style="color: #070f0f"
                                    ></span>
                                  </button>
                                </li>
                                <!-- <li><button data-text="Reject Aplication"><span class="la la-pencil" style="color:   #b4e515;"></span></button></li> -->
                             
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Pagination -->
                <nav class="ls-pagination">
                  <ul>
                    <li class="prev">
                      <a href="#"><i class="fa fa-arrow-left"></i></a>
                    </li>
                    <li><a href="#">1</a></li>
                    <li><a href="#" class="current-page">2</a></li>
                    <li><a href="#">3</a></li>
                    <li class="next">
                      <a href="#"><i class="fa fa-arrow-right"></i></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CandidateAppliedJobsView",

  data() {
    return {
      applied_job: [],
    };
  },

  methods: {
    applied_jobs() {
      axios
        .get("applicant/job_application/")
        .then((res) => {
          if (res.status == 200) {
            this.applied_job = res.data.results;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    openjob(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "candidate-appliedjob-single",
          query: { uuid: uuid },
        });
      }
    },
  },

  mounted: function () {
    this.applied_jobs();
  },
};
</script>

<style></style>
