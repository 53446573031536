<template>
  <div style="margin-top: 100px">
    <section class="call-to-action-two" style="background-color: #36a8a6">
      <div class="auto-container">
        <div class="sec-title light text-center">
          <h2
            style="font-size: 60px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:white"
          >
            Direct Hire
          </h2>
          <div
            class="text"
            style="font-size: 25px; line-height: 35px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:white"
          >
            Over hundreds interactions, 500 success stories Make yours now.
          </div>
        </div>
      </div>
    </section>

    <!-- Dashboard -->
    <section @submit.prevent="add_direct_hire">
      <div class="dashboard-outer mt-5">
        <div class="upper-title-box"></div>
        <div class="row">
          <div class="col-lg-8 m-auto">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box p-3">
                <div class="widget-content">
                  <div class="typography">
                    <h4
                      class="title"
                      style="font-size: 30px; line-height: 40px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#3f4a5a"
                    >
                      New Edge of Recruitment
                    </h4>
                    <br />
                    <p
                      style="font-size: 20px; line-height: 30px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#666666"
                    >
                      Direct hiring is when a company offers you a job without
                      using a third party. These jobs are typically permanent or
                      long-term positions with benefits, a salary and paid time
                      off (PTO).
                    </p>
                    <p
                      style="font-size: 20px; line-height: 30px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#666666"
                    >
                      The recruitment process tends to be longer and more
                      thorough. As the employer is making a long-term investment
                      in you, they want to ensure you are the right person for
                      the job.
                    </p>
                    <blockquote class="mb-5 mt-5">
                      <p
                        style="font-size: 20px; line-height: 30px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#666666"
                      >
                        While a recruitment firm might manage the initial
                        recruiting process, you report to the hiring company if
                        your application is successful.
                      </p>
                    </blockquote>
                  </div>

                  <!-- <h5 style="padding-top: 30px;">If you are interested fill the following information</h5> -->

                  <br />
                  <br />

                  <!-- <form class="default-form">
                    <div class="row">
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Street</label>
                        <input type="text" v-model="street" placeholder="" />
                      </div>

                      
                      <div class="form-group col-lg-6 col-md-12">
                        <label>House</label>
                        <input type="text" v-model="house" placeholder="" />
                      </div>

                      
                      <div class="form-group col-lg-6 col-md-12">
                        <label>County</label>
                        <input type="text" v-model="county" placeholder="" />
                      </div>
                      
                      <div class="form-group col-lg-6 col-md-12">
                        <label>City</label>
                        <input type="text" v-model="city" placeholder="" />
                      </div>
                      
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Province</label>
                        <input type="text" v-model="province" placeholder="" />
                      </div>
                     
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Country</label>
                        <input type="text" v-model="country" placeholder="" />
                      </div>
                     
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Description</label>
                        <input
                          type="text"
                          v-model="description"
                          placeholder=""
                        />
                      </div>

                      
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Job <Title></Title></label>
                        <input type="text" v-model="job_title" placeholder="" />
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization name</label>
                        <input type="text" v-model="org_name" placeholder="" />
                      </div>

                      
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Salary Type</label>
                        <select v-model="salary_type">
                          <option value="hourly">Hourly</option>
                          <option value="monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                        </select>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Salary Range</label>

                        <div style="display: flex">
                          <input
                            type="number"
                            style="width: 80%"
                            v-model="salary_range"
                            placeholder="Salary Range"
                          />
                          
                          <button
                            class="button_plus"
                            @click="salaryrange"
                          ></button>
                        </div>
                        <div v-html="add4" class="mt-2"></div>
                      </div>


                      <div class="form-group col-lg-6 col-md-12">
                        <label>Working Hour Range</label>

                        <div style="display: flex">
                          <input
                            class="form-control"
                            type="time"
                            style="width: 80% "
                            v-model="working_hour_range"
                            placeholder=""
                          />
                          
                          <button
                            class="button_plus"
                            @click="working_hour_ranges"
                          ></button>
                        </div>
                        <div v-html="add5" class="mt-2"></div>
                      </div>


                      <div class="form-group col-lg-6 col-md-12">
                        <label>Available from Date </label>

                        <input
                          class="form-control"
                          type="date"
                          v-model="date_from"
                          placeholder="06.04.2020"
                        />
                      </div>


                      <div class="form-group col-lg-6 col-md-12">
                        <label>Available till Date</label>
                        <input
                          class="form-control"
                          type="date"
                          v-model="date_to"
                          placeholder="06.04.2020"
                        />
                      </div>


                      <div class="form-group col-lg-6 col-md-12">
                        <label>Skills</label>
                        <div style="display: flex">
                          <input
                            type="text"
                            style="width: 80%"
                            v-model="adds"
                            placeholder="Add skils"
                          />
                          <button @click="addskill" type="button" class="theme-btn btn-style-one ms-3">Add</button>
                          <button
                            class="button_plus"
                            @click="addskill"
                          ></button>
                        </div>
                        <div v-html="add" class="mt-2"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Exprience Ranges</label>
                        <div style="display: flex">
                          <input
                            type="number"
                            style="width: 80%"
                            v-model="experience_range"
                            placeholder="Experience Range"
                          />
                         
                          <button
                            class="button_plus"
                            @click="addexperiance"
                          ></button>
                        </div>
                        <div v-html="add2" class="mt-2"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Position No</label>
                        <input type="number" v-model="position_no" placeholder="" />
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact number</label>
                        <input type="number" v-model="contact_no" placeholder="" />
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Email</label>
                        <input type="email" v-model="email" placeholder="" />
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Job Category</label>
                        <select v-model="category_id">
                          <option>Select a category</option>
                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.cat_name }}
                          </option>
                        </select>
                      </div>

                      
                     
                      <div class="form-group col-lg-12 col-md-12 text-right">
                        <button type="submit" class="theme-btn btn-style-one">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DirectHireView",
  data() {
    return {
      add: "",
      add2: "",
      add4: "",
      add5: "",
      salary: [],
      working_hour: [],
      skill: [],
      experience: [],
      categories: [],
    };
  },

  methods: {
    salaryrange() {
      if (this.salary.length < 2) {
        this.salary.push(this.salary_range);
        this.add4 = "" + this.salary;
      } else {
        alert("Salary range Max length will be 2");
      }
    },

    working_hour_ranges() {
      if (this.working_hour.length < 2) {
        this.working_hour.push(this.working_hour_range);
        this.add5 = "" + this.working_hour;
      } else {
        alert("Salary range Max length will be 2");
      }
    },

    addskill() {
      if (this.skill.length < 10) {
        this.skill.push(this.adds);
        this.add = "" + this.skill;
      } else {
        alert("Skills Max length will be 10");
      }
    },

    addexperiance() {
      if (this.experience.length < 2) {
        this.experience.push(this.experience_range);
        this.add2 = "" + this.experience;
      } else {
        alert("Experience Max length will be 2");
      }
    },

    category() {
      axios
        .get("anonymous/job-category/")
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_direct_hire() {
      axios
        .post("anonymous/direct_hire/", {
          street: this.street,
          house: this.house,
          county: this.county,
          city: this.city,
          province: this.province,
          country: this.country,
          description: this.description,
          job_title: this.job_title,
          org_name: this.org_name,
          salary_type: this.salary_type,
          salary_range: this.salary,
          working_hour_range: this.working_hour,
          date_from: this.date_from,
          date_to: this.date_to,
          skill: this.skill,
          experience: this.experience,
          position_no: this.position_no,
          contact_no: this.contact_no,
          email: this.email,
          job_category: this.category_id,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted: function () {
    this.category();
    this.add_direct_hire();
  },
};
</script>

<style></style>
