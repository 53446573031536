<template>
     <footer class="main-footer style-two">
      <div class="auto-container">
        <!--Widgets Section-->
        <div class="widgets-section">
          <div class="row">
            <div class="big-column col-xl-4 col-lg-3 col-md-12">
              <div class="footer-column about-widget">
                <div class="logo"><a href="/"><img src="images/logo.png" alt=""></a></div>
                <p class="phone-num"><span>Call us </span><a href="support@hive4work.co.uk">+44 7495 587992</a></p>
                <p class="address">128-130 the Grove,Startford,London,England,E15 1NS<br><a href="mailto:support@hive4work.co.uk" class="email">support@hive4work.co.uk</a></p>
              </div>
            </div>

            <div class="big-column col-xl-8 col-lg-9 col-md-12">
              <div class="row">
               

                <div class="footer-column col-lg-5 col-md-6 col-sm-12">
                  <div class="footer-widget links-widget">
                    <h4 class="widget-title">Find Important Information</h4>
                    <div class="widget-content d-flex">
                      <ul class="list">
                        <li><a href="/terms">Terms of Conditions</a></li>
                        <li><a href="#">Privacy & Securty</a></li>
                        <li><a href="#">Lending Licnses</a></li>
                        <li><a href="#">Disclaimers</a></li>
                      </ul>

                      <ul class="list">
                        <li><a href="#">Support</a></li>
                        <li><a href="#">How It Works</a></li>
                        <li><a href="#">For Employers</a></li>
              
                        <li><a href="#">Contact Us</a></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="footer-column col-lg-6 col-md-12 col-sm-12">
                  <div class="footer-widget">
                    <h4 class="widget-title">Join Us On</h4>
                    <div class="widget-content">
                      <div class="newsletter-form">
                        <div class="text">We don’t send spam so don’t worry.</div>
                        <form method="post" action="#" id="subscribe-form">
                          <div class="form-group">
                            <div class="response"></div>
                          </div>
                          <div class="form-group">
                            <input type="email" name="email" class="email" value="" placeholder="Email" required>
                            <button type="button" id="subscribe-newslatters" class="theme-btn"><i class="flaticon-envelope"></i></button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--Bottom-->
      <div class="footer-bottom">
        <div class="auto-container">
          <div class="outer-box">
            <div class="copyright-text">© 2023 <a href="#">Hive4Work LTD</a>. All Right Reserved.</div>

            <div class="copyright-text"><strong>Powered by </strong> <a href="/">DataFluent Analytics</a></div>
            <div class="social-links">
              <a href="https://www.facebook.com/profile.php?id=100093448454141"><i class="fab fa-facebook-f"></i></a>
              <a href="https://twitter.com/home"><i class="fab fa-twitter"></i></a>
              <a href="#"><i class="fab fa-instagram"></i></a>
              <a href="https://www.linkedin.com/company/95059378/admin/"><i class="fab fa-linkedin-in"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="scroll-to-top scroll-to-target" data-target="html"><span class="fa fa-angle-up"></span></div>
    </footer>
</template>


<script>
export default {
name:"AuthFooterView"
}
</script>

<style>

</style>