<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/recruiter-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/recruiter-profile"
              ><i class="la la-user-tie"></i>Profile</a
            >
          </li>

          <li>
            <a href="/recruiter-postjob"
              ><i class="la la-paper-plane"></i>Post a New Job</a
            >
          </li>
          <li class="active">
            <a href="/recruiter-managejob"
              ><i class="la la-briefcase"></i> Manage Jobs
            </a>
          </li>
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          <li>
            <a href="#"><i class="la la-trash"></i>Delete Profile</a>
          </li>
          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <!-- <li><a href="#"><i class="la la-lock"></i>Change Password</a></li> -->
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <!-- <li><a @click.prevent="logout" ><i class="la la-sign-out"></i>Logout</a></li> -->

          <!-- <li><img src="images/boy.png" style=" height: 400px;width: 100%;"></li> -->
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 200px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Manage Jobs</h3>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>My Job Listings</h4>
                </div>

                <div class="widget-content">
                  <div class="table-outer">
                    <table class="default-table manage-job-table">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Applications</th>
                          <th>Open & Deadline</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="job in jobs" :key="job.uuid">
                          <td>
                            <h6>{{ job.job_title }}</h6>
                            <span class="info" style="color: #36a8a6"
                              ><i
                                class="icon flaticon-map-locator"
                                style="color: #050e0d"
                              ></i>
                              {{ job.job_location }}</span
                            >
                          </td>
                          <td class="applied">
                            <a @click.prevent="openApplication(job.uuid)">{{
                              job.application_count
                            }}</a>
                          </td>
                          <td>
                            Open: {{ job.opening_date }}<br />Deadline:
                            {{ job.expire_date }}
                          </td>
                          <td class="status">{{ job.status }}</td>
                          <td>
                            <div class="option-box">
                              <ul class="option-list">
                                <li>
                                  <button
                                    @click.prevent="openjob(job.uuid)"
                                    data-text="Edit"
                                  >
                                    <span
                                      class="icon la la-pen"
                                      style="color: #070f0f"
                                    ></span>
                                  </button>
                                </li>
                                <!-- <li><button data-text="Reject Aplication"><span class="la la-pencil" style="color:   #b4e515;"></span></button></li> -->
                                <li>
                                  <button data-text="Delete">
                                    <span
                                      class="icon la la-trash"
                                      style="color: #744812"
                                    ></span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <nav class="ls-pagination">
                      <ul>
                        <li v-if="all_data.previous != null" class="prev">
                          <a @click.prevent="page(current_page - 1)"
                            ><i class="fa fa-arrow-left"></i
                          ></a>
                        </li>
                        <li v-for="i in pages" :key="i">
                          <a
                            :class="{ 'current-page': current_page == i }"
                            @click.prevent="page(i)"
                            >{{ i }}</a
                          >
                        </li>

                        <li v-if="all_data.next != null" class="next">
                          <a @click.prevent="page(current_page + 1)"
                            ><i class="fa fa-arrow-right"></i
                          ></a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->

    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "EmployerManageJobView",

  data() {
    return {
      jobs: [],

      current_page: 1,
      next_page: "",
      next: "",
      previous: "",
      pages: 1,
      total_job: "",

      all_data: {},
    };
  },

  methods: {
    getjob() {
      axios
        .get("recruiter/jobpost/?page=1&page_size=10")
        .then((res) => {
          if (res.status == 200) {
            this.jobs = res.data.results;

            this.total_job = res.data.count;

            this.all_data = res.data;

            if (this.total_job > 0) {
              this.pages = Math.ceil(this.total_job / 10);
            }

            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    page(page_number) {
      axios
        .get("recruiter/jobpost/?page=" + page_number + "&page_size=" + 10)
        .then((res) => {
          if (res.status == 200) {
            this.current_page = page_number;

            this.jobs = res.data.results;
            this.all_data = res.data;

            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    openjob(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "employer-singlepostjob",
          query: { uuid: uuid },
        });
      }
    },

    openApplication(uuid) {
      if (uuid != null) {
        this.$router.push({ name: "applicant-list", query: { uuid: uuid } });
      }
    },

    get_jobss(key) {
      axios
        .get("recruiter/jobpost/", {
          params: {
            search: key,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.jobs = res.data.results;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    showjob(text) {
      if (text != null) {
        this.get_jobss(text);
      }
    },
  },

  mounted: function () {
    this.getjob();
  },
};
</script>

<style></style>
