<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li class="active">
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
          
        </ul>
      </div>
    </div>

    <!-- End User Sidebar -->

    <!--Page Title-->

    <!--End Page Title-->

    <!-- Listing Section -->
    <section
      style="background: none; text-align: left"
      class="page-title style-two"
    >
      <div class="auto-container">
        <div class="title-outer">
          <h1 class="text-left">Edit Organization Type</h1>
          <!-- <ul class="page-breadcrumb">
              <li><a href="/">Dashboard</a></li>
              <li>Add Job Category</li>
            </ul> -->
        </div>
        <!-- <div class="title-outer">
                  <h1>Find Jobs</h1>
                  <ul class="page-breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li>Jobs</li>
                  </ul> 
              </div> -->

        <!-- Contact Form -->
        <div class="contact-form default-form">
          <!--Contact Form-->
          <form @submit.prevent="update_org_type">
            <div class="row">
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <div class="response"></div>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                <!--label>Your Name</label-->
                <input
                  type="text"
                  v-model="org_type_data.code"
                  class="username"
                  placeholder="Organization Type Code*"
                  @input="code_error = ''"
                />

                <p v-if="code_error != ''" class="mt-1 text-danger">
                  {{ code_error }}
                </p>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                <!--label>Your Email</label-->
                <input
                  type="text"
                  v-model="org_type_data.org_type"
                  class="username"
                  placeholder="Organization Type*"
                  @input="type_error = ''"
                />
                <p v-if="type_error != ''" class="mt-1 text-danger">
                  {{ type_error }}
                </p>
              </div>

              <!--div class="col-lg-12 col-md-12 col-sm-12 form-group">
                  <label>Subject</label>
                  <input type="text" name="subject" class="subject" placeholder="Subject *" required>
                </div>
  
                <div class="col-lg-12 col-md-12 col-sm-12 form-group">username
                  <label>Your Message</label>
                  <textarea name="message" placeholder="Write your message..." required=""></textarea>
                </div-->
              <br /><br />
              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <button
                  class="theme-btn btn-style-one"
                  type="submit"
                  id="submit"
                  name="submit-form"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
          <br />
          <br />
        </div>
        <!--End Contact Form -->
        <!--div-->
        <!-- Pagination -->
        <!--nav class="ls-pagination">
                  <ul>
                    <li class="prev"><a href="#"><i class="fa fa-arrow-left"></i></a></li>
                    <li><a href="#">1</a></li>
                    <li><a href="#" class="current-page">2</a></li>
                    <li><a href="#">3</a></li>
                    <li class="next"><a href="#"><i class="fa fa-arrow-right"></i></a></li>
                  </ul>
                </nav>
              </div-->
      </div>
    </section>

    <!--End Listing Page Section -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AdminAddJobCategoryView",
  data() {
    return {
      org_type_data: {},
      id: "",
      code_error: "",
      type_error: "",
    };
  },
  methods: {
    update_org_type() {
      if (this.org_type_data.code == null || this.org_type_data.code == "") {
        this.code_error = "Please Enter Organization Type Code";
      } else if (
        this.org_type_data.org_type == null ||
        this.org_type_data.org_type == ""
      ) {
        this.type_error = "Please Enter Organization Type";
      } else {
        axios
          .put("app_admin/org-type/" + this.id + "/", {
            code: this.org_type_data.code,
            org_type: this.org_type_data.org_type,
          })
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$router.push({ name: "admin-add-employer-type" });
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    get_org_type(id) {
      axios
        .get("app_admin/org-type/" + id + "/")
        .then((response) => {
          if (response.status == 200) {
            this.org_type_data = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted: function () {
    var id = this.$route.query.id;
    if (id != null) {
      this.get_org_type(id);
      this.id = id;
    }
  },
};
</script>

<style></style>
