<template>
  <!--Page Title-->
  <div>
    <br /><br /><br />
    <section class="page-title style-two">
      <div class="auto-container">
        <div class="row">
          <div class="col-lg-7 text-left">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="sec-title text-center">
                  <h2
                    class="text-white mt-3 text-left"
                    style="font-size: 57px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
                  >
                    Build Your Skills
                  </h2>

                  <!-- <div class="text text-white text-left" style="font-size: 30px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">
                Are you looking for a job!
              </div> -->

                  <div
                    class="text text-white text-left"
                    style="font-size: 25px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
                  >
                    Offering courses through hive4work
                  </div>
                </div>
              </div>
            </div>

            <div style="background-color: white; border-radius: 10px">
              <div class="row">
                <div
                  class="col-lg-10"
                  style="margin: 0px; padding: 0px; z-index: 10"
                >
                  <div class="form-group text-left px-5 py-3">
                    <span
                      style="color: grey"
                      class="icon flaticon-search-1"
                    ></span>
                    <input
                      style="
                        border-radius: 10px;
                        width: 80%;
                        color: grey;
                        padding-left: 20px;
                      "
                      type="text"
                      @input="showjob($event.target.value)"
                      name="field_name"
                      placeholder="Search Course"
                    />

                    <span style="height: 100%; background-color: red"></span>
                  </div>
                </div>
                <div
                  id="find-job"
                  class="col-2 pt-3 text-center"
                  style="
                    background-color: #3f4a5a;
                    margin: 0px;
                    padding: 0px;
                    border-radius: 0px 10px 10px 0px;
                    color: white;
                  "
                >
                  <span class="text-center">SEARCH</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <img src="images/skill.png" style="height: 100%; width: 100%" />
          </div>
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Shop Single -->
    <section class="shop-single">
      <div class="auto-container">
        <!-- Product Detail -->
        <div class="product-details">
          <!--Basic Details-->
          <div class="basic-details">
            <div class="row clearfix">
              <div class="image-column col-md-6 col-sm-12">
                <figure class="image">
                  <a
                    href="images/course1.jpg"
                    class="lightbox-image"
                    title="Image Caption Here"
                    ><img src="images/course1.jpg" alt="" /><span
                      class="icon flaticon-magnifying-glass"
                    ></span
                  ></a>
                </figure>
              </div>
              <div class="info-column col-md-6 col-sm-12">
                <div
                  class="inner-column"
                  style="margin-left: 150px; margin-top: 30px"
                >
                  <h4 class="product-name">Professional Chef</h4>
                  <div class="text">
                    This Professional Chef, Hospitality & Catering management
                    Diploma Level 2 & 3 course covers all you need to know about
                    cooking, as well as Catering and Managing restaurants.
                  </div>
                  <div class="item-price">Free</div>

                  <div class="other-options">
                    <!-- <div class="item-quantity">
                      <input class="qty" type="number" value="1" name="quantity">
                      <span class="in-stock">432 in stock</span>
                    </div> -->
                    <div class="btn-box">
                      <a
                        style="font-size: 100%; z-index: 30; margin-top: 20px"
                        href="/contact"
                        class="theme-btn btn-style-one"
                        >CONTACT US</a
                      >
                      <!-- <button type="button" class="theme-btn btn-style-one"><span></span>CONTACT US</button> -->
                      <!-- <button type="button" class="theme-btn btn-style-three bookmark-btn"><span class="flaticon-bookmark"></span></button> -->
                    </div>
                  </div>

                  <ul class="product-meta">
                    <li><span>Study method:</span> Online</li>
                    <li><span>Duration:</span> 2 hours</li>
                    <li><span>Qualification:</span>No formal qualification</li>
                    <li>
                      <span>Certificates:</span>Courses Certificate of
                      Completion - £3
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--Basic Details-->

          <!--Product Info Tabs-->
          <div class="product-info-tabs">
            <!--Product Tabs-->
            <div class="prod-tabs tabs-box">
              <!--Tab Btns-->
              <ul class="tab-btns tab-buttons clearfix">
                <!-- <li data-tab="#prod-details" class="tab-btn active-btn" style="font-size: 40px;">Description</li> -->
              </ul>

              <!--Tabs Container-->
              <div class="tabs-content">
                <!--Tab-->
                <div class="tab active-tab" id="prod-details">
                  <div class="content">
                    <h4 class="product-name" style="font-size: 30px">
                      Description
                    </h4>

                    <p>
                      Professional Chef with Higher Technician in Cooking and
                      Gastronomy
                    </p>

                    <h4 class="product-name" style="font-size: 30px">
                      Who is this course for?
                    </h4>
                    <p>
                      Anyone from any background can enrol in this Chef Training
                      bundle.
                    </p>

                    <h4 class="product-name" style="font-size: 30px">
                      Certification
                    </h4>
                    <p>
                      When you successfully complete your Professional Chef with
                      Higher Technician in Cooking and Gastronomy course, you
                      will get a FREE immediate digital certificate from
                      Hive4Work.
                    </p>

                    <h4 class="product-name" style="font-size: 30px">
                      Requirements
                    </h4>
                    <p>
                      Our Chef Training course is fully compatible with PC’s,
                      Mac’s, Laptop, Tablet and Smartphone devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--End Product Info Tabs-->
        </div>
      </div>
    </section>
    <!-- End Shop Single -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CareerAdviceView",
  data() {
    return {
      jobs: [
        {
          jobtitle: "Software Developer",
          segmemt: "segmemt",
          location: "Dhaka",
          time: "11",
          salary: "35k",
          type: "Fulltime",
        },
        {
          jobtitle: "Product Manager",
          segmemt: "segmemt",
          location: "Khulna",
          time: "12",
          salary: "30k",
          type: "Fulltime",
        },
        {
          jobtitle: "HR",
          segmemt: "segmemt",
          location: "Chandpur",
          time: "13",
          salary: "35k",
          type: "Parttime",
        },
        {
          jobtitle: "Accounts",
          segmemt: "segmemt",
          location: "Dhaka",
          time: "11",
          salary: "25k",
          type: "Fulltime",
        },
      ],
      ano_jobs: [],

      page_number: 1,
      next: null,
      previous: null,
      count: "",
    };
  },

  methods: {
    get_jobs() {
      axios
        .get("anonymous/job-list/", { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;
            this.next = res.data.next;
            this.previous = res.data.previous;
            this.count = res.data.count;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    page(pageno) {
      axios
        .get("anonymous/job-list/?page" + "=" + pageno, { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;
            this.page_number = pageno;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    next_page(nextpage) {
      axios
        .get(nextpage, { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    previous_page(prepage) {
      axios
        .get(prepage, { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    openjob(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "career-advice-single",
          query: { uuid: uuid },
        });
      }
    },

    get_jobss(key) {
      axios
        .get(
          "anonymous/job-list/",
          {
            params: {
              search: key,
            },
          },
          { ignore: "1" }
        )
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    showjob(text) {
      if (text != null) {
        this.get_jobss(text);
      }
    },
  },

  mounted: function () {
    this.get_jobs();
  },
};
</script>

<style>
.news-block .inner-box:hover {
  background: none;
}
</style>
