<template>
  <div>
    <br /><br />
    <!--Page Title-->
    <section class="page-title style-two">
      <div class="auto-container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="sec-title text-center">
              <h2>Search suitable candidates!</h2>
              <div class="text">
                Are you looking for competent and suitable candidates for your
                company! Please search CV from hundreds applicants adding daily
                in Hive4Works
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="title-outer">
                <h1>Find Jobs</h1>
                <ul class="page-breadcrumb">
                    <li><a href="index.html">Home</a></li>
                    <li>Jobs</li>
                </ul> 
            </div> -->

        <!-- Job Search Form -->
        <div class="job-search-form">
          <form method="post" action="job-list-v10.html">
            <div class="row">
              <!-- Form Group -->
              <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <span class="icon flaticon-search-1"></span>
                <input
                  type="text"
                  name="field_name"
                  placeholder="Job title, keywords, or company"
                />
              </div>

              <!-- Form Group -->
              <div class="form-group col-lg-3 col-md-12 col-sm-12 location">
                <span class="icon flaticon-map-locator"></span>
                <input type="text" name="field_name" placeholder="City" />
              </div>

              <!-- Form Group -->
              <div class="form-group col-lg-3 col-md-12 col-sm-12 location">
                <span class="icon flaticon-briefcase"></span>
                <select class="chosen-select">
                  <option value="">All Categories</option>
                  <option value="44">Hospitality Management</option>
                  <option value="106">Restaurant</option>
                  <option value="46">Hotel/Resort</option>
                  <option value="48">Bar/Caffe</option>
                  <option value="47">Cleaning</option>
                  <option value="45">Health and Care</option>
                  <option value="107">Event Management</option>
                </select>
              </div>

              <!-- Form Group -->
              <div class="form-group col-lg-2 col-md-12 col-sm-12 text-right">
                <button type="submit" class="theme-btn btn-style-one">
                  Find Jobs
                </button>
              </div>
            </div>
          </form>
        </div>
        <!-- Job Search Form -->
      </div>
    </section>
    <!--End Page Title-->

    <!-- Listing Section -->
    <section class="ls-section">
      <div class="auto-container">
        <div class="filters-backdrop"></div>

        <div class="row">
          <!-- Content Column -->
          <div class="content-column col-lg-12">
            <div class="ls-outer">
              <!-- ls Switcher -->
              <div class="ls-switcher">
                <div class="showing-result">
                  <div class="top-filters">
                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Candidate Gender</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>other</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Date Posted</option>
                        <option>Today's Job</option>
                        <option>This week</option>
                        <option>Last week</option>
                        <option>This Month</option>
                        <option>Last 6 months</option>
                        <option>This year</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Experience Level</option>
                        <option>No Experience</option>
                        <option>1 year Experience</option>
                        <option>1-3 years Experience</option>
                        <option>3-6 years Experience</option>
                        <option>6-10 years Experience</option>
                        <option>10+ Years Experience</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Education Level</option>
                        <option>High School</option>
                        <option>Diploma</option>
                        <option>Graduate</option>
                        <option>Post Graduate</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="sort-by">
                  <select class="chosen-select">
                    <option>Most Recent</option>
                    <option>Freelance</option>
                    <option>Full Time</option>
                    <option>Internship</option>
                    <option>Part Time</option>
                    <option>Temporary</option>
                  </select>

                  <select class="chosen-select">
                    <option>Show 10</option>
                    <option>Show 20</option>
                    <option>Show 30</option>
                    <option>Show 40</option>
                    <option>Show 50</option>
                    <option>Show 60</option>
                  </select>
                </div>
              </div>

              <div class="row">
                <!-- Candidate block Four -->
                <div
                  v-for="candidates in CandidatesList"
                  :key="candidates.id"
                  class="candidate-block-four col-lg-4 col-md-6 col-sm-12"
                >
                  <div class="inner-box">
                    <ul class="job-other-info">
                      <li class="green">Featured</li>
                    </ul>
                    <span class="thumb"
                      ><img src="images/resource/candidate-1.png" alt=""
                    /></span>
                    <h3 class="name">
                      <a href="#">{{ candidates.CandidatesName }}</a>
                    </h3>
                    <span class="cat">{{ candidates.Designamtion }}</span>
                    <ul class="job-info">
                      <li>
                        <span class="icon flaticon-map-locator"></span
                        >{{ candidates.location }}
                      </li>
                      <li>
                        <span class="icon flaticon-money"></span
                        >{{ candidates.Payment }}
                      </li>
                    </ul>
                    <ul class="post-tags">
                      <li>
                        <a href="#">{{ candidates.App }}</a>
                      </li>
                      <li>
                        <a href="#">{{ candidates.Design }}</a>
                      </li>
                      <li>
                        <a href="#">{{ candidates.Digital }}</a>
                      </li>
                    </ul>
                    <a href="/candidate" class="theme-btn btn-style-three"
                      >View Profile</a
                    >
                  </div>
                </div>

                <!-- Pagination -->
                <nav class="ls-pagination">
                  <ul>
                    <li class="prev">
                      <a href="#"><i class="fa fa-arrow-left"></i></a>
                    </li>
                    <li><a href="#">1</a></li>
                    <li><a href="#" class="current-page">2</a></li>
                    <li><a href="#">3</a></li>
                    <li class="next">
                      <a href="#"><i class="fa fa-arrow-right"></i></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "CandidatesListView",

  data() {
    return {
      CandidatesList: [
        {
          CandidatesName: "Hridoy",
          Designamtion: "Software Developer",
          location: "Dhaka",
          Payment: "$25 hours",
          App: "app",
          Design: "design",
          Digital: "digital",
        },
        {
          CandidatesName: "Tawsif",
          Designamtion: "Product Manager",
          location: "Khulna",
          Payment: "$15 hours",
          App: "app",
          Design: "design",
          Digital: "digital",
        },
        {
          CandidatesName: "Noman",
          Designamtion: "HR",
          location: "Chandpur",
          Payment: "$10 hours",
          App: "app",
          Design: "design",
          Digital: "digital",
        },
        {
          CandidatesName: "Faysal",
          Designamtion: "Accounts",
          location: "Dhaka",
          Payment: "$20 hours",
          App: "app",
          Design: "design",
          Digital: "digital",
        },
      ],
    };
  },
};
</script>

<style></style>
