<template>
  <div style="margin-top: 100px">
    <section class="call-to-action-two" style="background-color: #36a8a6">
      <div class="auto-container">
        <div class="sec-title light text-center">
          <h2>Fill this form for PART TIME JOB</h2>
          <div class="text">
            Over hundreds interactions, 500 success stories Make yours now.
          </div>
        </div>
      </div>
    </section>

    <!-- Dashboard -->
    <section>
      <div class="dashboard-outer mt-5">
        <div class="upper-title-box"></div>
        <div class="row">
          <div class="col-lg-8 m-auto">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box p-3">
                <div class="widget-content">
                  <form
                    ref="form_part_time"
                    id="part_time_form"
                     
                    @submit.prevent="add_parttime_job"
                    class="default-form"
                  >
                    <div class="row">
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Street</label>
                        <input
                          @input="clearError('street')"
                          type="text"
                          v-model="street"
                          placeholder=""
                        />
                        <p id="street" class="text-danger"></p>
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>House</label>
                        <input
                          @input="clearError('house')"
                          type="text"
                          v-model="house"
                          placeholder=""
                        />
                        <p id="house" class="text-danger"></p>
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>County</label>
                        <input
                          @input="clearError('county')"
                          type="text"
                          v-model="county"
                          placeholder=""
                        />
                        <p id="county" class="text-danger"></p>
                      </div>
                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>City</label>
                        <input
                          type="text"
                          @input="clearError('city')"
                          v-model="city"
                          placeholder=""
                        />
                        <p id="city" class="text-danger"></p>
                      </div>
                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Province</label>
                        <input
                          type="text"
                          @input="clearError('province')"
                          v-model="province"
                          placeholder=""
                        />
                        <p id="province" class="text-danger"></p>
                      </div>
                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Country</label>
                        <input
                          type="text"
                          @input="clearError('country')"
                          v-model="country"
                          placeholder=""
                        />
                        <p id="country" class="text-danger"></p>
                      </div>
                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Description</label>
                        <input
                          type="text"
                          v-model="description"
                          placeholder=""
                          @input="clearError('description')"
                        />
                        <p id="description" class="text-danger"></p>
                      </div>
                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Full Name</label>
                        <input
                          type="text"
                          @input="clearError('full_name')"
                          v-model="full_name"
                          placeholder=""
                        />
                        <p id="full_name" class="text-danger"></p>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Email</label>
                        <input
                          type="text"
                          @input="clearError('email')"
                          v-model="email"
                          placeholder=""
                        />
                        <p id="email" class="text-danger"></p>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact number</label>
                        <input
                          type="number"
                          v-model="contact_no"
                          placeholder=""
                          @input="clearError('contact_no')"
                        />

                        <p id="contact_no" class="text-danger"></p>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Available from Hour</label>
                        <input
                          class="form-control"
                          type="time"
                          v-model="available_hour_from"
                          @input="clearError('available_hour_from')"
                          placeholder=""
                        />
                        <p id="available_hour_from" class="text-danger"></p>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Available to Hour</label>
                        <input
                          class="form-control"
                          type="time"
                          v-model="available_hour_to"
                          placeholder=""
                          @input="clearError('available_hour_to')"
                        />
                        <p id="available_hour_to" class="text-danger"></p>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Available from Date </label>

                        <input
                          class="form-control"
                          type="date"
                          v-model="available_from"
                          placeholder="06.04.2020"
                          @input="clearError('available_from')"
                        />
                        <p id="available_from" class="text-danger"></p>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Available to date</label>
                        <input
                          class="form-control"
                          type="date"
                          v-model="available_to"
                          placeholder=""
                          @input="clearError('available_to')"
                        />
                        <p id="available_to" class="text-danger"></p>
                      </div>

                      <!-- Input -->

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Skills*</label>
                        <div class="row">
                          <div class="col-9">
                            <input
                              type="text"
                              v-model.trim="skill"
                              placeholder="Add skills"
                              @input="clearError('skill')"
                            />
                          </div>
                          <div class="col-3">
                            <button
                              class="theme-button h-100 w-100"
                              @click.prevent="addskill"
                            >
                              ADD
                            </button>
                          </div>
                        </div>
                        <div v-if="skills != ''" class="mt-2">
                          <span
                            v-for="skill in skills"
                            :key="skill"
                            class="mt-2 addElement"
                          >
                            {{ skill }}
                            <i
                              @click="removekeyword(skill)"
                              class="fa-solid fa-xmark removeElebtn"
                            ></i>
                          </span>
                        </div>

                        <div id="skill" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Job Category</label>
                        <select
                          v-model="job_category"
                          @change="clearError('job_category')"
                        >
                          <option disabled value=null>Select a category</option>
                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.cat_name }}
                          </option>
                        </select>
                        <div id="job_category" class="text-danger error"></div>
                      </div>

                


                      <div class="form-group col-lg-6 col-md-12">
                        <label>Upload CV(Pdf,doc,docx)</label>
                        <input
                          class="form-control"
                          type="file"
                          accept=".pdf,.doc,.docx"
                          @change="handleFileUpload($event)"
                        />

                        <div id="file" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">

                        <div id="loader-small">
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
            </div>
                   
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12 text-right">
                        <button type="submit" class="theme-btn btn-style-one">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "ParttimeView",
  data() {
    return {
      add: "",
      add2: "",
      add4: "",
      add5: "",
      salary: [],
      working_hour: [],
      skills: [],
      experience: [],
      categories: [],
      file: "",
      error: "",
      job_category:null
    };
  },

  methods: {
    salaryrange() {
      if (this.salary.length < 2) {
        this.salary.push(this.salary_range);
        this.add4 = "" + this.salary;
      } else {
        alert("Salary range Max length will be 2");
      }
    },

    working_hour_ranges() {
      if (this.working_hour.length < 2) {
        this.working_hour.push(this.working_hour_range);
        this.add5 = "" + this.working_hour;
      } else {
        alert("Salary range Max length will be 2");
      }
    },

    addskill() {
      if (this.skills.length < 10) {
        if (this.skill != null && this.skill != "") {
          this.skills.push(this.skill);
          this.skill = "";
        } else {
          $("#skill").html("Field is empty");
        window.scrollTo(0, $("#skill").offset().top - 200);
        }
      } else {
        alert("Skills Max length will be 10");
      }
    },

    removekeyword(skill) {
      this.skills.splice(this.skills.indexOf(skill), 1);
    },

    addexperiance() {
      if (this.experience.length < 2) {
        this.experience.push(this.experience_range);
        this.add2 = "" + this.experience;
      } else {
        alert("Experience Max length will be 2");
      }
    },

    category() {
      axios
        .get("anonymous/job-category/")
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.clearError("file");
    },

    add_parttime_job() {

      var loader = document.getElementById("loader-small");
      loader.style.display = "block";
      let formData = new FormData();
      if (
        this.nullcheck(this.street, "street") &&
        this.nullcheck(this.house, "house") &&
        this.nullcheck(this.county, "county") &&
        this.nullcheck(this.city, "city") &&
        this.nullcheck(this.province, "province") &&
        this.nullcheck(this.country, "country") &&
        this.nullcheck(this.description, "description") &&
        this.nullcheck(this.full_name, "full_name") &&
        this.nullcheck(this.email, "email") &&
        this.emailcheck(this.email, "email") &&
        this.nullcheck(this.contact_no, "contact_no") &&
        this.nullcheck(this.available_hour_from, "available_hour_from") &&
        this.nullcheck(this.available_hour_to, "available_hour_to") &&
        this.nullcheck(this.available_from, "available_from") &&
        this.nullcheck(this.available_to, "available_to") &&
        this.nullcheck(this.skills, "skill") &&
        this.nullcheck(this.job_category, "job_category") &&
        this.nullcheck(this.file, "file")
      ) {
        formData.append("street", this.street);
        formData.append("house", this.house);
        formData.append("county", this.county);
        formData.append("city", this.city);
        formData.append("province", this.province);
        formData.append("country", this.country);
        formData.append("description", this.description);
        formData.append("full_name", this.full_name);
        formData.append("email", this.email);
        formData.append("contact_no", this.contact_no);
        formData.append("available_hour_from", this.available_hour_from);
        formData.append("available_hour_to", this.available_hour_to);
        formData.append("available_from", this.available_from);
        formData.append("available_to", this.available_to);
        formData.append("skill", this.skills);
        formData.append("job_category", this.job_category);
        this.skills=[]
        this.job_category=null

        if (this.file != "") {
          formData.append("resume", this.file);
        }

        axios
          .post("anonymous/part_time_apply/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
        
            if (res.status == 201) {
              console.log(res.data);
              var loader = document.getElementById("loader-small");
              loader.style.display = "none";
             
              $('#part_time_form')[0].reset()

              this.$emit('get_message', "Apply Successfully")
            
  

            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    clearError(element) {
      $("#" + element).html("");
    },

    // validation(name, value, type) {
    //   if (type == "null") {
    //     if (value == null || value == "") {
    //       var input_group = document.getElementById(name);

    //       var child_p = document.getElementsByTagName("p");

    //       if (!input_group.contains(child_p[0])) {
    //         const para = document.createElement("p");
    //         para.style.color = "red";
    //         para.style.padding = "0px";
    //         para.style.margin = "0px";
    //         const node = document.createTextNode("This field is required");

    //         para.appendChild(node);
    //         input_group.appendChild(para);
    //       }

    //       document.getElementById(name).scrollIntoView();

    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }

    //   if (type == "length") {
    //     return true;
    //   }
    // },

    nullcheck(value, element) {
      if (value == null || value == "") {
        $("#" + element).html("This field is required");
        window.scrollTo(0, $("#" + element).offset().top - 200);

        return false;
      } else {
        return true;
      }
    },
    emailcheck(value, element) {
      if (!value.includes("@")) {
        $("#" + element).html("Email is not valid");
        window.scrollTo(0, $("#" + element).offset().top - 200);

        return false;
      } else {
        return true;
      }
    },
  },

  mounted: function () {
    this.category();
  },
};
</script>

<style></style>
