<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/candidate-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/candidate-profile-single"
              ><i class="la la-user-tie"></i>My Resume</a
            >
          </li>
          <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
          <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
          <li class="active">
            <a href="/candidate-findjob"
              ><i class="la la-user-alt"></i>Find jobs</a
            >
          </li>
          <li>
            <a href="/candidate-appliedjob"
              ><i class="la la-briefcase"></i> Applied Jobs
            </a>
          </li>
          <!-- <li><a href="/candidate-change-password"><i class="la la-lock"></i>Change Password</a></li> -->
          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 250px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!--Page Title-->
    <section class="page-title style-two" style="background: local">
      <div class="auto-container">
        <div class="upper-title-box">
          <h3>Find Jobs</h3>
          <div class="text">Ready to jump back in?</div>
        </div>
        <!-- Job Search Form -->
        <div style="background-color: white; border-radius: 10px">
          <div class="row">
            <div
              class="col-lg-10"
              style="margin: 0px; padding: 0px; z-index: 10"
            >
              <div class="form-group text-left px-5 py-3">
                <span style="color: grey" class="icon flaticon-search-1"></span>
                <input
                  style="
                    border-radius: 10px;
                    width: 80%;
                    color: grey;
                    padding-left: 20px;
                  "
                  type="text"
                  @input="showjob($event.target.value)"
                  name="field_name"
                  placeholder="Job title, keywords, or company"
                />

                <span style="height: 100%; background-color: red"></span>
              </div>
            </div>
            <div
              id="find-job"
              class="col-2 pt-3 text-center"
              style="
                background-color: #3f4a5a;
                margin: 0px;
                padding: 0px;
                border-radius: 0px 10px 10px 0px;
                color: white;
              "
            >
              <span class="text-center">FIND JOB</span>
            </div>
          </div>
        </div>

        <!-- Job Search Form -->
      </div>
    </section>
    <!--End Page Title-->

    <!-- Listing Section -->
    <section class="ls-section">
      <div class="auto-container">
        <div class="filters-backdrop"></div>

        <div class="row">
          <!-- Content Column -->
          <div class="content-column col-lg-12">
            <div class="ls-outer">
              <!-- ls Switcher -->
              <div class="ls-switcher">
                <!-- <div class="showing-result">
                  <div class="top-filters">
                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Job Type</option>
                        <option>New Jobs</option>
                        <option>Freelance</option>
                        <option>Full Time</option>
                        <option>Internship</option>
                        <option>Part Time</option>
                        <option>Temporary</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Date Posted</option>
                        <option>New Jobs</option>
                        <option>Freelance</option>
                        <option>Full Time</option>
                        <option>Internship</option>
                        <option>Part Time</option>
                        <option>Temporary</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Experience Level</option>
                        <option>New Jobs</option>
                        <option>Freelance</option>
                        <option>Full Time</option>
                        <option>Internship</option>
                        <option>Part Time</option>
                        <option>Temporary</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Salary estimate</option>
                        <option>New Jobs</option>
                        <option>Freelance</option>
                        <option>Full Time</option>
                        <option>Internship</option>
                        <option>Part Time</option>
                        <option>Temporary</option>
                      </select>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="sort-by">
                  <select class="chosen-select">
                    <option>New Jobs</option>
                    <option>Freelance</option>
                    <option>Full Time</option>
                    <option>Internship</option>
                    <option>Part Time</option>
                    <option>Temporary</option>
                  </select>

                  <select class="chosen-select">
                    <option>Show 10</option>
                    <option>Show 20</option>
                    <option>Show 30</option>
                    <option>Show 40</option>
                    <option>Show 50</option>
                    <option>Show 60</option>
                  </select>
                </div> -->
              </div>

              <div class="row">
                <div
                  v-for="job in ano_jobs"
                  :key="job.id"
                  class="job-block col-lg-6"
                >
                  <div @click="openjob(job.uuid)" class="inner-box">
                    <div class="content">
                      <span
                        v-if="job.recruiter.logo != null"
                        class="company-logo"
                        ><img :src="job.recruiter.logo.logo" alt="Logo"
                      /></span>

                      <h4 class="c-text-left">{{ job.job_title }}</h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span
                          >{{ job.recruiter.org_name }}
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span
                          >{{ job.job_location }}
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> Closing
                          date:{{ job.expire_date }}
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> &pound;{{
                            job.salary_range[0]
                          }}-&pound;{{ job.salary_range[1] }}
                        </li>
                      </ul>
                      <ul class="job-other-info">
                        <li class="time">{{ job.job_type }}</li>
                        <!-- <li class="privacy">Private</li>
                        <li class="required">Urgent</li> -->
                      </ul>

                      <!-- <div class="col-md-3 text-lg-center mt-sm-5 m-lg-auto">
                  <button href="#" class="theme-button mt-2 px-3 py-2">
                    Apply
                  </button>
                </div> -->
                      <!-- <button class="bookmark-btn"><span class="flaticon-bookmark"></span></button> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- Pagination -->
              <nav class="ls-pagination">
                <ul>
                  <li class="prev">
                    <a href="#"><i class="fa fa-arrow-left"></i></a>
                  </li>
                  <li><a href="#">1</a></li>
                  <li><a href="#" class="current-page">2</a></li>
                  <li><a href="#">3</a></li>
                  <li class="next">
                    <a href="#"><i class="fa fa-arrow-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Listing Page Section -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CandidateFindJobsView",
  data() {
    return {
      jobs: [
        {
          jobtitle: "Software Developer",
          segmemt: "segmemt",
          location: "Dhaka",
          time: "11",
          salary: "35k",
          type: "Fulltime",
        },
        {
          jobtitle: "Product Manager",
          segmemt: "segmemt",
          location: "Khulna",
          time: "12",
          salary: "30k",
          type: "Fulltime",
        },
        {
          jobtitle: "HR",
          segmemt: "segmemt",
          location: "Chandpur",
          time: "13",
          salary: "35k",
          type: "Parttime",
        },
        {
          jobtitle: "Accounts",
          segmemt: "segmemt",
          location: "Dhaka",
          time: "11",
          salary: "25k",
          type: "Fulltime",
        },
      ],
      ano_jobs: [],
    };
  },

  methods: {
    get_jobs() {
      axios
        .get("anonymous/job-list/", { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    openjob(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "candidate-findjob-single",
          query: { uuid: uuid },
        });
      }
    },

    page(pageno) {
      axios
        .get("anonymous/job-list/?page" + "=" + pageno, { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;
            this.page_number = pageno;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    next_page(nextpage) {
      axios
        .get(nextpage, { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    previous_page(prepage) {
      axios
        .get(prepage, { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    get_jobss(key) {
      axios
        .get(
          "anonymous/job-list/",
          {
            params: {
              search: key,
            },
          },
          { ignore: "1" }
        )
        .then((res) => {
          if (res.status == 200) {
            this.ano_jobs = res.data.results;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    showjob(text) {
      if (text != null) {
        this.get_jobss(text);
      }
    },
  },

  mounted: function () {
    this.get_jobs();
  },
};
</script>

<style></style>
