<template>
  <!--Page Title-->
  <div>
    <br /><br /><br />
    <section class="page-title style-two">
      <div class="auto-container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="sec-title text-center">
              <h2>Checkout to confirm Plan</h2>
              <div class="text">
                Checkout to confirm your job management plan in Hive4Works
              </div>
            </div>
          </div>
        </div>
        <!--Cart Section-->
        <section class="cart-section">
          <div class="auto-container">
            <div class="row">
              <div class="column col-lg-8 col-md-12 col-sm-12">
                <!--Cart Outer-->
                <div class="cart-outer">
                  <div class="table-outer">
                    <table class="default-table">
                      <thead class="cart-header">
                        <tr>
                          <th class="product-name">Product</th>
                          <th class="product-thumbnail">&nbsp;</th>
                          <th class="product-price">Price</th>
                          <th class="product-quantity">Quantity</th>
                          <th class="product-subtotal">Subtotal</th>
                          <th class="product-remove">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="cart-item">
                          <td class="product-thumbnail">
                            <a href="shop-single.html"
                              ><img
                                src="images/resource/products/cart-img.png"
                                alt=""
                            /></a>
                          </td>
                          <td class="product-name">
                            <a href="shop-single.html">Album</a>
                          </td>
                          <td class="product-price">$298</td>
                          <td class="product-quantity">
                            <div class="item-quantity">
                              <input
                                type="number"
                                class="qty"
                                name="qty"
                                value="1"
                              />
                            </div>
                          </td>
                          <td class="product-subtotal">
                            <span class="amount">$1.298</span>
                          </td>
                          <td class="product-remove">
                            <a href="#" class="remove"
                              ><span class="flaticon-dustbin"></span
                            ></a>
                          </td>
                        </tr>

                        <tr class="cart-item">
                          <td class="product-thumbnail">
                            <a href="shop-single.html"
                              ><img
                                src="images/resource/products/cart-img.png"
                                alt=""
                            /></a>
                          </td>
                          <td class="product-name">
                            <a href="shop-single.html">Album</a>
                          </td>
                          <td class="product-price">$298</td>
                          <td class="product-quantity">
                            <div class="item-quantity">
                              <input
                                type="number"
                                class="qty"
                                name="qty"
                                value="1"
                              />
                            </div>
                          </td>
                          <td class="product-subtotal">
                            <span class="amount">$1.298</span>
                          </td>
                          <td class="product-remove">
                            <a href="#" class="remove"
                              ><span class="flaticon-dustbin"></span
                            ></a>
                          </td>
                        </tr>

                        <tr class="cart-item">
                          <td class="product-thumbnail">
                            <a href="shop-single.html"
                              ><img
                                src="images/resource/products/cart-img.png"
                                alt=""
                            /></a>
                          </td>
                          <td class="product-name">
                            <a href="shop-single.html">Album</a>
                          </td>
                          <td class="product-price">$298</td>
                          <td class="product-quantity">
                            <div class="item-quantity">
                              <input
                                type="number"
                                class="qty"
                                name="qty"
                                value="1"
                              />
                            </div>
                          </td>
                          <td class="product-subtotal">
                            <span class="amount">$1.298</span>
                          </td>
                          <td class="product-remove">
                            <a href="#" class="remove"
                              ><span class="flaticon-dustbin"></span
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="cart-options clearfix">
                    <div class="apply-coupon">
                      <div class="form-group">
                        <input
                          type="text"
                          name="coupon-code"
                          class="input"
                          placeholder="Coupon Code"
                        />
                      </div>
                      <div class="form-group">
                        <button type="button" class="theme-btn btn-style-one">
                          Apply Coupon
                        </button>
                      </div>
                      <div class="form-group pull-right">
                        <button type="button" class="theme-btn btn-style-three">
                          update cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column col-lg-4 col-md-12 col-sm-12">
                <div class="totals-table-outer">
                  <ul class="totals-table">
                    <li>
                      <h3>Cart Totals</h3>
                    </li>
                    <li>
                      <span class="col">Subtotal</span
                      ><span class="col price">$186.00</span>
                    </li>
                    <li>
                      <span class="col">Total</span
                      ><span class="col price">$186.00</span>
                    </li>
                  </ul>
                  <br />
                  <br />
                  <!--button type="button" class="theme-btn btn-style-one proceed-btn"><a href="/checkout"> Proceed to Checkout</a></button-->
                  <a href="/checkout" class="theme-btn btn-style-three"
                    >Proceed to Checkout</a
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--End Cart Section-->
      </div>
    </section>
    <!--End Listing Page Section -->
  </div>
</template>

<script>
export default {
  name: "ShopingCartView",
  data() {
    return {};
  },
};
</script>

<style></style>
