import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './interceptor/axios'
import MasterView from './views/master/MasterView'
import DashboardMasterView from './views/master/DashboardMasterView'
import AuthMasterView from './views/master/AuthMasterView'


const app = createApp(App)
app.use(store)
app.use(router)
app.component('MasterView',MasterView);
app.component('DashboardMasterView',DashboardMasterView);
app.component('AuthMasterView',AuthMasterView)

app.mount('#app')




