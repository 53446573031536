<template>
  <div>
    <!-- Fun Fact Section -->
    <div class="fun-fact-section style-two">
      <div class="auto-container"></div>
    </div>
    <div>
      <section class="call-to-action-two" style="background-color: #36a8a6">
        <div class="auto-container">
          <div class="sec-title light text-center">
            <h2>How It Works?</h2>
            <div class="text">
              Do you want to hire people for you organization? Sign up with
              Hive4Work and publish jobs to reach hundreds of applicants.
            </div>
          </div>
        </div>
      </section>
      <!--Page Title-->
      <br /><br /><br />
      <!-- Work Section -->
      <section class="layout-pt-60 layout-pb-60">
        <div class="auto-container">
          <div class="row justify-content-center">
            <div class="col-lg-5"></div>
          </div>

          <div class="row grid-base wow fadeInUp">
            <!-- Work Block -->
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="work-block -type-2 mb-0">
                <div class="inner-box">
                  <div class="icon-wrap -blue">
                    <span class="icon icon-case"></span>
                  </div>
                  <h5>Register an account to start</h5>
                  <p>
                    Choos a price plan and subscribe with us and post jobs with
                    your own management.
                  </p>
                </div>
              </div>
            </div>

            <!-- Work Block -->
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="work-block -type-2 mb-0">
                <div class="inner-box">
                  <div class="icon-wrap -red">
                    <span class="icon icon-contact"></span>
                  </div>
                  <h5>Explore over thousands of resumes</h5>
                  <p>
                    After signing up with us, you will get access hundreds of
                    resume to find the competent applicant.
                  </p>
                </div>
              </div>
            </div>

            <!-- Work Block -->
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="work-block -type-2 mb-0">
                <div class="inner-box">
                  <div class="icon-wrap -yellow">
                    <span class="icon icon-doc"></span>
                  </div>
                  <h5>Find the most suitable candidate</h5>
                  <p>Get applications, short list and hire the excellents.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Work Section -->

      <!-- Pricing Sectioin -->

      <!-- End Pricing Section -->

      <br /><br /><br /><br />
    </div>

    <!-- Top Companies -->
    <!-- <section class="playout-pt-60 layout-pb-60">
      <div class="auto-container">
        <div class="row justify-content-between align-items-end">
          <div class="col-lg-6">
            <div class="sec-title mb-0">
              <br>
              <br><br>
              <h2 class="color-blue-dark fw-700">Top Company Registered</h2>
              <div class="text">Some of the companies we've helped recruit excellent applicants over the years.</div>
            </div>
          </div>

          <div class="col-auto">
            <a href="#" class="button -arrow text-dark-blue">
              Browse
              <span class="fa fa-angle-right"></span>
            </a>
          </div>
        </div>

        <div class="carousel-outer pt-50 wow fadeInUp">
          <div class="companies-carousel owl-carousel owl-theme default-dots">
        
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-1.png" alt=""></figure>
                <h4 class="name">Udemy</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">15 Open Position</a>
              </div>
            </div>

      
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-2.png" alt=""></figure>
                <h4 class="name">Stripe</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

        
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-3.png" alt=""></figure>
                <h4 class="name">Dropbox</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

           
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-4.png" alt=""></figure>
                <h4 class="name">Figma</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

          
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-1.png" alt=""></figure>
                <h4 class="name">Udemy</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">15 Open Position</a>
              </div>
            </div>

        
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-2.png" alt=""></figure>
                <h4 class="name">Stripe</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

          
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-3.png" alt=""></figure>
                <h4 class="name">Dropbox</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

    
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-4.png" alt=""></figure>
                <h4 class="name">Figma</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

        
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-1.png" alt=""></figure>
                <h4 class="name">Udemy</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">15 Open Position</a>
              </div>
            </div>

         
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-2.png" alt=""></figure>
                <h4 class="name">Stripe</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

        
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-3.png" alt=""></figure>
                <h4 class="name">Dropbox</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

          
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-4.png" alt=""></figure>
                <h4 class="name">Figma</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!--End Page Title-->
  </div>
</template>

<script>
export default {
  name: "HowItWorks",

  data() {
    return {
      CandidatesList: [
        {
          CandidatesName: "Hridoy",
          Designamtion: "Software Developer",
          location: "Dhaka",
          Payment: "$25 hours",
          App: "app",
          Design: "design",
          Digital: "digital",
        },
        {
          CandidatesName: "Tawsif",
          Designamtion: "Product Manager",
          location: "Khulna",
          Payment: "$15 hours",
          App: "app",
          Design: "design",
          Digital: "digital",
        },
        {
          CandidatesName: "Noman",
          Designamtion: "HR",
          location: "Chandpur",
          Payment: "$10 hours",
          App: "app",
          Design: "design",
          Digital: "digital",
        },
        {
          CandidatesName: "Faysal",
          Designamtion: "Accounts",
          location: "Dhaka",
          Payment: "$20 hours",
          App: "app",
          Design: "design",
          Digital: "digital",
        },
      ],
    };
  },
};
</script>

<style></style>
