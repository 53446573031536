<template>
  <!--Page Title-->
  <div>
    <br /><br /><br />
    <section class="page-title style-two">
      <div class="auto-container">
        <div class="row">
          <div class="col-lg-7 text-left">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="sec-title text-center">
                  <h2
                    class="text-white mt-3 text-left"
                    style="font-size: 57px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
                  >
                    Career Advice
                  </h2>

                  <!-- <div class="text text-white text-left" style="font-size: 30px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">
                Are you looking for a job!
              </div> -->

                  <div
                    class="text text-white text-left"
                    style="font-size: 25px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
                  >
                    Unlock Your Potential, Navigate Your Career
                  </div>
                </div>
              </div>
            </div>

            <div style="background-color: white; border-radius: 10px">
              <div class="row">
                <div
                  class="col-lg-10"
                  style="margin: 0px; padding: 0px; z-index: 10"
                >
                  <div class="form-group text-left px-5 py-3">
                    <span
                      style="color: grey"
                      class="icon flaticon-search-1"
                    ></span>
                    <input
                      style="
                        border-radius: 10px;
                        width: 80%;
                        color: grey;
                        padding-left: 20px;
                      "
                      type="text"
                      @input="showjob($event.target.value)"
                      name="field_name"
                      placeholder="Search Career Advice"
                    />
                    <span style="height: 100%; background-color: red"></span>
                  </div>
                </div>
                <div
                  id="find-job"
                  class="col-2 pt-3 text-center"
                  style="
                    background-color: #3f4a5a;
                    margin: 0px;
                    padding: 0px;
                    border-radius: 0px 10px 10px 0px;
                    color: white;
                  "
                >
                  <span class="text-center">SEARCH</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <img src="images/skill.png" style="height: 100%; width: 100%" />
          </div>
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Sidebar Page Container -->
    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row">
          <!--Content Side-->
          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="blog-grid">
              <!-- News Block -->
              <div @click.prevent="openCareer(career.uuid)"  v-for="career in careers" :key="career" class="news-block-two">
                <div class="sec-title text-left">
                  <h2>{{career.article_category.cat_name}}</h2>
                  <div
                    style="
                      display: block;
                      margin-top: 0.5em;
                      margin-bottom: 0.5em;
                      margin-left: auto;
                      margin-right: auto;
                      border-style: inset;
                      border-width: 1px;
                    "
                  ></div>
                </div>
                <div class="inner-box">
                  <div class="row">
                    <div class="col-5">
                      <div class="image-box">
                        <figure class="image">
                          <img
                            v-if="career.image"
                            :src="career.image"
                            alt=""
                            style="height: 100%; width: 100%"
                          />
                        </figure>
                      </div>
                    </div>

                    <div class="col-7">
                      <div class="content-box">
                        <ul class="post-meta">
                          <li><a href="#">{{format_date(career.created)}}</a></li>
                          <!-- <li><a href="#">12 Comment</a></li> -->
                        </ul>
                        <h3>
                          <a
                            @click.prevent="openCareer(career.uuid)"
                            style="font-size: 25px"
                            >{{career.title}}</a
                          >
                        </h3>
                        
            <p v-if="career.abstract.length > 200" class="mt-3">
              {{ career.abstract.substring(1, 200) }}....
            </p>
            <p v-else class="mt-3">{{  career.abstract }}</p>
                        <a  @click.prevent="openCareer(career.uuid)" class="read-more"
                          >Read More <i class="fa fa-angle-right"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            

             

          
            

              <nav class="ls-pagination">
                <ul>
                  <li v-if="all_data.previous != null" class="prev">
                    <a @click.prevent="page(current_page - 1)"
                      ><i class="fa fa-arrow-left"></i
                    ></a>
                  </li>
                  <li v-for="i in pages" :key="i">
                    <a
                      :class="{ 'current-page': current_page == i }"
                      @click.prevent="page(i)"
                      >{{ i }}</a
                    >
                  </li>

                  <li v-if="all_data.next != null" class="next">
                    <a @click.prevent="page(current_page + 1)"
                      ><i class="fa fa-arrow-right"></i
                    ></a>
                  </li>
                </ul>
              </nav>

              <!-- Pagination -->
            
            </div>
          </div>

          <!--Sidebar Side-->
          <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
            <aside class="sidebar blog-sidebar">
              <!-- Recent Post -->
              <!-- <div class="sidebar-widget search-widget">
                <div class="sidebar-title">
                  <h4>Search by Keywords</h4>
                </div>

                
                <div class="search-box">
                  <form method="post" action="blog.html">
                    <div class="form-group">
                      <span class="icon flaticon-search-1"></span>
                      <input type="search" name="search-field" value="" placeholder="keywords" required="">
                    </div>
                  </form>
                </div>
              </div> -->

              <!-- Shop Widget -->
              <div class="sidebar-widget catagory-widget">
                <div class="sidebar-title">
                  <h4>Categories</h4>
                </div>
                <ul class="catagory-list">
                  <li v-for="cat in categories" :key="cat" class="catagory-item" ><a @click.prevent="openCareerCategory(cat.id)">{{cat.cat_name}}</a></li>
                
                </ul>
              </div>

              <!-- Recent Post -->
              <!-- <div class="sidebar-widget recent-post">
                <div class="sidebar-title">
                  <h4>Recent Posts</h4>
                </div>

                <div class="widget-content">
                  <article class="post">
                    <div class="post-thumb"><a href="blog-single.html"><img src="images/resource/post-thumb-1.png" alt=""></a></div>
                    <h6><a href="blog-single.html">Attract Sales And Profits</a></h6>
                    <div class="post-info">August 9, 2021</div>
                  </article>

                  <article class="post">
                    <div class="post-thumb"><a href="blog-single.html"><img src="images/resource/post-thumb-2.png" alt=""></a></div>
                    <h6><a href="blog-single.html">5 Tips For Your Job Interviews</a></h6>
                    <div class="post-info">August 9, 2021</div>
                  </article>

                  <article class="post">
                    <div class="post-thumb"><a href="blog-single.html"><img src="images/resource/post-thumb-3.png" alt=""></a></div>
                    <h6><a href="blog-single.html">The Best Account Providers</a></h6>
                    <div class="post-info">August 9, 2021</div>
                  </article>
                </div>
              </div> -->

              <!-- Shop Widget -->
              <!-- <div class="sidebar-widget">
                <div class="sidebar-title">
                  <h4>Tags</h4>
                </div>
                <ul class="tag-list">
                  <li><a href="#">app</a></li>
                  <li><a href="#">administrative</a></li>
                  <li><a href="#">android</a></li>
                  <li><a href="#">wordpress</a></li>
                  <li><a href="#">design</a></li>
                  <li><a href="#">react</a></li>
                </ul>
              </div> -->
            </aside>
          </div>
        </div>
      </div>
    </div>
    <!-- End Sidebar Container -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "CareerAdviceView",
  data() {
    return {
      jobs: [
        {
          jobtitle: "Software Developer",
          segmemt: "segmemt",
          location: "Dhaka",
          time: "11",
          salary: "35k",
          type: "Fulltime",
        },
        {
          jobtitle: "Product Manager",
          segmemt: "segmemt",
          location: "Khulna",
          time: "12",
          salary: "30k",
          type: "Fulltime",
        },
        {
          jobtitle: "HR",
          segmemt: "segmemt",
          location: "Chandpur",
          time: "13",
          salary: "35k",
          type: "Parttime",
        },
        {
          jobtitle: "Accounts",
          segmemt: "segmemt",
          location: "Dhaka",
          time: "11",
          salary: "25k",
          type: "Fulltime",
        },
      ],
      careers: [],
      categories:[],

   
      //Pagenation
      current_page: 1,
      next_page: "",
      next: "",
      previous: "",
      pages: 1,
      total_item: "",
      all_data: {},
      id:""
    };
  },

  methods: {


    openCareer(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "career-advice-single",
          query: { uuid: uuid },
        });
      }
    },

    openCareerCategory(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "career-advice-category",
          query: { id: uuid },
        }) .then(() => {
        // Wait for the navigation to complete and then reload the page.
        window.location.reload();
      });
      }
    },

    getCareer(id) {
      axios
        .get(
          "/anonymous/category/"+id+"/career_advice/?page=1&page_size=10",
         
          { ignore: "1" }
        )
        .then((res) => {
          if (res.status == 200) {
            this.careers = res.data.results;
            this.total_item = res.data.count;
            this.all_data = res.data;
            if (this.total_item > 0) {
              this.pages = Math.ceil(this.total_item / 10);
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    page(page_number) {
      axios
        .get("/anonymous/category/"+this.id+"/career_advice/?page=" + page_number + "&page_size=" + 10)
        .then((res) => {
          if (res.status == 200) {
            this.current_page = page_number;

            this.careers = res.data.results;
            this.all_data = res.data;
            window.scrollTo(0, 0);

            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getCareerCategory() {
      axios
        .get(
          "/anonymous/career_advice_category/",
         
          { ignore: "1" }
        )
        .then((res) => {
          if (res.status == 200) {
            this.categories = res.data;
         
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
     

    



    format_date(value) {
      if (value) {
        return moment(String(value)).format("MMM Do YY");
      }
    },

    showjob(text) {
      if (text != null) {
        this.get_jobss(text);
      }
    },
  },

  mounted: function () {

    this.getCareerCategory()
    var id = this.$route.query.id;
    this.id=id

if (id != null) {
  this.getCareer(id);
  window.scrollTo(0, 0);
}
  },
};
</script>

<style>
.news-block-two .inner-box:hover {
  background: none;
}
</style>
