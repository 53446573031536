<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/recruiter-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/recruiter-profile"
              ><i class="la la-user-tie"></i>Profile</a
            >
          </li>

          <li>
            <a href="/recruiter-postjob"
              ><i class="la la-paper-plane"></i>Post a New Job</a
            >
          </li>
          <li>
            <a href="/recruiter-managejob"
              ><i class="la la-briefcase"></i> Manage Jobs
            </a>
          </li>
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          <li>
            <a href="#"><i class="la la-trash"></i>Delete Profile</a>
          </li>
          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <!-- <li><a href="#"><i class="la la-lock"></i>Change Password</a></li> -->
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <!-- <li><a @click.prevent="logout" ><i class="la la-sign-out"></i>Logout</a></li> -->

          <!-- <li><img src="images/boy.png" style=" height: 400px;width: 100%;"></li> -->
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 200px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <!-- <div class="upper-title-box">
          <h3>Application view</h3>
      
        </div> -->

        <div class="upper-box">
          <div class="auto-container">
            <!-- Candidate block Five -->
            <div class="candidate-block-five">
              <div class="inner-box">
                <div class="content">
                  <figure v-if="photograph != null" class="image">
                    <img :src="photograph.photograph" alt="" />
                  </figure>
                  <h4 class="name" style="text-align: left">
                    <a href="#"
                      >{{ basic_information.first_name }}
                      {{ basic_information.last_name }}</a
                    >
                  </h4>

                  <ul class="candidate-info">
                    <!-- <li class="designation">UI Designer at Invision</li> -->
                    <li>
                      <span class="icon flaticon-map-locator"></span
                      >{{ per_address.country }}
                    </li>
                    <!-- <li><span class="icon flaticon-money"></span> $99 / hour</li> -->
                    <!-- <li><span class="icon flaticon-clock"></span> Member Since,Aug 19, 2020</li> -->
                  </ul>
                  <!-- <ul class="post-tags">
                  <li v-for="experience in experience.area_expertise" :key="experience.uuid">{{ area_expertise }}</li>
                  <li><a href="#">Design</a></li>
                  <li><a href="#">Digital</a></li>
                </ul> -->
                </div>

                <!-- <div class="btn-box">
                <a href="#" class="theme-btn btn-style-one">Download CV</a>
                <button class="bookmark-btn"><i class="flaticon-bookmark"></i></button>
              </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <!-- <div class="widget-title">
                  <h4>My Job Listings</h4>

                  
                </div> -->

                <div class="candidate-detail-outer">
                  <div class="auto-container">
                    <div class="row">
                      <div class="content-column col-lg-8 col-md-12 col-sm-12">
                        <div class="job-detail">
                          <!-- <h4>Candidates About</h4>
                <p>Hello my name is Nicole Wells and web developer from Portland. In pharetra orci dignissim, blandit mi semper, ultricies diam. Suspendisse malesuada suscipit nunc non volutpat. Sed porta nulla id orci laoreet tempor non consequat enim. Sed vitae aliquam velit. Aliquam ante erat, blandit at pretium et, accumsan ac est. Integer vehicula rhoncus molestie. Morbi ornare ipsum sed sem condimentum, et pulvinar tortor luctus. Suspendisse condimentum lorem ut elementum aliquam.</p>
                <p>Mauris nec erat ut libero vulputate pulvinar. Aliquam ante erat, blandit at pretium et, accumsan ac est. Integer vehicula rhoncus molestie. Morbi ornare ipsum sed sem condimentum, et pulvinar tortor luctus. Suspendisse condimentum lorem ut elementum aliquam. Mauris nec erat ut libero vulputate pulvinar.</p> -->

                          <!-- Resume / Work & Experience -->
                          <div class="resume-outer theme-blue">
                            <div class="upper-title">
                              <h4>Work & Experience</h4>
                            </div>
                            <!-- Resume BLock -->
                            <div
                              v-for="experience in experience"
                              :key="experience.id"
                              class="resume-block"
                            >
                              <div class="inner">
                                <span class="name">#</span>
                                <div class="title-box">
                                  <div class="info-box">
                                    <h3>{{ experience.designation }}</h3>
                                    <span>{{ experience.org_name }}</span>
                                  </div>
                                  <div class="edit-box">
                                    <span class="year"
                                      >{{ experience.from_date }} to
                                      {{ experience.to_date }}</span
                                    >
                                  </div>
                                </div>
                                <div class="text">
                                  {{ experience.responsibilities }}
                                </div>
                              </div>
                            </div>

                            <!-- Resume BLock -->
                            <!-- <div class="resume-block">
                    <div class="inner">
                      <span class="name">D</span>
                      <div class="title-box">
                        <div class="info-box">
                          <h3>Sr UX Engineer</h3>
                          <span>Dropbox Inc.</span>
                        </div>
                        <div class="edit-box">
                          <span class="year">2012 - 2014</span>
                        </div>
                      </div>
                      <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante<br> ipsum primis in faucibus.</div>
                    </div>
                  </div> -->
                          </div>

                          <!-- Resume / Work & Experience -->
                          <div class="resume-outer theme-blue">
                            <div class="upper-title">
                              <h4>Skills</h4>
                            </div>

                            <!-- Resume BLock -->
                            <div
                              v-for="skill in skill"
                              :key="skill.id"
                              class="resume-block"
                            >
                              <div class="inner">
                                <span class="name">#</span>
                                <div class="title-box">
                                  <div class="info-box">
                                    <h3>{{ skill.skill_name }}</h3>
                                    <span>{{ skill.acquired_from }}</span>
                                  </div>
                                  <!-- <div class="edit-box">
                          <span class="year">2012 - 2014</span>
                        </div> -->
                                </div>
                                <div class="text">{{ skill.description }}</div>
                              </div>
                            </div>
                          </div>

                          <!-- Resume / Education -->
                          <div class="resume-outer">
                            <div class="upper-title">
                              <h4>Education</h4>
                            </div>
                            <!-- Resume BLock -->
                            <div
                              v-for="education in education"
                              :key="education.id"
                              class="resume-block"
                            >
                              <div class="inner">
                                <span class="name">#</span>
                                <div class="title-box">
                                  <div class="info-box">
                                    <h3>{{ education.edu_level }}</h3>
                                    <span>{{ education.institute }}</span>
                                  </div>
                                  <div class="edit-box">
                                    <span class="year">{{
                                      education.passing_year
                                    }}</span>
                                  </div>
                                </div>

                                <ul class="list-style-four">
                                  <li></li>
                                  <li>Degree: {{ education.degree_title }}</li>
                                  <li>Major: {{ education.major }}</li>
                                  <li>Result: {{ education.result }}</li>
                                  <li>Marks: {{ education.marks }}</li>
                                  <li>
                                    Duration: {{ education.duration }} years
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <!-- Resume / Education -->
                          <div class="resume-outer">
                            <div class="upper-title">
                              <h4>Basic Informations</h4>
                            </div>
                            <!-- Resume BLock -->
                            <div class="resume-block">
                              <div class="inner">
                                <!-- <span class="name">1</span> -->
                                <div class="title-box">
                                  <div class="info-box">
                                    <!-- <h3>{{present_address.edu_level}}</h3> -->
                                    <!-- <span>{{ education.institute }}</span> -->
                                  </div>
                                  <!-- <div class="edit-box">
                          <span class="year">{{ education.passing_year }}</span>
                        </div> -->
                                </div>

                                <ul class="list-style-four">
                                  <li></li>
                                  <li>
                                    Mother's Name:
                                    {{ basic_information.fathers_name }}
                                  </li>
                                  <li>
                                    Mother's name:
                                    {{ basic_information.mothers_name }}
                                  </li>
                                  <li>
                                    Gender: {{ basic_information.gender }}
                                  </li>
                                  <li>
                                    Nationality:
                                    {{ basic_information.nationality }}
                                  </li>
                                  <li>
                                    Religion: {{ basic_information.religion }}
                                  </li>
                                  <li>
                                    Blood: {{ basic_information.blood_group }}
                                  </li>
                                  <li>
                                    Weight: {{ basic_information.weight }}
                                  </li>
                                  <li>
                                    Height: {{ basic_information.height }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <!-- Resume / Education -->
                          <div class="resume-outer">
                            <div class="upper-title">
                              <h4>Present Address</h4>
                            </div>
                            <!-- Resume BLock -->
                            <div class="resume-block">
                              <div class="inner">
                                <!-- <span class="name">1</span> -->
                                <div class="title-box">
                                  <div class="info-box">
                                    <!-- <h3>{{present_address.edu_level}}</h3> -->
                                    <!-- <span>{{ education.institute }}</span> -->
                                  </div>
                                  <!-- <div class="edit-box">
                          <span class="year">{{ education.passing_year }}</span>
                        </div> -->
                                </div>

                                <ul
                                  v-if="present_address != null"
                                  class="list-style-four"
                                >
                                  <li></li>
                                  <li>Street: {{ present_address.street }}</li>
                                  <li>house: {{ present_address.house }}</li>
                                  <li>County: {{ present_address.county }}</li>
                                  <li>City: {{ present_address.city }}</li>
                                  <li>
                                    Province: {{ present_address.province }}
                                  </li>
                                  <li>
                                    Country: {{ present_address.country }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <!-- Resume / Education -->
                          <div class="resume-outer">
                            <div class="upper-title">
                              <h4>Permanent Address</h4>
                            </div>
                            <!-- Resume BLock -->
                            <div class="resume-block">
                              <div class="inner">
                                <!-- <span class="name">1</span> -->
                                <div class="title-box">
                                  <div class="info-box">
                                    <!-- <h3>{{present_address.edu_level}}</h3> -->
                                    <!-- <span>{{ education.institute }}</span> -->
                                  </div>
                                  <!-- <div class="edit-box">
                          <span class="year">{{ education.passing_year }}</span>
                        </div> -->
                                </div>

                                <ul class="list-style-four">
                                  <li></li>
                                  <li>
                                    Street: {{ permanent_address.street }}
                                  </li>
                                  <li>house: {{ permanent_address.house }}</li>
                                  <li>
                                    County: {{ permanent_address.county }}
                                  </li>
                                  <li>City: {{ permanent_address.city }}</li>
                                  <li>
                                    Province: {{ permanent_address.province }}
                                  </li>
                                  <li>
                                    Country: {{ permanent_address.country }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <!-- Portfolio -->
                          <div class="portfolio-outer">
                            <div class="row"></div>
                          </div>

                          <!-- Resume / Awards -->
                          <!-- <div class="resume-outer theme-yellow">
                  <div class="upper-title">
                    <h4>Awards</h4>
                  </div>
                  
                  <div class="resume-block">
                    <div class="inner">
                      <span class="name"></span>
                      <div class="title-box">
                        <div class="info-box">
                          <h3>Perfect Attendance Programs</h3>
                          <span></span>
                        </div>
                        <div class="edit-box">
                          <span class="year">2012 - 2014</span>
                        </div>
                      </div>
                      <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante<br> ipsum primis in faucibus.</div>
                    </div>
                  </div>


                  
                  <div class="resume-block">
                    <div class="inner">
                      <span class="name"></span>
                      <div class="title-box">
                        <div class="info-box">
                          <h3>Top Performer Recognition</h3>
                          <span></span>
                        </div>
                        <div class="edit-box">
                          <span class="year">2012 - 2014</span>
                        </div>
                      </div>
                      <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante<br> ipsum primis in faucibus.</div>
                    </div>
                  </div>
                </div> -->

                          <!-- Video Box -->
                        </div>
                      </div>

                      <div class="sidebar-column col-lg-4 col-md-12 col-sm-12">
                        <aside class="sidebar">
                          <div class="sidebar-widget">
                            <div class="widget-content">
                              <ul class="job-overview">
                                <!-- <li>
                        <i class="icon icon-calendar"></i>
                        <h5>Experience:</h5>
                        <span>0-2 Years</span>
                      </li> -->

                                <li>
                                  <i class="icon icon-expiry"></i>
                                  <h5>Date of Birth:</h5>
                                  <span>{{ basic_information.dob }}</span>
                                </li>

                                <li>
                                  <i class="icon icon-rate"></i>
                                  <h5>Current Salary:</h5>
                                  <span>
                                    &pound;
                                    {{ career_info.present_salary }}</span
                                  >
                                </li>

                                <li>
                                  <i class="icon icon-salary"></i>
                                  <h5>Expected Salary:</h5>
                                  <span>
                                    &pound;
                                    {{ career_info.expected_salary }}</span
                                  >
                                </li>

                                <li>
                                  <i class="icon icon-contact"></i>
                                  <h5>Contact:</h5>
                                  <span>{{
                                    basic_information.primary_contact_no
                                  }}</span>
                                </li>

                                <li>
                                  <i class="icon icon-user-2"></i>
                                  <h5>Gender:</h5>
                                  <span>{{ basic_information.gender }}</span>
                                </li>

                                <li
                                  v-for="language in language"
                                  :key="language.id"
                                >
                                  <i class="icon icon-language"></i>
                                  <h5>Language:</h5>
                                  <span>{{ language.language }}</span>
                                </li>

                                <li
                                  v-for="education in education"
                                  :key="education.id"
                                >
                                  <i class="icon icon-degree"></i>
                                  <h5>Education Level:</h5>
                                  <span>{{ education.edu_level }}</span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <!-- <div class="sidebar-widget social-media-widget">
                  <h4 class="widget-title">Social media</h4>
                  <div class="widget-content">
                    <div class="social-links">
                      <a href="#"><i class="fab fa-facebook-f"></i></a>
                      <a href="#"><i class="fab fa-twitter"></i></a>
                      <a href="#"><i class="fab fa-instagram"></i></a>
                      <a href="#"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                  </div>
                </div> -->

                          <!-- <div class="sidebar-widget">
                  
                  <h4 class="widget-title">Professional Skills</h4>
                  <div class="widget-content">
                    <ul class="job-skills">
                      <li><a href="#">app</a></li>
                      <li><a href="#">administrative</a></li>
                      <li><a href="#">android</a></li>
                      <li><a href="#">wordpress</a></li>
                      <li><a href="#">design</a></li>
                      <li><a href="#">react</a></li>
                    </ul>
                  </div>
                </div> -->
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="widget-content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->

    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
import vcookie from "vue-cookie";

export default {
  name: "EmployerApplicantSingleView",

  data() {
    return {
      basic_information: {},
      per_address: {},
      photograph: {},
      experience: [],
      education: [],
      present_address: {},
      permanent_address: {},
      career_info: {},
      language: {},
      skill: {},
    };
  },

  methods: {
    getapplicant(uuid) {
      axios
        .get("recruiter/application/" + uuid + "/")
        .then((res) => {
          this.basic_information = res.data.applicant.basic_info;
          this.per_address = res.data.applicant.permanent_address;
          this.photograph = res.data.applicant.photograph;
          this.experience = res.data.applicant.experience;
          this.education = res.data.applicant.education;
          this.present_address = res.data.applicant.present_address;
          this.permanent_address = res.data.applicant.permanent_address;
          this.career_info = res.data.applicant.career_info;
          this.language = res.data.applicant.language;
          this.skill = res.data.applicant.skill;

          if (res.status == 200) {
            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    applyjob() {
      if (this.jobid != "") {
        const user = JSON.parse(vcookie.get("user"));

        if (user) {
          if (user.user.role == "J") {
            axios
              .post("applicant/job_application/", {
                job_post: this.jobid,
              })
              .then((res) => {
                if (res.status == 201) {
                  alert("You applied for the job successfully !");
                }
                if (res.status == 200) {
                  alert(res.data.status);
                }
              })
              .catch((error) => {
                alert(error.response.data.status);
              });
          } else {
            this.$router.push({ name: "login" });
          }
        } else {
          this.$router.push({ name: "login" });
        }
      }
    },
  },

  mounted: function () {
    var uuid = this.$route.query.uuid;

    if (uuid != null) {
      this.getapplicant(uuid);
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style></style>
