<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
          <li class="active">
            <a href="/admin-payment-status"
              ><i class="la la-comment-o"></i>Payment Status</a
            >
          </li>
          <li>
            <a href="/admin-delete-profile"
              ><i class="la la-trash"></i>Delete Profile</a
            >
          </li>
          <!-- <li><a href="index.html"><i class="la la-trash"></i>Publish Ads</a></li> -->
          <li>
            <a href="/admin-change-password"
              ><i class="la la-lock"></i>Change Password</a
            >
          </li>
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <li>
            <a href="/login"><i class="la la-sign-out"></i>Logout</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- End User Sidebar -->

    <!--Page Title-->
    <!--Page Title-->
    <section class="page-title">
      <div class="auto-container">
        <div class="title-outer">
          <h1>Employer Payment Status</h1>
          <ul class="page-breadcrumb">
            <li><a href="index.html">Dashboard</a></li>
            <li>Payment Status</li>
          </ul>
        </div>
      </div>
    </section>
    <!--End Page Title-->
    <!--End Page Title-->

    <!-- Listing Section -->
    <!-- Listing Section -->
    <section class="ls-section style-three">
      <div class="auto-container">
        <div class="filters-backdrop"></div>

        <div class="row">
          <!-- Content Column -->
          <div class="content-column col-lg-12">
            <div class="ls-outer">
              <!-- ls Switcher -->
              <div class="ls-switcher">
                <div class="showing-result">
                  <div class="text">
                    Showing <strong>41-60</strong> of <strong>944</strong> jobs
                  </div>
                </div>

                <div class="sort-by">
                  <select class="chosen-select">
                    <option>New Jobs</option>
                    <option>Freelance</option>
                    <option>Full Time</option>
                    <option>Internship</option>
                    <option>Part Time</option>
                    <option>Temporary</option>
                  </select>

                  <select class="chosen-select">
                    <option>Show 10</option>
                    <option>Show 20</option>
                    <option>Show 30</option>
                    <option>Show 40</option>
                    <option>Show 50</option>
                    <option>Show 60</option>
                  </select>
                </div>
              </div>

              <div class="row">
                <!-- Job Block-two -->
                <div class="job-block-two col-lg-12">
                  <div class="inner-box">
                    <div class="content">
                      <span class="company-logo"
                        ><img src="images/resource/company-logo/1-1.png" alt=""
                      /></span>
                      <h4>
                        <a href="#">Software Engineer (Android), Libraries</a>
                      </h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span> Segment
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          London, UK
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> 11 hours
                          ago
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> $35k - $45k
                        </li>
                      </ul>
                    </div>
                    <ul class="job-other-info">
                      <li class="privacy">Private</li>
                      <li class="required">Urgent</li>
                      <li class="time">Full Time</li>
                    </ul>
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                  </div>
                </div>

                <!-- Job Block -->
                <div class="job-block-two col-lg-12">
                  <div class="inner-box">
                    <div class="content">
                      <span class="company-logo"
                        ><img src="images/resource/company-logo/1-2.png" alt=""
                      /></span>
                      <h4><a href="#">Recruiting Coordinator</a></h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span> Segment
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          London, UK
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> 11 hours
                          ago
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> $35k - $45k
                        </li>
                      </ul>
                    </div>
                    <ul class="job-other-info">
                      <li class="privacy">Private</li>
                      <li class="time">Full Time</li>
                    </ul>
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                  </div>
                </div>

                <!-- Job Block -->
                <div class="job-block-two col-lg-12">
                  <div class="inner-box">
                    <div class="content">
                      <span class="company-logo"
                        ><img src="images/resource/company-logo/1-3.png" alt=""
                      /></span>
                      <h4><a href="#">Product Manager, Studio</a></h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span> Segment
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          London, UK
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> 11 hours
                          ago
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> $35k - $45k
                        </li>
                      </ul>
                    </div>
                    <ul class="job-other-info">
                      <li class="required">Urgent</li>
                      <li class="time">Full Time</li>
                    </ul>
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                  </div>
                </div>

                <!-- Job Block -->
                <div class="job-block-two col-lg-12">
                  <div class="inner-box">
                    <div class="content">
                      <span class="company-logo"
                        ><img src="images/resource/company-logo/1-4.png" alt=""
                      /></span>
                      <h4><a href="#">)Web Developer</a></h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span> Segment
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          London, UK
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> 11 hours
                          ago
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> $35k - $45k
                        </li>
                      </ul>
                    </div>
                    <ul class="job-other-info">
                      <li class="time">Full Time</li>
                    </ul>
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                  </div>
                </div>

                <!-- Job Block -->
                <div class="job-block-two col-lg-12">
                  <div class="inner-box">
                    <div class="content">
                      <span class="company-logo"
                        ><img src="images/resource/company-logo/1-5.png" alt=""
                      /></span>
                      <h4><a href="#">Senior Product Designer</a></h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span> Segment
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          London, UK
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> 11 hours
                          ago
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> $35k - $45k
                        </li>
                      </ul>
                    </div>
                    <ul class="job-other-info">
                      <li class="time">Full Time</li>
                    </ul>
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                  </div>
                </div>

                <!-- Job Block-two -->
                <div class="job-block-two col-lg-12">
                  <div class="inner-box">
                    <div class="content">
                      <span class="company-logo"
                        ><img src="images/resource/company-logo/1-6.png" alt=""
                      /></span>
                      <h4>
                        <a href="#">Software Engineer (Android), Libraries</a>
                      </h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span> Segment
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          London, UK
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> 11 hours
                          ago
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> $35k - $45k
                        </li>
                      </ul>
                    </div>
                    <ul class="job-other-info">
                      <li class="privacy">Private</li>
                      <li class="required">Urgent</li>
                      <li class="time">Full Time</li>
                    </ul>
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                  </div>
                </div>

                <!-- Job Block -->
                <div class="job-block-two col-lg-12">
                  <div class="inner-box">
                    <div class="content">
                      <span class="company-logo"
                        ><img src="images/resource/company-logo/1-7.png" alt=""
                      /></span>
                      <h4><a href="#">Recruiting Coordinator</a></h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span> Segment
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          London, UK
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> 11 hours
                          ago
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> $35k - $45k
                        </li>
                      </ul>
                    </div>
                    <ul class="job-other-info">
                      <li class="privacy">Private</li>
                      <li class="time">Full Time</li>
                    </ul>
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                  </div>
                </div>

                <!-- Job Block -->
                <div class="job-block-two col-lg-12">
                  <div class="inner-box">
                    <div class="content">
                      <span class="company-logo"
                        ><img src="images/resource/company-logo/1-8.png" alt=""
                      /></span>
                      <h4><a href="#">Product Manager, Studio</a></h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span> Segment
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          London, UK
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> 11 hours
                          ago
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> $35k - $45k
                        </li>
                      </ul>
                    </div>
                    <ul class="job-other-info">
                      <li class="required">Urgent</li>
                      <li class="time">Full Time</li>
                    </ul>
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                  </div>
                </div>

                <!-- Job Block -->
                <div class="job-block-two col-lg-12">
                  <div class="inner-box">
                    <div class="content">
                      <span class="company-logo"
                        ><img src="images/resource/company-logo/1-9.png" alt=""
                      /></span>
                      <h4><a href="#">)Web Developer</a></h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span> Segment
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          London, UK
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> 11 hours
                          ago
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> $35k - $45k
                        </li>
                      </ul>
                    </div>
                    <ul class="job-other-info">
                      <li class="time">Full Time</li>
                    </ul>
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                  </div>
                </div>

                <!-- Job Block -->
                <div class="job-block-two col-lg-12">
                  <div class="inner-box">
                    <div class="content">
                      <span class="company-logo"
                        ><img src="images/resource/company-logo/1-1.png" alt=""
                      /></span>
                      <h4><a href="#">Senior Product Designer</a></h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span> Segment
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          London, UK
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> 11 hours
                          ago
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> $35k - $45k
                        </li>
                      </ul>
                    </div>
                    <ul class="job-other-info">
                      <li class="time">Full Time</li>
                    </ul>
                    <button class="bookmark-btn">
                      <span class="flaticon-bookmark"></span>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Listing Show More -->
              <div class="ls-show-more">
                <p>Showing 36 of 497 Jobs</p>
                <div class="bar">
                  <span class="bar-inner" style="width: 40%"></span>
                </div>
                <button class="show-more">Show More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Listing Page Section -->

    <!--End Listing Page Section -->
  </div>
</template>

<script>
export default {
  name: "AdminPaymentStatusView",
};
</script>

<style></style>
