<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/recruiter-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li class="active">
            <a href="/recruiter-profile"
              ><i class="la la-user-tie"></i>Profile</a
            >
          </li>

          <li>
            <a href="/recruiter-postjob"
              ><i class="la la-paper-plane"></i>Post a New Job</a
            >
          </li>
          <li>
            <a href="/recruiter-managejob"
              ><i class="la la-briefcase"></i> Manage Jobs
            </a>
          </li>
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          <li>
            <a href="#"><i class="la la-trash"></i>Delete Profile</a>
          </li>
          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <!-- <li><a href="#"><i class="la la-lock"></i>Change Password</a></li> -->
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <!-- <li><a @click.prevent="logout" ><i class="la la-sign-out"></i>Logout</a></li> -->

          <!-- <li><img src="images/boy.png" style=" height: 400px;width: 100%;"></li> -->
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 200px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="job-block-seven custom-card mt-4">
          <div class="inner-box p-2">
            <div class="content">
              <span v-if="profile.logo != null" class="company-logo">
                <img
                  style="height: 80px; width: 80px"
                  :src="profile.logo.logo"
                  alt=""
                />
              </span>

              <!-- <span style="height: 80px;width: 80px;" class="company-logo "><img height="80x" width="80px" src="images/resource/company-logo/5-1.png" alt=""></span> -->
              <h4 class="text-left">
                <a href="#">{{ profile.org_name }}</a>
              </h4>
              <ul class="job-info">
                <li>
                  <span class="icon flaticon-map-locator"></span
                  >{{ profile.city }}
                </li>
                <!-- <li><span class="icon flaticon-briefcase"></span> Accounting / Finance</li> -->
                <li>
                  <span class="icon flaticon-telephone-1"></span
                  >{{ profile.cont_per_mobile }}
                </li>
                <li>
                  <span class="icon las la-globe"></span>{{ profile.website }}
                </li>
                <li>
                  <button @click="editprofile(profile.uuid)" data-text="Edit">
                    <span
                      class="icon la la-pen"
                      style="color: #070f0f; font-size: 180%"
                    ></span>
                  </button>
                </li>
              </ul>
              <!-- <ul class="job-other-info">
                  <li class="time">Open Jobs – 3</li>
                </ul> -->
            </div>
          </div>
        </div>

        <div class="job-block-seven mt-3">
          <div class="row">
            <div class="column col-lg-6 col-md-12 col-sm-12">
              <div class="job-detail text-left p-3">
                <div class="sidebar-widget company-widget">
                  <div class="widget-content">
                    <h3>About Company</h3>
                    <div
                      style="
                        display: block;
                        margin-top: 0.5em;
                        margin-bottom: 0.5em;
                        margin-left: auto;
                        margin-right: auto;
                        border-style: inset;
                        border-width: 2px;
                      "
                    ></div>

                    <p>{{ profile.description }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="column col-lg-6 col-md-12 col-sm-12">
              <div class="job-detail text-left p-3">
                <div class="sidebar-widget company-widget">
                  <div class="widget-content">
                    <h3>Basic Info</h3>
                    <div
                      style="
                        display: block;
                        margin-top: 0.5em;
                        margin-bottom: 0.5em;
                        margin-left: auto;
                        margin-right: auto;
                        border-style: inset;
                        border-width: 2px;
                      "
                    ></div>

                    <ul class="company-info mt-0">
                      <li>
                        Organization name: <span>{{ profile.org_name }}</span>
                      </li>
                      <li>
                        Company size: <span>{{ profile.org_size }}</span>
                      </li>
                      <li>
                        Founded in: <span>{{ profile.est_year }}</span>
                      </li>
                      <li>
                        Company Registration No: <span>{{ profile.business_id }}</span>
                      </li>
                      <!-- <li>
                        Trade License: <span>{{ profile.trade_license }}</span>
                      </li> -->
                      <li>
                        Website: <span>{{ profile.website }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="column col-lg-6 col-md-12 col-sm-12">
              <div class="job-detail text-left p-3">
                <div class="sidebar-widget company-widget">
                  <div class="widget-content">
                    <h3>Authorised Person Details</h3>
                    <div
                      style="
                        display: block;
                        margin-top: 0.5em;
                        margin-bottom: 0.5em;
                        margin-left: auto;
                        margin-right: auto;
                        border-style: inset;
                        border-width: 2px;
                      "
                    ></div>

                    <ul class="company-info mt-0">
                      <li>
                        Name: <span>{{ profile.con_per_name }}</span>
                      </li>
                      <li>
                        Designation:
                        <span>{{ profile.cont_per_designation }}</span>
                      </li>
                      <li>
                        Email <span>{{ profile.cont_per_mail }}</span>
                      </li>
                      <li>
                        Phone: <span>{{ profile.cont_per_mobile }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="column col-lg-6 col-md-12 col-sm-12">
              <div class="job-detail text-left p-3">
                <div class="sidebar-widget company-widget">
                  <div class="widget-content">
                    <h3>Organization Address</h3>
                    <div
                      style="
                        display: block;
                        margin-top: 0.5em;
                        margin-bottom: 0.5em;
                        margin-left: auto;
                        margin-right: auto;
                        border-style: inset;
                        border-width: 2px;
                      "
                    ></div>

                    <ul class="company-info mt-0">
                      <li>
                        Street: <span>{{ profile.street }}</span>
                      </li>
                      <li>
                        House: <span>{{ profile.house }}</span>
                      </li>
                      <li>
                        County <span>{{ profile.county }}</span>
                      </li>
                      <li>
                        City: <span>{{ profile.city }}</span>
                      </li>
                      <li>
                        Post Code: <span>{{ profile.province }}</span>
                      </li>
                      <li>
                        Country: <span>{{ profile.country }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="column col-lg-6 col-md-12 col-sm-12">
              <div class="job-detail text-left p-5">
                <h4>About Company</h4>
                <p>{{ profile.business_description }}</p>
             
        
              </div>

              

          
            </div> -->

            <!-- <div class="sidebar-column col-lg-12 col-md-12 col-sm-12">
              <aside class="sidebar">
                <div class="sidebar-widget company-widget">
                  <div class="widget-content">

                    <ul class="company-info mt-0">
                      <li>Primary industry: <span>Software</span></li>
                      <li>Company size: <span>501-1,000</span></li>
                      <li>Founded in: <span>2011</span></li>
                      <li>Phone: <span>123 456 7890</span></li>
                      <li>Email: <span>info@joio.com</span></li>
                      <li>Location: <span>London, UK</span></li>
                      <li>Social media:
                        <div class="social-links">
                          <a href="#"><i class="fab fa-facebook-f"></i></a>
                          <a href="#"><i class="fab fa-twitter"></i></a>
                          <a href="#"><i class="fab fa-instagram"></i></a>
                          <a href="#"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                      </li>
                    </ul>

                    <div class="btn-box"><a href="#" class="theme-btn btn-style-three">www.invisionapp.com</a></div>
                  </div>
                </div>

              


              </aside>
            </div> -->

            <!-- <div class="sidebar-column col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar">
                <div class="sidebar-widget company-widget">
                  <div class="widget-content">

                    <ul class="company-info mt-0">
                      <li>Primary industry: <span>Software</span></li>
                      <li>Company size: <span>501-1,000</span></li>
                      <li>Founded in: <span>2011</span></li>
                      <li>Phone: <span>123 456 7890</span></li>
                      <li>Email: <span>info@joio.com</span></li>
                      <li>Location: <span>London, UK</span></li>
                      <li>Social media:
                        <div class="social-links">
                          <a href="#"><i class="fab fa-facebook-f"></i></a>
                          <a href="#"><i class="fab fa-twitter"></i></a>
                          <a href="#"><i class="fab fa-instagram"></i></a>
                          <a href="#"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                      </li>
                    </ul>

                    <div class="btn-box"><a href="#" class="theme-btn btn-style-three">www.invisionapp.com</a></div>
                  </div>
                </div>

              


              </aside>
            </div>  -->
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="job-detail-section">
      
      <div class="upper-box">
        <div class="auto-container">
          
        </div>
      </div>

      <div class="job-detail-outer">
        <div class="auto-container">
       
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EmployerProfileView",

  data() {
    return {
      org_types: [],
      logo: "",
      file: "",
      county: "",
      country: "",
      org_size: "",
      profile: "",
    };
  },

  methods: {
    add_org_type() {
      axios
        .get("recruiter/org_type/")
        .then((res) => {
          if (res.status == 200) {
            this.org_types = res.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },

    getCompany() {
      axios
        .get("recruiter/create-profile/")
        .then((res) => {
          if (res.status == 200) {
            this.profile = res.data;
            if (res.data.logo != null) {
              this.logo = res.data.logo.logo;
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_logo() {
      if (this.file != "") {
        let formData = new FormData();
        formData.append("logo", this.file);

        axios
          .post("recruiter/logo/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              alert("Logo added");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        // alert("Select a logo first")
      }
    },
    editprofile(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "employer-profile-update",
          query: { uuid: uuid },
        });
      }
    },

    re_logo() {
      axios
        .get("recruiter/logo/")
        .then((res) => {
          this.logo = res.data;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.logo = "";
        });
    },
  },

  mounted: function () {
    this.add_org_type(), this.re_logo();
    this.getCompany();
  },
};
</script>

<style>
hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}
</style>
