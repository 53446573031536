<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li class="active">
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
          <li>
            <a href="/admin-payment-status"
              ><i class="la la-comment-o"></i>Payment Status</a
            >
          </li>
          <li>
            <a href="/admin-delete-profile"
              ><i class="la la-trash"></i>Delete Profile</a
            >
          </li>
          <!-- <li><a href="index.html"><i class="la la-trash"></i>Publish Ads</a></li> -->
          <li>
            <a href="/admin-change-password"
              ><i class="la la-lock"></i>Change Password</a
            >
          </li>
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <li>
            <a href="/login"><i class="la la-sign-out"></i>Logout</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- End User Sidebar -->

    <!--Page Title-->

    <!--End Page Title-->

    <!-- Job Detail Section -->
    <section v-if="recruiter != null" class="job-detail-section">
      <!-- Upper Box -->
      <div class="upper-box">
        <div class="auto-container">
          <!-- Job Block -->
          <div class="job-block-seven style-three">
            <div>
              <div class="content">
                <span v-if="recruiter.logo != null" class="company-logo"
                  ><img :src="recruiter.logo.logo" alt=""
                /></span>
                <h4>{{ recruiter.org_name }}</h4>
                <ul class="job-other-info">
                  <li class="time">
                    Open Jobs – {{ recruiter.approved_jobpost_count }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="job-detail-outer">
        <div class="auto-container">
          <div class="row">
            <div class="content-column text-center col-lg-12 col-md-12 col-sm-12 ">
              <div class="job-detail">
                <h4>About Company</h4>
                <p>
                  {{ recruiter.description }}
                </p>
                <!--               
                <div class="row images-outer">
                  <div class="col-lg-3 col-md-3 col-sm-6">
                    <figure class="image">
                      <a
                        href="images/resource/employers-single-1.png"
                        class="lightbox-image"
                        data-fancybox="gallery"
                        ><img
                          src="images/resource/employers-single-1.png"
                          alt=""
                      /></a>
                    </figure>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6">
                    <figure class="image">
                      <a
                        href="images/resource/employers-single-2.png"
                        class="lightbox-image"
                        data-fancybox="gallery"
                        ><img
                          src="images/resource/employers-single-2.png"
                          alt=""
                      /></a>
                    </figure>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6">
                    <figure class="image">
                      <a
                        href="images/resource/employers-single-3.png"
                        class="lightbox-image"
                        data-fancybox="gallery"
                        ><img
                          src="images/resource/employers-single-3.png"
                          alt=""
                      /></a>
                    </figure>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6">
                    <figure class="image">
                      <a
                        href="images/resource/employers-single-4.png"
                        class="lightbox-image"
                        data-fancybox="gallery"
                        ><img
                          src="images/resource/employers-single-4.png"
                          alt=""
                      /></a>
                    </figure>
                  </div>
                </div> -->
              </div>

              <!-- Related Jobs -->
              <div class="related-jobs">
                <div class="title-box">
                  <h3>
                    {{ recruiter.approved_active_jobpost_count }} Active Job
                  </h3>
                  <div class="text">
                    {{ recruiter.approved_jobpost_count }} jobs Approved -
                    {{ recruiter.reviewing_jobpost_count }} job on Reviewing -
                    {{ recruiter.rejected_jobpost_count }} job rejected
                  </div>
                </div>
              </div>
            </div>

            <div class="sidebar-column col-lg-6 col-md-12 col-sm-12">
              <aside class="sidebar pd-right">
                <div class="sidebar-widget company-widget">
                  <div class="widget-content">
                    <ul class="company-info mt-0">
                      <li v-if="recruiter.org_type != null">
                        Company Type:
                        <span>{{ recruiter.org_type.org_type }}</span>
                      </li>
                      <li>
                        Company size: <span>{{ recruiter.org_size }}</span>
                      </li>
                      <li>
                        Founded in: <span>{{ recruiter.est_year }}</span>
                      </li>
                      <li>
                        Phone: <span>{{ recruiter.cont_per_mobile }}</span>
                      </li>
                      <li>
                        Email: <span>{{ recruiter.cont_per_mail }}</span>
                      </li>
                      <li>
                        Code: <span>{{ recruiter.code }}</span>
                      </li>
                      <li>
                        Business ID: <span>{{ recruiter.business_id }}</span>
                      </li>
                      <!-- <li>Location: <span>London, UK</span></li>
                      <li>
                        Social media:
                        <div class="social-links">
                          <a href="#"><i class="fab fa-facebook-f"></i></a>
                          <a href="#"><i class="fab fa-twitter"></i></a>
                          <a href="#"><i class="fab fa-instagram"></i></a>
                          <a href="#"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                      </li> -->
                    </ul>

                    <div class="btn-box">
                      <a
                        :href="recruiter.website"
                        class="theme-btn btn-style-three"
                        >{{ recruiter.website }}</a
                      >
                    </div>
                  </div>
                </div>

               
              </aside>
            </div>

            <div class="sidebar-column col-lg-6 col-md-12 col-sm-12">
              <aside class="sidebar pd-right">
              

                <div style="padding: 0px 0px 0px 0px" class="sidebar-widget">
                  <!-- Map Widget -->
                  <!-- <h4 class="widget-title">Job Location</h4> -->

                  <div class="sidebar-widget company-widget">
                    <div class="widget-content">
                      <ul class="company-info mt-0">
                        <li>
                          Street: <span>{{ recruiter.street }}</span>
                        </li>
                        <li>
                          House: <span>{{ recruiter.house }}</span>
                        </li>
                        <li>
                          County: <span>{{ recruiter.est_year }}</span>
                        </li>
                        <li>
                          City: <span>{{ recruiter.city }}</span>
                        </li>
                        <li>
                          Province: <span>{{ recruiter.province }}</span>
                        </li>
                        <li>
                          Country: <span>{{ recruiter.country }}</span>
                        </li>
                        <!-- <li>Location: <span>London, UK</span></li>
                      <li>
                        Social media:
                        <div class="social-links">
                          <a href="#"><i class="fab fa-facebook-f"></i></a>
                          <a href="#"><i class="fab fa-twitter"></i></a>
                          <a href="#"><i class="fab fa-instagram"></i></a>
                          <a href="#"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                      </li> -->
                      </ul>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Job Detail Section -->

    <!--End Listing Page Section -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AdminDetailEmpView.",

  data() {
    return {
      recruiter: {},
    };
  },
  methods: {
    get_recruiter_detail(uuid) {
      axios
        .get("/app_admin/recruiter/" + uuid + "/")
        .then((response) => {
          if (response.status == 200) {
            this.recruiter = response.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted: function () {
    var uuid = this.$route.query.uuid;
    if (uuid != null) {
      this.get_recruiter_detail(uuid);
      this.uuide = uuid;
    }
  },
};
</script>

<style></style>
