<template>
  <div>
    <!--End Main Header -->

    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/candidate-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li class="active">
            <a href="/candidate-profile-single"
              ><i class="la la-user-tie"></i>My Resume</a
            >
          </li>
          <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
          <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
          <li>
            <a href="/candidate-findjob"
              ><i class="la la-user-alt"></i>Find jobs</a
            >
          </li>
          <li>
            <a href="/candidate-appliedjob"
              ><i class="la la-briefcase"></i> Applied Jobs
            </a>
          </li>

          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 250px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Faqs Section -->
    <section class="faqs-section">
      <div class="auto-container">
        <h2 class="sec-title">Update your Profile</h2>

        <button class="accordionc">Photograph</button>
        <div class="panel" >
          <br>

          <div class="uploading-outer">
            <div class="uploadButton">
              <input
                class="uploadButton-input"
                type="file"
                @change="select_photo($event)"
                accept="image/*"
                id="upload"
              />

              <div>
                <label
                  style="position: relative"
                  class="uploadButton-button ripple-effect"
                  for="upload"
                >
                  <img
                    v-if="photograph_url"
                    :src="photograph_url"
                    style="
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      z-index: 0;
                    "
                    alt=""
                  />

                  <span v-if="photograph_url == '' || url == photograph_url"
                    >Browse Photograph</span
                  >
                </label>
                <button
                  @click.prevent="photograph"
                  class="btn btn-primary theme-color-bg mt-2 photo_upload_btn"
                >
                  Upload Photo
                </button>
              </div>

              <span class="uploadButton-file-name"></span>
            </div>
          </div>


          
 

        </div>

        <button class="accordionc">Basic Informations</button>
        <div class="panel">
          <br />


          <form
            @submit.prevent="add_basic_info"
            style="margin-left: 20px; margin-right: 20px; margin-bottom: 20px"
            class="default-form"
          >
            <div class="row">
              
              <div class="form-group col-lg-6 col-md-12">
                <label>First Name</label>
                <input v-if="basic_info_data"
                  type="text"
                  v-model="basic_info_data.first_name"
                  placeholder=""
                  @input="clearError('FirstName')"
                />
                <input v-else
                  type="text"
                  v-model="first_name"
                  placeholder=""
                  @input="clearError('FirstName')"
                />

                <div id="FirstName" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Last Name</label>
                <input
                v-if="basic_info_data"
                  type="text"
                  v-model="basic_info_data.last_name"
                  placeholder=""
                  @input="clearError('LastName')"
                />

                <input 
                  v-else
                  type="text"
                  v-model="last_name"
                  placeholder=""
                  @input="clearError('LastName')"
                />
                <div id="LastName" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Date of Birth</label>
                <input
                  v-if="basic_info_data"
                  class="form-control"
                  v-model="basic_info_data.dob"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('DateofBirth')"
                />

                <input
                  v-else
                  class="form-control"
                  v-model="dob"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('DateofBirth')"
                />


                <div id="DateofBirth" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Religion</label>
                <input
                 v-if="basic_info_data"
                  type="text"
                  v-model="basic_info_data.religion"
                  placeholder=""
                  @input="clearError('Religion')"
                />

                <input
                  v-else
                  type="text"
                  v-model="religion"
                  placeholder=""
                  @input="clearError('Religion')"
                />


                <div id="Religion" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Ethnicity</label>
                <input
                  v-if="basic_info_data"
                  type="text"
                  v-model="basic_info_data.ethnicity"
                  placeholder=""
                  @input="clearError('Ethnicity')"
                />
                <input
                  v-else
                  type="text"
                  v-model="ethnicity"
                  placeholder=""
                  @input="clearError('Ethnicity')"
                />

                <div id="Ethnicity" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Gender</label>
                <select 
                v-if="basic_info_data"

                  v-model="basic_info_data.gender"
                  @input="clearError('Gender')"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>

                <select 

                 v-else
                  v-model="gender"
                  @input="clearError('Gender')"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
                <div id="Gender" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Primary Number</label>
                <input
                v-if="basic_info_data"

                  type="text"
                  v-model="basic_info_data.primary_contact_no"
                  placeholder=""
                  @input="clearError('PrimaryNumber')"
                />
                <input
                v-else
                  type="text"
                  v-model="primary_contact_no"
                  placeholder=""
                  @input="clearError('PrimaryNumber')"
                />

                <div id="PrimaryNumber" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Nationality</label>
                <input
                v-if="basic_info_data"

                  type="text"
                  v-model="basic_info_data.nationality"
                  name="name"
                  placeholder=""
                  @input="clearError('Nationality')"
                />
                <input
                v-else
                  type="text"
                  v-model="nationality"
                  name="name"
                  placeholder=""
                  @input="clearError('Nationality')"
                />

                <div id="Nationality" class="text-danger error"></div>
              </div>

              
              <!-- <div class="form-group col-lg-6 col-md-12">
                <label>Father's Name</label>
                <input
                  type="text"
                  v-model="basic_info_data.fathers_name"
                  placeholder=""
                  @input="clearError('FatherName')"
                />

                <div id="FatherName" class="text-danger error"></div>
              </div> -->

              
              <!-- <div class="form-group col-lg-6 col-md-12">
                <label>Mother's Name</label>
                <input
                  type="text"
                  v-model="basic_info_data.mothers_name"
                  placeholder=""
                  @input="clearError('MotherName')"
                />

                <div id="MotherName" class="text-danger error"></div>
              </div> -->

              <div class="form-group col-lg-6 col-md-12">
                <label>Secondary Contact Number</label>
                <input
                v-if="basic_info_data"

                  type="number"
                  v-model="basic_info_data.secondary_contact_no"
                  placeholder=""
                  @input="clearError('SecondaryContactNumber')"
                />
                <input
                  v-else
                  type="number"
                  v-model="secondary_contact_no"
                  placeholder=""
                  @input="clearError('SecondaryContactNumber')"
                />

                <div
                  id="SecondaryContactNumber"
                  class="text-danger error"
                ></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Emergency Contact Number</label>
                <input
                  v-if="basic_info_data"

                  type="number"
                  v-model="basic_info_data.emergency_contact_no"
                  placeholder=""
                  @input="clearError('EmergencyContactNumber')"
                />
                <input
                  v-else
                  type="number"
                  v-model="emergency_contact_no"
                  placeholder=""
                  @input="clearError('EmergencyContactNumber')"
                />


                <div
                  id="EmergencyContactNumber"
                  class="text-danger error"
                ></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Secondary Email</label>
                <input
                  v-if="basic_info_data"
                  type="email"
                  v-model="basic_info_data.secondary_mail"
                  placeholder=""
                  @input="clearError('SecondaryEmail')"
                />

                <input
                  v-else
                  type="email"
                  v-model="secondary_mail"
                  placeholder=""
                  @input="clearError('SecondaryEmail')"
                />

                <div id="SecondaryEmail" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>NI No.</label>
                <input
                  v-if="basic_info_data"
                  type="text"
                  v-model="basic_info_data.nid"
                  placeholder=""
                  @input="clearError('NID')"
                />
                <input
                  v-else
                  type="text"
                  v-model="nid"
                  placeholder=""
                  @input="clearError('NID')"
                />

                <div id="NID" class="text-danger error"></div>
              </div>

              <!-- <div class="form-group col-lg-6 col-md-12">
                <label>Passport Number</label>
                <input
                  type="text"
                  v-model="basic_info_data.passport_no"
                  placeholder=""
                  @input="clearError('PassportNumber')"
                />

                <div id="PassportNumber" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Blood Group</label>
                <input
                  type="text"
                  v-model="basic_info_data.blood_group"
                  placeholder=""
                  @input="clearError('BloodGroup')"
                />

                <div id="BloodGroup" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Weight</label>
                <input
                  type="text"
                  v-model="basic_info_data.weight"
                  placeholder=""
                  @input="clearError('Weight')"
                />

                <div id="Weight" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Height</label>
                <input
                  type="text"
                  v-model="basic_info_data.height"
                  placeholder=""
                  @input="clearError('Height')"
                />

                <div id="Height" class="text-danger error"></div>
              </div> -->

              
              <div
                class="form-group col-lg-6 col-md-12"
                style="margin-bottom: 30px"
              >
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <!-- <button
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button> -->
              </div>
            </div>
          </form>
        </div>

        <!-- <button class="accordionc">Career Informations</button>
        <div class="panel">
          <form
            @submit.prevent="career_info"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Objective</label>
                <input
                  type="text"
                  v-model="career_info_data.objective"
                  placeholder=""
                  @input="clearError('Objective')"
                />
                <div id="Objective" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Job Level</label>
                <input
                  type="text"
                  v-model="career_info_data.job_level"
                  placeholder=""
                  @input="clearError('JobLevel')"
                />
                <div id="JobLevel" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Job Nature</label>
                <select
                  v-model="career_info_data.job_nature"
                  @input="clearError('JobNature')"
                >
                  <option value="fulltime">Full time</option>
                  <option value="parttime">Part time</option>
                  <option value="contractual">Contractual</option>
                </select>
                <div id="JobNature" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Present Salary</label>
                <input
                  type="number"
                  v-model="career_info_data.present_salary"
                  placeholder=""
                  @input="clearError('PresentSalary')"
                />
                <div id="PresentSalary" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Present Salary Type</label>
                <input
                  type="text"
                  v-model="career_info_data.present_salary_type"
                  placeholder=""
                  @input="clearError('SalaryType')"
                />

                <div id="SalaryType" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Expected Salary</label>
                <input
                  type="number"
                  v-model="career_info_data.expected_salary"
                  placeholder=""
                  @input="clearError('ExpectedSalary')"
                />

                <div id="ExpectedSalary" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Expected Salary Type</label>
                <input
                  type="text"
                  v-model="career_info_data.expected_salary_type"
                  placeholder=""
                  @input="clearError('ExpectedSalaryType')"
                />

                <div id="ExpectedSalaryType" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-md-12" style="margin-bottom: 30px">
                <button type="submit" class="theme-btn btn-style-one">
                  Update
                </button>
        
              </div>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Certification</button>
        <div class="panel">
          <form
            @submit.prevent="certification"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div v-for="n in cer_form_count" :key="n" class="row">
              

              <h3 class="mt-2">Certification info {{ n }}</h3>

              <div class="form-group col-lg-6 col-md-12">
                <label>Certification Name</label>
                <input
                  v-if="certification_data[n - 1]"
                  type="text"
                  v-model="certification_data[n - 1].cer_name"
                  placeholder=""
                  @input="clearError('CertificationName' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="cer_name[n - 1]"
                  placeholder=""
                  @input="clearError('CertificationName' + (n - 1))"
                />

                <div
                  :id="'CertificationName' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Institution</label>
                <input
                  v-if="certification_data[n - 1]"
                  type="text"
                  v-model="certification_data[n - 1].location"
                  placeholder=""
                  @input="clearError('Institution' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="location[n - 1]"
                  placeholder=""
                  @input="clearError('Institution' + (n - 1))"
                />

                <div
                  :id="'Institution' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Start Date</label>
                <input
                  v-if="certification_data[n - 1]"
                  class="form-control"
                  v-model="certification_data[n - 1].start_date"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('StartDate' + (n - 1))"
                />

                <input
                  class="form-control"
                  v-else
                  type="date"
                  v-model="start_date[n - 1]"
                  placeholder=""
                  @input="clearError('StartDate' + (n - 1))"
                />

                <div
                  :id="'StartDate' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>End Date</label>
                <input
                  v-if="certification_data[n - 1]"
                  class="form-control"
                  v-model="certification_data[n - 1].end_date"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('EndDate' + (n - 1))"
                />

                <input
                  class="form-control"
                  v-else
                  type="date"
                  v-model="end_date[n - 1]"
                  placeholder=""
                  @input="clearError('EndDate' + (n - 1))"
                />

                <div :id="'EndDate' + n - 1" class="text-danger error"></div>
              </div>
            </div>

            
            <div
              class="form-group col-lg-6 col-md-12"
              style="margin-bottom: 30px"
            >
              <button type="submit" class="theme-btn btn-style-one">
                Update
              </button>
              <button
                @click.prevent="cer_form_count++"
                id="next-button-1"
                style="margin-left: 20px; background-color: brown"
                class="theme-btn btn-style-one"
              >
                Add more
              </button>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Education</button>
        <div style="overflow: auto" class="panel">
          <div id="education_block">
            <form
              id="edu_form"
              @submit.prevent="education"
              style="margin: 20px 20px 20px 20px"
              class="default-form"
            >
              <div v-for="n in edu_form_count" :key="n" class="row">
                <h3 class="mt-2">Education info {{ n }}</h3>

                
                <div class="form-group col-lg-6 col-md-12">
                  <label>Education Level</label>

                  <input
                    v-if="educations_data[n - 1]"
                    type="text"
                    v-model="educations_data[n - 1].edu_level"
                    placeholder=""
                    @input="clearError('EducationLevel' + (n - 1))"
                  />

                  <input
                    v-else
                    type="text"
                    v-model="edu_level[n - 1]"
                    placeholder=""
                    @input="clearError('EducationLevel' + (n - 1))"
                  />

                  <div
                    :id="'EducationLevel' + (n - 1)"
                    class="text-danger error"
                  ></div>
                </div>

                
                <div class="form-group col-lg-6 col-md-12">
                  <label>Degree</label>
                  <input
                    v-if="educations_data[n - 1]"
                    type="text"
                    v-model="educations_data[n - 1].degree_title"
                    placeholder=""
                    @input="clearError('Degree' + (n - 1))"
                  />
                  <input
                    v-else
                    type="text"
                    v-model="degree_title[n - 1]"
                    placeholder=""
                    @input="clearError('Degree' + (n - 1))"
                  />

                  <div :id="'Degree' + (n - 1)" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Major</label>
                  <input
                    v-if="educations_data[n - 1]"
                    class="form-control"
                    v-model="educations_data[n - 1].major"
                    type="text"
                    placeholder=""
                    @input="clearError('Major' + (n - 1))"
                  />
                  <input
                    v-else
                    type="text"
                    v-model="major[n - 1]"
                    placeholder=""
                    @input="clearError('Major' + (n - 1))"
                  />

                  <div :id="'Major' + (n - 1)" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Institute</label>
                  <input
                    v-if="educations_data[n - 1]"
                    class="form-control"
                    v-model="educations_data[n - 1].institute"
                    type="text"
                    placeholder=""
                    @input="clearError('Institute' + (n - 1))"
                  />

                  <input
                    v-else
                    type="text"
                    v-model="institute[n - 1]"
                    placeholder=""
                    @input="clearError('Institute' + (n - 1))"
                  />

                  <div
                    :id="'Institute' + (n - 1)"
                    class="text-danger error"
                  ></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Grade</label>
                  <input
                    v-if="educations_data[n - 1]"
                    class="form-control"
                    v-model="educations_data[n - 1].result"
                    type="text"
                    placeholder=""
                    @input="clearError('Grade' + (n - 1))"
                  />
                  <input
                    v-else
                    type="text"
                    v-model="result[n - 1]"
                    placeholder=""
                    @input="clearError('Grade' + (n - 1))"
                  />

                  <div :id="'Grade' + (n - 1)" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Marks</label>
                  <input
                    v-if="educations_data[n - 1]"
                    class="form-control"
                    v-model="educations_data[n - 1].marks"
                    type="number"
                    placeholder=""
                    @input="clearError('Marks' + (n - 1))"
                  />
                  <input
                    v-else
                    type="number"
                    v-model="marks[n - 1]"
                    placeholder=""
                    @input="clearError('Marks' + (n - 1))"
                  />

                  <div :id="'Marks' + (n - 1)" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Passing Year</label>
                  <input
                    v-if="educations_data[n - 1]"
                    class="form-control"
                    v-model="educations_data[n - 1].passing_year"
                    type="number"
                    placeholder=""
                    @input="clearError('PassingYear' + (n - 1))"
                  />

                  <input
                    v-else
                    type="number"
                    v-model="passing_year[n - 1]"
                    placeholder=""
                    @input="clearError('PassingYear' + (n - 1))"
                  />

                  <div
                    :id="'PassingYear' + (n - 1)"
                    class="text-danger error"
                  ></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Duration</label>
                  <input
                    v-if="educations_data[n - 1]"
                    class="form-control"
                    v-model="educations_data[n - 1].duration"
                    type="number"
                    placeholder=""
                    @input="clearError('Duration' + (n - 1))"
                  />
                  <input
                    v-else
                    type="number"
                    v-model="duration[n - 1]"
                    placeholder=""
                    @input="clearError('Duration' + (n - 1))"
                  />

                  <div
                    :id="'Duration' + (n - 1)"
                    class="text-danger error"
                  ></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Achievement</label>
                  <input
                    v-if="educations_data[n - 1]"
                    class="form-control"
                    v-model="educations_data[n - 1].achievement"
                    type="text"
                    placeholder=""
                    @input="clearError('Achievement' + (n - 1))"
                  />
                  <input
                    v-else
                    type="text"
                    v-model="achievement[n - 1]"
                    placeholder=""
                    @input="clearError('Achievement' + (n - 1))"
                  />

                  <div
                    :id="'Achievement' + (n - 1)"
                    class="text-danger error"
                  ></div>
                </div>
              </div>

              
              <div
                class="form-group col-md-12 mt-3"
                style="margin-bottom: 30px"
              >
                <button type="submit" class="theme-btn btn-style-one">
                  Update
                </button>

             

                <button
                  @click.prevent="edu_form_count++"
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Add more
                </button>
              </div>
            </form>
          </div>
        </div> -->

        <!-- Experience Section -->

        <!-- <button class="accordionc">Experience</button>
        <div style="overflow: auto" class="panel">
          <form
            @submit.prevent="experience"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div v-for="n in exp_form_count" :key="n" class="row">
              <h3 class="mt-2">Experience info {{ n }}</h3>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Organization Name</label>
                <input
                  v-if="experiences_data[n - 1]"
                  type="text"
                  v-model="experiences_data[n - 1].org_name"
                  placeholder=""
                  @input="clearError('OrganizationName' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="org_name[n - 1]"
                  placeholder=""
                  @input="clearError('OrganizationName' + (n - 1))"
                />

                <div
                  :id="'OrganizationName' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Type of Organization</label>
                <input
                  v-if="experiences_data[n - 1]"
                  type="text"
                  v-model="experiences_data[n - 1].org_business"
                  placeholder=""
                  @input="clearError('TypeOfOrganization' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="org_business[n - 1]"
                  placeholder=""
                  @input="clearError('TypeOfOrganization' + (n - 1))"
                />

                <div
                  :id="'TypeOfOrganization' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Designation</label>
                <input
                  v-if="experiences_data[n - 1]"
                  type="text"
                  v-model="experiences_data[n - 1].designation"
                  placeholder=""
                  @input="clearError('Designation' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="designation[n - 1]"
                  placeholder=""
                  @input="clearError('Designation' + (n - 1))"
                />

                <div
                  :id="'Designation' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Department</label>
                <input
                  v-if="experiences_data[n - 1]"
                  type="text"
                  v-model="experiences_data[n - 1].department"
                  placeholder=""
                  @input="clearError('Department' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="department[n - 1]"
                  placeholder=""
                  @input="clearError('Department' + (n - 1))"
                />

                <div
                  :id="'Department' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Starting date</label>
                <input
                  v-if="experiences_data[n - 1]"
                  class="form-control"
                  v-model="experiences_data[n - 1].from_date"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('StartingDate' + (n - 1))"
                />

                <input
                  v-else
                  class="form-control"
                  v-model="from_date[n - 1]"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('StartingDate' + (n - 1))"
                />

                <div
                  :id="'StartingDate' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Ending date</label>
                <input
                  v-if="experiences_data[n - 1]"
                  class="form-control"
                  v-model="experiences_data[n - 1].to_date"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('EndingDate' + (n - 1))"
                />
                <input
                  v-else
                  class="form-control"
                  v-model="to_date[n - 1]"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('EndingDate' + (n - 1))"
                />

                <div
                  :id="'EndingDate' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Currently working</label>
                <select
                  v-if="experiences_data[n - 1]"
                  v-model="experiences_data[n - 1].curr_working"
                  @change="clearError('CurrentlyWorking' + (n - 1))"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
                <select
                  v-else
                  v-model="curr_working[n - 1]"
                  @change="clearError('CurrentlyWorking' + (n - 1))"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>

                <div
                  :id="'CurrentlyWorking' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Responsibilities</label>
                <input
                  v-if="experiences_data[n - 1]"
                  type="text"
                  v-model="experiences_data[n - 1].responsibilities"
                  placeholder=""
                  @input="clearError('Responsibilities' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="responsibilities[n - 1]"
                  placeholder=""
                  @input="clearError('Responsibilities' + (n - 1))"
                />

                <div
                  :id="'Responsibilities' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label> Area of expertise</label>

                <div v-if="experiences_data[n - 1]">
                  <div style="display: flex">
                    <input
                      v-if="area_expertise"
                      type="text"
                      style="width: 80%"
                      v-model="area_expertise[n - 1]"
                      placeholder=""
                      @input="clearError('AreaOfExpertise' + (n - 1))"
                    />

                    <div class="col-2">
                      <button
                        style="margin-left: 20px; background-color: #36a8a6"
                        class="theme-button h-100 w-100"
                        @click.prevent="add_expertises(n - 1)"
                      >
                        Add
                      </button>
                    </div>

                  </div>

                  <div v-if="experiences_data[n - 1]" class="mt-2">
                    <span
                      v-for="expertise in experiences_data[n - 1]
                        .area_expertise"
                      :key="expertise"
                      class="mt-2 addElement"
                    >
                      {{ expertise }}
                      <i
                        @click="removeEleexpertise(expertise, n - 1)"
                        class="fa-solid fa-xmark removeElebtn"
                      ></i>
                    </span>
                  </div>
                </div>

                <div v-else>
                  <div style="display: flex">
                    <input
                      v-if="area_expertise"
                      type="text"
                      style="width: 80%"
                      v-model="experty[n - 1]"
                      placeholder=""
                      @input="clearError('AreaOfExpertise' + (n - 1))"
                    />

                    <div class="col-2">
                      <button
                        style="margin-left: 20px; background-color: #36a8a6"
                        class="theme-button h-100 w-100"
                        @click.prevent="
                          add_expertises_new(n - 1, n - explength)
                        "
                      >
                        Add
                      </button>
                    </div>

                  </div>

                  <div class="mt-2">
                    <span
                      v-for="expertise in expertises[n - explength]"
                      :key="expertise"
                      class="mt-2 addElement"
                    >
                      {{ expertise }}
                      <i
                        @click="removeEleexpertiseNew(expertise, n - explength)"
                        class="fa-solid fa-xmark removeElebtn"
                      ></i>
                    </span>
                  </div>
                </div>

                <div
                  :id="'AreaOfExpertise' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Location of the organization</label>
                <input
                  v-if="experiences_data[n - 1]"
                  type="text"
                  v-model="experiences_data[n - 1].org_location"
                  placeholder=""
                  @input="clearError('Location' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="org_location[n - 1]"
                  placeholder=""
                  @input="clearError('Location' + (n - 1))"
                />

                <div :id="'Location' + (n - 1)" class="text-danger error"></div>
              </div>
            </div>

            
            <div
              style="padding-bottom: 50px"
              class="form-group col-lg-6 col-md-12"
            >
              <button type="submit" class="theme-btn btn-style-one">
                Save
              </button>
              <button
                id="next-button-1"
                @click.prevent="exp_add_btn_event"
                style="margin-left: 10px; background-color: brown"
                class="theme-btn btn-style-one"
              >
                Add more
              </button>
            </div>
          </form>
        </div> -->

        <!-- Skill Section -->

        <!-- <button class="accordionc">Skills</button>
        <div style="overflow: auto" class="panel">
          <form
            @submit.prevent="skills"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div v-for="n in skill_form_count" :key="n" class="row">
              <h3 class="mt-2">Skill info {{ n }}</h3>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Name of the Skill</label>
                <input
                  v-if="skill_data[n - 1]"
                  type="text"
                  v-model="skill_data[n - 1].skill_name"
                  placeholder=""
                  @input="clearError('SkillName' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="skill_name[n - 1]"
                  placeholder=""
                  @input="clearError('SkillName' + (n - 1))"
                />

                <div
                  :id="'SkillName' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Acquired category</label>
                <select
                  v-if="skill_data[n - 1]"
                  v-model="skill_data[n - 1].acquired_from"
                  @change="clearError('AcquiredFrom' + (n - 1))"
                >
                  <option value="self">Self</option>
                  <option value="training">Training</option>
                  <option value="education">Eduction</option>
                  <option value="job">Job</option>
                </select>

                <select
                  v-else
                  v-model="acquired_from[n - 1]"
                  @change="clearError('AcquiredFrom' + (n - 1))"
                >
                  <option value="self">Self</option>
                  <option value="training">Training</option>
                  <option value="education">Eduction</option>
                  <option value="job">Job</option>
                </select>

                <div
                  :id="'AcquiredFrom' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Description</label>
                <input
                  v-if="skill_data[n - 1]"
                  type="text"
                  v-model="skill_data[n - 1].description"
                  placeholder=""
                  @input="clearError('Description' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="description[n - 1]"
                  placeholder=""
                  @input="clearError('Description' + (n - 1))"
                />

                <div
                  :id="'Description' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>
            </div>

            
            <div class="form-group col-md-12 mt-3" style="margin-bottom: 30px">
              <button type="submit" class="theme-btn btn-style-one">
                Update
              </button>
              <button
                id="next-button-1"
                @click.prevent="skill_form_count++"
                style="margin-left: 20px; background-color: brown"
                class="theme-btn btn-style-one"
              >
                ADD MORE
              </button>
            </div>
          </form>
        </div> -->

        <!-- Skill section end -->

        <!-- Training section strated -->

        <!-- <button class="accordionc">Training</button>
        <div style="overflow: auto" class="panel">
          <form
            @submit.prevent="training"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div v-for="n in tra_form_count" :key="n" class="row">
              <h3 class="mt-2">Traning info {{ n }}</h3>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Title</label>
                <input
                  v-if="trainings_data[n - 1]"
                  type="text"
                  v-model="trainings_data[n - 1].title"
                  placeholder=""
                  @input="clearError('Title' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="tra_title[n - 1]"
                  placeholder=""
                  @input="clearError('Title' + (n - 1))"
                />

                <div :id="'Title' + (n - 1)" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Type</label>
                <input
                  v-if="trainings_data[n - 1]"
                  type="text"
                  v-model="trainings_data[n - 1].type"
                  placeholder=""
                  @input="clearError('Type' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="tra_type[n - 1]"
                  placeholder=""
                  @input="clearError('Type' + (n - 1))"
                />

                <div :id="'Type' + (n - 1)" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label> Topics covered</label>

                <div v-if="trainings_data[n - 1]">
                  <div style="display: flex">
                    <input
                      type="text"
                      style="width: 80%"
                      v-model="topics_covered[n - 1]"
                      placeholder=""
                      @input="clearError('TopicCovered' + (n - 1))"
                    />

                    <div class="col-2">
                      <button
                        style="margin-left: 20px; background-color: #36a8a6"
                        class="theme-button h-100 w-100"
                        @click.prevent="add_topics(n - 1)"
                      >
                        ADD
                      </button>
                    </div>

                  </div>

                  <div
                    v-if="trainings_data[n - 1].topics_covered != ''"
                    class="mt-2"
                  >
                    <span
                      v-for="topic in trainings_data[n - 1].topics_covered"
                      :key="topic"
                      class="mt-2 addElement"
                    >
                      {{ topic }}
                      <i
                        @click="removeEletopic(topic, n - 1)"
                        class="fa-solid fa-xmark removeElebtn"
                      ></i>
                    </span>
                  </div>
                </div>

                <div v-else>
                  <div style="display: flex">
                    <input
                      type="text"
                      style="width: 80%"
                      v-model="topics_covered_new[n - 1]"
                      placeholder=""
                      @input="clearError('TopicCovered' + (n - 1))"
                    />

                    <div class="col-2">
                      <button
                        style="margin-left: 20px; background-color: #36a8a6"
                        class="theme-button h-100 w-100"
                        @click.prevent="add_topics_new(n - 1, n - tra_length)"
                      >
                        ADD
                      </button>
                    </div>

                  </div>

                  <div class="mt-2">
                    <span
                      v-for="topic in topics_covered_new_all[n - tra_length]"
                      :key="topic"
                      class="mt-2 addElement"
                    >
                      {{ topic }}
                      <i
                        @click="removeEletopicNew(topic, n - tra_length)"
                        class="fa-solid fa-xmark removeElebtn"
                      ></i>
                    </span>
                  </div>
                </div>

                <div
                  :id="'TopicCovered' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Duration</label>
                <input
                  v-if="trainings_data[n - 1]"
                  type="number"
                  v-model="trainings_data[n - 1].duration"
                  placeholder=""
                  @input="clearError('duration' + (n - 1))"
                />
                <input
                  v-else
                  type="number"
                  v-model="tra_duration[n - 1]"
                  placeholder=""
                  @input="clearError('duration' + (n - 1))"
                />

                <div :id="'duration' + (n - 1)" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Year</label>
                <input
                  v-if="trainings_data[n - 1]"
                  type="number"
                  v-model="trainings_data[n - 1].year"
                  placeholder=""
                  @input="clearError('Year' + (n - 1))"
                />
                <input
                  v-else
                  type="number"
                  v-model="tra_year[n - 1]"
                  placeholder=""
                  @input="clearError('Year' + (n - 1))"
                />

                <div :id="'Year' + (n - 1)" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Institute</label>
                <input
                  v-if="trainings_data[n - 1]"
                  type="text"
                  v-model="trainings_data[n - 1].institute"
                  placeholder=""
                  @input="clearError('institute' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="tra_institute[n - 1]"
                  placeholder=""
                  @input="clearError('institute' + (n - 1))"
                />

                <div
                  :id="'institute' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Location</label>
                <input
                  v-if="trainings_data[n - 1]"
                  type="text"
                  v-model="trainings_data[n - 1].location"
                  placeholder=""
                  @input="clearError('location' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="tra_location[n - 1]"
                  placeholder=""
                  @input="clearError('location' + (n - 1))"
                />

                <div :id="'location' + (n - 1)" class="text-danger error"></div>
              </div>
            </div>

            
            <div style="padding-bottom: 50px" class="form-group col-md-12 mt-3">
              <button type="submit" class="theme-btn btn-style-one">
                Update
              </button>
              <button
                id="next-button-1"
                @click.prevent="tra_more_btn_event"
                style="margin-left: 20px; background-color: brown"
                class="theme-btn btn-style-one"
              >
                ADD MORE
              </button>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Extracurricular Activity</button>
        <div style="overflow: auto" class="panel">
          <form
            @submit.prevent="extracurricular"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div v-for="n in extra_co_form_count" :key="n" class="row">
              <h3 class="mt-2">Extra courricular info {{ n }}</h3>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Extra-Curricular Activities</label>
                <input
                  v-if="extracurricular_data[n - 1]"
                  type="text"
                  v-model="extracurricular_data[n - 1].activity"
                  placeholder=""
                  @input="clearError('Activities' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="activity[n - 1]"
                  placeholder=""
                  @input="clearError('Activities' + (n - 1))"
                />

                <div
                  :id="'Activities' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>
            </div>

            
            <div class="form-group col-md-12 mt-3" style="margin-bottom: 30px">
              <button type="submit" class="theme-btn btn-style-one">
                Update
              </button>
              <button
                id="next-button-1"
                @click.prevent="extra_co_form_count++"
                style="margin-left: 20px; background-color: brown"
                class="theme-btn btn-style-one"
              >
                ADD MORE
              </button>
            </div>
          </form>
        </div> -->

        <!-- Language Section start -->

        <!-- <button class="accordionc">Languages</button>
        <div style="overflow: auto" class="panel">
          <form
            @submit.prevent="language_add"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div v-for="n in lang_form_count" :key="n" class="row">
              <h3 class="mt-2">Language info {{ n }}</h3>
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Language name</label>
                <input
                  v-if="language_data[n - 1]"
                  type="text"
                  v-model="language_data[n - 1].language"
                  placeholder=""
                  @input="clearError('LanguageName' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="language[n - 1]"
                  placeholder=""
                  @input="clearError('LanguageName' + (n - 1))"
                />

                <div
                  :id="'LanguageName' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Learned By</label>
                <select
                  v-if="language_data[n - 1]"
                  v-model="language_data[n - 1].learned_by"
                  @change="clearError('LearnedBy' + (n - 1))"
                >
                  <option value="native">Native</option>
                  <option value="official">Official</option>
                  <option value="training">Training</option>
                </select>

                <select
                  v-else
                  v-model="learned_by[n - 1]"
                  @change="clearError('LearnedBy' + (n - 1))"
                >
                  <option value="native">Native</option>
                  <option value="official">Official</option>
                  <option value="training">Training</option>
                </select>

                <div
                  :id="'LearnedBy' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Reading</label>
                <select
                  v-if="language_data[n - 1]"
                  v-model="language_data[n - 1].reading"
                  @change="clearError('Reading' + (n - 1))"
                >
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>

                <select
                  v-else
                  v-model="reading[n - 1]"
                  @change="clearError('Reading' + (n - 1))"
                >
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>

                <div :id="'Reading' + (n - 1)" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Writing</label>
                <select
                  v-if="language_data[n - 1]"
                  v-model="language_data[n - 1].writing"
                  @change="clearError('Writing' + (n - 1))"
                >
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>
                <select
                  v-else
                  v-model="writing[n - 1]"
                  @change="clearError('Writing' + (n - 1))"
                >
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>

                <div :id="'Writing' + (n - 1)" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Speaking</label>
                <select
                  v-if="language_data[n - 1]"
                  v-model="language_data[n - 1].speaking"
                  @change="clearError('Speaking' + (n - 1))"
                >
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>

                <select
                  v-else
                  v-model="speaking[n - 1]"
                  @change="clearError('Speaking' + (n - 1))"
                >
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>

                <div :id="'Speaking' + (n - 1)" class="text-danger error"></div>
              </div>
            </div>

            
            <div class="form-group col-md-12 mt-3" style="margin-bottom: 30px">
              <button type="submit" class="theme-btn btn-style-one">
                Update
              </button>
              <button
                id="next-button-1"
                @click.prevent="lang_form_count++"
                style="margin-left: 20px; background-color: brown"
                class="theme-btn btn-style-one"
              >
                ADD MORE
              </button>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Permanent Address</button>
        <div class="panel">
          <form
            @submit.prevent="permanent_address"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Street</label>
                <input
                  type="text"
                  v-model="permanent_address_data.street"
                  placeholder=""
                  @input="clearError('Street')"
                />

                <div id="Street" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>House</label>
                <input
                  type="text"
                  v-model="permanent_address_data.house"
                  placeholder=""
                  @input="clearError('House')"
                />

                <div id="House" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>County</label>
                <input
                  type="text"
                  v-model="permanent_address_data.county"
                  placeholder=""
                  @input="clearError('County')"
                />

                <div id="County" class="text-danger error"></div>
              </div>
              
              <div class="form-group col-lg-6 col-md-12">
                <label>City</label>
                <input
                  type="text"
                  v-model="permanent_address_data.city"
                  placeholder=""
                  @input="clearError('City')"
                />

                <div id="City" class="text-danger error"></div>
              </div>
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Province</label>
                <input
                  type="text"
                  v-model="permanent_address_data.province"
                  placeholder=""
                  @input="clearError('Province')"
                />

                <div id="Province" class="text-danger error"></div>
              </div>
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Country</label>
                <input
                  type="text"
                  v-model="permanent_address_data.country"
                  placeholder=""
                  @input="clearError('Country')"
                />

                <div id="Country" class="text-danger error"></div>
              </div>
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Description</label>
                <input
                  type="text"
                  v-model="permanent_address_data.description"
                  placeholder=""
                  @input="clearError('Description')"
                />

                <div id="Description" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-md-12" style="margin-bottom: 30px">
                <button type="submit" class="theme-btn btn-style-one">
                  Update
                </button>
       
              </div>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Present Address</button>
        <div class="panel">
          <form
            @submit.prevent="present_address_add"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Street</label>
                <input
                  type="text"
                  v-model="present_address_data.street"
                  placeholder=""
                  @input="clearError('street')"
                />

                <div id="street" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>House</label>
                <input
                  type="text"
                  v-model="present_address_data.house"
                  placeholder=""
                  @input="clearError('house')"
                />

                <div id="house" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>County</label>
                <input
                  type="text"
                  v-model="present_address_data.county"
                  placeholder=""
                  @input="clearError('county')"
                />

                <div id="county" class="text-danger error"></div>
              </div>
              
              <div class="form-group col-lg-6 col-md-12">
                <label>City</label>
                <input
                  type="text"
                  v-model="present_address_data.city"
                  placeholder=""
                  @input="clearError('city')"
                />

                <div id="city" class="text-danger error"></div>
              </div>
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Province</label>
                <input
                  type="text"
                  v-model="present_address_data.province"
                  placeholder=""
                  @input="clearError('province')"
                />

                <div id="province" class="text-danger error"></div>
              </div>
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Country</label>
                <input
                  type="text"
                  v-model="present_address_data.country"
                  placeholder=""
                  @input="clearError('country')"
                />

                <div id="country" class="text-danger error"></div>
              </div>
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Description</label>
                <input
                  type="text"
                  v-model="present_address_data.description"
                  placeholder=""
                  @input="clearError('description')"
                />

                <div id="description" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-md-12" style="margin-bottom: 30px">
                <button type="submit" class="theme-btn btn-style-one">
                  Update
                </button>
   
              </div>
            </div>
          </form>
        </div> -->

        <button class="accordionc">Preference</button>
        <div class="panel">
          <form
            @submit.prevent="preference"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Job Category Choice</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="job_category_choice"
                    placeholder=""
                    @input="clearError('ChoiceCategory')"
                  />

                  <div class="col-2">
                    <button
                      style="margin-left: 20px; background-color: #36a8a6"
                      class="theme-button h-100 w-100"
                      @click.prevent="add_cho_category"
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div
                  v-if="preference_data"
                  class="mt-2"
                >
                  <span
                    v-for="choice_add in preference_data.job_category_choice"
                    :key="choice_add"
                    class="mt-2 addElement"
                  >
                    {{ choice_add }}
                    <i
                      @click="removeElechoice(choice_add)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div
                  v-else
                  class="mt-2"
                >
                  <span
                    v-for="choice_add in job_category_choice_array"
                    :key="choice_add"
                    class="mt-2 addElement"
                  >
                    {{ choice_add }}
                    <i
                      @click="removeElechoice(choice_add)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div id="ChoiceCategory" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Keywords preferences</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="keyword"
                    placeholder=""
                    @input="clearError('Keyword')"
                  />

                  <div class="col-2">
                    <button
                      style="margin-left: 20px; background-color: #36a8a6"
                      class="theme-button h-100 w-100"
                      @click.prevent="keyword_add"
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div v-if="preference_data" class="mt-2">
                  <span
                    v-for="keyword in preference_data.keyword"
                    :key="keyword"
                    class="mt-2 addElement"
                  >
                    {{ keyword }}
                    <i
                      @click="removeElekeyword(keyword)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>  
                
                <div v-else class="mt-2">
                  <span
                    v-for="keyword in keyword_array"
                    :key="keyword"
                    class="mt-2 addElement"
                  >
                    {{ keyword }}
                    <i
                      @click="removeElekeyword(keyword)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>


                <div id="Keyword" class="text-danger error"></div>
              </div>

              <!-- <div class="form-group col-lg-6 col-md-12">
                <label>Keywords preferences</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="keywords"
                    placeholder=""  @input="clearError('Responsibilities')"
                  />
                 
                  <button class="button_plus" @click="add_keywords"></button>
                </div>

                <div v-html="add3" class="mt-2"></div>
              </div> -->

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Preferred city list</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="pref_city"
                    placeholder=""
                    @input="clearError('CityList')"
                  />

                  <div class="col-2">
                    <button
                      style="margin-left: 20px; background-color: #36a8a6"
                      class="theme-button h-100 w-100"
                      @click.prevent="Preferred_city_add"
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div v-if="preference_data" class="mt-2">
                  <span
                    v-for="Preferred in preference_data.pref_city"
                    :key="Preferred"
                    class="mt-2 addElement"
                  >
                    {{ Preferred }}
                    <i
                      @click="removeElePreferred(Preferred)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div v-else class="mt-2">
                  <span
                    v-for="Preferred in pref_city_array"
                    :key="Preferred"
                    class="mt-2 addElement"
                  >
                    {{ Preferred }}
                    <i
                      @click="removeElePreferred(Preferred)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div id="CityList" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Special Skills</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="special_skills"
                    placeholder=""
                    @input="clearError('SpecialSkills')"
                  />

                  <div class="col-2">
                    <button
                      style="margin-left: 20px; background-color: #36a8a6"
                      class="theme-button h-100 w-100"
                      @click.prevent="special_skill_add"
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div v-if="preference_data" class="mt-2">
                  <span
                    v-for="special in preference_data.special_skill"
                    :key="special"
                    class="mt-2 addElement"
                  >
                    {{ special }}
                    <i
                      @click="removeEleSpecial_skill(special)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div v-else class="mt-2">
                  <span
                    v-for="special in special_skill_array"
                    :key="special"
                    class="mt-2 addElement"
                  >
                    {{ special }}
                    <i
                      @click="removeEleSpecial_skill(special)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div id="SpecialSkills" class="text-danger error"></div>
              </div>

              
              <div
                style="padding-bottom: 50px"
                class="form-group col-lg-6 col-md-12"
              >
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <!-- <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button> -->
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Reference</button>
        <div style="overflow: auto" class="panel">
          <form
            @submit.prevent="reference"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div v-for="n in ref_form_count" :key="n" class="row">
              <h3>Reference {{ n }}</h3>
              
              <div class="form-group col-lg-6 col-md-12">
                <label>Name</label>
                <input
                  v-if="references_data[n - 1]"
                  type="text"
                  v-model="references_data[n - 1].name"
                  placeholder=""
                  @input="clearError('Name' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="name[n - 1]"
                  placeholder=""
                  @input="clearError('Name' + (n - 1))"
                />

                <div :id="'Name' + (n - 1)" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Designation</label>
                <input
                  v-if="references_data[n - 1]"
                  type="text"
                  v-model="references_data[n - 1].designation"
                  placeholder=""
                  @input="clearError('designation' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="ref_designation[n - 1]"
                  placeholder=""
                  @input="clearError('designation' + (n - 1))"
                />

                <div
                  :id="'designation' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Organization</label>

                <input
                  v-if="references_data[n - 1]"
                  type="text"
                  v-model="references_data[n - 1].organization"
                  placeholder=""
                  @input="clearError('Organization' + (n - 1))"
                />
                <input
                  v-else
                  type="text"
                  v-model="organization[n - 1]"
                  placeholder=""
                  @input="clearError('Organization' + (n - 1))"
                />

                <div
                  :id="'Organization' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Email</label>
                <input
                  v-if="references_data[n - 1]"
                  type="email"
                  v-model="references_data[n - 1].email"
                  placeholder=""
                  @input="clearError('Email' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="email[n - 1]"
                  placeholder=""
                  @input="clearError('Email' + (n - 1))"
                />

                <div :id="'Email' + (n - 1)" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Relation</label>
                <input
                  v-if="references_data[n - 1]"
                  type="text"
                  v-model="references_data[n - 1].relation"
                  placeholder=""
                  @input="clearError('Relation' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="relation[n - 1]"
                  placeholder=""
                  @input="clearError('Relation' + (n - 1))"
                />

                <div :id="'Relation' + (n - 1)" class="text-danger error"></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label>Contact number</label>
                <input
                  v-if="references_data[n - 1]"
                  type="number"
                  v-model="references_data[n - 1].cell_no"
                  placeholder=""
                  @input="clearError('ContactNumber' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="cell_no[n - 1]"
                  placeholder=""
                  @input="clearError('ContactNumber' + (n - 1))"
                />

                <div
                  :id="'ContactNumber' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>

              
              <div class="form-group col-lg-6 col-md-12">
                <label> Full address</label>
                <input
                  v-if="references_data[n - 1]"
                  type="text"
                  v-model="references_data[n - 1].address"
                  placeholder=""
                  @input="clearError('FullAddress' + (n - 1))"
                />

                <input
                  v-else
                  type="text"
                  v-model="address[n - 1]"
                  placeholder=""
                  @input="clearError('FullAddress' + (n - 1))"
                />

                <div
                  :id="'FullAddress' + (n - 1)"
                  class="text-danger error"
                ></div>
              </div>
            </div>
            
            <div class="form-group col-md-12 mt-3" style="margin-bottom: 30px">
              <button type="submit" class="theme-btn btn-style-one">
                Save
              </button>
              <button
                id="next-button-1"
                @click.prevent="ref_form_count++"
                style="margin-left: 20px; background-color: brown"
                class="theme-btn btn-style-one"
              >
                Add More
              </button>
            </div>
          </form>
        </div>

        <button class="accordionc">Update your Resume</button>
        <div class="panel">
          <!-- Dashboard -->
          <section class="user-dashboard">
            <div class="dashboard-outer">
              <div v-if="resume">
                Your have a resume Already . You can update it
                <br>
                *Your resume must contain latest CV, latest Passport and BRP card. Example page 1 CV, page 2 passport, page 3 BRP
              </div>

              <div class="row">
                <div class="col-lg-4">
                  <input
                    @change="handleResumeUpload($event)"
                    class="form-control"
                    type="file"
                  />

                  <span
                    @click="addresume"
                    class="mt-3 mb-3 theme-btn btn-style-one"
                    >Upload Resume</span
                  >

                  <!--               
                  <div class="cv-manager-widget ls-widget">
                    <div class="widget-content">
                      <div class="uploading-resume">
                        <div class="uploadButton">
                          <input
                            class="uploadButton-input"
                            type="file"
                            @change="handleResumeUpload($event)"
                            accept="image/*"
                            id="upload"
                          />

                          <label class="cv-uploadButton" for="upload">
                            <span class="title"
                              >Drop or click here to select file</span
                            >
                            <span class="text"
                              >To upload file size is (Max 5Mb) and allowed file
                              types are (.doc, .docx, .pdf)</span
                            >
                     

                            <span class="uploadButton-file-name font130"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>

                <div class="col-lg-4 p-3"></div>
              </div>
            </div>
          </section>
          <!-- End Dashboard -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "CandidateProfileView",
  data() {
    return {
      topics: [],
      choice_category: [],
      keywords: [],
      Preferred_city: [],
      special_skill: [],
      file: "",
      photo_file: "",
      // Form count
      edu_form_count: 1,
      cer_form_count: 1,
      exp_form_count: 1,
      exp_new_array_count: 1,

      skill_form_count: 1,
      tra_form_count: 1,

      tra_new_array_count: 1,
      extra_co_form_count: 1,
      lang_form_count: 1,
      ref_form_count: 1,

      //

      photograph_url: "",

      uuid: "",

      add: "",
      add1: "",
      add2: "",
      add3: "",
      add4: "",
      add5: "",

      // for Education

      edu_level: [],
      degree_title: [],
      major: [],
      institute: [],
      result: [],
      marks: [],
      passing_year: [],
      duration: [],
      achievement: [],
      educations: [],

      // Education end

      // for cirtification

      cer_name: [],
      location: [],
      start_date: [],
      end_date: [],

      // Cirtification end

      // for Experience

      area_expertise: [],
      org_name: [],
      org_business: [],
      designation: [],
      department: [],
      from_date: [],
      to_date: [],
      curr_working: [],
      responsibilities: [],
      org_location: [],
      expertises: [[]],
      experty: [],
      selectedIndex: 0,
      explength: "",

      // for Experince end

      //for skill
      skill_name: [],
      acquired_from: [],
      description: [],

      //for skill end

      //for traning;

      topics_covered: [],
      tra_title: [],
      tra_type: [],
      tra_duration: [],
      tra_location: [],
      tra_year: [],
      tra_institute: [],
      topics_covered_new: [],
      topics_covered_new_all: [[]],
      tra_length: [],
      //for traning end

      //Extra co start

      activity: [],

      //extra co end

      language: [],
      learned_by: [],
      reading: [],
      writing: [],
      listening: [],
      speaking: [],

      //Referance

      name: [],
      ref_designation: [],
      organization: [],
      email: [],
      relation: [],
      cell_no: [],
      address: [],

      //Referance end

      //Resume

      resume: {},

      resume_file: "",

      // Get Profile Info
      basic_info_data: "",
      per_address: {},
      photograph_data: {},
      experiences_data: [],
      educations_data: [],
      present_address_data: {},
      permanent_address_data: {},
      career_info_data: {},
      language_data: [],
      skill_data: {},
      references_data: [],
      trainings_data: [],
      preference_data: {},
      certification_data: [],
      extracurricular_data: [],
      all_data: {},

      job_category_choice_array:[],
      keyword_array:[],
      pref_city_array:[],
      special_skill_array:[],

      // End Get Profile Info
    };
  },

  methods: {
    removeEleexpertise(ad, index) {
      this.experiences_data[index].area_expertise.splice(
        this.experiences_data[index].area_expertise.indexOf(ad),
        1
      );
    },

    add_expertises(index) {
      if (
        this.area_expertise[index] != "" ||
        this.area_expertise[index] != null
      ) {
        if (this.experiences_data[index].area_expertise.length < 10) {
          this.experiences_data[index].area_expertise.push(
            this.area_expertise[index]
          );

          this.area_expertise[index] = null;
        } else {
          alert("expertise Max length will be 10");
        }
      } else {
        alert("expertise is empty");
      }
    },

    add_expertises_new(index, new_index) {
      if (this.experty[index] == "" || this.experty[index] == null) {
        alert("Expertise is empty");
      } else {
        this.expertises[new_index].push(this.experty[index]);

        this.experty[index] == "";
      }
    },

    add_topics_new(index, new_index) {
      if (
        this.topics_covered_new[index] == "" ||
        this.topics_covered_new[index] == null
      ) {
        alert("Expertise is empty");
      } else {
        this.topics_covered_new_all[new_index].push(
          this.topics_covered_new[index]
        );

        this.topics_covered_new[index] == "";
      }
    },

    removeEleexpertiseNew(ad, index) {
      this.expertises[index].splice(this.expertises[index].indexOf(ad), 1);
    },

    exp_add_btn_event() {
      this.exp_form_count++;
      this.exp_new_array_count++;
      this.expertises.push([]);
    },
    tra_more_btn_event() {
      this.tra_form_count++;
      this.tra_new_array_count++;
      this.topics_covered_new_all.push([]);
    },

    removeEletopic(ad, index) {
      this.trainings_data[index].topics_covered.splice(
        this.trainings_data[index].topics_covered.indexOf(ad),
        1
      );
    },
    removeEletopicNew(ad, index) {
      this.topics_covered_new_all[index].splice(
        this.topics_covered_new_all[index].indexOf(ad),
        1
      );
    },

    add_topics(index) {
      if (
        this.topics_covered[index] == "" ||
        this.topics_covered[index] == null
      ) {
        alert("expertise is empty");
      } else {
        if (this.trainings_data[index].topics_covered.length < 10) {
          this.trainings_data[index].topics_covered.push(
            this.topics_covered[index]
          );

          this.topics_covered[index] = null;
        } else {
          alert("expertise Max length will be 10");
        }
      }
    },

    removeElechoice(ad) {
      if(this.preference_data){
        this.preference_data.job_category_choice.splice(
        this.preference_data.job_category_choice.indexOf(ad),
        1
      );

      }else{
        this.job_category_choice_array.splice(
        this.job_category_choice_array.indexOf(ad),
        1
      );


      }
     
    },

    // Preference section

    add_cho_category() {
      if (this.job_category_choice == "" || this.job_category_choice == null) {
        alert("Job category choice is empty");
      } else {
        if (this.choice_category.length < 10) {

          if(this.preference_data){
            this.preference_data.job_category_choice.push(
            this.job_category_choice

            
          );
          this.job_category_choice = null;


          }else{
            this.job_category_choice_array.push(
            this.job_category_choice

            
          );

            



          }
        

     
        } else {
          alert("Job category choice Max length will be 10");
        }
      }
    },

    removeElekeyword(ad) {

      if(this.preference_data){
        this.preference_data.keyword.splice(
        this.preference_data.keyword.indexOf(ad),
        1
      );


      }else{

        this.keyword_array.splice(
        this.keyword_array.indexOf(ad),
        1
      );
      }
   
    },

    keyword_add() {
      if (this.keyword == "" || this.keyword == null) {
        alert("Keyword is empty");
      } else {
        if(this.preference_data){

          if (this.preference_data.keyword.length < 10) {
          this.preference_data.keyword.push(this.keyword);

          this.keyword = null;
        } else {
          alert("Keyword Max length will be 10");
        }

        }else{
          
          if (this.keyword_array.length < 10) {
          this.keyword_array.push(this.keyword);

          this.keyword = null;
        } else {
          alert("Keyword Max length will be 10");
        }




        }
       
      }
    },

    removeElePreferred(ad) {
      if(this.preference_data){
        this.preference_data.pref_city.splice(
        this.preference_data.pref_city.indexOf(ad),
        1
      );
      }else{
        this.pref_city_array.splice(
        this.pref_city_array.indexOf(ad),
        1
      );

      }
     
    },

    Preferred_city_add() {
      if (this.pref_city == "" || this.pref_city == null) {
        alert("City is empty");
      } else {

        if(this.preference_data){
          if (this.preference_data.pref_city.length < 10) {
          this.preference_data.pref_city.push(this.pref_city);

          this.pref_city = null;
        } else {
          alert("City Max length will be 10");
        }


        }else{

          if (this.pref_city_array.length < 10) {
          this.pref_city_array.push(this.pref_city);

          this.pref_city = null;
        } else {
          alert("City Max length will be 10");
        }



        }
      
      }
    },

    removeEleSpecial_skill(ad) {
      if(this.preference_data){
        this.preference_data.special_skill.splice(
        this.preference_data.special_skill.indexOf(ad),
        1
      );
      }else{
        this.special_skill_array.splice(
        this.special_skill_array.indexOf(ad),
        1
      );


      }
   
    },

    special_skill_add() {
      if (this.special_skills == "" || this.special_skills == null) {
        alert("Special skill is empty");
      } else {
        if(this.preference_data){
          if (this.preference_data.special_skill.length < 10) {
          this.preference_data.special_skill.push(this.special_skills);
          this.special_skills = null;
        } else {
          alert("Special skill Max length will be 10");
        }
          
        }else{
          if (this.special_skill_array.length < 10) {
          this.special_skill_array.push(this.special_skills);
          this.special_skills = null;
        } else {
          alert("Special skill Max length will be 10");
        }
          
        }
     
      }
    },

    nullcheck(element) {
      $("#" + element).html("This filed is required");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    addcheck(element) {
      $("#" + element).html("Add" + " " + element);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    lengthcheck(element, value) {
      $("#" + element).html(element + " length will be max " + value);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    filecheck(element) {
      $("#" + element).html("Upload a image");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    clearError(element) {
      $("#" + element).html("");
    },

    // handleFileUpload(event) {
    //   this.background = event.target.files[0];
    //   this.urls=URL.createObjectURL(this.background);
    //   this.clearError('image')

    // },

    add_basic_info() {

      if(this.basic_info_data){

        if (
        this.basic_info_data.first_name == null ||
        this.basic_info_data.first_name == ""
      ) {
        this.nullcheck("FirstName");
      } else if (
        this.basic_info_data.last_name == null ||
        this.basic_info_data.last_name == ""
      ) {
        this.nullcheck("LastName");
      } else if (
        this.basic_info_data.dob == null ||
        this.basic_info_data.dob == ""
      ) {
        this.nullcheck("DateofBirth");
      } else if (
        this.basic_info_data.religion == null ||
        this.basic_info_data.religion == ""
      ) {
        this.nullcheck("Religion");
      } else if (
        this.basic_info_data.ethnicity == null ||
        this.basic_info_data.ethnicity == ""
      ) {
        this.nullcheck("Ethnicity");
      } else if (
        this.basic_info_data.gender == null ||
        this.basic_info_data.gender == ""
      ) {
        this.nullcheck("Gender");
      } else if (
        this.basic_info_data.primary_contact_no == null ||
        this.basic_info_data.primary_contact_no == ""
      ) {
        this.nullcheck("PrimaryNumber");
      } else if (
        this.basic_info_data.nationality == null ||
        this.basic_info_data.nationality == ""
      ) {
        this.nullcheck("Nationality");
      } 
       else {
        axios
          .patch("applicant/basic_info/" + this.basic_info_data.uuid + "/", {
            first_name: this.basic_info_data.first_name,
            last_name: this.basic_info_data.last_name,
            dob: this.basic_info_data.dob,
            religion: this.basic_info_data.religion,
            ethnicity: this.basic_info_data.ethnicity,
            gender: this.basic_info_data.gender,
            primary_contact_no: this.basic_info_data.primary_contact_no,
            nationality: this.basic_info_data.nationality,
            fathers_name: this.basic_info_data.fathers_name,
            mothers_name: this.basic_info_data.mothers_name,
            secondary_contact_no: this.basic_info_data.secondary_contact_no,
            emergency_cont_no: this.basic_info_data.emergency_cont_no,
            secondary_mail: this.basic_info_data.secondary_mail,
            nid: this.basic_info_data.nid,
            passport_no: this.basic_info_data.passport_no,
            blood_group: this.basic_info_data.blood_group,
            weight: this.basic_info_data.weight,
            height: this.basic_info_data.height,
          })
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$emit("get_message", "Basic info Added");
            }
          })
          .catch((error) => {
            console.log(error.response);
            this.$emit("get_message", "Already Added");

          });
      }




      }else{



        if (
        this.first_name == null ||
        this.first_name == ""
      ) {
        this.nullcheck("FirstName");
      } else if (
        this.last_name == null ||
        this.last_name == ""
      ) {
        this.nullcheck("LastName");
      } else if (
        this.dob == null ||
        this.dob == ""
      ) {
        this.nullcheck("DateofBirth");
      } else if (
        this.religion == null ||
        this.religion == ""
      ) {
        this.nullcheck("Religion");
      } else if (
        this.ethnicity == null ||
        this.ethnicity == ""
      ) {
        this.nullcheck("Ethnicity");
      } else if (
        this.gender == null ||
        this.gender == ""
      ) {
        this.nullcheck("Gender");
      } else if (
        this.primary_contact_no == null ||
        this.primary_contact_no == ""
      ) {
        this.nullcheck("PrimaryNumber");
      } else if (
        this.nationality == null ||
        this.nationality == ""
      ) {
        this.nullcheck("Nationality");
      } 
       else {
        axios
          .post("applicant/basic_info/", {
            first_name: this.first_name,
            last_name: this.last_name,
            dob: this.dob,
            religion: this.religion,
            ethnicity: this.ethnicity,
            gender: this.gender,
            primary_contact_no: this.primary_contact_no,
            nationality: this.nationality,
            fathers_name: this.fathers_name,
            mothers_name: this.mothers_name,
            secondary_contact_no: this.secondary_contact_no,
            emergency_cont_no: this.emergency_cont_no,
            secondary_mail: this.secondary_mail,
            nid: this.nid,
            passport_no: this.passport_no,
            blood_group: this.blood_group,
            weight: this.weight,
            height: this.height,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
             alert("Basic info added");

           
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }





      }
     
    },

    career_info() {
      if (
        this.career_info_data.objective == null ||
        this.career_info_data.objective == ""
      ) {
        this.nullcheck("Objective");
      } else if (
        this.career_info_data.job_level == null ||
        this.career_info_data.job_level == ""
      ) {
        this.nullcheck("JobLevel");
      } else if (
        this.career_info_data.job_nature == null ||
        this.career_info_data.job_nature == ""
      ) {
        this.nullcheck("JobNature");
      } else if (
        this.career_info_data.present_salary == null ||
        this.career_info_data.present_salary == ""
      ) {
        this.nullcheck("PresentSalary");
      } else if (
        this.career_info_data.present_salary_type == null ||
        this.career_info_data.present_salary_type == ""
      ) {
        this.nullcheck("SalaryType");
      } else if (
        this.career_info_data.expected_salary == null ||
        this.career_info_data.expected_salary == ""
      ) {
        this.nullcheck("ExpectedSalary");
      } else if (
        this.career_info_data.expected_salary_type == null ||
        this.career_info_data.expected_salary_type == ""
      ) {
        this.nullcheck("ExpectedSalaryType");
      } else {
        axios
          .patch("applicant/career_info/" + this.career_info_data.uuid + "/", {
            objective: this.career_info_data.objective,
            job_level: this.career_info_data.job_level,
            job_nature: this.career_info_data.job_nature,
            present_salary: this.career_info_data.present_salary,
            present_salary_type: this.career_info_data.present_salary_type,
            expected_salary: this.career_info_data.expected_salary,
            expected_salary_type: this.career_info_data.expected_salary_type,
          })
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              alert("Career info updated");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    certification() {
      for (var i = 0; i < this.cer_form_count; i++) {
        if (this.certification_data[i]) {
          if (
            this.certification_data[i].cer_name == null ||
            this.certification_data[i].cer_name == ""
          ) {
            this.nullcheck("CertificationName" + i);
          } else if (
            this.certification_data[i].location == null ||
            this.certification_data[i].location == ""
          ) {
            this.nullcheck("Institution" + i);
          } else if (
            this.certification_data[i].start_date == null ||
            this.certification_data[i].start_date == ""
          ) {
            this.nullcheck("StartDate" + i);
          } else if (
            this.certification_data[i].end_date == null ||
            this.certification_data[i].end_date == ""
          ) {
            this.nullcheck("EndDate" + i);
          } else {
            axios
              .patch(
                "applicant/certification/" +
                  this.certification_data[i].uuid +
                  "/",
                {
                  cer_name: this.certification_data[i].cer_name,
                  location: this.certification_data[i].location,
                  start_date: this.certification_data[i].start_date,
                  end_date: this.certification_data[i].end_date,
                }
              )
              .then((res) => {
                if (res.status == 200) {
                  console.log(res.data);
                  alert("Certification updated");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        } else {
          if (this.cer_name[i] == null || this.cer_name[i] == "") {
            this.nullcheck("CertificationName" + i);
          } else if (this.location[i] == null || this.location[i] == "") {
            this.nullcheck("Institution" + i);
          } else if (this.start_date[i] == null || this.start_date[i] == "") {
            this.nullcheck("StartDate" + i);
          } else if (this.end_date[i] == null || this.end_date[i] == "") {
            this.nullcheck("EndDate" + i);
          } else {
            axios
              .post("applicant/certification/", {
                cer_name: this.cer_name[i],
                location: this.location[i],
                start_date: this.start_date[i],
                end_date: this.end_date[i],
              })
              .then((res) => {
                if (res.status == 200) {
                  console.log(res.data);
                  alert("New Certification added");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        }
      }
    },

    education() {
      // for (var key of Object.keys(this.values)) {
      //         alert(key + " -> " + this.values[key])
      //     }

      for (var i = 0; i < this.edu_form_count; i++) {
        if (this.educations_data[i]) {
          if (
            this.educations_data[i].edu_level == null ||
            this.educations_data[i].edu_level == ""
          ) {
            this.nullcheck("EducationLevel" + i);
          } else if (
            this.educations_data[i].degree_title == null ||
            this.educations_data[i].degree_title == ""
          ) {
            this.nullcheck("Degree" + i);
          } else if (
            this.educations_data[i].major == null ||
            this.educations_data[i].major == ""
          ) {
            this.nullcheck("Major" + i);
          } else if (
            this.educations_data[i].institute == null ||
            this.educations_data[i].institute == ""
          ) {
            this.nullcheck("Institute" + i);
          } else if (
            this.educations_data[i].result == null ||
            this.educations_data[i].result == ""
          ) {
            this.nullcheck("Grade" + i);
          } else if (
            this.educations_data[i].marks == null ||
            this.educations_data[i].marks == ""
          ) {
            this.nullcheck("Marks" + i);
          } else if (
            this.educations_data[i].passing_year == null ||
            this.educations_data[i].passing_year == ""
          ) {
            this.nullcheck("PassingYear" + i);
          } else if (
            this.educations_data[i].duration == null ||
            this.educations_data[i].duration == ""
          ) {
            this.nullcheck("Duration" + i);
          } else if (
            this.educations_data[i].achievement == null ||
            this.educations_data[i].achievement == ""
          ) {
            this.nullcheck("Achievement" + i);
          } else {
            // var education={
            //         edu_level: this.edu_level[i],
            //         degree_title: this.degree_title[i],
            //         major: this.major[i],
            //         institute: this.institute[i],
            //         result: this.result[i],
            //         marks: this.marks[i],
            //         passing_year: this.passing_year[i],
            //         duration: this.duration[i],
            //         achievement: this.achievement[i],

            // }

            var education = {
              edu_level: this.educations_data[i].edu_level,
              degree_title: this.educations_data[i].degree_title,
              major: this.educations_data[i].major,
              institute: this.educations_data[i].institute,
              result: this.educations_data[i].result,
              marks: this.educations_data[i].marks,
              passing_year: this.educations_data[i].passing_year,
              duration: this.educations_data[i].duration,
              achievement: this.educations_data[i].achievement,
            };

            axios
              .patch(
                "applicant/education/" + this.educations_data[i].uuid + "/",
                education
              )
              .then((res) => {
                if (res.status == 200) {
                  console.log(res.data);

                  if (i == this.edu_form_count) {
                    alert("Education updated");
                  }
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        } else {
          if (this.edu_level[i] == null || this.edu_level[i] == "") {
            this.nullcheck("EducationLevel" + i);
          } else if (
            this.degree_title[i] == null ||
            this.degree_title[i] == ""
          ) {
            this.nullcheck("Degree" + i);
          } else if (this.major[i] == null || this.major[i] == "") {
            this.nullcheck("Major" + i);
          } else if (this.institute[i] == null || this.institute[i] == "") {
            this.nullcheck("Institute" + i);
          } else if (this.result[i] == null || this.result[i] == "") {
            this.nullcheck("Grade" + i);
          } else if (this.marks[i] == null || this.marks[i] == "") {
            this.nullcheck("Marks" + i);
          } else if (
            this.passing_year[i] == null ||
            this.passing_year[i] == ""
          ) {
            this.nullcheck("PassingYear" + i);
          } else if (this.duration[i] == null || this.duration[i] == "") {
            this.nullcheck("Duration" + i);
          } else if (this.achievement[i] == null || this.achievement[i] == "") {
            this.nullcheck("Achievement" + i);
          } else {
            var education_added = {
              edu_level: this.edu_level[i],
              degree_title: this.degree_title[i],
              major: this.major[i],
              institute: this.institute[i],
              result: this.result[i],
              marks: this.marks[i],
              passing_year: this.passing_year[i],
              duration: this.duration[i],
              achievement: this.achievement[i],
            };

            axios
              .post("applicant/education/", education_added)
              .then((res) => {
                if (res.status == 201) {
                  console.log(res.data);

                  if (i == this.edu_form_count) {
                    alert("New Education added");
                  }
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        }
      }
    },

    experience() {
      for (var i = 0; i < this.exp_form_count; i++) {
        if (this.experiences_data[i]) {
          if (
            this.experiences_data[i].org_name == null ||
            this.experiences_data[i].org_name == ""
          ) {
            this.nullcheck("OrganizationName" + i);
          } else if (
            this.experiences_data[i].org_business == null ||
            this.experiences_data[i].org_business == ""
          ) {
            this.nullcheck("TypeOfOrganization" + i);
          } else if (
            this.experiences_data[i].designation == null ||
            this.experiences_data[i].designation == ""
          ) {
            this.nullcheck("Designation" + i);
          } else if (
            this.experiences_data[i].department == null ||
            this.experiences_data[i].department == ""
          ) {
            this.nullcheck("Department" + i);
          } else if (
            this.experiences_data[i].from_date == null ||
            this.experiences_data[i].from_date == ""
          ) {
            this.nullcheck("StartingDate" + i);
          } else if (
            this.experiences_data[i].to_date == null ||
            this.experiences_data[i].to_date == ""
          ) {
            this.nullcheck("EndingDate" + i);
          } else if (this.experiences_data[i].curr_working == null) {
            this.nullcheck("CurrentlyWorking" + i);
          } else if (
            this.experiences_data[i].responsibilities == null ||
            this.experiences_data[i].responsibilities == ""
          ) {
            this.nullcheck("Responsibilities" + i);
          } else if (!this.experiences_data[i].area_expertise.length > 0) {
            this.nullcheck("AreaOfExpertise" + i);
          } else if (
            this.experiences_data[i].org_location == null ||
            this.experiences_data[i].org_location == ""
          ) {
            this.nullcheck("Location" + i);
          } else {
            axios
              .patch(
                "applicant/experience/" + this.experiences_data[i].uuid + "/",
                {
                  org_name: this.experiences_data[i].org_name,
                  org_business: this.experiences_data[i].org_business,
                  designation: this.experiences_data[i].designation,
                  department: this.experiences_data[i].department,
                  from_date: this.experiences_data[i].from_date,
                  to_date: this.experiences_data[i].to_date,
                  curr_working: this.experiences_data[i].curr_working,
                  responsibilities: this.experiences_data[i].responsibilities,
                  area_expertise: this.experiences_data[i].area_expertise,
                  org_location: this.experiences_data[i].org_location,
                }
              )
              .then((res) => {
                if (res.status == 200) {
                  console.log(res.data);
                  alert("Profile updated");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        } else {
          if (this.org_name[i] == null || this.org_name[i] == "") {
            this.nullcheck("OrganizationName" + i);
          } else if (
            this.org_business[i] == null ||
            this.org_business[i] == ""
          ) {
            this.nullcheck("TypeOfOrganization" + i);
          } else if (this.designation[i] == null || this.designation[i] == "") {
            this.nullcheck("Designation" + i);
          } else if (this.department[i] == null || this.department[i] == "") {
            this.nullcheck("Department" + i);
          } else if (this.from_date[i] == null || this.from_date[i] == "") {
            this.nullcheck("StartingDate" + i);
          } else if (this.to_date[i] == null || this.to_date[i] == "") {
            this.nullcheck("EndingDate" + i);
          } else if (this.curr_working[i] == null) {
            this.nullcheck("CurrentlyWorking" + i);
          } else if (
            this.responsibilities[i] == null ||
            this.responsibilities[i] == ""
          ) {
            this.nullcheck("Responsibilities" + i);
          } else if (!this.expertises[i + 1 - this.explength].length > 0) {
            this.nullcheck("AreaOfExpertise" + i);
          } else if (
            this.org_location[i] == null ||
            this.org_location[i] == ""
          ) {
            this.nullcheck("Location" + i);
          } else {
            axios
              .post("applicant/experience/", {
                org_name: this.org_name[i],
                org_business: this.org_business[i],
                designation: this.designation[i],
                department: this.department[i],
                from_date: this.from_date[i],
                to_date: this.to_date[i],
                curr_working: this.curr_working[i],
                responsibilities: this.responsibilities[i],
                area_expertise: this.expertises[i + 1 - this.explength],
                org_location: this.org_location[i],
              })
              .then((res) => {
                if (res.status == 200) {
                  console.log(res.data);
                  alert("New Experience added");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        }
      }
    },

    skills() {
      for (var i = 0; i < this.skill_form_count; i++) {
        if (this.skill_data[i]) {
          if (
            this.skill_data[i].skill_name == null ||
            this.skill_data[i].skill_name == ""
          ) {
            this.nullcheck("SkillName" + i);
          } else if (this.skill_data[i].acquired_from == null) {
            this.nullcheck("AcquiredFrom" + i);
          } else if (
            this.skill_data[i].description == null ||
            this.skill_data[i].description == ""
          ) {
            this.nullcheck("Description" + i);
          } else {
            axios
              .patch("applicant/skill/" + this.skill_data[i].uuid + "/", {
                skill_name: this.skill_data[i].skill_name,
                acquired_from: this.skill_data[i].acquired_from,
                description: this.skill_data[i].description,
              })
              .then((res) => {
                if (res.status == 200) {
                  console.log(res.data);
                  alert("Skill Updated");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        } else {
          if (this.skill_name[i] == null || this.skill_name[i] == "") {
            this.nullcheck("SkillName" + i);
          } else if (this.acquired_from[i] == null) {
            this.nullcheck("AcquiredFrom" + i);
          } else if (this.description[i] == null || this.description[i] == "") {
            this.nullcheck("Description" + i);
          } else {
            axios
              .post("applicant/skill/", {
                skill_name: this.skill_name[i],
                acquired_from: this.acquired_from[i],
                description: this.description[i],
              })
              .then((res) => {
                if (res.status == 201) {
                  console.log(res.data);
                  alert("New Skill Added");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        }
      }
    },

    training() {
      for (var i = 0; i < this.tra_form_count; i++) {
        if (this.trainings_data[i]) {
          if (
            this.trainings_data[i].title == null ||
            this.trainings_data[i].title == ""
          ) {
            this.nullcheck("Title" + i);
          } else if (
            this.trainings_data[i].type == null ||
            this.trainings_data[i].type == ""
          ) {
            this.nullcheck("Type" + i);
          }

          // else if(this.topics_covered == null || this.topics_covered  == ""){
          //   this.nullcheck("TopicCovered");
          // }
          else if (!this.trainings_data[i].topics_covered.length > 0) {
            this.nullcheck("TopicCovered" + i);
          } else if (
            this.trainings_data[i].duration == null ||
            this.trainings_data[i].duration == ""
          ) {
            this.nullcheck("duration" + i);
          } else if (
            this.trainings_data[i].year == null ||
            this.trainings_data[i].year == ""
          ) {
            this.nullcheck("Year" + i);
          } else if (
            this.trainings_data[i].institute == null ||
            this.trainings_data[i].institute == ""
          ) {
            this.nullcheck("institute" + i);
          } else if (
            this.trainings_data[i].location == null ||
            this.trainings_data[i].location == ""
          ) {
            this.nullcheck("location" + i);
          } else {
            axios
              .patch(
                "applicant/training/" + this.trainings_data[i].uuid + "/",
                {
                  title: this.trainings_data[i].title,
                  type: this.trainings_data[i].type,
                  topics_covered: this.trainings_data[i].topics_covered,
                  duration: this.trainings_data[i].duration,
                  year: this.trainings_data[i].year,
                  institute: this.trainings_data[i].institute,
                  location: this.trainings_data[i].location,
                }
              )
              .then((res) => {
                if (res.status == 200) {
                  console.log(res.data);
                  alert("Traning Updated");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        } else {
          if (this.tra_title[i] == null || this.tra_title[i] == "") {
            this.nullcheck("Title" + i);
          } else if (this.tra_type[i] == null || this.tra_type[i] == "") {
            this.nullcheck("Type" + i);
          }

          // else if(this.topics_covered == null || this.topics_covered  == ""){
          //   this.nullcheck("TopicCovered");
          // }
          else if (
            !this.topics_covered_new_all[i + 1 - this.tra_length].length > 0
          ) {
            this.nullcheck("TopicCovered" + i);
          } else if (
            this.tra_duration[i] == null ||
            this.tra_duration[i] == ""
          ) {
            this.nullcheck("duration" + i);
          } else if (this.tra_year[i] == null || this.tra_year[i] == "") {
            this.nullcheck("Year" + i);
          } else if (
            this.tra_institute[i] == null ||
            this.tra_institute[i] == ""
          ) {
            this.nullcheck("institute" + i);
          } else if (
            this.tra_location[i] == null ||
            this.tra_location[i] == ""
          ) {
            this.nullcheck("location" + i);
          } else {
            axios
              .post("applicant/training/", {
                title: this.tra_title[i],
                type: this.tra_type[i],
                topics_covered:
                  this.topics_covered_new_all[i + 1 - this.tra_length],
                duration: this.tra_duration[i],
                year: this.tra_year[i],
                institute: this.tra_institute[i],
                location: this.tra_location[i],
              })
              .then((res) => {
                if (res.status == 201) {
                  console.log(res.data);
                  alert("New Traning added");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        }
      }
    },
    // Photograph

    select_photo(event) {
      this.photo_file = event.target.files[0];

      $(".photo_upload_btn").css("display", "block");

      this.photograph_url = URL.createObjectURL(this.photo_file);
    },

    photograph() {
      if (this.photo_file != "") {
        let form_data = new FormData();

        form_data.append("photograph", this.photo_file);

        axios
          .post(
            "/applicant/photograph/",

            form_data,

            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.status == 201) {
              this.$emit("get_message", "Photograph Added");
            }
          })
          .catch((error) => {
            console.log(error.response);
            this.$emit("get_message", "Already Added");

          });
      }
    },

    extracurricular() {
      for (var i = 0; i < this.extra_co_form_count; i++) {
        if (this.extracurricular_data[i]) {
          if (
            this.extracurricular_data[i].activity == null ||
            this.extracurricular_data[i].activity == ""
          ) {
            this.nullcheck("Activities" + i);
          } else {
            axios
              .patch(
                "applicant/extracurricular/" +
                  this.extracurricular_data[i].uuid +
                  "/",
                {
                  activity: this.extracurricular_data[i].activity,
                }
              )
              .then((res) => {
                if (res.status == 200) {
                  console.log(res.data);
                  alert("Extracurricular Updated");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        } else {
          if (this.activity[i] == null || this.activity[i] == "") {
            this.nullcheck("Activities" + i);
          } else {
            axios
              .post("applicant/extracurricular/", {
                activity: this.activity[i],
              })
              .then((res) => {
                if (res.status == 201) {
                  console.log(res.data);
                  alert("Extracurricular Added");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        }
      }
    },

    language_add() {
      for (var i = 0; i < this.lang_form_count; i++) {
        if (this.language_data[i]) {
          if (
            this.language_data[i].language == null ||
            this.language_data[i].language == ""
          ) {
            this.nullcheck("LanguageName" + i);
          } else if (
            this.language_data[i].learned_by == null ||
            this.language_data[i].learned_by == ""
          ) {
            this.nullcheck("LearnedBy" + i);
          } else if (
            this.language_data[i].reading == null ||
            this.language_data[i].reading == ""
          ) {
            this.nullcheck("Reading" + i);
          } else if (
            this.language_data[i].writing == null ||
            this.language_data[i].writing == ""
          ) {
            this.nullcheck("Writing" + i);
          } else if (
            this.language_data[i].speaking == null ||
            this.language_data[i].speaking == ""
          ) {
            this.nullcheck("Speaking" + i);
          } else {
            axios
              .patch("applicant/language/" + this.language_data[i].uuid + "/", {
                language: this.language_data[i].language,
                learned_by: this.language_data[i].learned_by,
                reading: this.language_data[i].reading,
                writing: this.language_data[i].writing,
                speaking: this.language_data[i].speaking,
              })
              .then((res) => {
                if (res.status == 200) {
                  console.log(res.data);
                  alert("Language Updated");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        } else {
          if (this.language[i] == null || this.language[i] == "") {
            this.nullcheck("LanguageName" + i);
          } else if (this.learned_by[i] == null || this.learned_by[i] == "") {
            this.nullcheck("LearnedBy" + i);
          } else if (this.reading[i] == null || this.reading[i] == "") {
            this.nullcheck("Reading" + i);
          } else if (this.writing[i] == null || this.writing[i] == "") {
            this.nullcheck("Writing" + i);
          } else if (this.speaking[i] == null || this.speaking[i] == "") {
            this.nullcheck("Speaking" + i);
          } else {
            axios
              .post("applicant/language/", {
                language: this.language[i],
                learned_by: this.learned_by[i],
                reading: this.reading[i],
                writing: this.writing[i],
                speaking: this.speaking[i],
              })
              .then((res) => {
                if (res.status == 201) {
                  console.log(res.data);
                  alert("New Language added");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        }
      }
    },

    permanent_address() {
      if (
        this.permanent_address_data.street == null ||
        this.permanent_address_data.street == ""
      ) {
        this.nullcheck("Street");
      } else if (
        this.permanent_address_data.house == null ||
        this.permanent_address_data.house == ""
      ) {
        this.nullcheck("House");
      } else if (
        this.permanent_address_data.county == null ||
        this.permanent_address_data.county == ""
      ) {
        this.nullcheck("County");
      } else if (
        this.permanent_address_data.city == null ||
        this.permanent_address_data.city == ""
      ) {
        this.nullcheck("City");
      } else if (
        this.permanent_address_data.province == null ||
        this.permanent_address_data.province == ""
      ) {
        this.nullcheck("Province");
      } else if (
        this.permanent_address_data.country == null ||
        this.permanent_address_data.country == ""
      ) {
        this.nullcheck("Country");
      } else if (
        this.permanent_address_data.description == null ||
        this.permanent_address_data.description == ""
      ) {
        this.nullcheck("Description");
      } else {
        axios
          .patch(
            "applicant/permanent_address/" +
              this.permanent_address_data.uuid +
              "/",
            {
              street: this.permanent_address_data.street,
              house: this.permanent_address_data.house,
              county: this.permanent_address_data.county,
              city: this.permanent_address_data.city,
              province: this.permanent_address_data.province,
              country: this.permanent_address_data.country,
              description: this.permanent_address_data.description,
            }
          )
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              alert("Permanent address updated");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    present_address_add() {
      if (
        this.present_address_data.street == null ||
        this.present_address_data.street == ""
      ) {
        this.nullcheck("street");
      } else if (
        this.present_address_data.house == null ||
        this.present_address_data.house == ""
      ) {
        this.nullcheck("house");
      } else if (
        this.present_address_data.county == null ||
        this.present_address_data.county == ""
      ) {
        this.nullcheck("county ");
      } else if (
        this.present_address_data.city == null ||
        this.present_address_data.city == ""
      ) {
        this.nullcheck("city");
      } else if (
        this.present_address_data.province == null ||
        this.present_address_data.province == ""
      ) {
        this.nullcheck("province");
      } else if (
        this.present_address_data.country == null ||
        this.present_address_data.country == ""
      ) {
        this.nullcheck("country");
      } else if (
        this.present_address_data.description == null ||
        this.present_address_data.description == ""
      ) {
        this.nullcheck("description");
      } else {
        axios
          .patch(
            "applicant/present_address/" + this.present_address_data.uuid + "/",
            {
              street: this.present_address_data.street,
              county: this.present_address_data.county,
              city: this.present_address_data.city,
              province: this.present_address_data.province,
              country: this.present_address_data.country,
              description: this.present_address_data.description,
            }
          )
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              alert("Present address updated");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    preference() {

      if(this.preference_data){
        if (!this.preference_data.job_category_choice.length > 0) {
        this.nullcheck("ChoiceCategory");
      } else if (!this.preference_data.keyword.length > 0) {
        this.nullcheck("Keyword");
      } else if (!this.preference_data.pref_city.length > 0) {
        this.nullcheck("CityList");
      } else if (!this.preference_data.special_skill.length > 0) {
        this.nullcheck("SpecialSkills");
      } else {
        axios
          .patch("applicant/preference/" + this.preference_data.uuid + "/", {
            job_category_choice: this.preference_data.job_category_choice,
            keyword: this.preference_data.keyword,
            pref_city: this.preference_data.pref_city,
            special_skill: this.preference_data.special_skill,
          })
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$emit("get_message", "Preference updated");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }


      }else{

        if (!this.job_category_choice_array.length > 0) {
        this.nullcheck("ChoiceCategory");
      } else if (!this.keyword_array.length > 0) {
        this.nullcheck("Keyword");
      } else if (!this.pref_city_array.length > 0) {
        this.nullcheck("CityList");
      } else if (!this.special_skill_array.length > 0) {
        this.nullcheck("SpecialSkills");
      } else {
        axios
          .post("applicant/preference/" , {
            job_category_choice: this.job_category_choice_array,
            keyword: this.keyword_array,
            pref_city: this.pref_city_array,
            special_skill: this.special_skill_array,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              this.$emit("get_message", "Preference added");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }



      }

    },

    reference() {
      for (var i = 0; i < this.ref_form_count; i++) {
        if (this.references_data[i]) {
          if (
            this.references_data[i].name == null ||
            this.references_data[i].name == ""
          ) {
            this.nullcheck("Name" + i);
          } else if (
            this.references_data[i].designation == null ||
            this.references_data[i].designation == ""
          ) {
            this.nullcheck("designation" + i);
          } else if (
            this.references_data[i].organization == null ||
            this.references_data[i].organization == ""
          ) {
            this.nullcheck("Organization" + i);
          } else if (
            this.references_data[i].email == null ||
            this.references_data[i].email == ""
          ) {
            this.nullcheck("Email" + i);
          } else if (
            this.references_data[i].relation == null ||
            this.references_data[i].relation == ""
          ) {
            this.nullcheck("Relation" + i);
          } else if (
            this.references_data[i].cell_no == null ||
            this.references_data[i].cell_no == ""
          ) {
            this.nullcheck("ContactNumber" + i);
          } else if (
            this.references_data[i].address == null ||
            this.references_data[i].address == ""
          ) {
            this.nullcheck("FullAddress" + i);
          } else {
            axios
              .patch(
                "applicant/reference/" + this.references_data[i].uuid + "/",
                {
                  name: this.references_data[i].name,
                  designation: this.references_data[i].designation,
                  organization: this.references_data[i].organization,
                  email: this.references_data[i].email,
                  relation: this.references_data[i].relation,
                  cell_no: this.references_data[i].cell_no,
                  address: this.references_data[i].address,
                }
              )
              .then((res) => {
                if (res.status == 200) {
                  console.log(res.data);
                  this.$emit("get_message", "Reference updated");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        } else {
          if (this.name[i] == null || this.name[i] == "") {
            this.nullcheck("Name" + i);
          } else if (
            this.ref_designation[i] == null ||
            this.ref_designation[i] == ""
          ) {
            this.nullcheck("designation" + i);
          } else if (
            this.organization[i] == null ||
            this.organization[i] == ""
          ) {
            this.nullcheck("Organization" + i);
          } else if (this.email[i] == null || this.email[i] == "") {
            this.nullcheck("Email" + i);
          } else if (this.relation[i] == null || this.relation[i] == "") {
            this.nullcheck("Relation" + i);
          } else if (this.cell_no[i] == null || this.cell_no[i] == "") {
            this.nullcheck("ContactNumber" + i);
          } else if (this.address[i] == null || this.address[i] == "") {
            this.nullcheck("FullAddress" + i);
          } else {
            axios
              .post("applicant/reference/", {
                name: this.name[i],
                designation: this.ref_designation[i],
                organization: this.organization[i],
                email: this.email[i],
                relation: this.relation[i],
                cell_no: this.cell_no[i],
                address: this.address[i],
              })
              .then((res) => {
                if (res.status == 201) {
                  console.log(res.data);
                  this.$emit("get_message", "Reference added");
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        }
      }
    },

    addexpertise() {
      if (this.expertise.length < 10) {
        this.expertise.push(this.area_expertise);
        this.add = "" + this.expertise;
      } else {
        alert("Expertise Max length will be 10");
      }
    },

    add_topics_covered() {
      if (this.topics_covered.length < 10) {
        this.topics_covered.push(this.topics_covereds);
        this.add1 = "" + this.topics_covered;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_category_choice() {
      if (this.category_choice.length < 10) {
        this.category_choice.push(this.category_choices);
        this.add2 = "" + this.category_choice;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_keywords() {
      if (this.keyword.length < 10) {
        this.keyword.push(this.keywords);
        this.add3 = "" + this.keyword;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_Preferred_city() {
      if (this.Preferred_city.length < 10) {
        this.Preferred_city.push(this.Preferred_city_list);
        this.add4 = "" + this.Preferred_city;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_special_skill() {
      if (this.special_skill.length < 10) {
        this.special_skill.push(this.special_skills);
        this.add5 = "" + this.special_skill;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    // add_basic_info1() {
    //   axios
    //     .post("applicant/basic_info/", {
    //       basic_info_data.first_name: this.fname,
    //       basic_info_data.last_name: this.lname,
    //       dob: this.Date_of_Birth,
    //       religion: this.religion,
    //       ethnicity: this.ethnicity,
    //       gender: this.gender,
    //       primary_contact_no: this.primary_no,
    //       nationality: this.nationality,
    //       fathers_name: this.father_name,
    //       mothers_name: this.mother_name,
    //       secondary_contact_no: this.secondary_cont_no,
    //       emergency_contact_no: this.emergency_cont_no,
    //       secondary_mail: this.secondary_mail,
    //       nid: this.nid,
    //       passport_no: this.passport_no,
    //       blood_group: this.blood_group,
    //       weight: this.weight,
    //       height: this.height,
    //     })
    //     .then((res) => {
    //       if (res.status == 201) {
    //         console.log(res.data);
    //         alert("ok");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //     });
    // },

    add_career_info() {
      axios
        .post("applicant/career_info/", {
          objective: this.career_info_data.objective,
          job_level: this.career_info_data.job_level,
          job_nature: this.career_info_data.job_nature,
          present_salary: this.career_info_data.present_salary,
          present_salary_type: this.career_info_data.present_salary_type,
          expected_salary: this.career_info_data.expected_salary,
          expected_salary_type: this.career_info_data.expected_salary_type,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_certification() {
      axios
        .post("applicant/certification/", {
          cer_name: this.certification_data.cer_name,
          location: this.location,
          start_date: this.start_date,
          end_date: this.end_date,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_education() {
      axios
        .post("applicant/education/", {
          edu_level: this.edu_level,
          degree_title: this.degree_title,
          major: this.major,
          institute: this.institute,
          result: this.result,
          marks: this.marks,
          passing_year: this.passing_year,
          duration: this.duration,
          achievement: this.achievement,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_experience() {
      axios
        .post("applicant/experience/", {
          org_name: this.org_name,
          org_business: this.org_business,
          designation: this.designation,
          department: this.department,
          from_date: this.from_date,
          to_date: this.to_date,
          curr_working: this.curr_working,
          responsibilities: this.responsibilities,
          area_expertise: this.expertise,
          org_location: this.org_location,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_skills() {
      axios
        .post("applicant/skill/", {
          skill_name: this.skill_name,
          acquired_from: this.acquired_from,
          description: this.description,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_training() {
      axios
        .post("applicant/training/", {
          title: this.title,
          type: this.type,
          topics_covered: this.topics_covered,
          duration: this.duration,
          year: this.year,
          institute: this.institute,
          location: this.location,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_extracurricular() {
      axios
        .post("applicant/extracurricular/", {
          activity: this.activity,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_languages() {
      axios
        .post("applicant/language/", {
          language: this.language,
          learned_by: this.learned_by,
          reading: this.reading,
          writing: this.writing,
          speaking: this.speaking,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_permanent_address() {
      axios
        .post("applicant/permanent_address/", {
          street: this.street,
          house: this.house,
          county: this.county,
          city: this.city,
          province: this.province,
          country: this.country,
          description: this.description,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_present_address() {
      axios
        .post("applicant/present_address/", {
          street: this.street,
          house: this.house,
          county: this.county,
          city: this.city,
          province: this.province,
          country: this.country,
          description: this.description,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_preference() {
      axios
        .post("applicant/preference/", {
          job_category_choice: this.category_choice,
          keyword: this.keyword,
          pref_city: this.Preferred_city,
          special_skill: this.special_skill,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_reference() {
      axios
        .post("applicant/reference/", {
          name: this.name,
          designation: this.designation,
          organization: this.organization,
          email: this.email,
          relation: this.relation,
          cell_no: this.cell_no,
          address: this.address,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    handleResumeUpload(event) {
      this.resume_file = event.target.files[0];
    },

    addresume() {
      if (this.resume) {
        if (this.resume_file != "") {

          let formData = new FormData();
          formData.append("resume", this.resume_file);

          axios
            .patch("/applicant/resume/" + this.resume.uuid + "/", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.status == 200) {
                console.log(res.data);
              this.$emit("get_message", "Resume Updated");
              }
            })
            .catch((error) => {
              alert(error.response.data.user);
            });
        }
      } else {
        if (this.resume_file != "") {
          let formData = new FormData();
          formData.append("resume", this.resume_file);

          axios
            .patch("/applicant/resume/" + this.resume.uuid + "/", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.status == 200) {
                console.log(res.data);
                this.$emit("get_message", "Resume Updated");
              }
            })
            .catch((error) => {
              alert(error.response.data.user);
            });
        }
      }
    },

    getProfile() {
      axios
        .get("applicant/applicant_profile/")
        .then((res) => {
          this.all_data = res.data;

          this.basic_info_data = res.data.basic_info;
          this.permanent_address_data = res.data.permanent_address;
          this.photograph_data = res.data.photograph;
          this.experiences_data = res.data.experience;
          this.educations_data = res.data.education;
          this.present_address_data = res.data.present_address;
          this.career_info_data = res.data.career_info;
          this.language_data = res.data.language;
          this.skill_data = res.data.skill;
          this.trainings_data = res.data.training;
          this.references_data = res.data.reference;
          this.preference_data = res.data.preference;
          this.certification_data = res.data.certification;
          this.extracurricular_data = res.data.extracurricular;
          this.photograph_url="https://api.hive4work.co.uk/"+res.data.photograph.photograph

          //assign form count length

          this.cer_form_count = res.data.certification.length;
          this.edu_form_count = res.data.education.length;
          this.exp_form_count = res.data.experience.length;
          this.explength = res.data.experience.length;
          this.skill_form_count = res.data.skill.length;
          this.tra_form_count = res.data.training.length;
          this.tra_length = res.data.training.length;
          this.extra_co_form_count = res.data.extracurricular.length;
          this.lang_form_count = res.data.language.length;
          this.ref_form_count = res.data.reference.length;

          if(this.ref_form_count==0){
            this.ref_form_count=1


          }

       
         

          //

          if (res.status == 200) {
            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getResume() {
      axios
        .get("applicant/resume/")
        .then((res) => {
          if (res.status == 200) {
            this.resume = res.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted: function () {
    this.getProfile();
    this.getResume();
  },
};
</script>

<style></style>
