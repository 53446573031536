<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li class="active">
            <a href="/recruiter-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/recruiter-profile"
              ><i class="la la-user-tie"></i>Profile</a
            >
          </li>

          <li>
            <a href="/recruiter-postjob"
              ><i class="la la-paper-plane"></i>Post a New Job</a
            >
          </li>
          <li>
            <a href="/recruiter-managejob"
              ><i class="la la-briefcase"></i> Manage Jobs
            </a>
          </li>
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          <li>
            <a href="#"><i class="la la-trash"></i>Delete Profile</a>
          </li>
          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <!-- <li><a href="#"><i class="la la-lock"></i>Change Password</a></li> -->
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <!-- <li><a @click.prevent="logout" ><i class="la la-sign-out"></i>Logout</a></li> -->

          <!-- <li><img src="images/boy.png" style=" height: 400px;width: 100%;"></li> -->
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 200px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3 style="color: #073f3f">
            Hi <span style="color: #36a8a6"> {{ name }}</span>
          </h3>
          <!-- <div class="text">Welcome to Recruiter!</div> -->
        </div>
        <div class="row">
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item">
              <div class="left">
                <i class="icon flaticon-briefcase"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.total_approved_job_posts }}</h4>
                <p>Posted Jobs</p>
              </div>
            </div>
          </div>
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item ui-red">
              <div class="left">
                <i class="icon las la-exclamation"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.reviewing_job_posts }}</h4>
                <p>Reviewing Job</p>
              </div>
            </div>
          </div>
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item ui-yellow">
              <div class="left">
                <i class="icon las la-low-vision"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.non_viewed_applications }}</h4>
                <p>Non viewed</p>
              </div>
            </div>
          </div>
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item ui-green">
              <div class="left">
                <i class="icon la la-file-invoice"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.total_applications }}</h4>
                <p>Total Applications</p>
              </div>
            </div>
          </div>

          <!-- <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div class="ui-item ui-green">
          <div class="left">
            <i class="icon la la-file-invoice"></i>
          </div>
          <div class="right">
            <h4>{{ dashboardcount.non_viewed_applications }}</h4>
            <p>Non Viewed</p>
          </div>
        </div>
      </div> -->
        </div>
      </div>
    </section>
    <!-- End Dashboard -->

    <!-- Copyright -->
    <!--div class="copyright-text">
  <p>© 2021 Superio. All Right Reserved.</p>
</div-->
  </div>
</template>

<script>
import axios from "axios";
import vcookie from "vue-cookie";

export default {
  name: "DashboardAdminView",

  data() {
    return {
      dashboardcount: "",
      name: "",

      reload: false,
    };
  },

  methods: {
    getName() {
      const user = JSON.parse(vcookie.get("user"));
      if (user) {
        this.name = user.user.first_name + " " + user.user.last_name;
      }
    },

    logout() {
      const user = JSON.parse(vcookie.get("user"));

      if (user) {
        vcookie.delete("user");

        this.$router.push({ name: "home" });
        location.reload();
      }
    },

    dashboard_count() {
      axios
        .get("recruiter/dashboard/")
        .then((res) => {
          this.dashboardcount = res.data;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted: function () {
    this.dashboard_count();
    this.getName();
  },
};
</script>
<style></style>
