<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li class="active">
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li>
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
       
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3 style="color: #073f3f">
            Hi <span style="color: #36a8a6"> {{ name }}</span>
          </h3>
          <!-- <div class="text">Welcome to Recruiter!</div> -->
        </div>
        <div class="row">
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item">
              <div class="left">
                <i class="icon flaticon-briefcase"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.active_approved }}</h4>
                <p>Total Approved</p>
              </div>
            </div>
          </div>
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item ui-red">
              <div class="left">
                <i class="icon las la-exclamation"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.total_featured }}</h4>
                <p>Total Featured</p>
              </div>
            </div>
          </div>
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item ui-yellow">
              <div class="left">
                <i class="icon las la-low-vision"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.total_sponsored }}</h4>
                <p>Total Sponsored</p>
              </div>
            </div>
          </div>
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item ui-green">
              <div class="left">
                <i class="icon la la-file-invoice"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.active_sponsored }}</h4>
                <p>Active Sponsored</p>
              </div>
            </div>
          </div>

          <!-- <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div class="ui-item ui-green">
          <div class="left">
            <i class="icon la la-file-invoice"></i>
          </div>
          <div class="right">
            <h4>{{ dashboardcount.non_viewed_applications }}</h4>
            <p>Non Viewed</p>
          </div>
        </div>
      </div> -->
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
import vcookie from "vue-cookie";

export default {
  name: "DashboardAdminView",

  data() {
    return {
      dashboardcount: "",
      name: "",
    };
  },

  methods: {
    getName() {
      const user = JSON.parse(vcookie.get("user"));
      if (user) {
        this.name = user.user.first_name + " " + user.user.last_name;
      }
    },

    logout() {
      const user = JSON.parse(vcookie.get("user"));

      if (user) {
        vcookie.delete("user");

        this.$router.push({ name: "home" });
        location.reload();
      }
    },

    dashboard_count() {
      axios
        .get("app_admin/dashboard/")
        .then((res) => {
          this.dashboardcount = res.data.jobpost;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted: function () {
    this.dashboard_count();
    this.getName();
  },
};
</script>

<style></style>
