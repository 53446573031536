<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a>
          </li>
          <li>
            <a href="/admin-dashboard-allemployer"
              ><i class="la la-user-tie"></i>All Employers</a
            >
          </li>
          <li>
            <a href="/admin-managejob"
              ><i class="la la-paper-plane"></i>Manage Job</a
            >
          </li>
          <li>
            <a href="/admin-add-job-category"
              ><i class="la la-briefcase"></i> Add Job Category
            </a>
          </li>
          <li>
            <a href="/admin-add-employer-type"
              ><i class="la la-file-invoice"></i> Add Employer Type</a
            >
          </li>
          <li>
            <a href="/admin-add-contracthire-type"
              ><i class="la la-bookmark-o"></i>Direct Hire</a
            >
          </li>
          <li>
            <a href="/admin-add-contract-type"
              ><i class="la la-bookmark-o"></i>Part Time Job</a
            >
          </li>
          <li>
            <a href="/admin-manage-ad-board"
              ><i class="la la-bookmark-o"></i>Manage Ad Board</a
            >
          </li>
          <li class="active">
            <a href="/admin-manage-course"
              ><i class="la la-bookmark-o"></i>Manage Course</a
            >
          </li>
          <li>
            <a href="/admin-manage-carrer-advice"
              ><i class="la la-bookmark-o"></i>Manage Career Advice</a
            >
          </li>
          <li>
            <a href="/admin-payment-status"
              ><i class="la la-comment-o"></i>Payment Status</a
            >
          </li>
          <li>
            <a href="/admin-delete-profile"
              ><i class="la la-trash"></i>Delete Profile</a
            >
          </li>
          <!-- <li><a href="index.html"><i class="la la-trash"></i>Publish Ads</a></li> -->
          <li>
            <a href="/admin-change-password"
              ><i class="la la-lock"></i>Change Password</a
            >
          </li>
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <li>
            <a href="/login"><i class="la la-sign-out"></i>Logout</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box text-center">
          <h3>Add Course</h3>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box p-3">
                <div class="widget-content">
                  <form
                    @submit.prevent="addCourse"
                    ref="form_course"
                    class="default-form"
                  >
                    <div class="row">
                      <!-- Input -->

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Course Title*</label>
                        <input
                          type="text"
                          v-model.trim="title"
                          placeholder="Title"
                          @input="clearError('title')"
                        />

                        <div id="title" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Overview*</label>
                        <textarea
                          v-model.trim="overview"
                          placeholder="Overview"
                          @input="clearError('overview')"
                        ></textarea>

                        <div id="overview" class="text-danger error"></div>
                      </div>

                      <!-- About Company -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Description*</label>
                        <textarea
                          v-model.trim="description"
                          placeholder="Description"
                          @input="clearError('description')"
                        ></textarea>

                        <div id="description" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Course type*</label>
                        <input
                          type="text"
                          v-model.trim="course_type"
                          placeholder="Study method"
                          @input="clearError('course_type')"
                        />

                        <div id="course_type" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Price</label>
                        <input
                          type="number"
                          v-model.trim="price"
                          placeholder="Price"
                          @input="clearError('price')"
                        />

                        <div id="Title" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Discount</label>
                        <input
                          type="number"
                          v-model.trim="discount"
                          placeholder="Discount"
                          @input="clearError('discount')"
                        />

                        <div id="discount" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Key Goals</label>
                        <input
                          type="text"
                          v-model.trim="key_goals"
                          placeholder="Key Goals"
                          @input="clearError('key_goals')"
                        />

                        <div id="key_goals" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Qualification</label>
                        <input
                          type="text"
                          v-model.trim="qualification"
                          placeholder="qualification"
                          @input="clearError('qualification')"
                        />

                        <div id="qualification" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Course content*</label>
                        <input
                          type="text"
                          v-model.trim="course_content"
                          placeholder="Course content"
                          @input="clearError('course_content')"
                        />

                        <div
                          id="course_content"
                          class="text-danger error"
                        ></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Target people*</label>
                        <input
                          type="text"
                          v-model.trim="target_people"
                          placeholder="Target people"
                          @input="clearError('target_people')"
                        />

                        <div id="target_people" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Career path</label>
                        <input
                          type="text"
                          v-model.trim="career_path"
                          placeholder="Career path"
                          @input="clearError('career_path')"
                        />

                        <div id="career_path" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Duration*</label>
                        <input
                          type="text"
                          v-model.trim="duration"
                          placeholder="Duration"
                          @input="clearError('duration')"
                        />

                        <div id="duration" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Category*</label>
                        <select
                          v-model="course_category"
                          @change="clearError('course_category')"
                        >
                          <option disabled :value="null">
                            Select a option
                          </option>
                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.cat_name }}
                          </option>
                        </select>
                        <div
                          id="course_category"
                          class="text-danger error"
                        ></div>
                      </div>

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Eligibility*</label>
                        <input
                          type="text"
                          v-model.trim="eligibility"
                          placeholder="Eligibility"
                          @input="clearError('eligibility')"
                        />

                        <div id="eligibility" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <div class="uploading">
                          <div class="uploadButton">
                            <input
                              class="uploadButton-input"
                              type="file"
                              @change="handleFileUpload($event)"
                              accept="image/*"
                              id="upload"
                            />
                            <label
                              style="position: relative"
                              class="uploadButton-button ripple-effect"
                              for="upload"
                            >
                              <img
                                v-if="url"
                                :src="url"
                                style="
                                  position: absolute;
                                  top: 0;
                                  bottom: 0;
                                  right: 0;
                                  left: 0;
                                  width: 100%;
                                  height: 100%;
                                  z-index: 0;
                                "
                                alt=""
                              />

                              <span v-if="url == null"
                                >Browse image</span
                              ></label
                            >
                            <div id="image" class="text-danger error"></div>
                            <span class="uploadButton-file-name"></span>
                          </div>
                          <!-- <button @click.prevent="add_logo"  type="submit" class="btn btn-primary" >Upload</button> -->
                        </div>
                      </div>

                     

                      <!-- <div class="uploading-outer">
                        <div class="uploadButton">
                          <input
                            class="uploadButton-input"
                            type="file"
                            @change="handleFileUpload($event)"
                            accept="image/*"
                            id="upload"
                            multiple
                          />
                          <label
                            style="position:relative"
                              class="uploadButton-button ripple-effect"
                              for="upload"
                              >

                              <img v-if="url" :src="url"  style="position: absolute;top:0;bottom: 0;right: 0;left: 0;width: 100%;height: 100%;z-index: 0;"  alt="">
                             
                              
                              
                              <span v-if="url==''" >Browse image</span></label
                            >
                          <span class="uploadButton-file-name"></span>
                          <span id="Image" class="text-danger error"></span>

                        </div>
                        <button @click.prevent="add_logo"  type="submit" class="btn btn-primary" >Upload</button>
                      </div> -->

                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12 text-right">
                        <button type="submit" class="theme-btn btn-style-one">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "AdminAddCourseView",

  data() {
    return {
      categories: [],

      course_category: null,
      image: "",
      url:""
    };
  },

  methods: {
    handleFileUpload(e) {
      this.image = e.target.files[0];
      this.url = URL.createObjectURL(this.image);
     
    },

    category() {
      axios
        .get("/app_admin/course_category/")
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    nullcheck(element) {
      $("#" + element).html("This field is required");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },
    addcheck(element) {
      $("#" + element).html("Add" + " " + element);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },
    lengthcheck(element, value) {
      $("#" + element).html("Length will be max " + value);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    clearError(element) {
      $("#" + element).html("");
    },

    addCourse() {
      $(".error").html("");

      if (this.title == null || this.title == "") {
        this.nullcheck("title");
      } else if (this.title.length > 255) {
        this.lengthcheck("title", 255);
      } else if (this.overview == null || this.overview == "") {
        this.nullcheck("overview");
      } else if (this.description == null || this.description == "") {
        this.nullcheck("description");
      } else if (this.course_type == null || this.course_type == "") {
        this.nullcheck("course_type");
      } else if (this.course_content == null || this.course_content == "") {
        this.nullcheck("course_content");
      } else if (this.target_people == null || this.target_people == "") {
        this.nullcheck("target_people");
      } else if (this.duration == null || this.duration == "") {
        this.nullcheck("duration");
      } else if (this.course_category == null || this.course_category == "") {
        this.nullcheck("course_category");
      } else if (this.eligibility == null || this.eligibility == "") {
        this.nullcheck("eligibility");
      } else if (this.image == null || this.image == "") {
        this.nullcheck("image");
      } else {
        var formdata = new FormData();

        formdata.append("title", this.title);
        formdata.append("overview", this.overview);
        formdata.append("description", this.description);
        formdata.append("course_type", this.course_type);
        formdata.append("price", this.price);
        formdata.append("discount", this.discount);
        formdata.append("key_goals", this.course_type);
        formdata.append("qualification", this.qualification);
        formdata.append("course_content", this.course_content);
        formdata.append("target_people", this.target_people);
        formdata.append("career_path", this.career_path);
        formdata.append("duration", this.duration);
        formdata.append("course_category", this.course_category);
        formdata.append("eligibility", this.eligibility);
        formdata.append("image", this.image);

        axios
          .post("/app_admin/course/", formdata)
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              this.$router.push({ name: "admin-manage-course" });
              this.$emit("get_message", "Course added Successfully");

            
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
  },
  mounted: function () {
    this.category();
  },
};
</script>

<style>
.button_plus {
  position: absolute;
  width: 35px;
  height: 35px;
  background: #fff;
  cursor: pointer;
  border: 2px solid #095776;
  display: flex;
  top: 44%;
}

.button_plus:after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  height: 4px;
  width: 50%;
  background: #095776;
  top: 50%;
  left: 50%;
}

.button_plus:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #095776;
  height: 50%;
  width: 4px;
}

.button_plus:hover:before,
.button_plus:hover:after {
  background: #fff;
  transition: 0.2s;
}

.button_plus:hover {
  background-color: #095776;
  transition: 0.2s;
}
</style>
