<template>
  <div>
    <!--End Main Header -->

    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/candidate-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li class="active">
            <a href="/candidate-profile-single"
              ><i class="la la-user-tie"></i>My Resume</a
            >
          </li>
          <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
          <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
          <li>
            <a href="/candidate-findjob"
              ><i class="la la-user-alt"></i>Find jobs</a
            >
          </li>
          <li>
            <a href="/candidate-appliedjob"
              ><i class="la la-briefcase"></i> Applied Jobs
            </a>
          </li>

          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 250px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Faqs Section -->
    <section class="faqs-section">
      <div class="auto-container">
        <h2 class="sec-title">Create your Profile</h2>

        <button class="accordionc">Photograph</button>
        <div class="panel" >
          <br>

          <div class="uploading-outer">
            <div class="uploadButton">
              <input
                class="uploadButton-input"
                type="file"
                @change="select_photo($event)"
                accept="image/*"
                id="upload"
              />

              <div>
                <label
                  style="position: relative"
                  class="uploadButton-button ripple-effect"
                  for="upload"
                >
                  <img
                    v-if="photograph_url"
                    :src="photograph_url"
                    style="
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      z-index: 0;
                    "
                    alt=""
                  />

                  <span v-if="photograph_url == '' || url == photograph_url"
                    >Browse Photograph</span
                  >
                </label>
                <button
                  @click.prevent="photograph"
                  class="btn btn-primary theme-color-bg mt-2 photo_upload_btn"
                >
                  Upload Photo
                </button>
              </div>

              <span class="uploadButton-file-name"></span>
            </div>
          </div>
 

        </div>

        <button class="accordionc">Basic Informations</button>
        <div class="panel">
          <br>
        

      

          <form
            @submit.prevent="add_basic_info"
            style="margin-left: 20px; margin-right: 20px; margin-bottom: 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>First Name</label>
                <input
                  type="text"
                  v-model="first_name"
                  placeholder=""
                  @input="clearError('FirstName')"
                />

                <div id="FirstName" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Last Name</label>
                <input
                  type="text"
                  v-model="last_name"
                  placeholder=""
                  @input="clearError('LastName')"
                />
                <div id="LastName" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Date of Birth</label>
                <input
                  class="form-control"
                  v-model="dob"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('DateofBirth')"
                />

                <div id="DateofBirth" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Religion</label>
                <input
                  type="text"
                  v-model="religion"
                  placeholder=""
                  @input="clearError('Religion')"
                />

                <div id="Religion" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Ethnicity</label>
                <input
                  type="text"
                  v-model="ethnicity"
                  placeholder=""
                  @input="clearError('Ethnicity')"
                />

                <div id="Ethnicity" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Gender</label>
                <select v-model="gender" @change="clearError('Gender')">
                  <option disabled  value="null">Select gender</option>

                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
                <div id="Gender" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Primary Number</label>
                <input
                  type="number"
                  v-model="primary_contact_no"
                  placeholder=""
                  @input="clearError('PrimaryNumber')"
                />

                <div id="PrimaryNumber" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Nationality</label>
                <input
                  type="text"
                  v-model="nationality"
                  name="name"
                  placeholder=""
                  @input="clearError('Nationality')"
                />

                <div id="Nationality" class="text-danger error"></div>
              </div>

             
              <!-- <div class="form-group col-lg-6 col-md-12">
                <label>Father's Name</label>
                <input
                  type="text"
                  v-model="fathers_name"
                  placeholder=""
                  @input="clearError('FatherName')"
                />

                <div id="FatherName" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Mother's Name</label>
                <input
                  type="text"
                  v-model="mothers_name"
                  placeholder=""
                  @input="clearError('MotherName')"
                />

                <div id="MotherName" class="text-danger error"></div>
              </div> -->

              <div class="form-group col-lg-6 col-md-12">
                <label>Secondary Contact Number</label>
                <input
                  type="number"
                  v-model="secondary_contact_no"
                  placeholder=""
                  @input="clearError('SecondaryContactNumber')"
                />

                <div
                  id="SecondaryContactNumber"
                  class="text-danger error"
                ></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Emergency Contact Number</label>
                <input
                  type="text"
                  v-model="emergency_contact_no"
                  placeholder=""
                  @input="clearError('EmergencyContactNumber')"
                />

                <div
                  id="EmergencyContactNumber"
                  class="text-danger error"
                ></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Secondary Email</label>
                <input
                  type="email"
                  v-model="secondary_mail"
                  placeholder=""
                  @input="clearError('SecondaryEmail')"
                />

                <div id="SecondaryEmail" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>NI No.</label>
                <input
                  type="text"
                  v-model="nid"
                  placeholder=""
                  @input="clearError('NID')"
                />

                <div id="NID" class="text-danger error"></div>
              </div>

              <!-- <div class="form-group col-lg-6 col-md-12">
                <label>Passport Number</label>
                <input
                  type="text"
                  v-model="passport_no"
                  placeholder=""
                  @input="clearError('PassportNumber')"
                />

                <div id="PassportNumber" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Blood Group</label>
                <input
                  type="text"
                  v-model="blood_group"
                  placeholder=""
                  @input="clearError('BloodGroup')"
                />

                <div id="BloodGroup" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Weight</label>
                <input
                  type="text"
                  v-model="weight"
                  placeholder=""
                  @input="clearError('Weight')"
                />

                <div id="Weight" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Height</label>
                <input
                  type="text"
                  v-model="height"
                  placeholder=""
                  @input="clearError('Height')"
                />

                <div id="Height" class="text-danger error"></div>
              </div> -->

             
              <div
                class="form-group col-lg-6 col-md-12"
                style="margin-bottom: 30px"
              >
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <!-- <button
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button> -->
              </div>
            </div>
          </form>
        </div>

        <!-- <button class="accordionc">Career Informations</button>
        <div class="panel">
          <form
            @submit.prevent="career_info"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <div class="form-group col-lg-6 col-md-12">
                <label>Objective</label>
                <input
                  type="text"
                  v-model="objective"
                  placeholder=""
                  @input="clearError('Objective')"
                />
                <div id="Objective" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Job Level</label>
                <input
                  type="text"
                  v-model="job_level"
                  placeholder=""
                  @input="clearError('JobLevel')"
                />
                <div id="JobLevel" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Job Nature</label>
                <select v-model="job_nature" @input="clearError('JobNature')">
                  <option value="fulltime">Full time</option>
                  <option value="parttime">Part time</option>
                  <option value="contractual">Contractual</option>
                </select>
                <div id="JobNature" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Present Salary</label>
                <input
                  type="number"
                  v-model="present_salary"
                  placeholder=""
                  @input="clearError('PresentSalary')"
                />
                <div id="PresentSalary" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Present Salary Type</label>
                <input
                  type="text"
                  v-model="present_salary_type"
                  placeholder=""
                  @input="clearError('SalaryType')"
                />

                <div id="SalaryType" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Expected Salary</label>
                <input
                  type="number"
                  v-model="expected_salary"
                  placeholder=""
                  @input="clearError('ExpectedSalary')"
                />

                <div id="ExpectedSalary" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Expected Salary Type</label>
                <input
                  type="text"
                  v-model="expected_salary_type"
                  placeholder=""
                  @input="clearError('ExpectedSalaryType')"
                />

                <div id="ExpectedSalaryType" class="text-danger error"></div>
              </div>

              <div class="form-group col-md-12" style="margin-bottom: 30px">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
               
              </div>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Certification</button>
        <div class="panel">
          <form
            @submit.prevent="certification"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Certification Name</label>
                <input
                  type="text"
                  v-model="cer_name"
                  placeholder=""
                  @input="clearError('CertificationName')"
                />

                <div id="CertificationName" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Institution</label>
                <input
                  type="text"
                  v-model="location"
                  placeholder=""
                  @input="clearError('Institution')"
                />

                <div id="Institution" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Start Date</label>
                <input
                  class="form-control"
                  v-model="start_date"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('StartDate')"
                />

                <div id="StartDate" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>End Date</label>
                <input
                  class="form-control"
                  v-model="end_date"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('EndDate')"
                />

                <div id="EndDate" class="text-danger error"></div>
              </div>

             
              <div
                class="form-group col-lg-6 col-md-12"
                style="margin-bottom: 30px"
              >
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>

              </div>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Education</button>
        <div style="overflow: auto" class="panel">
          <div id="education_block">
            <form
              id="edu_form"
              @submit.prevent="education"
              style="margin: 20px 20px 20px 20px"
              class="default-form"
            >
              <div v-for="n in form_count" :key="n" class="row">
                <h3 class="mt-2">Education info {{ n }}</h3>

               
                <div class="form-group col-lg-6 col-md-12">
                  <label>Education Level</label>
                  <input
                    type="text"
                    v-model="edu_level[n]"
                    placeholder=""
                    @input="clearError('EducationLevel' + n)"
                  />

                  <div
                    :id="'EducationLevel' + n"
                    class="text-danger error"
                  ></div>
                </div>

               
                <div class="form-group col-lg-6 col-md-12">
                  <label>Degree</label>
                  <input
                    type="text"
                    v-model="degree_title[n]"
                    placeholder=""
                    @input="clearError('Degree' + n)"
                  />

                  <div :id="'Degree' + n" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Major</label>
                  <input
                    class="form-control"
                    v-model="major[n]"
                    type="text"
                    placeholder=""
                    @input="clearError('Major' + n)"
                  />

                  <div :id="'Major' + n" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Institute</label>
                  <input
                    class="form-control"
                    v-model="institute[n]"
                    type="text"
                    placeholder=""
                    @input="clearError('Institute' + n)"
                  />

                  <div :id="'Institute' + n" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Grade</label>
                  <input
                    class="form-control"
                    v-model="result[n]"
                    type="text"
                    placeholder=""
                    @input="clearError('Grade' + n)"
                  />

                  <div :id="'Grade' + n" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Marks</label>
                  <input
                    class="form-control"
                    v-model="marks[n]"
                    type="number"
                    placeholder=""
                    @input="clearError('Marks' + n)"
                  />

                  <div :id="'Marks' + n" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Passing Year</label>
                  <input
                    class="form-control"
                    v-model="passing_year[n]"
                    type="number"
                    placeholder=""
                    @input="clearError('PassingYear' + n)"
                  />

                  <div :id="'PassingYear' + n" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Duration</label>
                  <input
                    class="form-control"
                    v-model="duration[n]"
                    type="number"
                    placeholder=""
                    @input="clearError('Duration' + n)"
                  />

                  <div :id="'Duration' + n" class="text-danger error"></div>
                </div>

                <div class="form-group col-lg-6 col-md-12">
                  <label>Achievement</label>
                  <input
                    class="form-control"
                    v-model="achievement[n]"
                    type="text"
                    placeholder=""
                    @input="clearError('Achievement' + n)"
                  />

                  <div :id="'Achievement' + n" class="text-danger error"></div>
                </div>
              </div>

             
              <div
                class="form-group col-md-12 mt-3"
                style="margin-bottom: 30px"
              >
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>

               

                <button
                  @click.prevent="form_count++"
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Add more
                </button>
              </div>
            </form>
          </div>
        </div> -->

        <!-- <button class="accordionc">Experience</button>
        <div class="panel">
          <form
            @submit.prevent="experience"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Organization Name</label>
                <input
                  type="text"
                  v-model="org_name"
                  placeholder=""
                  @input="clearError('OrganizationName')"
                />

                <div id="OrganizationName" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Type of Organization</label>
                <input
                  type="text"
                  v-model="org_business"
                  placeholder=""
                  @input="clearError('TypeOfOrganization')"
                />

                <div id="TypeOfOrganization" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Designation</label>
                <input
                  type="text"
                  v-model="designation"
                  placeholder=""
                  @input="clearError('Designation')"
                />

                <div id="Designation" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Department</label>
                <input
                  type="text"
                  v-model="department"
                  placeholder=""
                  @input="clearError('Department')"
                />

                <div id="Department" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Starting date</label>
                <input
                  class="form-control"
                  v-model="from_date"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('StartingDate')"
                />

                <div id="StartingDate" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Ending date</label>
                <input
                  class="form-control"
                  v-model="to_date"
                  type="date"
                  placeholder="06.04.2020"
                  @input="clearError('EndingDate')"
                />

                <div id="EndingDate" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Currently working</label>
                <select
                  v-model="curr_working"
                  @change="clearError('CurrentlyWorking')"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>

                <div id="CurrentlyWorking" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Responsibilities</label>
                <input
                  type="text"
                  v-model="responsibilities"
                  placeholder=""
                  @input="clearError('Responsibilities')"
                />

                <div id="Responsibilities" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label> Area of expertise</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="area_expertise"
                    placeholder=""
                    @input="clearError('AreaOfExpertise')"
                  />

                  <div class="col-2">
                    <button
                      style="margin-left: 20px; background-color: #36a8a6"
                      class="theme-button h-100 w-100"
                      @click.prevent="add_expertises"
                    >
                      Add
                    </button>
                  </div>

                </div>

                <div v-if="expertises != ''" class="mt-2">
                  <span
                    v-for="expertise in expertises"
                    :key="expertise"
                    class="mt-2 addElement"
                  >
                    {{ expertise }}
                    <i
                      @click="removeEleexpertise(expertise)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div id="AreaOfExpertise" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Location of the organization</label>
                <input
                  type="text"
                  v-model="org_location"
                  placeholder=""
                  @input="clearError('Location')"
                />

                <div id="Location" class="text-danger error"></div>
              </div>

             
              <div
                style="padding-bottom: 50px"
                class="form-group col-lg-6 col-md-12"
              >
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 10px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Add more
                </button>
              </div>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Skills</button>
        <div class="panel">
          <form
            @submit.prevent="skills"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Name of the Skill</label>
                <input
                  type="text"
                  v-model="skill_name"
                  placeholder=""
                  @input="clearError('SkillName')"
                />

                <div id="SkillName" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Acquired category</label>
                <select
                  v-model="acquired_from"
                  @input="clearError('AcquiredFrom')"
                >
                  <option value="self">Self</option>
                  <option value="training">Training</option>
                  <option value="education">Eduction</option>
                  <option value="job">Job</option>
                </select>

                <div id="AcquiredFrom" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Description</label>
                <input
                  type="text"
                  v-model="description"
                  placeholder=""
                  @input="clearError('Description')"
                />

                <div id="Description" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-md-12" style="margin-bottom: 30px">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
     
              </div>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Training</button>
        <div class="panel">
          <form
            @submit.prevent="training"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Title</label>
                <input
                  type="text"
                  v-model="title"
                  placeholder=""
                  @input="clearError('Title')"
                />

                <div id="Title" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Type</label>
                <input
                  type="text"
                  v-model="type"
                  placeholder=""
                  @input="clearError('Type')"
                />

                <div id="Type" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label> Topics covered</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="topics_covered"
                    placeholder=""
                    @input="clearError('TopicCovered')"
                  />

                  <div class="col-2">
                    <button
                      style="margin-left: 20px; background-color: #36a8a6"
                      class="theme-button h-100 w-100"
                      @click.prevent="add_topics"
                    >
                      ADD
                    </button>
                  </div>

                </div>

                <div v-if="topics != ''" class="mt-2">
                  <span
                    v-for="topic in topics"
                    :key="topic"
                    class="mt-2 addElement"
                  >
                    {{ topic }}
                    <i
                      @click="removeEletopic(topic)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div id="TopicCovered" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Duration</label>
                <input
                  type="text"
                  v-model="duration"
                  placeholder=""
                  @input="clearError('duration')"
                />

                <div id="duration" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Year</label>
                <input
                  type="text"
                  v-model="year"
                  placeholder=""
                  @input="clearError('Year')"
                />

                <div id="Year" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Institute</label>
                <input
                  type="text"
                  v-model="institute"
                  placeholder=""
                  @input="clearError('institute')"
                />

                <div id="institute" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Location</label>
                <input
                  type="text"
                  v-model="location"
                  placeholder=""
                  @input="clearError('location')"
                />

                <div id="location" class="text-danger error"></div>
              </div>

             
              <div style="padding-bottom: 50px" class="form-group col-md-12">
                <button class="theme-btn btn-style-one">Save</button>
  
              </div>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Extracurricular Activity</button>
        <div class="panel">
          <form
            @submit.prevent="extracurricular"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Extra-Curricular Activities</label>
                <input
                  type="text"
                  v-model="activity"
                  placeholder=""
                  @input="clearError('Activities')"
                />

                <div id="Activities" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-md-12" style="margin-bottom: 30px">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
  
              </div>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Languages</button>
        <div class="panel">
          <form
            @submit.prevent="language_add"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Language name</label>
                <input
                  type="text"
                  v-model="language"
                  placeholder=""
                  @input="clearError('LanguageName')"
                />

                <div id="LanguageName" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Learned By</label>
                <select v-model="learned_by" @input="clearError('LearnedBy')">
                  <option value="native">Native</option>
                  <option value="official">Official</option>
                  <option value="training">Training</option>
                </select>

                <div id="LearnedBy" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Reading</label>
                <select v-model="reading" @input="clearError('Reading')">
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>

                <div id="Reading" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Writing</label>
                <select v-model="writing" @input="clearError('Writing')">
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>

                <div id="Writing" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Speaking</label>
                <select v-model="speaking" @input="clearError('Speaking')">
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>

                <div id="Speaking" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-md-12" style="margin-bottom: 30px">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>

              </div>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Permanent Address</button>
        <div class="panel">
          <form
            @submit.prevent="permanent_address"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Street</label>
                <input
                  type="text"
                  v-model="street"
                  placeholder=""
                  @input="clearError('Street')"
                />

                <div id="Street" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>House</label>
                <input
                  type="text"
                  v-model="house"
                  placeholder=""
                  @input="clearError('House')"
                />

                <div id="House" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>County</label>
                <input
                  type="text"
                  v-model="county"
                  placeholder=""
                  @input="clearError('County')"
                />

                <div id="County" class="text-danger error"></div>
              </div>
             
              <div class="form-group col-lg-6 col-md-12">
                <label>City</label>
                <input
                  type="text"
                  v-model="city"
                  placeholder=""
                  @input="clearError('City')"
                />

                <div id="City" class="text-danger error"></div>
              </div>
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Province</label>
                <input
                  type="text"
                  v-model="province"
                  placeholder=""
                  @input="clearError('Province')"
                />

                <div id="Province" class="text-danger error"></div>
              </div>
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Country</label>
                <input
                  type="text"
                  v-model="country"
                  placeholder=""
                  @input="clearError('Country')"
                />

                <div id="Country" class="text-danger error"></div>
              </div>
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Description</label>
                <input
                  type="text"
                  v-model="description"
                  placeholder=""
                  @input="clearError('Description')"
                />

                <div id="Description" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-md-12" style="margin-bottom: 30px">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>

              </div>
            </div>
          </form>
        </div> -->

        <!-- <button class="accordionc">Present Address</button>
        <div class="panel">
          <form
            @submit.prevent="present_address_add"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Street</label>
                <input
                  type="text"
                  v-model="street"
                  placeholder=""
                  @input="clearError('street')"
                />

                <div id="street" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>House</label>
                <input
                  type="text"
                  v-model="house"
                  placeholder=""
                  @input="clearError('house')"
                />

                <div id="house" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>County</label>
                <input
                  type="text"
                  v-model="county"
                  placeholder=""
                  @input="clearError('county')"
                />

                <div id="county" class="text-danger error"></div>
              </div>
             
              <div class="form-group col-lg-6 col-md-12">
                <label>City</label>
                <input
                  type="text"
                  v-model="city"
                  placeholder=""
                  @input="clearError('city')"
                />

                <div id="city" class="text-danger error"></div>
              </div>
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Province</label>
                <input
                  type="text"
                  v-model="province"
                  placeholder=""
                  @input="clearError('province')"
                />

                <div id="province" class="text-danger error"></div>
              </div>
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Country</label>
                <input
                  type="text"
                  v-model="country"
                  placeholder=""
                  @input="clearError('country')"
                />

                <div id="country" class="text-danger error"></div>
              </div>
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Description</label>
                <input
                  type="text"
                  v-model="description"
                  placeholder=""
                  @input="clearError('description')"
                />

                <div id="description" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-md-12" style="margin-bottom: 30px">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
       
              </div>
            </div>
          </form>
        </div> -->

        <button class="accordionc">Preference</button>
        <div class="panel">
          <form
            @submit.prevent="preference"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Job Category Choice</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="job_category_choice"
                    placeholder=""
                    @input="clearError('ChoiceCategory')"
                  />

                  <div class="col-2">
                    <button
                      style="margin-left: 20px; background-color: #36a8a6"
                      class="theme-button h-100 w-100"
                      @click.prevent="add_cho_category"
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div v-if="choice_category != ''" class="mt-2">
                  <span
                    v-for="choice_add in choice_category"
                    :key="choice_add"
                    class="mt-2 addElement"
                  >
                    {{ choice_add }}
                    <i
                      @click="removeElechoice(choice_add)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div id="ChoiceCategory" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Keywords preferences</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="keyword"
                    placeholder=""
                    @input="clearError('Keyword')"
                  />

                  <div class="col-2">
                    <button
                      style="margin-left: 20px; background-color: #36a8a6"
                      class="theme-button h-100 w-100"
                      @click.prevent="keyword_add"
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div v-if="keywords != ''" class="mt-2">
                  <span
                    v-for="keyword in keywords"
                    :key="keyword"
                    class="mt-2 addElement"
                  >
                    {{ keyword }}
                    <i
                      @click="removeElekeyword(keyword)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div id="Keyword" class="text-danger error"></div>
              </div>

              <!-- <div class="form-group col-lg-6 col-md-12">
                <label>Keywords preferences</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="keywords"
                    placeholder=""  @input="clearError('Responsibilities')"
                  />
                 
                  <button class="button_plus" @click="add_keywords"></button>
                </div>

                <div v-html="add3" class="mt-2"></div>
              </div> -->

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Preferred city list</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="pref_city"
                    placeholder=""
                    @input="clearError('CityList')"
                  />

                  <div class="col-2">
                    <button
                      style="margin-left: 20px; background-color: #36a8a6"
                      class="theme-button h-100 w-100"
                      @click.prevent="Preferred_city_add"
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div v-if="Preferred_city != ''" class="mt-2">
                  <span
                    v-for="Preferred in Preferred_city"
                    :key="Preferred"
                    class="mt-2 addElement"
                  >
                    {{ Preferred }}
                    <i
                      @click="removeElePreferred(Preferred)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div id="CityList" class="text-danger error"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Special Skills</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="special_skills"
                    placeholder=""
                    @input="clearError('SpecialSkills')"
                  />

                  <div class="col-2">
                    <button
                      style="margin-left: 20px; background-color: #36a8a6"
                      class="theme-button h-100 w-100"
                      @click.prevent="special_skill_add"
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div v-if="special_skill != ''" class="mt-2">
                  <span
                    v-for="special in special_skill"
                    :key="special"
                    class="mt-2 addElement"
                  >
                    {{ special }}
                    <i
                      @click="removeEleSpecial_skill(special)"
                      class="fa-solid fa-xmark removeElebtn"
                    ></i>
                  </span>
                </div>

                <div id="SpecialSkills" class="text-danger error"></div>
              </div>

             
              <div
                style="padding-bottom: 50px"
                class="form-group col-lg-6 col-md-12"
              >
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <!-- <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button> -->
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Reference</button>
        <div class="panel">
          <form
            @submit.prevent="reference"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
             
              <div class="form-group col-lg-6 col-md-12">
                <label>Name</label>
                <input
                  type="text"
                  v-model="name"
                  placeholder=""
                  @input="clearError('Name')"
                />

                <div id="Name" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Designation</label>
                <input
                  type="text"
                  v-model="designation"
                  placeholder=""
                  @input="clearError('designation')"
                />

                <div id="designation" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Organization</label>
                <input
                  type="text"
                  v-model="organization"
                  placeholder=""
                  @input="clearError('Organization')"
                />

                <div id="Organization" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Email</label>
                <input
                  type="email"
                  v-model="email"
                  placeholder=""
                  @input="clearError('Email')"
                />

                <div id="Email" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Relation</label>
                <input
                  type="text"
                  v-model="relation"
                  placeholder=""
                  @input="clearError('Relation')"
                />

                <div id="Relation" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label>Contact number</label>
                <input
                  type="number"
                  v-model="cell_no"
                  placeholder=""
                  @input="clearError('ContactNumber')"
                />

                <div id="ContactNumber" class="text-danger error"></div>
              </div>

             
              <div class="form-group col-lg-6 col-md-12">
                <label> Full address</label>
                <input
                  type="text"
                  v-model="address"
                  placeholder=""
                  @input="clearError('FullAddress')"
                />

                <div id="FullAddress" class="text-danger error"></div>
              </div>
             
              <div class="form-group col-md-12" style="margin-bottom: 30px">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <!-- <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button> -->
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Upload your Resume</button>
        <div class="panel">
          <!-- Dashboard -->
          <section class="user-dashboard">
            <div class="dashboard-outer">
              <div class="row">
                <div class="col-lg-4">
                  <!-- CV Manager Widget -->
                  <div class="cv-manager-widget ls-widget">
                    <div class="widget-content">
                      <div class="uploading-resume">
                        <div class="uploadButton">
                          file size is (Max 5Mb) and allowed file types are
                          (.doc, .docx, .pdf)
                          <br>
                          *Your resume must contain latest CV, latest Passport and BRP card. Example page 1 CV, page 2 passport, page 3 BRP


                          <input
                            class="form-control"
                            type="file"
                            @change="handleFileUpload($event)"
                            accept=".doc, .docx, .pdf"
                            id="upload"
                          />

                          <!-- <label class="cv-uploadButton" for="upload">
                            <span class="title">Drop or click here to select file</span>
                            <span class="text"
                              >To upload file size is (Max 5Mb) and allowed file
                              types are (.doc, .docx, .pdf)</span
                            >
                         
                            

                            <span class="uploadButton-file-name font130 "></span>
                          </label>
                         -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <button @click.prevent="addresume" class="btn btn-primary">
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </section>
          <!-- End Dashboard -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import vcookie from "vue-cookie";
import $ from "jquery";
export default {
  name: "CandidateProfileView",
  data() {
    return {
      expertises: [],
      topics: [],
      choice_category: [],
      keywords: [],
      Preferred_city: [],
      special_skill: [],
      file: "",
      photo_file: "",
      gender:null,

      form_count: 1,
      photograph_url: "",

      add: "",
      add1: "",
      add2: "",
      add3: "",
      add4: "",
      add5: "",

      // for Education

      edu_level: [],
      degree_title: [],
      major: [],
      institute: [],
      result: [],
      marks: [],
      passing_year: [],
      duration: [],
      achievement: [],
      educations: [],
    };
  },

  methods: {
    removeEleexpertise(ad) {
      this.expertises.splice(this.expertises.indexOf(ad), 1);
    },

    add_expertises() {
      if (this.area_expertise != "" || this.area_expertise != null) {
        if (this.expertises.length < 10) {
          this.expertises.push(this.area_expertise);

          this.area_expertise = null;
        } else {
          alert("expertise Max length will be 10");
        }
      } else {
        alert("expertise is empty");
      }
    },

    removeEletopic(ad) {
      this.topics.splice(this.topics.indexOf(ad), 1);
    },

    add_topics() {
      if (this.topics_covered != "" || this.topics_covered != null) {
        if (this.topics.length < 10) {
          this.topics.push(this.topics_covered);

          this.topics_covered = null;
        } else {
          alert("expertise Max length will be 10");
        }
      } else {
        alert("expertise is empty");
      }
    },

    removeElechoice(ad) {
      this.choice_category.splice(this.choice_category.indexOf(ad), 1);
    },

    add_cho_category() {
      if (this.job_category_choice != "" || this.job_category_choice != null) {
        if (this.choice_category.length < 10) {
          this.choice_category.push(this.job_category_choice);

          this.job_category_choice = null;
        } else {
          alert("expertise Max length will be 10");
        }
      } else {
        alert("expertise is empty");
      }
    },

    removeElekeyword(ad) {
      this.keywords.splice(this.keywords.indexOf(ad), 1);
    },

    keyword_add() {
      if (this.keyword != "" || this.keyword != null) {
        if (this.keywords.length < 10) {
          this.keywords.push(this.keyword);

          this.keyword = null;
        } else {
          alert("expertise Max length will be 10");
        }
      } else {
        alert("expertise is empty");
      }
    },

    removeElePreferred(ad) {
      this.Preferred_city.splice(this.Preferred_city.indexOf(ad), 1);
    },

    Preferred_city_add() {
      if (this.pref_city != "" || this.pref_city != null) {
        if (this.Preferred_city.length < 10) {
          this.Preferred_city.push(this.pref_city);

          this.pref_city = null;
        } else {
          alert("expertise Max length will be 10");
        }
      } else {
        alert("expertise is empty");
      }
    },

    removeEleSpecial_skill(ad) {
      this.special_skill.splice(this.special_skill.indexOf(ad), 1);
    },

    special_skill_add() {
      if (this.special_skills != "" || this.special_skills != null) {
        if (this.special_skill.length < 10) {
          this.special_skill.push(this.special_skills);

          this.special_skills = null;
        } else {
          alert("expertise Max length will be 10");
        }
      } else {
        alert("expertise is empty");
      }
    },

    nullcheck(element) {
      $("#" + element).html("This field is required");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    addcheck(element) {
      $("#" + element).html("Add" + " " + element);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    lengthcheck(element, value) {
      $("#" + element).html(element + " length will be max " + value);
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    filecheck(element) {
      $("#" + element).html("Upload a image");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    clearError(element) {
      $("#" + element).html("");
    },

    // handleFileUpload(event) {
    //   this.background = event.target.files[0];
    //   this.urls=URL.createObjectURL(this.background);
    //   this.clearError('image')

    // },

    add_basic_info() {
      if (this.first_name == null || this.first_name == "") {
        this.nullcheck("FirstName");
      } else if (this.last_name == null || this.last_name == "") {
        this.nullcheck("LastName");
      } else if (this.dob == null || this.dob == "") {
        this.nullcheck("DateofBirth");
      } else if (this.religion == null || this.religion == "") {
        this.nullcheck("Religion");
      } else if (this.ethnicity == null || this.ethnicity == "") {
        this.nullcheck("Ethnicity");
      } else if (this.gender == null || this.gender == "") {
        this.nullcheck("Gender");
      } else if (
        this.primary_contact_no == null ||
        this.primary_contact_no == ""
      ) {
        this.nullcheck("PrimaryNumber");
      } else if (this.nationality == null || this.nationality == "") {
        this.nullcheck("Nationality");
      } 
      // else if (this.fathers_name == null || this.fathers_name == "") {
      //   this.nullcheck("FatherName");
      // } else if (this.mothers_name == null || this.mothers_name == "") {
      //   this.nullcheck("MotherName");
      // } else if (
      //   this.secondary_contact_no == null ||
      //   this.secondary_contact_no == ""
      // ) {
      //   this.nullcheck("SecondaryContactNumber");
      // } else if (
      //   this.emergency_contact_no == null ||
      //   this.emergency_contact_no == ""
      // ) {
      //   this.nullcheck("EmergencyContactNumber");
      // } else if (this.secondary_mail == null || this.secondary_mail == "") {
      //   this.nullcheck("SecondaryEmail");
      // } else if (this.nid == null || this.nid == "") {
      //   this.nullcheck("NID");
      // } else if (this.passport_no == null || this.passport_no == "") {
      //   this.nullcheck("PassportNumber");
      // } else if (this.blood_group == null || this.blood_group == "") {
      //   this.nullcheck("BloodGroup");
      // } else if (this.weight == null || this.weight == "") {
      //   this.nullcheck("Weight");
      // } else if (this.height == null || this.height == "") {
      //   this.nullcheck("Height");
      // } 
      else {
        axios
          .post("applicant/basic_info/", {
            first_name: this.first_name,
            last_name: this.last_name,
            dob: this.dob,
            religion: this.religion,
            ethnicity: this.ethnicity,
            gender: this.gender,
            primary_contact_no: this.primary_contact_no,
            nationality: this.nationality,
            fathers_name: this.fathers_name,
            mothers_name: this.mothers_name,
            secondary_contact_no: this.secondary_contact_no,
            emergency_cont_no: this.emergency_cont_no,
            secondary_email: this.secondary_email,
            nid: this.nid,
            passport_no: this.passport_no,
            blood_group: this.blood_group,
            weight: this.weight,
            height: this.height,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);

        
              this.$emit("get_message", "Basic info saved");
             
            }
          })
          .catch((error) => {
            console.log(error.response)

this.$emit("get_message", "Already added");

          });
      }
    },

    // career_info() {
    //   if (this.objective == null || this.objective == "") {
    //     this.nullcheck("Objective");
    //   } else if (this.job_level == null || this.job_level == "") {
    //     this.nullcheck("JobLevel");
    //   } else if (this.job_nature == null || this.job_nature == "") {
    //     this.nullcheck("JobNature");
    //   } else if (this.present_salary == null || this.present_salary == "") {
    //     this.nullcheck("PresentSalary");
    //   } else if (
    //     this.present_salary_type == null ||
    //     this.present_salary_type == ""
    //   ) {
    //     this.nullcheck("SalaryType");
    //   } else if (this.expected_salary == null || this.expected_salary == "") {
    //     this.nullcheck("ExpectedSalary");
    //   } else if (
    //     this.expected_salary_type == null ||
    //     this.expected_salary_type == ""
    //   ) {
    //     this.nullcheck("ExpectedSalaryType");
    //   } else {
    //     axios
    //       .post("applicant/career_info/", {
    //         objective: this.objective,
    //         job_level: this.job_level,
    //         job_nature: this.job_nature,
    //         present_salary: this.present_salary,
    //         present_salary_type: this.present_salary_type,
    //         expected_salary: this.expected_salary,
    //         expected_salary_type: this.expected_salary_type,
    //       })
    //       .then((res) => {
    //         if (res.status == 201) {
    //           console.log(res.data);
    //           alert("Profile Created");
    //           this.add_logo();
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error.response);
    //       });
    //   }
    // },

    certification() {
      if (this.cer_name == null || this.cer_name == "") {
        this.nullcheck("CertificationName");
      } else if (this.location == null || this.location == "") {
        this.nullcheck("Institution");
      } else if (this.start_date == null || this.start_date == "") {
        this.nullcheck("StartDate");
      } else if (this.end_date == null || this.end_date == "") {
        this.nullcheck("EndDate");
      } else {
        axios
          .post("applicant/certification/", {
            cer_name: this.cer_name,
            location: this.location,
            start_date: this.start_date,
            end_date: this.end_date,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              alert("Profile Created");
              this.add_logo();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    education() {
      // for (var key of Object.keys(this.values)) {
      //         alert(key + " -> " + this.values[key])
      //     }

      for (var i = 1; i <= this.form_count; i++) {
        if (this.edu_level[i] == null || this.edu_level[i] == "") {
          this.nullcheck("EducationLevel" + i);
        } else if (this.degree_title[i] == null || this.degree_title[i] == "") {
          this.nullcheck("Degree" + i);
        } else if (this.major[i] == null || this.major[i] == "") {
          this.nullcheck("Major" + i);
        } else if (this.institute[i] == null || this.institute[i] == "") {
          this.nullcheck("Institute" + i);
        } else if (this.result[i] == null || this.result[i] == "") {
          this.nullcheck("Grade" + i);
        } else if (this.marks[i] == null || this.marks[i] == "") {
          this.nullcheck("Marks" + i);
        } else if (this.passing_year[i] == null || this.passing_year[i] == "") {
          this.nullcheck("PassingYear" + i);
        } else if (this.duration[i] == null || this.duration[i] == "") {
          this.nullcheck("Duration" + i);
        } else if (this.achievement[i] == null || this.achievement[i] == "") {
          this.nullcheck("Achievement" + i);
        } else {
          var education = {
            edu_level: this.edu_level[i],
            degree_title: this.degree_title[i],
            major: this.major[i],
            institute: this.institute[i],
            result: this.result[i],
            marks: this.marks[i],
            passing_year: this.passing_year[i],
            duration: this.duration[i],
            achievement: this.achievement[i],
          };

          axios
            .post("applicant/education/", education)
            .then((res) => {
              if (res.status == 201) {
                console.log(res.data);

                if (i == this.form_count + 1) {
                  alert("Education Added");
                  $("#edu_form")[0].reset();
                }
              }
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
      }
    },

    experience() {
      if (this.org_name == null || this.org_name == "") {
        this.nullcheck("OrganizationName");
      } else if (this.org_business == null || this.org_business == "") {
        this.nullcheck("TypeOfOrganization");
      } else if (this.designation == null || this.designation == "") {
        this.nullcheck("Designation");
      } else if (this.department == null || this.department == "") {
        this.nullcheck("Department");
      } else if (this.from_date == null || this.from_date == "") {
        this.nullcheck("StartingDate");
      } else if (this.to_date == null || this.to_date == "") {
        this.nullcheck("EndingDate");
      } else if (this.curr_working == null || this.curr_working == "") {
        this.nullcheck("CurrentlyWorking");
      } else if (this.responsibilities == null || this.responsibilities == "") {
        this.nullcheck("Responsibilities");
      } else if (!this.expertises.length > 0) {
        this.nullcheck("AreaOfExpertise");
      } else if (this.org_location == null || this.org_location == "") {
        this.nullcheck("Location");
      } else {
        axios
          .post("applicant/experience/", {
            org_name: this.org_name,
            org_business: this.org_business,
            designation: this.designation,
            department: this.department,
            from_date: this.from_date,
            to_date: this.to_date,
            curr_working: this.curr_working,
            responsibilities: this.responsibilities,
            area_expertise: this.expertises,
            org_location: this.org_location,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
             
              this.add_logo();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    skills() {
      if (this.skill_name == null || this.skill_name == "") {
        this.nullcheck("SkillName");
      } else if (this.acquired_from == null || this.acquired_from == "") {
        this.nullcheck("AcquiredFrom");
      } else if (this.description == null || this.description == "") {
        this.nullcheck("Description");
      } else {
        axios
          .post("applicant/skill/", {
            skill_name: this.skill_name,
            acquired_from: this.acquired_from,
            description: this.description,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              alert("Profile Created");
              this.add_logo();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    training() {
      if (this.title == null || this.title == "") {
        this.nullcheck("Title");
      } else if (this.type == null || this.type == "") {
        this.nullcheck("Type");
      }

      // else if(this.topics_covered == null || this.topics_covered  == ""){
      //   this.nullcheck("TopicCovered");
      // }
      else if (!this.topics.length > 0) {
        this.nullcheck("TopicCovered");
      } else if (this.duration == null || this.duration == "") {
        this.nullcheck("duration");
      } else if (this.year == null || this.year == "") {
        this.nullcheck("Year");
      } else if (this.institute == null || this.institute == "") {
        this.nullcheck("institute");
      } else if (this.location == null || this.location == "") {
        this.nullcheck("location");
      } else {
        axios
          .post("applicant/training/", {
            title: this.title,
            type: this.type,
            topics_covered: this.topics_covered,
            duration: this.duration,
            year: this.year,
            institute: this.institute,
            location: this.location,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              alert("Profile Created");
              this.add_logo();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    // Photograph

    select_photo(event) {
      this.photo_file = event.target.files[0];

      $(".photo_upload_btn").css("display", "block");

      this.photograph_url = URL.createObjectURL(this.photo_file);
    },

    photograph() {
      if (this.photo_file != "") {
        let form_data = new FormData();

        form_data.append("photograph", this.photo_file);

        axios
          .post(
            "/applicant/photograph/",

            form_data,

            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.status == 201) {
              this.$emit("get_message", "Photograph Added");
            }
          })
          .catch((error) => {

              console.log(error.response)

              this.$emit("get_message", "Already uploaded");


          
            
          });
      }
    },

    extracurricular() {
      if (this.activity == null || this.activity == "") {
        this.nullcheck("Activities");
      } else {
        axios
          .post("applicant/extracurricular/", {
            activity: this.activity,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              alert("Profile Created");
              this.add_logo();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    language_add() {
      if (this.language == null || this.language == "") {
        this.nullcheck("LanguageName");
      } else if (this.learned_by == null || this.learned_by == "") {
        this.nullcheck("LearnedBy");
      } else if (this.reading == null || this.reading == "") {
        this.nullcheck("Reading");
      } else if (this.writing == null || this.writing == "") {
        this.nullcheck("Writing");
      } else if (this.speaking == null || this.speaking == "") {
        this.nullcheck("Speaking");
      } else {
        axios
          .post("applicant/language/", {
            language: this.language,
            learned_by: this.learned_by,
            reading: this.reading,
            writing: this.writing,
            speaking: this.speaking,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              alert("Profile Created");
              this.add_logo();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    permanent_address() {
      if (this.street == null || this.street == "") {
        this.nullcheck("Street");
      } else if (this.house == null || this.house == "") {
        this.nullcheck("House");
      } else if (this.county == null || this.county == "") {
        this.nullcheck("County");
      } else if (this.city == null || this.city == "") {
        this.nullcheck("City");
      } else if (this.province == null || this.province == "") {
        this.nullcheck("Province");
      } else if (this.country == null || this.country == "") {
        this.nullcheck("Country");
      } else if (this.description == null || this.description == "") {
        this.nullcheck("Description");
      } else {
        axios
          .post("applicant/permanent_address/", {
            street: this.street,
            house: this.house,
            county: this.county,
            city: this.city,
            province: this.province,
            country: this.country,
            description: this.description,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              alert("Profile Created");
              this.add_logo();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    present_address_add() {
      if (this.street == null || this.street == "") {
        this.nullcheck("street");
      } else if (this.house == null || this.house == "") {
        this.nullcheck("house");
      } else if (this.county == null || this.county == "") {
        this.nullcheck("county ");
      } else if (this.city == null || this.city == "") {
        this.nullcheck("city");
      } else if (this.province == null || this.province == "") {
        this.nullcheck("province");
      } else if (this.country == null || this.country == "") {
        this.nullcheck("country");
      } else if (this.description == null || this.description == "") {
        this.nullcheck("description");
      } else {
        axios
          .post("applicant/present_address/", {
            street: this.street,
            house: this.house,
            county: this.county,
            city: this.city,
            province: this.province,
            country: this.country,
            description: this.description,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              alert("Profile Created");
              this.add_logo();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    preference() {
      if (!this.choice_category.length > 0) {
        this.nullcheck("ChoiceCategory");
      } else if (!this.keywords.length > 0) {
        this.nullcheck("Keyword");
      } else if (!this.Preferred_city.length > 0) {
        this.nullcheck("CityList");
      } else if (!this.special_skill.length > 0) {
        this.nullcheck("SpecialSkills");
      } else {
        axios
          .post("applicant/preference/", {
            job_category_choice: this.choice_category,
            keyword: this.keywords,
            pref_city: this.Preferred_city,
            special_skill: this.special_skill,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
            
              this.$emit("get_message", "Preference Added");
             
            }
          })
          .catch((error) => {
            console.log(error.response)

this.$emit("get_message", "Already added");
          });
      }
    },

    reference() {
      if (this.name == null || this.name == "") {
        this.nullcheck("Name");
      } else if (this.designation == null || this.designation == "") {
        this.nullcheck("designation");
      } else if (this.organization == null || this.organization == "") {
        this.nullcheck("Organization");
      } else if (this.email == null || this.email == "") {
        this.nullcheck("Email");
      } else if (this.relation == null || this.relation == "") {
        this.nullcheck("Relation");
      } else if (this.cell_no == null || this.cell_no == "") {
        this.nullcheck("ContactNumber");
      } else if (this.address == null || this.address == "") {
        this.nullcheck("FullAddress");
      } else {
        axios
          .post("applicant/reference/", {
            name: this.name,
            designation: this.designation,
            organization: this.organization,
            email: this.email,
            relation: this.relation,
            cell_no: this.cell_no,
            address: this.address,
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              //alert("Profile Created");
              this.$emit("get_message", "Reference Added");
              this.add_logo();
            }
          })
          .catch((error) => {
            console.log(error.response)

this.$emit("get_message", "Already added");
          });
      }
    },

    addexpertise() {
      if (this.expertise.length < 10) {
        this.expertise.push(this.area_expertise);
        this.add = "" + this.expertise;
      } else {
        alert("Expertise Max length will be 10");
      }
    },

    add_topics_covered() {
      if (this.topics_covered.length < 10) {
        this.topics_covered.push(this.topics_covereds);
        this.add1 = "" + this.topics_covered;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_category_choice() {
      if (this.category_choice.length < 10) {
        this.category_choice.push(this.category_choices);
        this.add2 = "" + this.category_choice;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_keywords() {
      if (this.keyword.length < 10) {
        this.keyword.push(this.keywords);
        this.add3 = "" + this.keyword;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_Preferred_city() {
      if (this.Preferred_city.length < 10) {
        this.Preferred_city.push(this.Preferred_city_list);
        this.add4 = "" + this.Preferred_city;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_special_skill() {
      if (this.special_skill.length < 10) {
        this.special_skill.push(this.special_skills);
        this.add5 = "" + this.special_skill;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    // add_basic_info1() {
    //   axios
    //     .post("applicant/basic_info/", {
    //       first_name: this.fname,
    //       last_name: this.lname,
    //       dob: this.Date_of_Birth,
    //       religion: this.religion,
    //       ethnicity: this.ethnicity,
    //       gender: this.gender,
    //       primary_contact_no: this.primary_no,
    //       nationality: this.nationality,
    //       fathers_name: this.father_name,
    //       mothers_name: this.mother_name,
    //       secondary_contact_no: this.secondary_cont_no,
    //       emergency_contact_no: this.emergency_cont_no,
    //       secondary_mail: this.secondary_mail,
    //       nid: this.nid,
    //       passport_no: this.passport_no,
    //       blood_group: this.blood_group,
    //       weight: this.weight,
    //       height: this.height,
    //     })
    //     .then((res) => {
    //       if (res.status == 201) {
    //         console.log(res.data);
    //         alert("ok");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //     });
    // },

    add_career_info() {
      axios
        .post("applicant/career_info/", {
          objective: this.objective,
          job_level: this.job_level,
          job_nature: this.job_nature,
          present_salary: this.present_salary,
          present_salary_type: this.present_salary_type,
          expected_salary: this.expected_salary,
          expected_salary_type: this.expected_salary_type,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_certification() {
      axios
        .post("applicant/certification/", {
          cer_name: this.cer_name,
          location: this.location,
          start_date: this.start_date,
          end_date: this.end_date,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_education() {
      axios
        .post("applicant/education/", {
          edu_level: this.edu_level,
          degree_title: this.degree_title,
          major: this.major,
          institute: this.institute,
          result: this.result,
          marks: this.marks,
          passing_year: this.passing_year,
          duration: this.duration,
          achievement: this.achievement,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_experience() {
      axios
        .post("applicant/experience/", {
          org_name: this.org_name,
          org_business: this.org_business,
          designation: this.designation,
          department: this.department,
          from_date: this.from_date,
          to_date: this.to_date,
          curr_working: this.curr_working,
          responsibilities: this.responsibilities,
          area_expertise: this.expertise,
          org_location: this.org_location,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_skills() {
      axios
        .post("applicant/skill/", {
          skill_name: this.skill_name,
          acquired_from: this.acquired_from,
          description: this.description,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_training() {
      axios
        .post("applicant/training/", {
          title: this.title,
          type: this.type,
          topics_covered: this.topics_covered,
          duration: this.duration,
          year: this.year,
          institute: this.institute,
          location: this.location,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_extracurricular() {
      axios
        .post("applicant/extracurricular/", {
          activity: this.activity,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_languages() {
      axios
        .post("applicant/language/", {
          language: this.language,
          learned_by: this.learned_by,
          reading: this.reading,
          writing: this.writing,
          speaking: this.speaking,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_permanent_address() {
      axios
        .post("applicant/permanent_address/", {
          street: this.street,
          house: this.house,
          county: this.county,
          city: this.city,
          province: this.province,
          country: this.country,
          description: this.description,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_present_address() {
      axios
        .post("applicant/present_address/", {
          street: this.street,
          house: this.house,
          county: this.county,
          city: this.city,
          province: this.province,
          country: this.country,
          description: this.description,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_preference() {
      axios
        .post("applicant/preference/", {
          job_category_choice: this.category_choice,
          keyword: this.keyword,
          pref_city: this.Preferred_city,
          special_skill: this.special_skill,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            this.$emit("get_message", "Preference Added");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_reference() {
      axios
        .post("applicant/reference/", {
          name: this.name,
          designation: this.designation,
          organization: this.organization,
          email: this.email,
          relation: this.relation,
          cell_no: this.cell_no,
          address: this.address,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },

    addresume() {
      if (this.file != "") {
        let formData = new FormData();
        formData.append("resume", this.file);

        axios
          .post("/applicant/resume/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              const user = JSON.parse(vcookie.get("user"));

              if (user) {
                user.user.action_capability = true;

                vcookie.set("user", JSON.stringify(user), "1");
              }

              this.$router.push({ name: "candidate-profile-single" })

              this.$emit("get_message", "Resume Added");
            }
          })
          .catch((error) => {
            console.log(error.response)

this.$emit("get_message", "Already added");
          });
      }
    },
  },

  mounted: function () {},
};
</script>

<style></style>
