<template>
  <!--Page Title-->
  <div>
    <br /><br /><br />
    <section class="page-title style-two">
      <div class="auto-container">
        <div class="row">
          <div class="col-lg-7 text-left">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="sec-title text-center">
                  <h2
                    class="text-white mt-3 text-left"
                    style="font-size: 57px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
                  >
                    Career Advice
                  </h2>

                  <!-- <div class="text text-white text-left" style="font-size: 30px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">
                Are you looking for a job!
              </div> -->

                  <div
                    class="text text-white text-left"
                    style="font-size: 25px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
                  >
                    Unlock Your Potential, Navigate Your Career
                  </div>
                </div>
              </div>
            </div>
<!-- 
            <div style="background-color: white; border-radius: 10px">
              <div class="row">
                <div
                  class="col-lg-10"
                  style="margin: 0px; padding: 0px; z-index: 10"
                >
                  <div class="form-group text-left px-5 py-3">
                    <span
                      style="color: grey"
                      class="icon flaticon-search-1"
                    ></span>
                    <input
                      style="
                        border-radius: 10px;
                        width: 80%;
                        color: grey;
                        padding-left: 20px;
                      "
                      type="text"
                      @input="showjob($event.target.value)"
                      name="field_name"
                      placeholder="Search Career Advice"
                    />

                    <span style="height: 100%; background-color: red"></span>
                  </div>
                </div>
                <div
                  id="find-job"
                  class="col-2 pt-3 text-center"
                  style="
                    background-color: #3f4a5a;
                    margin: 0px;
                    padding: 0px;
                    border-radius: 0px 10px 10px 0px;
                    color: white;
                  "
                >
                  <span class="text-center">SEARCH</span>
                </div>
              </div>
            </div> -->
          </div>
          <div class="col-lg-5">
            <img src="images/adv.png" style="height: 100%; width: 100%" />
          </div>
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Blog Single -->
    <section class="blog-single">
      <div class="auto-container">
        <div class="upper-box">
          <h3>{{career.title}}</h3>
          <ul class="post-info">
            <li>
              <!-- <span class="thumb"
                ><img src="images/ope/ope3.jpg" alt=""
              /></span> -->
              {{career.author}}
            </li>
            <li>{{format_date(career.created)}}</li>
            <!-- <li>12 Comment</li> -->
          </ul>
        </div>
      </div>
      <!-- <figure class="main-image"><img src="images/resource/blog-single.jpg" alt=""></figure> -->
      <div class="auto-container">
        <img  v-if="career.image" class="w-100" style="height: 50vh;" :src="career.image" alt="" />
     <h5 class="my-3">{{ career.abstract }}</h5>

        <div v-for="section  in career.sections" :key="section.id" >

          <h4>{{ section.title }}</h4>
        <p>
      {{section.content}}
        </p>



        </div>
        
      
        <!-- <blockquote class="blockquote-style-one mb-5 mt-5">
          <p>
            As it’s the first thing that’s shown on your CV, a personal
            statement is an essential part of standing out from the crowd. It
            explains who you are, what you’re offering, and what you’re looking
            for. Aim to prove why you’re suitable in one short and succinct
            paragraph.
          </p>
          <cite>Personal statement</cite>
        </blockquote> -->

    

        <!-- Other Options -->
        <div class="other-options">
          <div class="social-share">
            <h5>Share this post</h5>
            <a href="#" class="facebook"
              ><i class="fab fa-facebook-f"></i> Facebook</a
            >
            <a href="#" class="twitter"
              ><i class="fab fa-twitter"></i> Twitter</a
            >
            <a href="#" class="google"
              ><i class="fab fa-instagram"></i>Instagram</a
            >
          </div>

          <!-- <div class="tags">
            <a href="#">App</a>
            <a href="#">Design</a>
            <a href="#">Digital</a>
          </div> -->
        </div>

        <!-- Post Control -->
        <!-- <div class="post-control">
          <div class="prev-post">
            <span class="icon flaticon-back"></span>
            <span class="title">Previous Post</span>
            <h5><a href="#">Given Set was without from god divide rule Hath</a></h5>
          </div>
          <div class="next-post">
            <span class="icon flaticon-next"></span>
            <span class="title">Next Post</span>
            <h5><a href="#">Tree earth fowl given moveth deep lesser After</a></h5>
          </div>
        </div> -->

        <!-- <div class="comments-area">
        
          <div class="comment-box">
            <h4>Comment</h4>
            
            <div class="comment">
              <div class="user-thumb"><img src="images/resource/avatar-1.jpg" alt=""></div>
              <div class="comment-info">
                <div class="user-name">Oscar Cafeo</div>
                <div class="title">Beautiful courses</div>
              </div>
              <div class="text">Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly vehement tantaneously eel valiantly petted this along across highhandedly much.</div>
            </div>
          </div>

          <div class="comment-box">
           
            <div class="comment">
              <div class="user-thumb"><img src="images/resource/avatar-2.jpg" alt=""></div>
              <div class="comment-info">
                <div class="user-name">Alex Morgan</div>
                <div class="title">Beautiful courses</div>
              </div>
              <div class="text">Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly vehement tantaneously eel valiantly petted this along across highhandedly much.</div>
            </div>
          </div>
        </div> -->

        <!-- Comment Form -->
        <!-- <div class="comment-form default-form">
          <h4>Leave your thought here</h4>
          <form>
            <div class="row clearfix">
              <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                <label>Your Name</label>
                <input type="text" name="username" placeholder="Name" required>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                <label>Your Email</label>
                <input type="email" name="email" placeholder="Email" required>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <label>Your Comment</label>
                <textarea name="message" placeholder="Write Comment"></textarea>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <button class="theme-btn btn-style-one" type="submit" name="submit-form">Post Comment</button>
              </div>
            </div>
          </form>
        </div> -->
        <!--End Comment Form -->
      </div>
    </section>
    <!-- End Blog Single -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "CareerAdviceSingleView",
  data() {
    return {
      jobs: [
        {
          jobtitle: "Software Developer",
          segmemt: "segmemt",
          location: "Dhaka",
          time: "11",
          salary: "35k",
          type: "Fulltime",
        },
        {
          jobtitle: "Product Manager",
          segmemt: "segmemt",
          location: "Khulna",
          time: "12",
          salary: "30k",
          type: "Fulltime",
        },
        {
          jobtitle: "HR",
          segmemt: "segmemt",
          location: "Chandpur",
          time: "13",
          salary: "35k",
          type: "Parttime",
        },
        {
          jobtitle: "Accounts",
          segmemt: "segmemt",
          location: "Dhaka",
          time: "11",
          salary: "25k",
          type: "Fulltime",
        },
      ],
      career:{},

      page_number: 1,
      next: null,
      previous: null,
      count: "",
    };
  },

  methods: {
    getCareer(uuid) {
      axios
        .get("/anonymous/career_advice/" + uuid + "/", { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.career = res.data


            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("MMM DD, YYYY");
      }
    },

  },



  mounted: function () {
    var uuid = this.$route.query.uuid;

if (uuid != null) {
  this.getCareer(uuid);
  window.scrollTo(0, 0);
}
},
  
};
</script>

<style>
.news-block-two .inner-box:hover {
  background: none;
}
</style>
