<template>
  <div>
    <!--End Main Header -->

    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/candidate-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/candidate-profile"
              ><i class="la la-user-tie"></i>My Resume</a
            >
          </li>
          <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></!li-->
          <!--li class="active"><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
          <li>
            <a href="/candidate-findjob"
              ><i class="la la-user-alt"></i>Find jobs</a
            >
          </li>
          <li>
            <a href="/candidate-appliedjob"
              ><i class="la la-briefcase"></i> Applied Jobs
            </a>
          </li>

          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 250px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Cv Manager</h3>
          <div class="text">Ready to jump back in?</div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <!-- CV Manager Widget -->
            <div class="cv-manager-widget ls-widget">
              <div class="widget-title">
                <h4>Cv Manager</h4>
              </div>
              <div class="widget-content">
                <div class="uploading-resume">
                  <div class="uploadButton">
                    <input
                      class="uploadButton-input"
                      type="file"
                      name="attachments[]"
                      accept="image/*, application/pdf"
                      id="upload"
                      multiple
                    />
                    <label class="cv-uploadButton" for="upload">
                      <span class="title">Drop files here to upload</span>
                      <span class="text"
                        >To upload file size is (Max 5Mb) and allowed file types
                        are (.doc, .docx, .pdf)</span
                      >
                      <span class="theme-btn btn-style-one">Upload Resume</span>
                    </label>
                    <span class="uploadButton-file-name"></span>
                  </div>
                </div>

                <div class="files-outer">
                  <div class="file-edit-box">
                    <span class="title">Sample CV</span>
                    <div class="edit-btns">
                      <button><span class="la la-pencil"></span></button>
                      <button><span class="la la-trash"></span></button>
                    </div>
                  </div>

                  <div class="file-edit-box">
                    <span class="title">Sample CV</span>
                    <div class="edit-btns">
                      <button><span class="la la-pencil"></span></button>
                      <button><span class="la la-trash"></span></button>
                    </div>
                  </div>

                  <div class="file-edit-box">
                    <span class="title">Sample CV</span>
                    <div class="edit-btns">
                      <button><span class="la la-pencil"></span></button>
                      <button><span class="la la-trash"></span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->

    <!-- End Dashboard -->

    <!-- Copyright -->
    <!--div class="copyright-text">
      <p>© 2023 work4Hives. All Right Reserved.</p>
    </div-->
  </div>
</template>

<script>
export default {
  name: "CandidateCvManagerView",
};
</script>

<style></style>
