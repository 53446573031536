<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li class="active">
            <a href="/candidate-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/candidate-profile-single"
              ><i class="la la-user-tie"></i>My Resume</a
            >
          </li>
          <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
          <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
          <li>
            <a href="/candidate-findjob"
              ><i class="la la-user-alt"></i>Find jobs</a
            >
          </li>
          <li>
            <a href="/candidate-appliedjob"
              ><i class="la la-briefcase"></i> Applied Jobs
            </a>
          </li>
          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 250px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3 style="color: #073f3f">
            Hi <span style="color: #36a8a6"> {{ name }}</span>
          </h3>
          <!-- <div class="text">Welcome to Recruiter!</div> -->
        </div>
        <div class="row">
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item">
              <div class="left">
                <i class="icon flaticon-briefcase"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.total_application }}</h4>
                <p>Total Applied</p>
              </div>
            </div>
          </div>
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item ui-red">
              <div class="left">
                <i class="icon las la-exclamation"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.viewed_application }}</h4>
                <p>Viewed Application</p>
              </div>
            </div>
          </div>
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item ui-yellow">
              <div class="left">
                <i class="icon las la-low-vision"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.this_month_application }}</h4>
                <p>Recent Applied</p>
              </div>
            </div>
          </div>
          <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="ui-item ui-green">
              <div class="left">
                <i class="icon la la-file-invoice"></i>
              </div>
              <div class="right">
                <h4>{{ dashboardcount.profile_score }}</h4>
                <p>Profile Score</p>
              </div>
            </div>
          </div>

          <!-- <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div class="ui-item ui-green">
          <div class="left">
            <i class="icon la la-file-invoice"></i>
          </div>
          <div class="right">
            <h4>{{ dashboardcount.non_viewed_applications }}</h4>
            <p>Non Viewed</p>
          </div>
        </div>
      </div> -->
        </div>
      </div>
    </section>
    <!-- End Dashboard -->

    <!-- Copyright -->
    <!--div class="copyright-text">
      <p>© 2023 work4Hives. All Right Reserved.</p>
    </div-->
  </div>
</template>

<script>
import axios from "axios";
import vcookie from "vue-cookie";

export default {
  name: "DashboardAdminView",

  data() {
    return {
      dashboardcount: "",
      name: "",
    };
  },

  methods: {
    getName() {
      const user = JSON.parse(vcookie.get("user"));
      if (user) {
        this.name = user.user.first_name + " " + user.user.last_name;
      }
    },

    logout() {
      const user = JSON.parse(vcookie.get("user"));

      if (user) {
        vcookie.delete("user");

        this.$router.push({ name: "home" });
        location.reload();
      }
    },

    dashboard_count() {
      axios
        .get("applicant/dashboard/")
        .then((res) => {
          this.dashboardcount = res.data;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted: function () {
    this.dashboard_count();
    this.getName();
  },
};
</script>

<style></style>
