<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/candidate-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li class="active">
            <a href="/candidate-profile-single"
              ><i class="la la-user-tie"></i>My Resume</a
            >
          </li>
          <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
          <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
          <li>
            <a href="/candidate-findjob"
              ><i class="la la-user-alt"></i>Find jobs</a
            >
          </li>
          <li>
            <a href="/candidate-appliedjob"
              ><i class="la la-briefcase"></i> Applied Jobs
            </a>
          </li>

          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 250px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section v-if="basic_information" class="user-dashboard">
      <div class="dashboard-outer">
        <!-- <div class="upper-title-box">
          <h3>Application view</h3>
      
        </div> -->

        <div class="upper-box">
          <div class="auto-container">
            <!-- Candidate block Five -->
            <div class="candidate-block-five">
              <div v-if="basic_information" class="inner-box">
                <div class="content">
                  <figure v-if="photograph != null" class="image">
                    <img :src="'https://api.hive4work.co.uk/'+photograph.photograph" alt="" />
                  </figure>
                  <h4 v-if="basic_information"  class="name" style="text-align: left">
                    <a v-if="basic_information" href="#"
                      >{{ basic_information.first_name }}
                      {{ basic_information.last_name }}</a>
                    <a v-else href="#"
                    
                    > No data</a>
                  </h4>

                  <ul class="candidate-info">
                    <!-- <li class="designation">UI Designer at Invision</li> -->
                    <li  v-if="basic_information"  > 
                      <span class="icon flaticon-map-locator"></span
                      >{{ basic_information.nationality }}
                    </li>
                    <!-- <li><span class="icon flaticon-money"></span> $99 / hour</li> -->
                    <!-- <li><span class="icon flaticon-clock"></span> Member Since,Aug 19, 2020</li> -->
                    <li>
                      <button
                        @click="editProfile(all_data.uuid)"
                        data-text="Edit"
                      >
                        <span
                          class="icon la la-pen"
                          style="color: #070f0f; font-size: 180%"
                        ></span> Update
                      </button>
                    </li>
                  </ul>
                  <!-- <ul class="post-tags">
                  <li v-for="experience in experience.area_expertise" :key="experience.uuid">{{ area_expertise }}</li>
                  <li><a href="#">Design</a></li>
                  <li><a href="#">Digital</a></li>
                </ul> -->
                </div>

                <!-- <div class="btn-box">
                <a href="#" class="theme-btn btn-style-one">Download CV</a>
                <button class="bookmark-btn"><i class="flaticon-bookmark"></i></button>
              </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <!-- <div class="widget-title">
                  <h4>My Job Listings</h4>

                  
                </div> -->

                <div class="candidate-detail-outer">
                  <div class="auto-container">
                    <div class="row">
                      <div class="content-column col-lg-8 col-md-12 col-sm-12">
                        <div class="job-detail">
                          <!-- <h4>Candidates About</h4>
                <p>Hello my name is Nicole Wells and web developer from Portland. In pharetra orci dignissim, blandit mi semper, ultricies diam. Suspendisse malesuada suscipit nunc non volutpat. Sed porta nulla id orci laoreet tempor non consequat enim. Sed vitae aliquam velit. Aliquam ante erat, blandit at pretium et, accumsan ac est. Integer vehicula rhoncus molestie. Morbi ornare ipsum sed sem condimentum, et pulvinar tortor luctus. Suspendisse condimentum lorem ut elementum aliquam.</p>
                <p>Mauris nec erat ut libero vulputate pulvinar. Aliquam ante erat, blandit at pretium et, accumsan ac est. Integer vehicula rhoncus molestie. Morbi ornare ipsum sed sem condimentum, et pulvinar tortor luctus. Suspendisse condimentum lorem ut elementum aliquam. Mauris nec erat ut libero vulputate pulvinar.</p> -->

                          <!-- Resume / Work & Experience -->
                          <!-- <div class="resume-outer theme-blue">
                            <div class="upper-title">
                              <h4>Work & Experience</h4>
                            </div>
                            <div
                              v-for="experience in experience"
                              :key="experience.id"
                              class="resume-block"
                            >
                              <div class="inner">
                                <span class="name">#</span>
                                <div class="title-box">
                                  <div class="info-box">
                                    <h3>{{ experience.designation }}</h3>
                                    <span>{{ experience.org_name }}</span>
                                  </div>
                                  <div class="edit-box">
                                    <span class="year"
                                      >{{ experience.from_date }} to
                                      {{ experience.to_date }}</span
                                    >
                                  </div>
                                </div>
                                <div class="text">
                                  {{ experience.responsibilities }}
                                </div>
                              </div>
                            </div>

                            
                          </div> -->

                          <!-- Resume / Work & Experience -->
                          <!-- <div class="resume-outer theme-blue">
                            <div class="upper-title">
                              <h4>Skills</h4>
                            </div>

                            <div
                              v-for="skill in skill"
                              :key="skill.id"
                              class="resume-block"
                            >
                              <div class="inner">
                                <span class="name">#</span>
                                <div class="title-box">
                                  <div class="info-box">
                                    <h3>{{ skill.skill_name }}</h3>
                                    <span>{{ skill.acquired_from }}</span>
                                  </div>

                                </div>
                                <div class="text">{{ skill.description }}</div>
                              </div>
                            </div>
                          </div> -->

                          <!-- <div class="resume-outer theme-blue">
                            <div class="upper-title">
                              <h4>Training</h4>
                            </div>

                            <div
                              v-for="training in trainings"
                              :key="training.id"
                              class="resume-block"
                            >
                              <div class="inner">
                                <span class="name">#</span>
                                <div class="title-box">
                                  <div class="info-box">
                                    <h3>{{ training.title }}</h3>
                                    <span>{{ training.type }}</span>
                                  </div>
                                </div>
                                <ul class="list-style-four">
                                  <li>Institute: {{ training.institute }}</li>
                                  <li>Duration: {{ training.duration }}</li>
                                  <li>Year: {{ training.year }}</li>
                                  <li>Location: {{ training.location }}</li>

                                  <li>
                                    Topics covered:
                                    <ul
                                      v-for="s in training.topics_covered"
                                      :key="s"
                                    >
                                      {{
                                        s
                                      }}
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> -->

                          <!-- Resume / Education -->
                          <div class="resume-outer">
                            <div class="upper-title">
                              <h4>Preference</h4>
                            </div>
                            <!-- Resume BLock -->
                            <div class="resume-block">
                              <div class="inner">
                                <!-- <span class="name">1</span> -->
                                <div class="title-box">
                                  <div class="info-box">
                                    <!-- <h3>{{present_address.edu_level}}</h3> -->
                                    <!-- <span>{{ education.institute }}</span> -->
                                  </div>
                                  <!-- <div class="edit-box">
                          <span class="year">{{ education.passing_year }}</span>
                        </div> -->
                                </div>

                                <ul class="list-style-four">
                                  <li v-if="preferences != null">
                                    Job Category:

                                    <ul
                                      v-for="s in preferences.job_category_choice"
                                      :key="s"
                                    >
                                      {{
                                        s
                                      }}
                                    </ul>
                                  </li>

                                  <li v-if="preferences != null">
                                    Keyword:

                                    <ul
                                      v-for="s in preferences.keyword"
                                      :key="s"
                                    >
                                      {{
                                        s
                                      }}
                                    </ul>
                                  </li>

                                  <li v-if="preferences != null">
                                    Preference city:

                                    <ul
                                      v-for="s in preferences.pref_city"
                                      :key="s"
                                    >
                                      {{
                                        s
                                      }}
                                    </ul>
                                  </li>

                                  <li v-if="preferences != null">
                                    Special Skills:

                                    <ul
                                      v-for="s in preferences.special_skill"
                                      :key="s"
                                    >
                                      {{
                                        s
                                      }}
                                    </ul>
                                  </li>

                                  <!-- <li>Keyword: {{preferences.keyword}}</li> -->
                                  <!-- <li>Preference city: {{preferences.pref_city}}</li> -->
                                  <!-- <li>Special Skills: {{preferences.special_skill}}</li> -->
                                </ul>
                              </div>
                            </div>
                          </div>

                          <!-- Resume / Education -->
                          <!-- <div class="resume-outer">
                            <div class="upper-title">
                              <h4>Education</h4>
                            </div>
                            <div
                              v-for="education in education"
                              :key="education.id"
                              class="resume-block"
                            >
                              <div class="inner">
                                <span class="name">#</span>
                                <div class="title-box">
                                  <div class="info-box">
                                    <h3>{{ education.edu_level }}</h3>
                                    <span>{{ education.institute }}</span>
                                  </div>
                                  <div class="edit-box">
                                    <span class="year">{{
                                      education.passing_year
                                    }}</span>
                                  </div>
                                </div>

                                <ul class="list-style-four">
                                  <li></li>
                                  <li>Degree: {{ education.degree_title }}</li>
                                  <li>Major: {{ education.major }}</li>
                                  <li>Result: {{ education.result }}</li>
                                  <li>Marks: {{ education.marks }}</li>
                                  <li>
                                    Duration: {{ education.duration }} years
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> -->

                          <!-- Resume / Education -->
                          <!-- <div class="resume-outer">
                            <div class="upper-title">
                              <h4>Certification</h4>
                            </div>
                            <div
                              v-for="certification in certifications"
                              :key="certification.id"
                              class="resume-block"
                            >
                              <div class="inner">
                                <span class="name">#</span>
                                <div class="title-box">
                                  <div class="info-box">
                                    <h3>{{ certification.cer_name }}</h3>
                                    <span>{{ certification.location }}</span>
                                  </div>
                                  <div class="edit-box">
                                    <span class="year"
                                      >{{ certification.start_date }} to
                                      {{ certification.end_date }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->

                          <!-- Resume / Education -->
                          <div v-if="basic_information" class="resume-outer">
                            <div class="upper-title">
                              <h4>Basic Informations</h4>
                            </div>
                            <!-- Resume BLock -->
                            <div class="resume-block">
                              <div class="inner">
                                <!-- <span class="name">1</span> -->
                                <div class="title-box">
                                  <div class="info-box">
                                    <!-- <h3>{{present_address.edu_level}}</h3> -->
                                    <!-- <span>{{ education.institute }}</span> -->
                                  </div>
                                  <!-- <div class="edit-box">
                          <span class="year">{{ education.passing_year }}</span>
                        </div> -->
                                </div>

                                <ul class="list-style-four">
                                  <li></li>
                                 
                                  <li>
                                    Gender: {{ basic_information.gender }}
                                  </li>
                                  <li>
                                    Nationality:
                                    {{ basic_information.nationality }}
                                  </li>
                                  <li>
                                    Religion: {{ basic_information.religion }}
                                  </li>
                                 
                                </ul>
                              </div>
                            </div>
                          </div>

                          <!-- Resume / Education -->
                          <!-- <div class="resume-outer">
                            <div class="upper-title">
                              <h4>Present Address</h4>
                            </div>
                            <div class="resume-block">
                              <div class="inner">
                                <div class="title-box">
                                  <div class="info-box">
                                  </div>
                     
                                </div>

                                <ul
                                  v-if="present_address != null"
                                  class="list-style-four"
                                >
                                  <li></li>
                                  <li>Street: {{ present_address.street }}</li>
                                  <li>house: {{ present_address.house }}</li>
                                  <li>County: {{ present_address.county }}</li>
                                  <li>City: {{ present_address.city }}</li>
                                  <li>
                                    Province: {{ present_address.province }}
                                  </li>
                                  <li>
                                    Country: {{ present_address.country }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> -->

                          <!-- Resume / Education -->
                          <!-- <div class="resume-outer">
                            <div class="upper-title">
                              <h4>Permanent Address</h4>
                            </div>
                            <div class="resume-block">
                              <div class="inner">
                                <div class="title-box">
                                  <div class="info-box">

                                  </div>

                                </div>

                                <ul class="list-style-four">
                                  <li></li>
                                  <li>Street: {{ per_address.street }}</li>
                                  <li>house: {{ per_address.house }}</li>
                                  <li>County: {{ per_address.county }}</li>
                                  <li>City: {{ per_address.city }}</li>
                                  <li>Province: {{ per_address.province }}</li>
                                  <li>Country: {{ per_address.country }}</li>
                                </ul>
                              </div>
                            </div>
                          </div> -->

                          <!-- Resume / Education -->
                          <div v-if="references" class="resume-outer">
                            <div class="upper-title">
                              <h4>Reference</h4>
                            </div>
                            <!-- Resume BLock -->
                            <div class="resume-block">
                              <div class="inner">
                                <!-- <span class="name">1</span> -->
                                <div class="title-box">
                                  <div class="info-box">
                                    <!-- <h3>{{present_address.edu_level}}</h3> -->
                                    <!-- <span>{{ education.institute }}</span> -->
                                  </div>
                                  <!-- <div class="edit-box">
                          <span class="year">{{ education.passing_year }}</span>
                        </div> -->
                                </div>

                                <ul
                                  v-for="reference in references"
                                  :key="reference.id"
                                  class="list-style-four"
                                >
                                  <li></li>
                                  <li>Name: {{ reference.name }}</li>
                                  <li>
                                    Designation: {{ reference.designation }}
                                  </li>
                                  <li>
                                    Organization: {{ reference.organization }}
                                  </li>
                                  <li>Email: {{ reference.email }}</li>
                                  <li>relation: {{ reference.relation }}</li>
                                  <li>Phone Number: {{ reference.cell_no }}</li>
                                  <li>Address: {{ reference.address }}</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <!-- Portfolio -->
                          <div class="portfolio-outer">
                            <div class="row"></div>
                          </div>

                          <!-- Resume / Awards -->
                          <!-- <div class="resume-outer theme-yellow">
                  <div class="upper-title">
                    <h4>Awards</h4>
                  </div>
                  
                  <div class="resume-block">
                    <div class="inner">
                      <span class="name"></span>
                      <div class="title-box">
                        <div class="info-box">
                          <h3>Perfect Attendance Programs</h3>
                          <span></span>
                        </div>
                        <div class="edit-box">
                          <span class="year">2012 - 2014</span>
                        </div>
                      </div>
                      <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante<br> ipsum primis in faucibus.</div>
                    </div>
                  </div>


                  
                  <div class="resume-block">
                    <div class="inner">
                      <span class="name"></span>
                      <div class="title-box">
                        <div class="info-box">
                          <h3>Top Performer Recognition</h3>
                          <span></span>
                        </div>
                        <div class="edit-box">
                          <span class="year">2012 - 2014</span>
                        </div>
                      </div>
                      <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante<br> ipsum primis in faucibus.</div>
                    </div>
                  </div>
                </div> -->

                          <!-- Video Box -->
                        </div>
                      </div>

                      <div class="sidebar-column col-lg-4 col-md-12 col-sm-12">
                        <aside class="sidebar">
                          <div class="sidebar-widget">
                            <div class="widget-content">
                              <ul v-if="basic_information" class="job-overview">
                                <!-- <li>
                        <i class="icon icon-calendar"></i>
                        <h5>Experience:</h5>
                        <span>0-2 Years</span>
                      </li> -->

                                <li >
                                  <i class="icon icon-expiry"></i>
                                  <h5>Date of Birth:</h5>
                                  <span>{{ basic_information.dob }}</span>
                                </li>

                             

                              

                                <li>
                                  <i class="icon icon-contact"></i>
                                  <h5>Contact:</h5>
                                  <span>{{
                                    basic_information.primary_contact_no
                                  }}</span>
                                </li>

                                <li>
                                  <i class="icon icon-user-2"></i>
                                  <h5>Gender:</h5>
                                  <span>{{ basic_information.gender }}</span>
                                </li>

                              

                              </ul>
                            </div>
                          </div>

                          <!-- <div class="sidebar-widget social-media-widget">
                  <h4 class="widget-title">Social media</h4>
                  <div class="widget-content">
                    <div class="social-links">
                      <a href="#"><i class="fab fa-facebook-f"></i></a>
                      <a href="#"><i class="fab fa-twitter"></i></a>
                      <a href="#"><i class="fab fa-instagram"></i></a>
                      <a href="#"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                  </div>
                </div> -->

                          <!-- <div class="sidebar-widget">
                  
                  <h4 class="widget-title">Professional Skills</h4>
                  <div class="widget-content">
                    <ul class="job-skills">
                      <li><a href="#">app</a></li>
                      <li><a href="#">administrative</a></li>
                      <li><a href="#">android</a></li>
                      <li><a href="#">wordpress</a></li>
                      <li><a href="#">design</a></li>
                      <li><a href="#">react</a></li>
                    </ul>
                  </div>
                </div> -->

                          <!-- <div class="sidebar-widget contact-widget">
                  <h4 class="widget-title">Contact Us</h4>
                  <div class="widget-content">
                    
                    <div class="default-form">
                      
                      <form>
                        <div class="row clearfix">
                          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                            <input type="text" name="username" placeholder="Your Name" required>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                            <input type="email" name="email" placeholder="Email Address" required>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                            <textarea class="darma" name="message" placeholder="Message"></textarea>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                            <button class="theme-btn btn-style-one" type="submit" name="submit-form">Send Message</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> -->
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="widget-content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-else class="p-4" >

      <h5  >You upload A resume | update your profile with Basic informfation </h5>
      <button class="btn btn-primary mt-3"
                        @click="editProfile(all_data.uuid)"
                        data-text="Edit"
                      >
                    Update Now
                      </button>

      

    </div>
    <!-- End Dashboard -->

    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
import vcookie from "vue-cookie";

export default {
  name: "CandidateProfileSingleView",

  data() {
    return {
      basic_information: {},
      // per_address: {},
       photograph: {},
      // experience: [],
      // education: [],
      // present_address: {},
      // permanent_address: {},
      // career_info: {},
      // language: {},
      // skill: {},
      references: [],
      //trainings: [],
      preferences: {},
      //certifications: {},
      all_data: {},
    };
  },

  methods: {
    getapplicant() {
      axios
        .get("applicant/applicant_profile/")
        .then((res) => {
          this.all_data = res.data;

          this.basic_information = res.data.basic_info;
          //this.per_address = res.data.permanent_address;
          this.photograph = res.data.photograph;
          // this.experience = res.data.experience;
          // this.education = res.data.education;
          // this.present_address = res.data.present_address;
          // this.permanent_address=res.data.permanent_address
          // this.career_info = res.data.career_info;
          // this.language = res.data.language;
          // this.skill = res.data.skill;
          // this.trainings = res.data.training;
          this.references = res.data.reference;
          this.preferences = res.data.preference;
         // this.certifications = res.data.certification;

          if (res.status == 200) {
            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    applyjob() {
      if (this.jobid != "") {
        const user = JSON.parse(vcookie.get("user"));

        if (user) {
          if (user.user.role == "J") {
            axios
              .post("applicant/job_application/", {
                job_post: this.jobid,
              })
              .then((res) => {
                if (res.status == 201) {
                  alert("You applied for the job successfully !");
                }
                if (res.status == 200) {
                  alert(res.data.status);
                }
              })
              .catch((error) => {
                alert(error.response.data.status);
              });
          } else {
            this.$router.push({ name: "login" });
          }
        } else {
          this.$router.push({ name: "login" });
        }
      }
    },

    editProfile(uuid) {
      if (uuid != null) {
        this.$router.push({
          name: "candidate-profile-update",
          query: { uuid: uuid },
        }) .then(() => {
        // Wait for the navigation to complete and then reload the page.
        window.location.reload();
      })
      .catch(error => {
        // Handle any errors that occurred during the navigation.
        console.error(error);
      });
      }
    },
  },

  mounted: function () {
    this.getapplicant();
  },
};
</script>

<style></style>
