<template>
  <div class="login-section">
    <div
      class="image-layer"
      style="background-image: url(frontend/images/background/13.jpg)"
    ></div>
    <div class="outer-box">
      <div></div>
      <!-- Login Form -->
      <div class="login-form default-form">
        <div class="form-inner">
          <h3 class="text-center">LOGIN TO HIVE4WORK</h3>
          <!--Login Form-->
          <form id="login-form" @submit.prevent="login">
            <div class="form-group">
              <label>Email</label>
              <input type="email" v-model="email" placeholder="Email" @input="passworderror=''" />

              <p v-if="emailerror != ''" class="mt-1 text-danger">
                {{ emailerror }}
              </p>
            </div>

            <div class="password-group">
              <label>Password</label>
              <p class="input-group">
                <input
                  id="passInput"
                  v-model="password"
                  class="custom-input"
                  placeholder=""
                  type="password"
                  size="5"
                  aria-required="false"
                  @input="passworderror=''"
                />
                <span
                  class="input-group-addon"
                  role="button"
                  title="veiw password"
                  id="passBtn"
                >
                  <i class="bi bi-eye toggle-password" aria-hidden="true"></i>
                </span>
              </p>
              <p v-if="passworderror != ''" class="mt-1 text-danger">
                {{ passworderror }}
              </p>
            </div>

            <div class="form-group">
              <div class="field-outer">
                <div class="input-group checkboxes square">
                  <input
                    type="checkbox"
                    name="remember-me"
                    value=""
                    id="remember"
                  />
                  <label for="remember" class="remember"
                    ><span class="custom-checkbox"></span> Remember me</label
                  >
                </div>
                <a @click="show_forgot_password_box" class="pwd"
                  >Forgot password?</a
                >
              </div>
            </div>

            <div class="form-group">
              <button
                class="theme-btn btn-style-one theme-color-bg"
                type="submit"
              >
                Log In
              </button>
            </div>

            <div class="text font110">
              Don't have an account?
              <a class="theme-color" href="/register">SIGNUP</a>
            </div>
          </form>

          <div class="bottom-box">
            <div id="loader-small">
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
            </div>

            <!-- <div class="divider"><span>or</span></div>
              <div class="btn-box row">
                <div class="col-lg-6 col-md-12">
                  <a href="#" class="theme-btn social-btn-two facebook-btn"><i class="fab fa-facebook-f"></i> Log In via Facebook</a>
                </div>
                <div class="col-lg-6 col-md-12">
                  <a href="#" class="theme-btn social-btn-two google-btn"><i class="fab fa-google"></i> Log In via Gmail</a>
                </div>
              </div> -->
          </div>

          <div
            id="forgot_password_box"
            style="border-radius: 10px"
            class="p-3 border text-center"
          >
            <h5 class="text-success">Forgot Password ?</h5>

            <form @submit.prevent="send_forgot_password_code">
              <div class="row mt-3">
                <div class="col-9">
                  <div class="form-group">
                    <input
                      id="password-field"
                      v-model="forgot_email"
                      type="email"
                      placeholder="Enter registered email"
                      @input="forgot_email_error = ''"
                    />
                    <p
                      v-if="forgot_email_error != ''"
                      class="mt-1 text-danger text-left"
                    >
                      {{ forgot_email_error }}
                    </p>
                  </div>
                </div>
                <div class="col-3 p-1">
                  <button class="btn btn-lg btn-primary">
                    <i class="las la-angle-double-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div
            id="reset_password_box"
            style="border-radius: 10px"
            class="p-3 border text-center"
          >
            <h5 class="text-success">
              Password reset code has been sent to your email
            </h5>

            <form @submit.prevent="reset_password">
              <div class="row mt-3">
                <div class="col-12">
                  <div class="form-group">
                    <input
                      id="password-field"
                      v-model="reset_email"
                      type="email"
                      placeholder="Enter registered email"
                      @input="reset_email_error = ''"
                    />
                    <p
                      v-if="reset_email_error != ''"
                      class="mt-1 text-danger text-left"
                    >
                      {{ reset_email_error }}
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      id="password-field"
                      v-model="reset_code"
                      type="password"
                      placeholder="Enter reset code"
                      @input="reset_code_error = ''"
                    />
                    <p
                      v-if="reset_code_error != ''"
                      class="mt-1 text-danger text-left"
                    >
                      {{ reset_code_error }}
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      id="password-field"
                      v-model="new_password"
                      type="password"
                      placeholder="Enter a new password"
                      @input="new_password_error = ''"
                    />
                    <p
                      v-if="new_password_error != ''"
                      class="mt-1 text-danger text-left"
                    >
                      {{ new_password_error }}
                    </p>
                  </div>
                </div>
                <div class="mt-2 text-left">
                  <button class="btn btn-sm btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>

          <div
            id="otpbox"
            style="border-radius: 10px"
            class="p-3 border text-center"
          >
            <h5 class="text-success">
             Your email is not verified yet.we sent you a mail again !
            </h5>

            <form @submit.prevent="emailverification">
              <div class="row mt-3">
                <div class="col-9">
                  <div class="form-group">
                    <p class="input-group">
                      <input
                        id="passInput"
                        v-model="otp"
                        class="custom-input"
                        placeholder=""
                        type="password"
                        size="5"
                        aria-required="false"
                      />
                      <span
                        class="input-group-addon"
                        role="button"
                        title="veiw password"
                        id="passBtn"
                      >
                        <i
                          class="bi bi-eye toggle-password"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </p>
                    <p v-if="otperror != ''" class="mt-1 text-danger">
                      {{ otperror }}
                    </p>
                  </div>
                </div>
                <div class="col-3 p-1">
                  <button class="btn btn-lg btn-primary">
                    <i class="las la-angle-double-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--End Login Form -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vcookie from "vue-cookie";
import $ from "jquery";

export default {
  name: "LoginView",

  data() {
    return {
      user: {},
      emailerror: "",
      passworderror: "",

      email: "",
      password: "",
      otperror: "",
      forgot_email_error: "",
      reset_email_error: "",
      reset_code_error: "",
      new_password_error: "",
      reset_email: "",
    };
  },
  methods: {
    login() {
      var loader = document.getElementById("loader-small");
      loader.style.display = "block";

      if (this.email == "") {
        this.emailerror = "Enter email Address";
        loader.style.display = "none";
      } else if (!this.email.includes("@")) {
        this.emailerror = "Enter valid email Address";
        loader.style.display = "none";
      } else if (this.password == "") {
        this.passworderror = "Enter password";
        loader.style.display = "none";
      } else if (this.password.length < 8) {
        this.passworderror = "Enter 8 digit password";
        loader.style.display = "none";
      } else {
        axios
          .post("access/login/", {
            email: this.email,
            password: this.password,
          })
          .then((res) => {
          //  localStorage.setItem("user", JSON.stringify(res.data));
     
    


            if (res.status == 200) {

              this.user = res.data;
              var loader = document.getElementById("loader-small");
              loader.style.display = "none";

              vcookie.set("user", JSON.stringify(res.data), "1");

              if (res.data.user.role == "A") {
                const url = new URL("/admin-dashboard", window.location.origin);
                window.location.href = url.toString();
              }
              if (res.data.user.role == "R") {
                const url = new URL(
                  "/recruiter-dashboard",
                  window.location.origin
                );
                window.location.href = url.toString();
              }
              if (res.data.user.role == "J") {
                const url = new URL(
                  "/candidate-dashboard",
                  window.location.origin
                );
                window.location.href = url.toString();
              }
            }
          })
          .catch((error) => {
            
            loader.style.display = "none";

            if(error.response.status){
              if (error.response.status == 400) {
              this.resendemail();
            }

            if (error.response.status == 401) {
              this.passworderror = "User is not exist or password is incorrect";
            }


            }else{
              this.passworderror = "User is not exist or password is incorrect";

            }

         
          });
      }
    },
    resendemail() {
      if (this.email != "") {
        axios
          .post("access/verification_code/", { email: this.email })
          .then((res) => {
            if (res.status == 200) {
              var loginForm = document.getElementById("login-form");
              var loader = document.getElementById("loader-small");

              var otpbox = document.getElementById("otpbox");

              otpbox.style.display = "block";
              loginForm.style.display = "none";
              loader.style.display = "none";
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    emailverification() {
      var loader = document.getElementById("loader-small");
      loader.style.display = "block";

      if (this.otp == null) {
        this.otperror = "Enter OTP";
        loader.style.display = "none";
      } else if (this.otp.length < 6) {
        this.otperror = "Enter 6 digit OTP";
        loader.style.display = "none";
      } else {
        axios
          .post("/access/verification/", {
            email: this.email,
            verification_code: this.otp,
          })
          .then((res) => {
            if (res.status == 202) {


            
              loader.style.display = "none";

              location.reload();

              this.$emit('get_message', "Verification Successfully")

            }
          })
          .catch((error) => {
            var loader = document.getElementById("loader-small");
            loader.style.display = "none";
            this.$emit("get_message", "Your otp expired or invalid");

 
            console.log(error.response);
          });
      }
    },

    show_forgot_password_box() {
      var loginForm = document.getElementById("login-form");

      var forgotPasswordBox = document.getElementById("forgot_password_box");
      forgotPasswordBox.style.display = "block";

      loginForm.style.display = "none";
    },

    send_forgot_password_code() {
      var loader = document.getElementById("loader-small");
      loader.style.display = "block";

      if (this.forgot_email == null || this.forgot_email == "") {
        this.forgot_email_error = "Enter registered email";
      } else if (!this.forgot_email.includes("@")) {
        this.forgot_email_error = "Enter valid email Address";
      } else {
        axios
          .post("access/forgot_password/", {
            email: this.forgot_email,
          })
          .then((res) => {
            if (res.status == 200) {
              loader.style.display = "none";
              var reset_password_box =
                document.getElementById("reset_password_box");

              var forgotPasswordBox = document.getElementById(
                "forgot_password_box"
              );
              forgotPasswordBox.style.display = "none";

              reset_password_box.style.display = "block";

              this.reset_email = this.forgot_email;
            }
          })
          .catch((error) => {
            var loader = document.getElementById("loader-small");
            loader.style.display = "none";

            this.$emit("get_message", "Your email is not registered");

            
            console.log(error.response);
          });
      }
    },

    reset_password() {
      var loader = document.getElementById("loader-small");
      loader.style.display = "block";

      if (this.reset_email == null || this.reset_email == "") {
        this.reset_email_error = "Enter registered email";
        loader.style.display = "none";
      } else if (!this.reset_email.includes("@")) {
        loader.style.display = "none";
        this.reset_email_error = "Enter valid email Address";
      } else if (this.reset_code == null || this.reset_code == "") {
        this.reset_code_error = "Enter reset code";
        loader.style.display = "none";
      } else if (this.reset_code.length < 6) {
        loader.style.display = "none";
        this.reset_code_error = "Enter 6 digit reset code";
      } else if (this.new_password == null || this.new_password == "") {
        this.new_password_error = "Enter a new password";
        loader.style.display = "none";
      } else if (this.new_password.length < 8) {
        loader.style.display = "none";
        this.new_password_error = "Enter a 8 digit password";
      } else {
        axios
          .post("access/reset_password/", {
            email: this.reset_email,
            reset_code: this.reset_code,
            new_password: this.new_password,
          })
          .then((res) => {
            if (res.status == 200) {
              loader.style.display = "none";

           
          

              location.reload();
              this.$emit("get_message", "Password reset successfully");
            }
          })
          .catch((error) => {
            var loader = document.getElementById("loader-small");
            loader.style.display = "none";
            console.log(error.response);
            this.$emit("get_message", "Something went wrong");

          });
      }
    },
  },

  mounted() {
    $(".toggle-password").click(function () {
      $(this).toggleClass("bi-eye bi-eye-slash");
      var input = $(".custom-input");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  },
};
</script>

<style></style>
