<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/recruiter-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/recruiter-profile"
              ><i class="la la-user-tie"></i>Profile</a
            >
          </li>

          <li>
            <a href="/recruiter-postjob"
              ><i class="la la-paper-plane"></i>Post a New Job</a
            >
          </li>
          <li>
            <a href="/recruiter-managejob"
              ><i class="la la-briefcase"></i> Manage Jobs
            </a>
          </li>
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          <li>
            <a href="#"><i class="la la-trash"></i>Delete Profile</a>
          </li>
          <li>
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
          <!-- <li><a href="#"><i class="la la-lock"></i>Change Password</a></li> -->
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <!-- <li><a @click.prevent="logout" ><i class="la la-sign-out"></i>Logout</a></li> -->

          <!-- <li><img src="images/boy.png" style=" height: 400px;width: 100%;"></li> -->
          <div style="margin-top: 50px">
            <img
              src="images/resource/manager.jpg"
              style="height: 200px; width: 100%"
            />
          </div>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Update your profile</h3>
          <!-- <div class="text">Ready to jump back in?</div> -->
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <!-- <h4>My Profile</h4> -->
                </div>

                <div class="widget-content">
                  <form @submit.prevent="addCompany" class="default-form">
                    <div class="row">
                      <!-- Input -->
                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Company Code</label>
                        <input
                          type="text"
                          v-model="profile.code"
                          placeholder="Company Code"
                        />
                      </div> -->

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Street*</label>
                        <input
                          type="text"
                          v-model.trim="profile.street"
                          placeholder="Street"
                          @input="clearError('Street')"
                        />

                        <div id="Street" class="text-danger error"></div>
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>House</label>
                        <input
                          type="text"
                          v-model.trim="profile.house"
                          placeholder="House"
                          @input="clearError('House')"
                        />
                        <div id="House" class="text-danger error"></div>
                      </div>

                      <!-- Input -->

                      <!-- Input -->

                      <div class="form-group col-lg-6 col-md-12">
                        <label>City</label>
                        <input
                          v-model.trim="profile.city"
                          type="text"
                          name="name"
                          placeholder="City"
                          @input="clearError('City')"
                        />
                        <div id="City" class="text-danger error"></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Post Code</label>
                        <input
                          v-model="profile.province"
                          type="text"
                          name="name"
                          placeholder="Province"
                          @input="clearError('Province')"
                        />
                        <div id="Province" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>County/Administrative Unit</label>
                        <input
                          v-model="profile.county"
                          type="text"
                          name="name"
                          placeholder="County"
                          @input="clearError('County')"
                        />
                        <!-- <select
                          v-model="profile.county"
                          @change="clearError('County')"
                        >
                          <option disabled value="null">Select a option</option>
                          <option value="Kent">Kent</option>
                          <option value="Surey">Surrey</option>
                        </select> -->
                        <div id="County" class="text-danger error"></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Country</label>
                        <select
                          v-model="profile.country"
                          @change="clearError('Country')"
                        >
                          <option disabled value="null">Select a option</option>
                          <option value="UK">UK</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Australia">Australia</option>
                          <option value="USA">USA</option>
                          <option value="Canada">Canada</option>
                          
                          
                        </select>
                        <div id="Country" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Description</label>
                        <textarea
                          v-model="profile.description"
                          placeholder="Description"
                          @input="clearError('Description')"
                        ></textarea>
                        <div id="Description" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact Person Name</label>
                        <input
                          v-model="profile.con_per_name"
                          type="text"
                          name="name"
                          placeholder="Contact Person Name"
                          @input="clearError('Contact_Person_Name')"
                        />
                        <div
                          id="Contact_Person_Name"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-6">
                        <label>Contact Person Designation</label>
                        <input
                          v-model="profile.cont_per_designation"
                          type="text"
                          placeholder="Contact Person Designation"
                          @input="clearError('Contact_Person_Designation')"
                        />
                        <div
                          id="Contact_Person_Designation"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact Person Email</label>
                        <input
                          v-model="profile.cont_per_mail"
                          type="email"
                          name="name"
                          placeholder="Contact Person Email"
                          @input="clearError('Contact_Person_Email')"
                        />
                        <div
                          id="Contact_Person_Email"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact Person Mobile</label>
                        <input
                          v-model="profile.cont_per_mobile"
                          type="number"
                          name="name"
                          placeholder="Contact Person Mobile"
                          @input="clearError('Contact_Person_Mobile')"
                        />
                        <div
                          id="Contact_Person_Mobile"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization name</label>
                        <input
                          v-model="profile.org_name"
                          type="text"
                          name="name"
                          placeholder="Organization name"
                          @input="clearError('Organization_name')"
                        />
                        <div
                          id="Organization_name"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Establist Year</label>
                        <input
                          v-model="profile.est_year"
                          type="text"
                          name="name"
                          placeholder="Establist Year"
                          @input="clearError('Establist_year')"
                        />
                        <div
                          id="Establist_year"
                          class="text-danger error"
                        ></div>
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization size*</label>
                        <select
                          v-model="profile.org_size"
                          @change="clearError('Organization_size')"
                        >
                          <option disabled value="null">Select a option</option>
                          <option value="small">Small</option>
                          <option value="medium">Medium</option>
                          <option value="large">Large</option>
                        </select>
                        <div
                          id="Organization_size"
                          class="text-danger error"
                        ></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Company Registration Number*</label>
                        <input
                          v-model="profile.business_id"
                          type="text"
                          name="name"
                          placeholder="Business ID"
                          @input="clearError('Business_id')"
                        />
                        <div id="Business_id" class="text-danger error"></div>
                      </div>

                      <!-- Search Select -->
                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Multiple Select boxes </label>
                        <select data-placeholder="Categories" class="chosen-select multiple" multiple tabindex="4">
                          <option value="Banking">Banking</option>
                          <option value="Digital&Creative">Digital & Creative</option>
                          <option value="Retail">Retail</option>
                          <option value="Human Resources">Human Resources</option>
                          <option value="Management">Management</option>
                        </select>
                      </div> -->
                      <div class="form-group col-lg-12 col-md-12">
                        <label>Business Description</label>
                        <textarea
                          v-model="profile.business_description"
                          placeholder="Business Description"
                          @input="clearError('Business_Description')"
                        ></textarea>

                        <div
                          id="Business_Description"
                          class="text-danger error"
                        ></div>
                      </div>
                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Trade License</label>
                        <input
                          v-model="profile.trade_license"
                          type="text"
                          name="name"
                          placeholder="Trade License"
                          @input="clearError('Trade_License')"
                        />
                        <div id="Trade_License" class="text-danger error"></div>
                      </div> -->

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization type</label>
                        <select
                          v-model="org_type_data.id"
                          @change="clearError('Organization_type')"
                        >
                          <option disabled value="null">
                            Select organization type
                          </option>

                          <option
                            v-for="orgtype in org_types"
                            :key="orgtype.id"
                            :value="orgtype.id"
                          >
                            {{ orgtype.org_type }}
                          </option>
                        </select>
                        <div
                          id="Organization_type"
                          class="text-danger error"
                        ></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Website</label>
                        <input
                          v-model="profile.website"
                          type="text"
                          name="name"
                          placeholder="Website"
                          @input="clearError('Website')"
                        />
                        <div id="Website" class="text-danger error"></div>
                      </div>
                      

                      <div class="uploading-outer">
                        <div class="uploadButton">
                          <input
                            class="uploadButton-input"
                            type="file"
                            @change="handleFileUpload($event)"
                            accept="image/*"
                            id="upload"
                            multiple
                          />
                          <label
                            style="position: relative"
                            class="uploadButton-button ripple-effect"
                            for="upload"
                          >
                            <img
                              v-if="url"
                              :src="url"
                              style="
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 0;
                              "
                              alt=""
                            />

                            <span v-if="url == '' || url == null"
                              >Browse logo</span
                            ></label
                          >
                          <span class="uploadButton-file-name"></span>
                          <span id="Image" class="text-danger error"></span>
                        </div>
                        <!-- <button @click.prevent="add_logo"  type="submit" class="btn btn-primary" >Upload</button> -->
                      </div>

                      <!-- About Company -->

                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12">
                        <button
                          type="submit"
                          class="theme-btn theme-color-bg btn-style-one"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
    <!-- End Dashboard -->

    <!-- Copyright -->
    <!--div class="copyright-text">
  <p>© 2021 Superio. All Right Reserved.</p>
</div-->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";

export default {
  name: "EmployerProfileView",

  data() {
    return {
      org_types: [],
      logo: [],
      file: "",
      county: "",
      country: "",
      org_size: "",
      url: "",
      profile: "",
      uuide: "",
      logo_uuid: "",

      org_type_data: "",
    };
  },

  methods: {
    add_org_type() {
      axios
        .get("recruiter/org_type/")
        .then((res) => {
          if (res.status == 200) {
            this.org_types = res.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    nullcheck(element) {
      $("#" + element).html(element + " is required");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    urlcheck(element) {
      $("#" + element).html("You must provide http or https with website");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    clearError(element) {
      $("#" + element).html("");
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.url = URL.createObjectURL(this.file);
    },

    addCompany() {
      if (this.profile.street == null || this.profile.street == "") {
        this.nullcheck("Street");
      } else if (this.profile.house == null || this.profile.house == "") {
        this.nullcheck("House");
      } else if (this.profile.city == null || this.profile.city == "") {
        this.nullcheck("City");
      } else if (this.profile.province == null || this.profile.province == "") {
        this.nullcheck("Province");
      } else if (this.profile.county == null || this.profile.county == "") {
        this.nullcheck("County");
      } else if (
        this.profile.description == null ||
        this.profile.description == ""
      ) {
        this.nullcheck("Description");
      } else if (
        this.profile.con_per_name == null ||
        this.profile.con_per_name == ""
      ) {
        this.nullcheck("Contact_Person_Name");
      } else if (
        this.profile.cont_per_designation == null ||
        this.profile.cont_per_designation == ""
      ) {
        this.nullcheck("Contact_Person_Designation");
      } else if (
        this.profile.cont_per_mail == null ||
        this.profile.cont_per_mail == ""
      ) {
        this.nullcheck("Contact_Person_Email");
      } else if (
        this.profile.cont_per_mobile == null ||
        this.profile.cont_per_mobile == ""
      ) {
        this.nullcheck("Contact_Person_Mobile");
      } else if (this.profile.org_name == null || this.profile.org_name == "") {
        this.nullcheck("Organization_name");
      } else if (this.profile.est_year == null || this.profile.est_year == "") {
        this.nullcheck("Establist_year");
      } else if (this.profile.org_size == null || this.profile.org_size == "") {
        this.nullcheck("Organization_size");
      } else if (
        this.profile.business_id == null ||
        this.profile.business_id == ""
      ) {
        this.nullcheck("Business_id");
      } else if (
        this.profile.business_description == null ||
        this.profile.business_description == ""
      ) {
        this.nullcheck("Business_Description");
       } 
      //else if (
      //   this.profile.trade_license == null ||
      //   this.profile.trade_license == ""
      // ) {
      //   this.nullcheck("Trade_License");
      // } 
      else if (this.org_type_data.id == null || this.org_type_data.id == "") {
        this.nullcheck("Organization_type");
      } else if (this.profile.website == null || this.profile.website == "") {
        this.nullcheck("Website");
      } else if (!this.profile.website.includes("http")) {
        this.urlcheck("Website");
      } else if (this.profile.country == null || this.profile.country == "") {
        this.nullcheck("Country");
      } else {
        axios
          .put("recruiter/modify-profile/" + this.uuide + "/", {
           // code: this.profile.code,
            is_accepted: true,
            street: this.profile.street,
            house: this.profile.house,
            county: this.profile.county,
            city: this.profile.city,
            province: this.profile.province,
            country: this.profile.country,
            description: this.profile.description,
            con_per_name: this.profile.con_per_name,
            cont_per_designation: this.profile.cont_per_designation,
            cont_per_mail: this.profile.cont_per_mail,
            cont_per_mobile: this.profile.cont_per_mobile,
            org_name: this.profile.org_name,
            est_year: this.profile.est_year,
            org_size: this.profile.org_size,
            business_id: this.profile.business_id,
            business_description: this.profile.business_description,
            trade_license: 'not_required',
            org_type: this.org_type_data.id,
            website: this.profile.website,
          })
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              window.open("/recruiter-profile", "_self");

              this.$emit("get_message", "Profile update sucessfully");

              if (this.file != "") {
                this.update_logo();
              } else {
                this.$router.push({ name: "recruiter-profile" });
              }
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    update_logo() {
      if (this.file != "") {
        if (this.logo_uuid != "") {
          let formData = new FormData();
          formData.append("logo", this.file);

          axios
            .patch("recruiter/logo/" + this.logo_uuid + "/", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.status == 200) {
                console.log(res.data);
                alert("Logo Updated");
              }
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
      } else {
        // alert("Select a logo first")
      }
    },

    re_logo() {
      axios
        .get("recruiter/logo/")
        .then((res) => {
          this.logo = res.data;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    // Form Validation

    getProfile(uuid) {
      axios
        .get("recruiter/modify-profile/" + uuid + "/")
        .then((res) => {
          this.profile = res.data;

          this.org_type_data = res.data.org_type;

          if (res.data.logo != null) {
            this.url = res.data.logo.logo;
            this.logo_uuid = res.data.logo.uuid;
          }

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted: function () {
    this.add_org_type(), this.re_logo();
    var uuid = this.$route.query.uuid;

    if (uuid != null) {
      this.getProfile(uuid);
      this.uuide = uuid;
    }
  },
};
</script>

<style></style>
