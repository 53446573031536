<template>


<header class="main-header">
      <!-- Main box -->
      <div class="main-box">
        <!--Nav Outer -->
        <div class="nav-outer">
          <div class="logo-box">
            <div class="logo">
              <a href="/"><img src="images/logo/logo.png" alt="" title="" /></a>
            </div>
          </div>

    <nav class="nav main-menu">
      <ul class="navigation" id="navbar">
        <li><a href="/">HOME</a></li>
        <!--li><a href="contact.html">ABOUT</a></li-->
        <li><a href="/job">FIND JOBS</a></li>
        <li><a href="/postajob">POST JOB</a></li>
        <!-- <li><a href="/candidates">CV search</a></li> -->
        <li><a href="/course">COURSES</a></li>
        <li><a href="/career-advice">CAREER ADVICE</a></li>  
        <li><a href="/needhelp">NEED HELP?</a></li>
        
        
        


              <!-- Only for Mobile View -->
              <li class="mm-add-listing">
                <a href="add-listing.html" class="theme-btn btn-style-one"
                  >Job Post</a
                >
                <span>
                  <span class="contact-info">
                    <span class="phone-num"
                      ><span>Call us</span
                      ><a href="tel:+44 7495 587992">+44 7495 587992</a></span
                    >
                    <span class="address"
                      >128-130 the Grove,Startford,London,England,E15 1NS
                    </span>
                    <a href="mailto:support@hive4works.com" class="email"
                      >support@hive4work.co.uk</a
                    >
                  </span>
                  <span class="social-links">
                    <a href="#"><span class="fab fa-facebook-f"></span></a>
                    <a href="#"><span class="fab fa-twitter"></span></a>
                    <a href="#"><span class="fab fa-instagram"></span></a>
                    <a href="#"><span class="fab fa-linkedin-in"></span></a>
                  </span>
                </span>
              </li>
            </ul>
          </nav>
          <!-- Main Menu End-->
        </div>

      </div>

      <!-- Mobile Header -->
      <div class="mobile-header">
        <div class="logo">
          <a href="/"
            ><img class="mt-2" src="images/logo/logo.png" alt="" title=""
          /></a>
        </div>

        <!--Nav Box-->
        <div class="nav-outer clearfix">
          <div class="outer-box text-black">
            <!-- Login/Register -->
            <div class="login-box">
              <a class="text-black" href="/login"
                ><span style="font-size: 120%" class="las la-user mt-2"></span
              ></a>
            </div>

            <a href="#nav-mobile" class="mobile-nav-toggler text-black"
              ><span class="flaticon-menu-1"></span
            ></a>
          </div>
        </div>
      </div>

      <!-- Mobile Nav -->

      <div id="nav-mobile"></div>
    </header>


  
</template>
   
   <script>
   
   export default {
      name:"AuthHeaderView",
   
      data() {
       
       return {
       
             
   
       }
     },
   
     methods:{

   
   
     },
     mounted: function(){
           
       }
   
   }
     
   
   </script>
   
   <style>
   
   </style>