<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/recruiter-dashboard">
              <i class="la la-home"></i> Dashboard</a
            >
          </li>
          <li class="active">
            <a href="/recruiter-profile"
              ><i class="la la-user-tie"></i>Profile</a
            >
          </li>
          <li>
            <a href="/recruiter-postjob"
              ><i class="la la-paper-plane"></i>Post a New Job</a
            >
          </li>
          <li>
            <a href="/recruiter-managejob"
              ><i class="la la-briefcase"></i> Manage Jobs
            </a>
          </li>
          <!-- <li>
            <a href="/employer-allapplicants"
              ><i class="la la-file-invoice"></i> All Applicants</a
            >
          </li> -->
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          <li>
            <a href="#"><i class="la la-trash"></i>Delete Profile</a>
          </li>
          <!-- <li>
            <a href="#"
              ><i class="la la-lock"></i>Change Password</a
            >
          </li> -->
          <!-- <li>
            <a  @click.prevent="logout"><i class="la la-sign-out"></i>Logout</a>
          </li> -->
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Create your profile</h3>
          <!-- <div class="text">Ready to jump back in?</div> -->
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>My Profile</h4>
                </div>

                <div class="widget-content">
                  <form @submit.prevent="addCompany" class="default-form">
                    <div class="row">
                      <!-- Input -->
                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Company Code</label>
                        <input
                          type="text"
                          v-model="code"
                          placeholder="Company Code"
                        />
                      </div> -->

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Street*</label>
                        <input
                          type="text"
                          v-model.trim="street"
                          placeholder="Street"
                          @input="clearError('Street')"
                        />

                        <div id="Street" class="text-danger error"></div>
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>House</label>
                        <input
                          type="text"
                          v-model.trim="house"
                          placeholder="House"
                          @input="clearError('House')"
                        />
                        <div id="House" class="text-danger error"></div>
                      </div>

                      <!-- Input -->

                      <!-- Input -->

                      <div class="form-group col-lg-6 col-md-12">
                        <label>City</label>
                        <input
                          v-model.trim="city"
                          type="text"
                          name="name"
                          placeholder="City"
                          @input="clearError('City')"
                        />
                        <div id="City" class="text-danger error"></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Post Code</label>
                        <input
                          v-model="province"
                          type="text"
                          name="name"
                          placeholder="Post Code"
                          @input="clearError('Province')"
                        />
                        <div id="Province" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>County</label>
                        <input
                          v-model="county"
                          type="text"
                          name="name"
                          placeholder="County"
                          @input="clearError('County')"
                        />
                        <!-- <select v-model="county" @change="clearError('County')">
                          <option value="Kent">Kent</option>
                          <option value="Surey">Surrey</option>
                        </select> -->
                        <div id="County" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Country</label>
                        <select
                          v-model="country"
                          @change="clearError('Country')"


                        >  

                        <option disabled value=null>Select a country</option>
                          <option value="UK">UK</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Australia">Australia</option>
                          <option value="USA">USA</option>
                          <option value="Canada">Canada</option>
                        </select>
                        <div id="Country" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Description</label>
                        <textarea
                          v-model="description"
                          placeholder="Description"
                          @input="clearError('Description')"
                        ></textarea>
                        <div id="Description" class="text-danger error"></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact Person Name</label>
                        <input
                          v-model="con_p_name"
                          type="text"
                          name="name"
                          placeholder="Contact Person Name"
                          @input="clearError('Contact_Person_Name')"
                        />
                        <div
                          id="Contact_Person_Name"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-6">
                        <label>Contact Person Designation</label>
                        <input
                          v-model="cont_per_designation"
                          type="text"
                          placeholder="Contact Person Designation"
                          @input="clearError('Contact_Person_Designation')"
                        />
                        <div
                          id="Contact_Person_Designation"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact Person Email</label>
                        <input
                          v-model="con_p_mail"
                          type="text"
                          placeholder="Contact Person Email"
                          @input="clearError('Contact_Person_Email')"
                        />
                        <div
                          id="Contact_Person_Email"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact Person Mobile</label>
                        <input
                          v-model="con_p_mob"
                          type="number"
                          name="name"
                          placeholder="Contact Person Mobile"
                          @input="clearError('Contact_Person_Mobile')"
                        />
                        <div
                          id="Contact_Person_Mobile"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization name</label>
                        <input
                          v-model="org_name"
                          type="text"
                          name="name"
                          placeholder="Organization name"
                          @input="clearError('Organization_name')"
                        />
                        <div
                          id="Organization_name"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Establist Year</label>

                        <select v-model="est_year" >
                          <option disabled value=null >Select a year</option>

                          <option v-for="n in 100" :key="n" >{{1950+n}}</option>

                        


                        </select>
                      
                        <div
                          id="Establist_year"
                          class="text-danger error"
                        ></div>
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization size*</label>
                        <select
                          v-model="org_size"
                          @change="clearError('Organization_size')"
                        >
                        <option disabled value=null>Select organization size</option>
                          <option value="small">Small</option>
                          <option value="medium">Medium</option>
                          <option value="large">Large</option>
                        </select>
                        <div
                          id="Organization_size"
                          class="text-danger error"
                        ></div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Company Registration Number*</label>
                        <input
                          v-model="business_id"
                          type="text"
                          name="name"
                          placeholder="Company Registration Number"
                          @input="clearError('Business_id')"
                        />
                        <div id="Business_id" class="text-danger error"></div>
                      </div>

                      <!-- Search Select -->
                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Multiple Select boxes </label>
                        <select data-placeholder="Categories" class="chosen-select multiple" multiple tabindex="4">
                          <option value="Banking">Banking</option>
                          <option value="Digital&Creative">Digital & Creative</option>
                          <option value="Retail">Retail</option>
                          <option value="Human Resources">Human Resources</option>
                          <option value="Management">Management</option>
                        </select>
                      </div> -->
                      <div class="form-group col-lg-12 col-md-12">
                        <label>Business Description</label>
                        <textarea
                          v-model="business_description"
                          placeholder="Business Description"
                          @input="clearError('Business_Description')"
                        ></textarea>

                        <div
                          id="Business_Description"
                          class="text-danger error"
                        ></div>
                      </div>
                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Trade License</label>
                        <input
                          v-model="trade_license"
                          type="text"
                          name="name"
                          placeholder="Trade License"
                          @input="clearError('Trade_License')"
                        />
                        <div id="Trade_License" class="text-danger error"></div>
                      </div> -->

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization type</label>
                        <select
                          v-model="org_type_id"
                          @change="clearError('Organization_type')"
                        >
                          <option disabled value=null>Select organization type</option>
                          <option
                            v-for="orgtype in org_types"
                            :key="orgtype.id"
                            :value="orgtype.id"
                          >
                            {{ orgtype.org_type }}
                          </option>
                        </select>
                        <div
                          id="Organization_type"
                          class="text-danger error"
                        ></div>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Website</label>
                        <input
                          v-model="website"
                          type="text"
                          name="name"
                          placeholder="Website"
                          @input="clearError('Website')"
                        />
                        <div id="Website" class="text-danger error"></div>
                      </div>
                      

                      <div class="uploading-outer">
                        <div class="uploadButton">
                          <input
                            class="uploadButton-input"
                            type="file"
                            @change="handleFileUpload($event)"
                            accept="image/*"
                            id="upload"
                            multiple
                          />
                          <label
                            style="position: relative"
                            class="uploadButton-button ripple-effect"
                            for="upload"
                          >
                            <img
                              v-if="url"
                              :src="url"
                              style="
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 0;
                              "
                              alt=""
                            />

                            <span v-if="url == ''">Browse logo</span></label
                          >
                          <span class="uploadButton-file-name"></span>
                          <span id="Image" class="text-danger error"></span>
                        </div>
                        <!-- <button @click.prevent="add_logo"  type="submit" class="btn btn-primary" >Upload</button> -->
                      </div>

                      <!-- About Company -->

                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12">
                        <button type="submit" class="theme-btn btn-style-one">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
    <!-- End Dashboard -->

    <!-- Copyright -->
    <!--div class="copyright-text">
  <p>© 2021 Superio. All Right Reserved.</p>
</div-->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import vcookie from "vue-cookie";

export default {
  name: "EmployerProfileView",

  data() {
    return {
      org_types: [],
      logo: [],
      file: "",
      county: "",
      org_size: null,
      url: "",
      country:null,
      org_type_id:null,
      est_year:null
    };
  },

  methods: {
    add_org_type() {
      axios
        .get("recruiter/org_type/")
        .then((res) => {
          if (res.status == 200) {
            this.org_types = res.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    nullcheck(element) {
      $("#" + element).html("This field is required");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },
   emailcheck(element) {
      $("#" + element).html("Email is not valid");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    bigcheck(element) {
      $("#" + element).html("Minimum is always less than maximum");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },
    numcheck(element) {
      $("#" + element).html("This field is not valid");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },
    webcheck(element) {
      $("#" + element).html("Enter website with www");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    clearError(element) {
      $("#" + element).html("");
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.url = URL.createObjectURL(this.file);
    },

    addCompany() {
      if (this.street == null || this.street == "") {
        this.nullcheck("Street");
      } else if (this.house == null || this.house == "") {
        this.nullcheck("House");
      } else if (this.city == null || this.city == "") {
        this.nullcheck("City");
      } else if (this.province == null || this.province == "") {
        this.nullcheck("Province");
      } else if (this.county == null || this.county == "") {
        this.nullcheck("County");
      }  else if (this.country == null || this.country == "") {
        this.nullcheck("Country");
      }else if (this.description == null || this.description == "") {
        this.nullcheck("Description");
      } else if (this.con_p_name == null || this.con_p_name == "") {
        this.nullcheck("Contact_Person_Name");
      } else if (
        this.cont_per_designation == null ||
        this.cont_per_designation == ""
      ) {
        this.nullcheck("Contact_Person_Designation");
      } else if (this.con_p_mail == null || this.con_p_mail == "") {
        this.nullcheck("Contact_Person_Email");
      } else if (this.con_p_mail.includes("@") == false) {
        this.emailcheck("Contact_Person_Email");
      }
      
      else if (this.con_p_mob == null || this.con_p_mob == "") {
        this.nullcheck("Contact_Person_Mobile");
      } else if (this.con_p_mob<0) {
        this.numcheck("Contact_Person_Mobile");
      }
       else if (this.org_name == null || this.org_name == "") {
        this.nullcheck("Organization_name");
      } else if (this.est_year == null || this.est_year == "") {
        this.nullcheck("Establist_year");
      } else if (this.org_size == null || this.org_size == "") {
        this.nullcheck("Organization_size");
      } else if (this.business_id == null || this.business_id == "") {
        this.nullcheck("Business_id");
      } else if (
        this.business_description == null ||
        this.business_description == ""
      ) {
        this.nullcheck("Business_Description");
      }  else if (this.org_type_id == null || this.org_type_id == "") {
        this.nullcheck("Organization_type");
      } else if (this.website == null || this.website == "") {
        this.nullcheck("Website");
      }else if (this.website.includes("www") == false) {
        this.webcheck("Website");
      }
      
      else {

      

        axios
          .post("recruiter/create-profile/", {
            //code: this.code,
            is_accepted: true,
            street: this.street,
            house: this.house,
            county: this.county,
            city: this.city,
            province: this.province,
            country: this.country,
            description: this.description,
            con_per_name: this.con_p_name,
            cont_per_designation: this.cont_per_designation,
            cont_per_mail: this.con_p_mail,
            cont_per_mobile: this.con_p_mob,
            org_name: this.org_name,
            est_year: this.est_year,
            org_size: this.org_size,
            business_id: this.business_id,
            business_description: this.business_description,
            trade_license: 'not_required',
            org_type: this.org_type_id,
            website:this.website,
          })
          .then((res) => {
            if (res.status == 201) {
              const user = JSON.parse(vcookie.get("user"));

              if (user) {
                user.user.action_capability = true;

                vcookie.set("user", JSON.stringify(user), "1");
              }

        

            
             

              if (this.file != "") {
                this.add_logo();
              } else {
              
                this.$router.push({ name: "employer-profile" })
      

                this.$emit("get_message", "Profile created successfully");
    
               
              }


            
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    add_logo() {
      if (this.file != "") {
        let formData = new FormData();
        formData.append("logo", this.file);

        axios
          .post("recruiter/logo/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              this.$router.push({ name: "employer-profile" })
      

               this.$emit("get_message", "Profile created successfully");
  
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        // alert("Select a logo first")
      }
    },

    re_logo() {
      axios
        .get("recruiter/logo/")
        .then((res) => {
          this.logo = res.data;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    // Form Validation
  },

  mounted: function () {
    this.add_org_type(), this.re_logo();
  },
};
</script>

<style></style>
